<template>
    <div>
        <!-- <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>
                            <select v-model="Environment" v-on:change="getAllTodaysJob" style="-webkit-appearance: menulist" autofocus ref="envname">
                                <option :value="{}">Select Environment</option>
                                <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                            </select>
                            </li>
                            <li>Jobs History</li>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row> -->

        <edc-data-grid :dataList="tableList" @onHistory="onHistory" @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"
        @onReExecute="onReExecute" @onDelete="onDelete" @onDownload="onDownload"
        @ondblClick="onHistory" @onShowPD="onShowPD" @showObjectTable="onShowPD"
        @ShowObjectGraph="onGraphView" @linkCellClicked="linkCellClicked"></edc-data-grid>

        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>


        <v-dialog v-model="viewpd_dialog" max-width="800" height='600' eager persistent>
        <v-col class="EDC-Col">
          <v-card class="rounded-card">
            <v-toolbar dark dense max-height="40">
              <v-col class="EDC-Col text-md-center">PD Viewer
                <v-icon class="text-lg-left" @click="viewpd_dialog = false" style="color:#dedede;font-size:15px;float:right">fa-times-circle</v-icon>
              </v-col>
            </v-toolbar>
            <v-card-text>
            <v-row no-gutters class="EDC-Row">
              <edc-pdviewer :objectdetails="objectdetails" key="pdviewer">

              </edc-pdviewer>
            </v-row>
          </v-card-text>
          </v-card>
        </v-col>
      </v-dialog>

    </div>
</template>
<script>
import moment from 'moment'
import Breadcrumb from "../../Breadcrumbs.vue"
import config from '../../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from '../../../methods/serverCall.js';
import {SERVER_ERROR} from '../../../data/client_message.js'
import { GETJOBHISTORY, EXPORT_JOB_HISTORY_DETAILS, DELETE_JOB_DATA }from '../../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
import getUserRole from '../../../methods/GetUserRole.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import objectPDTabularView from '../../../views/objectPDTabularView.vue'
import {getReadonlyRedirectionUrl} from '../../../methods/commonFunc.js'
import {getURLByDomainByProduct} from '@/methods/domain_management.js'
import {jobTypeHeader, jobModeHeader, jobEXEType, jobStatusOnHistory} from "@/data/job_management.js"

export default {
    name: 'jobHistory',
    components:{
      'edc-pdviewer':objectPDTabularView
    },
    data: function () {
        return {
        objectdetails:{},
        viewpd_dialog:false,
        job_list: [],
        status: "all",
        status_list: [{"id": "all", "name": "All"},
          {"id": "running", "name": "Running"},
          {"id": "completed", "name": "Completed"},
          {"id": "terminated", "name": "Terminated"},
          {"id": "failed", "name": "Failed"}],
        selected_process_definition:'',
        job_failed_reason:'',
        start_date: null,
        end_date: '',
        time2: null,
        tableHeader:[
          { text: 'Id', value: 'id', align:'right', title: 'Job Id', dataType:'int'},
          { text: 'Process Doc Name', value: 'process_definition_name',  width: '150px', options:[],  title: 'Process Doc Name'},
          _.cloneDeep(jobTypeHeader),
          _.cloneDeep(jobModeHeader),
          _.cloneDeep(jobEXEType),
          _.cloneDeep(jobStatusOnHistory),
          { text: 'Plan Name', value: 'job_plan_name', options:[], title: 'Job Plan Name'},
            { text: 'Duration', value: "duration", options:[],align:'right', title: 'Duration', hideInlineFilter: true},
          {text: 'Policy Date', value: 'actual_date', options:[], title: 'Job Policy Date', dataType:'date'},
          { text: 'Processing Time', value: "total_running_time", options:[],align:'right', title: 'Job Total Processing Time in Mins', hideInlineFilter: true},
          { text: 'Start Date', value: "timezone_aware_start_date", dataType:'date',  options:[], title: 'Job Start Date'},

          { text: 'Start Time', value: "timezone_aware_start_time", options:[], title: 'Job Start Time', dataType:'time', hideInlineFilter: true},
          { text: 'End Date', value: "timezone_aware_end_date", dataType:'date', options:[], title: 'Job End Date'},
          { text: 'End Time', value: "timezone_aware_end_time", options:[], title: 'Job End Time', dataType:'time', hideInlineFilter: true},
            { text: 'Dup Found', value: 'is_execute_duplicate', options:[], title: 'Duplicate Found &/or Skipped'},
          { text: 'Initiated By', value: "created_by", cssClass:'w16',width: '140px', options:[], title: 'Job Execution Initiated By'},
          { text: 'Report', value: "report_step_available",  options:[], title: 'Report Step Available', dataType:'boolean'},

          { text: 'Ver', value: 'process_definition_revision',  width: "10px;", align:'right', title: 'Job Version', dataType:'int'},
          { text: 'Object ID', value: 'process_definition_id', width: '200px', options:[], title: 'Object ID', align:'right'},
          /*  { text: 'Details', value: "", width: '150px', options:[]},
          { text: 'Details', defaultValue:"Column Wise Details",value: "", width: '150px', options:[]}, */
        ],
          tableList: {
              headers: [],
              actions: [],
              rows: []
          },
            //  flag:true,
             snackbar:false,
             snackbartext:'',
             colorValue:'success',
             snackbartimeout: Snackbar_Default_Timeout,
             showEnvType:false,
             valid:true,
             dbTypefltrAry:[],
             nameTypefltrAry:[],
            'envTypefltrAry':[],
            'chip1':true,
            'searchEnv':"",
            'showname':false,
            'showDBType':false,
             chips: [],
             items: [],
            sorting : config.SORTING_TYPE,
            envIpJson : {
                    "filter": [],//{"column_name":"id","operator":"_eq_","value1":9008}
                    "sort": [{"coumn_name":"id","type":"desc"}],
                    "page": 1,
                    "page_size": 10
                },
            isloading:false,
            menu:false,
            edit:"",
            searchArray: {
                'Environment Type': false,
                'Name': false,
                'Database Type': false,
                'Database Location':false,
                'Action': false
            },
            perPage: 5,
            perPageArray: [5, 10, 50, 'all'],
            page: 1,
            totalPages:1,
            totalRecords:1,
            filterKey: "",
            sortingData: [],
            inputArray: {
                'Environment Type': [],
                'Name': [],
                'Database Type': [],
                'Database Location':[],
                'Action': []
            },
            sortKey: '',
            sortOrders: {
                'type': 1,
                'name': 1,
                'environment_database_type': 1,
                'databases_locations':1,
                'Action': 1
            },
            loader:false,
            EnvironmentList:[],
            Environment:{},
            userRole:{},
        }
    },
    props:{
      changedEnv:{
        type:Object
      },
      finishedJobId:{
        type:Number
      }
    },
    watch:{
      changedEnv:{
        handler(newvalue){
          this.Environment = newvalue
          this.getAllTodaysJob(this.envIpJson)
        }
      },
      finishedJobId:{
        handler(newvalue){
          if(newvalue && this.Environment && this.Environment.id){
            this.getAllTodaysJob(this.envIpJson,true)
          }
        }
      }
    },
    mounted() {
        this.tableList = this.getGridObj(this.tableHeader,'id',false)
        this.envIpJson.page_size = manageScreenDisplay()
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions = [
                    {'text':'history','key':"id", selectType:"single",index:1},
                    {'text':'re_execute','key':"showreexcute", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),cmpValue:'Y',index:2},
                    // {'text': 'onCreateJobReport', 'key': 'report_step_available',cmpValue:'Y', selectType:"single",index:3,"displayText":"Download Report"},
                    {'text': 'delete', 'key': 'id', selectType:"multiple", index:4,role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,'delete')},
                    {'text': 'showpd', 'key': 'showreexcute', cmpValue:'Y', selectType:"single", index:5, role:true}
                  ]

        if(this.tableList.paginationType == CLIENT_SIDE)
            this.envIpJson.page_size="*";
      var env_value = this.$session.get('selected_env')
      if( env_value && env_value.id != '0' && env_value.id !='all_env'){
            this.Environment = this.$session.get('selected_env')
            this.getAllTodaysJob(this.envIpJson)
        }
      getEnvironmentList(this);
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
        onCreateJobReport(record){
            let _this = this;
           this.$router.push({ name: 'SelectReportDownload', params: {
            'client_id': this.$session.get('client_id'), 'env_id': this.Environment.id, 'job_id_list': [record.id]
           }});
        },
        onDownload(downloadParams, filterArray){
            let _this = this;
            _this.loader=true;

            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "env_id": _this.Environment.id,
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filterArray
            }
            var url = config.ENGINE_API_URL+EXPORT_JOB_HISTORY_DETAILS;
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.ENGINE_API_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
            _this.loader=false;
            if(objError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = objError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          });
        },
        onCellEvent(record){
            this.onHistory(record);
        },
        filterData(filterArray,pageno){

            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            // _.set(inputJson, 'page', 1);
            // filterArray.map((obj, index)=>{
            //     obj.stringArray.map((stringObj, stringIndex)=>{
            //         if(obj.value.split('.')[1]){
            //             var value = obj.value.split('.')[1]
            //         }else{
            //             var value = obj.value.split('.')[0]
            //         }
            //         let temp = {"column":value,"value": String(stringObj.text).trim()};
            //          inputJson.filter.push(cloneDeep(temp));
            //     })
            // });
            this.getAllTodaysJob(inputJson);
        },
        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllTodaysJob(inputJson);
        },
        onPerPageChange(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllTodaysJob(inputJson);
        },
        /* addEnvironment(){
            let _this = this;
        _this.$router.push('/manageenvironment');
        }, */
        hideLoader(){
            let _this = this;
            setTimeout(function(){
                  _this.loader = false;
              },500);
        },
        getAllTodaysJob (envIpJson,hideLoader=false) {
            let inputJson = this.envIpJson;
            var env_id = this.Environment.id;
            if (!env_id)
              return false
            let _this = this;
            this.$session.set('selected_env', this.Environment)
            var status = this.status
              _this.loader = !hideLoader;
            var client_id = this.$session.get('client_id')
            var start_date = ''
            var end_date = ''

            var job_data = {
              "client_id":client_id,
              "filter": inputJson.filter,
              "page": inputJson.page,
              "page_size": inputJson.page_size,
              "sort": inputJson.sort,
              "env_id": this.Environment.id
            }
            postToServer(this, config.ENGINE_API_URL + GETJOBHISTORY, job_data
          ).then(response => {
            var data = response
            if(data){
            _this.job_list = data.result;
            var total_count = data.total
            var row_id = 0
            _this.job_list.map(function(obj){
                if(obj.is_restored || obj.job_type === "sync_up")
                  obj.showreexcute ='N'
                else
                  obj.showreexcute = 'Y'
                var CurrentState = obj.status
                obj.is_restored = cloneDeep(obj.is_restored ? "Restoration" : "Normal");

                if (obj.job_type === "sync_up" && obj.is_restored === "Normal")
                  obj.is_restored = "Sychronization"
                else if (obj.job_type === "sync_up" && obj.is_restored === "Restoration")
                  obj.is_restored = "Un-Sychronization"

              if(obj.activity_type === 'Dispose')
                obj.is_restored = "Disposal"
              else if(obj.activity_type === 'Delete Copy')
                obj.is_restored = "Delete Copy"

              obj.mode_of_execution = obj.mode_of_execution.charAt(0).toUpperCase() + obj.mode_of_execution.slice(1)
              obj.exe_type = obj.exe_type === 'on_schedule' ? "On Schedule": "On Demand"
              obj.row_id= row_id
              // obj.status_to_show = ''
              if(obj.status)
                obj.status = obj.status.charAt(0).toUpperCase() +  obj.status.slice(1)
              row_id +=1
            })
            _this.tableList.rows = _this.job_list;
            _this.tableList.total_count = total_count;
            } else {
               this.errorMsg = data.message
            }
            this.hideLoader();
          }).catch(CurrentJobError => {
            this.hideLoader();
          })
        },
        capitalizeFirstLetter(value){
            if(!value)
                return value
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        //
        onReExecute(record){
            // this.$router.push({ name: 'managejob', params: { 'process_definition_id': record.process_definition_id, 'process_doc_name': record.process_definition_name, 'activity_id':null,'environment':this.Environment, 'is_restore':false}});record.mode_of_execution
            this.$store.state.createJobParams = { 'process_definition_id': record.process_definition_id,
                'process_doc_name': record.process_definition_name,
                'activity_id':null,'environment':this.Environment, 'is_restore':false, 'routingFrom':'jobHistory','job_id':record.id};
            this.$router.push({ name: 'createJob', params: { 'process_definition_id': record.process_definition_id,
                'process_doc_name': record.process_definition_name, 'activity_id':null,'environment':this.Environment, 'is_restore':false,'is_rexecute_request':true,'exe_mode':record.mode_of_execution,'job_id':record.id}});
        },
        onDelete:function(record){
            this.snackbar = false
            let _this = this
            var job_ids = _.map(record,"id")
            var client_id = this.$session.get('client_id')
            var env_id = this.Environment.id
            var job_data = {"env_id":env_id, "client_id":client_id,
                            "deleted_from_job_id_list":true,
                            "job_id_list": job_ids}
            this.loader = true;
            postToServer(this, config.ENGINE_API_URL + DELETE_JOB_DATA, job_data
            ).then(response => {
                this.loader = false
                let inputJson = _this.envIpJson;
                _this.getAllTodaysJob(inputJson);
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = response;

            }
            ).catch(CurrentJobError => {
             this.loader = false
              this.snackbar = true
             this.colorValue = 'error'
             this.snackbartext = CurrentJobError;
            })

        },

        onHistory:function(record){
          let showSummarization = false

          if(record.is_exists_summarization_step)
            showSummarization=true

          this.$router.push({ name: 'showjobstepsresult', params: {envname:this.Environment.name,jobname: record.process_definition_name,
	          jobid: record.id,mode:record.mode_of_execution,status:record.status, type:'jobHistory', 
            job_plan_name: record.job_plan_name,isDuplicateFound:record.is_execute_duplicate,'showSummarization':showSummarization,
             isShowWorktable:record.is_show_worktable}});
        },
        onDetailedHistory:function(record){
            this.$router.push({ name: 'operationwiseresult', params: { env_id: record.execution_environment, envname:this.Environment.name,jobid: record.id,mode:record.mode_of_execution,
                    jobname: record.process_definition_name, status:record.status, type:'jobHistory' }});
        },
        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getAllTodaysJob(inputJson);
        },
        onShowGraph(record){
          this.$router.push({ name: 'livetblgraph', params: { envname:this.Environment.name,jobname: record.process_definition_name, env_id:this.Environment.id,
               jobid: record.id,status:record.status}});
        },
        onShowPD(record){
        this.objectdetails  = {'env_id':this.Environment.id,'object_id':record.process_definition_id,'object_name':record.process_definition_name,'isfromjob':true,'policy_date':record.actual_date}
        this.viewpd_dialog = true
      },
      onGraphView(record){
        // let routeData = this.$router.resolve({name: 'editprocessdef',
        //   query: { 'process_def_id': record.process_definition_id,
        //   'is_read_only': true, 'env_id':  this.Environment.id, 'object_revision': record.process_definition_revision}});
        // window.open(routeData.href, '_blank');
        let designerHostDetails =_.find(this.$session.get('applications_configuration'),['product_name','OBJECT_DESIGNER'])
        let additionalUrl = getReadonlyRedirectionUrl('PD',this.Environment,{'object_id':record.process_definition_id,'object_version':record.process_definition_revision,'object_type':'process definition'})

        let url = getURLByDomainByProduct(this, designerHostDetails,'login',additionalUrl)
        window.open(url, '_blank');
      },
      linkCellClicked(event, header,record){
        let filter={
            "column_name": "job_id",
            "column_actual_name": "job_id",
            "value1": record.id+"", // number to string
            "operator": "_eq_",
            "hideKey": true,
            "isInlineFilter": true,
            "actual_value1":record.id+"", // number to string
            "s_parentheses": "(",
            "e_parentheses": ")"
        }
        this.$router.push({ name: 'businessobjectactivitylist', params: {'filter':[filter],'isFromJobStatus':true}});
      }
    }
}
</script>
