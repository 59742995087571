<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/jobplanlist">{{ execution_env_name }}</router-link>
              </li>
              <li>
                <router-link to="/jobplanlist">Job Plan List</router-link>
              </li>
              <li>Manage Job Plan</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    
    <v-container>
      <v-card elevation-1>
        <v-col cols="12" class="EDC-Col">
          <v-row class="EDC-Row" align="center">
            <v-col cols="3" class="EDC-Col EDC-ColsSpacing">
              <v-text-field ref="jobPlanName" label="Job Plan Name" v-model="name" type="text" autofocus @blur="untitle_name"></v-text-field>
            </v-col>
            <v-col cols="3" class="EDC-EDC-ColsSpacing ">
              <v-text-field v-model="description" label="Description" :rules="descRules" required/>
            </v-col>
            <v-col cols="3" class="EDC-Col">
              <v-text-field v-model="paralleljobcount" type='number' label="Maximum Running Jobs"  @blur='validateParellelJobCount' required/>
            </v-col>
            <v-col cols="3" class="EDC-Col">
              <vc-button type="button" item-text="Save" @click.native="savePlan"/>
              <vc-button type="button" item-text="Cancel" @click.native="OnCancelBtn()"/>
            </v-col>
          </v-row>
          <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
              <edc-data-grid :dataList="tableList" @onAddJob="onAddJob"  @addJobPlan="addJobPlan" @onDragEnd="onDragEnd" @onDelete="onDelete" @onUpArrow="onUpArrow" @onDownArrow="onDownArrow" @onEdit="onEdit" @onAddMultiple="onAddMultipleJobs" @onDependencyAdd="onDependencyAdd" @onDependencyRemove="onDependencyRemove" @ondblClick='onEdit'/>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>

    <v-dialog eager v-model="showDialog" :width="dialogWidth" persistent style="overflow-y:none; background:white">
      <v-card class="rounded-card">
        <v-toolbar dark dense>
          <v-col class="text-md-center">{{dialogHeader}}
            <v-icon class="text-lg-left" style="color:#dedede; height:22px; float:right" @click="showDialog = false">fa-times-circle</v-icon>
          </v-col>
        </v-toolbar>
        <v-card-text xs12 v-if="whichDialog==='mass'">
          <v-form ref="form" lazy-validation>
            <v-row class="EDC-Row" align="center" style="padding-left:20px !important;padding-top:10px !important;">
              <v-col class="EDC-Col" cols="12">
                <v-autocomplete hide-details="auto" v-model="massValueType" :items="massAddvalueType" label="Value Type"
                />
              </v-col>
              <v-col class="EDC-Col" cols="12">
                <edc-calender label="Start Date" :input="massStartValue" style="margin-right:10px" @update="setMinDate(...arguments)"/>
              </v-col>
              <v-col class="EDC-Col" cols="12">
                <edc-calender label="End Date" :input="massEndValue" style="margin-right:10px" @update="setMaxDate(...arguments)"/>
              </v-col>
              <v-col class="EDC-Col" cols="12" style="margin-top:20px !important;">
                <vc-button type="button" item-text="Save" @click.native="CreateMassJob"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text xs12 v-if="whichDialog==='newjob'">
          <edc-job-list key="joblist" :hideBreadscrumb="true" :showAddMultiple="true" @closePopUp="getJobDetails(),showDialog=false"></edc-job-list>
        </v-card-text>
        <v-card-text xs12 v-if="whichDialog==='newjobplan'">
          <edc-job-plan-list key="jobplanlist" :currentJobPlanId="job_plan_id" :hideBreadscrumb="true" :showAddMultiple="true" @closePopUp="getJobDetails(),showDialog=false"></edc-job-plan-list>
        </v-card-text>
        <v-card-text xs12 v-if="whichDialog==='managejob'">
          <edc-manage-job :hideBreadscrumb="true" :hideHistory="true" :hideMode="true" @closePopUp="getJobDetails(),showDialog=false" :hideRunBtn="true" :hideScheduleBtn="true" :showEditJobBtn="true" :editJobObj="editJobObj" requestFor="editjobplan" :key='nextId'></edc-manage-job>
        </v-card-text>
      </v-card>    
    </v-dialog>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import Simplert from 'vue2-simplert'
import config from "../../config.json"
import Breadcrumb from "../Breadcrumbs.vue"
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { CREATE_JOB_PLAN, UPDATE_JOB_PLAN, GET_JOB_PLAN,DELETE_JOB_PLAN_FROM_JOB } from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR} from '@/data/macros.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'    
import {SERVER_ERROR} from '@/data/client_message.js'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash'
import edcjoblist from '../jobManagement/grid/processDefListForExecution.vue'
import edcjobplanlist from '../jobPlan/JobPlanList.vue'

import edcmanagejob from '../jobManagement/manageJob.vue'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  components: {
    'edc-job-list':edcjoblist,
    'edc-job-plan-list':edcjobplanlist,
    'edc-manage-job':edcmanagejob
  },
  data() {
    return {
      paralleljobcount:1,
      nextId:1,
      outlineColor: BTN_COLOR,
      colorCode: COLOR_CODE,
      editJobObj:{},
      showJobList:false,
      valid: false,
      snackbar: false,
      snackbartext: '',
      colorValue: 'error',
      snackbartimeout: Snackbar_Default_Timeout,
      loader: false,
      name: "Untitled Name",
      description: "",
      sortedArray:[],
      dyamicvalue:true,
      lastSelectedRowsId:[],
      // tableList: {
      //   headers: [
      //   // {
      //   //   text: 'Seq No',
      //   //   value: 'index_no',
      //   //   disabled: true,
      //   //   width: '10%',
      //   //   align: 'right',
      //   //   dataType:'int'
      //   // },
      //   {
      //     text: 'Process Definition Name',
      //     value: 'displayPDName',
      //     disabled: true,
      //     //width: '30%',
      //     avoidTrim:true,
      //   },
        
      //   {
      //     text: 'Policy Type',
      //     value: 'policy_type',
      //     //width: '20%',
      //   },
      //   {
      //     text: 'Job Values',
      //     value: 'display_policy',
      //     //width: '30%',
      //     hideInlineFilter:true
      //   },
      //   {
      //     text: 'Process Definition Id',
      //     value: 'process_definition_id',
      //     //width: '30%',
      //     align:'right',
      //     hideInlineFilter:true
      //   },
      //   {
      //     text: 'Object Type',
      //     value: 'object_type',
      //     //width: '20%',
      //   },
        
      //   ],
      //   actions: [{
      //     'text': 'newWidOptions',
      //     'role':true,
      //     'key': "sequence_no",index:4,isJobPlan:true},
  
      //     {
      //       'text':'edit','key':"sequence_no",selectType:'single',role:true,index:1,'displayText':'Edit Jobs'
      //     },
      //     {'text':'addmultiple','key':"sequence_no", selectType:"multiple", role:true,index:2, 'displayText':'Create Bulk Jobs'},

      //     {'text':'delete','key':"sequence_no", selectType:"multiple", role:true,index:3},
      //     {'text':'up','key':"sequence_no", selectType:"multiple", role:true,index:4,displayText:'Move Job Up'},
      //     {'text':'down','key':"sequence_no", selectType:"multiple", role:true,index:5,displayText:'Move Job Down'},
      //     {'text':'depadd','key':"sequence_no", selectType:"multiple", role:true,index:6,displayText:'Add Dependency'},
      //     {'text':'depremove','key':"sequence_no", selectType:"multiple", role:true,index:7,displayText:'Remove Dependency'},
      //         ], //if we need conditional action in row then provide key
      //         sorting_type: CLIENT_SIDE,
      //         filterType: CLIENT_SIDE,
      //         paginationType: CLIENT_SIDE,
      //         total_count: 0,
      //         rows: [],
      //         selected_rows: [],
      //         syncHeaderScroll: false,
      //         isDraggable: true,
      //         disableDefaltSorting:true,
      //         id:"",
      //         hideExport:true,
      //         hideShowColumns:true,
      //         itemkey:'sequence_no',
      //       },
            tableList: {
                headers:[],
                actions:[],
                rows:[],
            },
            headers: [
        // {
        //   text: 'Seq No',
        //   value: 'index_no',
        //   disabled: true,
        //   width: '10%',
        //   align: 'right',
        //   dataType:'int'
        // },
        {
          text: 'Process Definition Name',
          value: 'displayPDName',
          disabled: true,
          //width: '30%',
          avoidTrim:true,
        },
        
        {
          text: 'Policy Type',
          value: 'policy_type',
          //width: '20%',
        },
        {
          text: 'Job Values',
          value: 'display_policy',
          //width: '30%',
          hideInlineFilter:true
        },
        {
          text: 'Process Definition Id',
          value: 'process_definition_id',
          //width: '30%',
          align:'right',
          hideInlineFilter:true
        },
        {
          text: 'Object Type',
          value: 'object_type',
          //width: '20%',
        },
        
        ],
            descRules: [
            v => !!v || 'Description is required'
            ],
            job_plan_id:"",
            execution_environment:this.$route.params.execution_environment,
            execution_env_name:this.$route.params.execution_env_name,
            massAddvalueType:['Days','Months','Quarters','Years'],
            showDialog:false,
            dialogWidth:400,
            whichDialog:'mass',
            dialogHeader:"Add Mass Jobs",
            massValueType:'Years',
            massEndValue:"",
            massStartValue:"",
            selectedJobs:[],
          }
        },
        computed: {
          isAddJobDisabled(){
            if(this.$route.params.job_id)
              return true
            return false
          }
        },
        mounted() {
          this.tableList = this.getGridObj(this.headers, 'sequence_no', true, {showExport : false,persist_selected_rows:true})
          this.tableList.actions =  [{
          'text': 'newWidOptions',
          'role':true,
          'key': "sequence_no",index:4,isJobPlan:true,
          availableOptions:[
            {title:'Add Job', event:'onAddJob'},
		  			{title:'Add Job Plan', event:'addJobPlan'}]},
  
          {
            'text':'edit','key':"sequence_no",selectType:'single',role:true,index:1,'displayText':'Edit Jobs'
          },
          {'text':'addmultiple','key':"sequence_no", selectType:"multiple", role:true,index:2, 'displayText':'Create Bulk Jobs'},

          {'text':'delete','key':"sequence_no", selectType:"multiple", role:true,index:3},
          {'text':'up','key':"sequence_no", selectType:"multiple", role:true,index:4,displayText:'Move Job Up'},
          {'text':'down','key':"sequence_no", selectType:"multiple", role:true,index:5,displayText:'Move Job Down'},
          {'text':'depadd','key':"sequence_no", selectType:"multiple", role:true,index:6,displayText:'Add Dependency'},
          {'text':'depremove','key':"sequence_no", selectType:"multiple", role:true,index:7,displayText:'Remove Dependency'},
              ]
          this.job_plan_id = this.$route.params.job_plan_id
          if(this.$route.params.job_plan_id)
          {
            this.getJobDetails(); 
          }
          else{
            //reset state object
            this.$store.state.jobPlanDetails = {}
          }
          if (this.$refs.jobPlanName.$el)
            this.$refs.jobPlanName.$el.focus()
          else this.$refs.jobPlanName.focus()
        }, 
      methods: {
        onAddJob() {
          if(!this.job_plan_id){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = "Please Save Job Plan First";
            return;
          }
          else{
            this.showDialog = true
            this.dialogWidth=900
            this.whichDialog='newjob'
            this.dialogHeader="Add New Jobs"
            // this.routeToJobList()
          }
        },
        addJobPlan() {
          if(!this.job_plan_id){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = "Please Save Job Plan First";
            return;
          }
          else{
            this.showDialog = true
            this.dialogWidth=900
            this.whichDialog='newjobplan'
            this.dialogHeader="Add New Job Plan"
            // this.routeToJobList()
          }
        },
        OnCancelBtn(){
          this.$router.push('/jobplanlist')
        },
        getJobDetails(){
          let _this = this;  
          _this.loader = true
          _this.$nextTick(()=>{
            _this.tableList.rows = []
          })
          var job_data = {"job_plan_id": this.job_plan_id}
          let baseUrl = config.JOB_PLAN_URL;
          baseUrl = baseUrl+ GET_JOB_PLAN;
          postToServer(this, baseUrl, job_data
            ).then(response => {
              
              let lastSelectedRows = []
              _this.tableList.persist_selected_rows = false
              response.job_list.map(planObj=>{ 

                if(!planObj.indentation)
                  planObj.indentation = 0
                planObj['displayPDName'] = _this.addDependency(planObj['process_definition_name'],planObj.indentation)
               var policy_details = _.find(response.recursive_job_list_1,["id",planObj.id])
               if(policy_details && policy_details.policy_details)
                  policy_details = policy_details.policy_details
                else
                  policy_details = planObj.policy_details
               var policy_value = ''
               var policy_type = ''
               if (Array.isArray(policy_details)){
                policy_details.map(obj=>{
                 var concated_string =  obj.policy_value + ' ' + obj.value_type
                 if(obj.already_calculated)
                  concated_string = obj.actual_date + ' Actual Date'
                if(policy_value === ''){
                  policy_value =  concated_string
                }
                else{
                  policy_value =  policy_value + "," + concated_string
                }

                if(policy_type === ''){
                  policy_type = obj.policy_type
                }
                else{
                  policy_type = policy_type + ','+obj.policy_type
                }

              })
               }
               planObj.policy_type = policy_type
               planObj.display_policy = policy_value
               if(_this.lastSelectedRowsId.indexOf(planObj.index_no)>-1){
                lastSelectedRows.push(_.cloneDeep(planObj)) 
              }
            })
              _this.lastSelectedRowsId = []
               _this.$nextTick(()=>{
                _this.tableList.rows = _.cloneDeep(response.job_list);
              })
              if(lastSelectedRows.length>0){
                _this.tableList.persist_selected_rows = true
                _this.tableList.selectedRows = _.cloneDeep(lastSelectedRows)
              }
              _this.$store.state.jobPlanDetails = response;
              _this.name = response.plan_name;
              _this.job_plan_id= response.id;
              _this.sortedArray = _.cloneDeep(response.job_list)
              _this.description = response.description;
              if(response.parallel_job_count)
                _this.paralleljobcount = response.parallel_job_count
              this.loader=false;
            }).catch(CurrentJobError => {
              this.loader=false;
              this.snackbar = true;
              this.colorValue = 'error'
              this.snackbartext = CurrentJobError;
            })
          },
          pushTableRow(object){
            let client_id = this.$session.get('client_id');
            let temp = {
              "sequence_no": this.tableList.rows.length,
              "client_id": client_id,
              "job_json": object
            }
            this.tableList.rows.push(temp);    
          },
          onDragEnd(rows){
            this.sortedArray = rows;
          },
          savePlan() {
            let _this = this;  
            var job_data = this.getPlanInputData();
            console.log(job_data)
            let baseUrl = config.JOB_PLAN_URL;
            if(this.job_plan_id){
              baseUrl = baseUrl+ UPDATE_JOB_PLAN;
            }else{
              baseUrl = baseUrl+CREATE_JOB_PLAN;
            }
            postToServer(this, baseUrl, job_data
              ).then(response => {
                //    this.$nextTick(function () {
                //        _this.tableList.rows = response.job_plan_details;
                //    })
                _this.tableList.rows=[];

                response.job_list.map(planObj=>{
                  _this.tableList.rows.push(planObj);
                })
                _this.$store.state.jobPlanDetails = response;
                console.log(_this.$store.state.jobPlanDetails)
                _this.sortedArray = response.job_list;
                // _this.$router.push({name:'jobplanlist'});
                this.job_plan_id = response.id;
                this.snackbar = true;
                this.colorValue = 'success'
                this.snackbartext = 'Details Saved successfully';
                _this.getJobDetails()
                _this.showDialog = false
               // this.loader=false;
             }).catch(CurrentJobError => {
              debugger
              this.snackbar = true;
              this.colorValue = 'error'
              this.snackbartext = CurrentJobError;
            })
           },
           getPlanInputData(){
            let client_id = this.$session.get('client_id');
            let data = {
              "plan_name": this.name,
              "client_id": client_id,
              "execution_environment": this.execution_environment,
              "execution_env_name": this.execution_env_name,
              "job_list": [],
              "id":this.job_plan_id,
              'description': this.description,
              "parallel_job_count": this.paralleljobcount
            }
            this.sortedArray.map((obj, index)=>{
              obj.sequence_no = index+1;
              data.job_list.push(_.cloneDeep(obj));
            })
            console.log(data)
            return data;  
          },
          onCellEvent() {

          },
          untitle_name() {
            if (!this.name || !this.name.trim()) {
              this.name = 'Untitled Name';
            }
          },
          routeToJobList() {
            this.$store.state.jobPlanDetails.job_plan_id = this.job_plan_id;
            this.$store.state.jobPlanDetails.name = this.name;
            this.$store.state.jobPlanDetails.description = this.description;
            this.$store.state.jobPlanDetails.job_list = this.sortedArray;
            this.$router.push({
              name: 'ProcessDefListExecution',
              'params': {
                previousUrl: 'jobPlan',
                id:this.job_plan_id,
                job_plan_name:this.name,
                execution_environment: this.execution_environment,
                execution_env_name: this.execution_env_name,
              }
            });
          },
          onDelete(record,mass_jobs){
            let _this = this; 
            let baseUrl = config.JOB_PLAN_URL; 
            baseUrl = baseUrl + DELETE_JOB_PLAN_FROM_JOB;
            var job_ids = _.map(record,"id")
            var delete_data = {"job_plan_id": record[0].job_plan_id,"job_plan_job_ids":job_ids}
            postToServer(this, baseUrl, delete_data
              ).then(response => {
                // _this.$router.push({name:'jobplanlist'});
                // this.job_plan_id = response.id;
                
                if(mass_jobs)
                  _this.AddMultipleJobs(job_ids,mass_jobs)
                else{
                  this.snackbar = true;
                  this.colorValue = 'success'
                  this.snackbartext = 'Job deleted successfully';
                  // // savePlan() function called to arrange sequence number. If user delete any job from middle then sequnce number should get rearranged. For example if we have job plan with sequnce number 1 to 10 and user delete 5th job then sequnce number should get rearrange as 1 to 9.
                  // _this.savePlan(); 
                  _this.getJobDetails()
                }
                //var jobs = _.cloneDeep(_this.tableList.rows)
                //var indexOfJob = _.findIndex(jobs,"id",record.id)
                //if(indexOfJob >=0)
                //  jobs.splice(indexOfJob,1)
                //_this.tableList.rows = jobs
               // this.loader=false;
             }).catch(CurrentJobError => {
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError.message;
             })

           },

           onEdit(record){
            if(record.is_mass_job)
              return
            this.$store.state.createJobParams={ 'process_definition_id': record.process_definition_id, 
            'process_doc_name': record.process_definition_name, 'activity_id':null,
            'environment':this.Environment, 'is_restore':false};
            this.editJobObj = {}
            this.nextId++
            this.showDialog = true
            this.dialogWidth=800
            this.whichDialog="managejob"
            this.dialogHeader ="Edit Job"

            this.editJobObj={
              id:this.job_plan_id,
              job_plan_name:this.name,
              execution_environment: this.execution_environment,
              execution_env_name: this.execution_env_name,
              process_definition_id:record.process_definition_id,
              process_doc_name:record.process_definition_name,
              job_plan_id:this.$route.params.job_plan_id,
              job_sequence:record.sequence_no
            }
            // this.$router.push({
            //   name: 'createJob',
            //   'params': {
            //     previousUrl: 'jobPlan',
                // id:this.job_plan_id,
                // job_plan_name:this.name,
                // execution_environment: this.execution_environment,
                // execution_env_name: this.execution_env_name,
                // process_definition_id:record.process_definition_id,
                // process_doc_name:record.process_definition_name,
                // job_plan_id:this.$route.params.job_plan_id,
                // job_sequence:record.sequence_no
            //   }
            // });
          },
          onAddMultipleJobs(records){
            // first check all selected job has policies or not
            if(records.length < 1)
              return

            for(let i=0;i<records.length;i++){
              if(!records[i].policy_details || records[i].policy_details.length === 0){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'All selected jobs should have policy';
                return
              }
            }

            this.selectedJobs = []
            this.selectedJobs = records
            this.showDialog = true
            this.dialogWidth=400
            this.whichDialog="mass"
            this.dialogHeader ="Add Mass Jobs"
          },
          setMaxDate(param){
            this.massEndValue = param; 
          },
          setMinDate(param){
            this.massStartValue = param; 
          },
          CreateMassJob(){
            var _this = this



        // 1) Validation -1 : Required field validation
        if(!this.massStartValue || !this.massEndValue){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'All value required';
          return false
        }

        // 30 is max limit eg. 30 days,30 months, 30 quarters, 30 years,
        /* Based on massValueType we need to perform validation */

        // 2) Validation-2 start date should be less than end date
        var start_date = moment(this.massStartValue,'YYYY-MM-DD').toDate()
        var end_date = moment(this.massEndValue,'YYYY-MM-DD').toDate()
        if(start_date > end_date){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Start date should be less than equal to end date';
          return false
        }

        // end date should be less all selected jobs policies default date
        for (var i = 0; i < this.selectedJobs.length; i++) {
         var policies = this.selectedJobs[i].policy_details

         for (var j=0;j<policies.length;j++) {
          if(end_date > moment(policies[j].actual_date).toDate()){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = 'Selected end date should be less than actual policy dates of selected policies';
            return
          }
        }
      }

        // calculate day difference
        // var diffTime = Math.abs(end_date.getTime() - start_date.getTime());
        // var diffDays =  Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        var diffDays = moment(this.massEndValue,'YYYY-MM-DD').diff(moment(this.massStartValue,'YYYY-MM-DD'),'days')

        // 3)Validation-3 if massValueType & day diff validation
        if(this.massValueType == 'Days' && diffDays > 30){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Day different should be less than 30 days';
          return false
        }
        if(this.massValueType == 'Months' && diffDays > 30*30){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Months diffrent should be less than 30 months';
          return false
        }
        if(this.massValueType == 'Quarters' && diffDays > 30*120){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Quarters diffrent should be less than 30 quarters';
          return false
        }
        if(this.massValueType == 'Years' && diffDays > 30*365){
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Years diffrent should be less than 30 years';
          return false
        }
        
        // now requirement is calculate the date based on user specification. Now need to consider fiscal year. This requirement given date is 27 may 2019
        var mass_job_json = []
        var unit_value_to_add = 1
        var unit = this.massValueType.toLowerCase()
        if(this.massValueType == 'Days' || this.massValueType == 'Months' || this.massValueType == 'Years'){
          unit_value_to_add = 1
        }
        else if(this.massValueType == 'Quarters'){
          unit = 'months'
          unit_value_to_add = 3
        }
        else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Value type not found';
          return false
        }
        _this.loader = true
        while (start_date <= end_date){
          _.forEach(_this.selectedJobs,function(obj){
            var new_obj = _.cloneDeep(obj)
            var policies = new_obj.policy_details
            for(var i =0 ;i < policies.length;i++){
              policies[i].already_calculated = true
              policies[i].actual_date = moment(start_date).format('YYYY-MM-DD')
              if(policies[i].parameter)
                delete policies[i].parameter
            } // for end
            new_obj['is_mass_job'] = true
            delete new_obj['id']
            new_obj['policy_details'] = policies
            mass_job_json.push(new_obj)

          })
          start_date = moment(start_date).add(unit_value_to_add,unit)
        } //while end
        console.log(mass_job_json)

        // now delete all previously selected jobs
        _this.onDelete(this.selectedJobs,mass_job_json)
      },
      AddMultipleJobs(deleted_job_ids,new_jobs){
        let _this = this
        this.loader = true; 
        var job_plan_data = cloneDeep(this.$store.state.jobPlanDetails)
        for (var i = 0; i < deleted_job_ids.length; i++) {
          var obj_index = _.findIndex(job_plan_data.job_list,["id",deleted_job_ids[i]])
          if(obj_index > -1)
          {
            job_plan_data.job_list.splice(obj_index,1)
          }
        }

        for (var i = 0; i < new_jobs.length; i++) {
          job_plan_data.job_list.push(new_jobs[i])
        }

        // this.$store.state.jobPlanDetails.job_list.push(cloneDeep(job_data))

        postToServer(_this, config.JOB_PLAN_URL +'/update_job_plan', job_plan_data).then(response => {
          _this.showDialog = false
          _this.getJobDetails()
        }).catch(CurrentJobError => {
            // this.$store.state.jobPlanDetails.job_list = job_list
            this.loader = false; 
            console.log(CurrentJobError)
            this.snackbar = true;
            this.colorValue = 'error'
            this.snackbartext = CurrentJobError;
            _this.getJobDetails()
          })
      },
      onUpArrow(records){
        this.snackbar = false
        this.lastSelectedRowsId = []
        if(!records.length)
          return
        let isSequentialRecordsSelected = this.checkSequentialRecordsSelected(records)
        if(!isSequentialRecordsSelected){
          this.snackbartext = 'Please select rows sequentially'
          this.snackbar = true
          this.colorValue = 'error'
          return
        }
        var _this = this
        let rowdata = _.cloneDeep(_this.tableList.rows)
        let indexOfFirst = records[0].index_no - 1
        let newrecords = _.cloneDeep(records)
        if(indexOfFirst <=0)
          return
        this.loader = true
        let inserAt = indexOfFirst - 1
        for(let i=0;i<newrecords.length;i++){
          rowdata = this.removeFromList(rowdata,newrecords[i])
          rowdata.splice(inserAt,0,newrecords[i])
          inserAt++
        }
        rowdata = this.resetIndentations(_.cloneDeep(rowdata))
        this.tableList.persist_selected_rows = false
        this.tableList.selectedRows = []
        this.tableList.rows = rowdata
        _this.sortedArray = rowdata
        _this.savePlan()
      },
      onDownArrow(records){
        this.snackbar = false
        this.lastSelectedRowsId = []
        if(!records.length)
          return
        let isSequentialRecordsSelected = this.checkSequentialRecordsSelected(records)
        if(!isSequentialRecordsSelected){
          this.snackbartext = 'Please select rows sequentially'
          this.snackbar = true
          this.colorValue = 'error'
          return
        }
        var _this = this
        let rowdata = _.cloneDeep(_this.tableList.rows)
        let indexOfFirst = records[0].index_no

        // here logic is littlebit different. instead of pushing selected rows down, we will push next first non selected row (after selected one) to above of first selected row. so insert at will be indexOfFirst-1.
        if(indexOfFirst === rowdata.length)
          return
        let inserAt = indexOfFirst - 1

        let rowToInsertIndex = records[records.length-1].index_no // no need to do minus, we are referening index here directly
        let rowToInsert = rowdata[rowToInsertIndex]
        let newrecords = _.cloneDeep(records)
        this.loader = true
        rowdata = this.removeFromList(rowdata,rowToInsert)
        rowdata.splice(inserAt,0,rowToInsert)
        // now need to fetch index_no from the records becuase auto selection
        this.lastSelectedRowsId = []
        for(var i=0;i < newrecords.length ;i++)
         this.lastSelectedRowsId.push(newrecords[i].index_no+1)
       rowdata = this.resetIndentations(_.cloneDeep(rowdata))
       this.tableList.persist_selected_rows = false
       this.tableList.selectedRows = []
       this.tableList.rows = rowdata
       _this.sortedArray = rowdata
       _this.savePlan()
     },
      checkSequentialRecordsSelected(records){
        for(var i=0;i<records.length;i++){
          if(i===0)
            continue
          if(records[i-1].index_no+1 != records[i].index_no)
            return false
        }
        return true
      },
      removeFromList(list,item){
        let index = item.index_no - 1
        this.lastSelectedRowsId.push(item.index_no-1)
        list.splice(index,1)
        return list
      },
      addDependency(name,indentation=0){
        for (var i = 0; i < indentation; i++) {
          name = '    '+name
        }
        return name
      },
      onDependencyAdd(records){
        this.manageDependency(records,'add')
        // _this.sortedArray = rowdata
        // _this.savePlan()
      },
      onDependencyRemove(records){
        this.manageDependency(records,'remove')
      },
      manageDependency(records,action){
        let _this = this
        let rowdata = _.cloneDeep(_this.tableList.rows)
        for (var i = 0; i < records.length; i++) {
          if(!records[i].indentation)
            records[i]['indentation'] = 0
          if(action === 'add'){
            // validate add indentation
            if(this.isInvalidAddIndentation(records[i],rowdata))
              return 
            records[i].indentation = records[i].indentation + 1 
          }
          else
          records[i].indentation = records[i].indentation - 1
        
          if(records[i].indentation < 0)
            records[i].indentation = 0
          records[i]['displayPDName'] = this.addDependency(records[i]['process_definition_name'],records[i].indentation)
          let inserAt = records[i]['index_no'] - 1
          rowdata = this.removeFromList(rowdata,records[i])
          rowdata.splice(inserAt,0,records[i])
        }

        this.tableList.persist_selected_rows = false
        this.tableList.selectedRows = []
        this.lastSelectedRowsId = []
        this.tableList.rows = rowdata
        _this.sortedArray = rowdata
        _this.savePlan()
      },
      isInvalidAddIndentation(record,dataList){
        // indentation not applied for first row
        if(record.index_no === 1)
          return true

        // if record.indentation > 0 then we will check previous record indentation.if Previous record < current record indentation dont allow.
        let index_of_previous = record.index_no - 1
        let previous_obj =_.find(dataList,['index_no',index_of_previous])
        if(!previous_obj)
          return true
        if(previous_obj.indentation < record.indentation)
          return true

        return false
      },
      validateParellelJobCount(){
        if(!this.paralleljobcount || isNaN(this.paralleljobcount) || parseInt(this.paralleljobcount) < 1)
          this.paralleljobcount = 1
      },
      resetIndentations(records){
        // Each row should contain the 1 of 3 indentatation rule as follow 
        // 1) Indentation = 0.
        // 2) Indentation should same as a previous column.
        // 3) Indentation should be +1 of parent.
        for(let i=0;i<records.length;i++){
          if(i===0){
            records[i].indentation = 0
            continue
          }
          if(records[i].indentation === 0)
            continue
          if(records[i].indentation === records[i-1].indentation)
            continue
          records[i].indentation = records[i-1].indentation+1
        }
        return records
      }
    }
  }
  </script>

  <style scoped>
  .customCheckbox >>> label{
    top: 5px !important;
  }

  .EDC-VCardJobPlan {
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  </style>