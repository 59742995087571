<template>
  <div>
    <v-row class="EDC-Row">
      <!-- <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" @change="getCompletedPlanList" autofocus style="-webkit-appearance: menulist">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Job Plan History List</li>
            </ul>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>

    <edc-data-grid :dataList="tableList" @onHistory="onHistory" @onDownload="onDownload" @ondblClick="onHistory" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import {JOB_PLAN_HISTORY, EXPORT_JOB_PLAN_HISTORY} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import getUserRole from '@/methods/GetUserRole.js'
import {SCHEDULER_SERVICE,SCHEDULE_ACTION,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from '@/data/macros.js'
import config from '../../config.json'
import {getEnvironmentList} from '../../methods/EnvironmentList.js'
import cloneDeep from 'lodash/cloneDeep';
import {SERVER_ERROR} from '@/data/client_message.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {manageScreenDisplay} from '@/methods/special.js'

export default {
    name: 'JobPlanHistory',

    data: function () {
        return {
            EnvironmentList: [],
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            calendarDetails: this.$store.state.calendarDetails,
            calenderList: [],
            loader:false,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            // tableList: {
            //     headers: [{
            //             text: 'Id',
            //             value: 'id',
            //             disabled: true,
            //             width: '10%',
            //             align:'right',
            //             title: 'Job Plan Id',
            //             dataType:'int'
            //         },
            //         {
            //             text: 'Name',
            //             value: 'plan_name',
            //             disabled: true,
            //             width: '20%',
            //             title: 'Job Plan Name'
            //         },
            //          {
            //             text: 'Mode',
            //             value: 'display_mode_of_execution',
            //             disabled: true,
            //             width: '20%',
            //             title: 'Job Plan Execution Mode'
            //         },
            //         {
            //             text: 'Status',
            //             value: 'display_status',
            //             width: '20%',
            //             title: 'Job Plan Current Status'
            //         },
            //         {
            //             text: 'Running',
            //             value: 'running',
            //             width: '20%',
            //             title: 'Running Jobs',
            //             align: 'right'
            //         },
            //         {
            //             text: 'Completed',
            //             value: 'completed',
            //             width: '20%',
            //             title: 'completed Jobs',
            //             align: 'right'
            //         },
            //         {
            //             text: 'Waiting',
            //             value: 'waiting_for_execution',
            //             width: '20%',
            //             title: 'Waiting Jobs',
            //             align: 'right'
            //         },
            //         {
            //             text: 'Suspended',
            //             value: 'suspended',
            //             width: '20%',
            //             title: 'Suspended Jobs',
            //             align: 'right'
            //         },
            //         {
            //             text: 'Error',
            //             value: 'error',
            //             width: '20%',
            //             title: 'error Jobs',
            //             align: 'right'
            //         },
            //         {
            //             text: 'Terminated',
            //             value: 'terminated',
            //             width: '20%',
            //             title: 'terminated Jobs',
            //             align: 'right'
            //         },
            //         {
            //             text: 'Start Date',
            //             value: 'start_date',
            //             width: '20%',
            //             title: 'Job Plan Start Date',
            //             dataType:'date'
            //         },
            //         {
            //             text: 'Start Time',
            //             value: 'start_time',
            //             width: '20%',
            //             title: 'Job Plan Created Time',
            //             dataType:'time',
            //             hideInlineFilter: true
            //         },
            //         {
            //             text: 'Created By',
            //             value: 'created_by',
            //             width: '30%',
            //             title: 'Job Plan Created By'
            //         },
            //     ],
            //     actions: [
            //     {'text':'history','key':"id", selectType:"single",index:1}
            //     ], //if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     syncHeaderScroll: false,
            //     hideExport:true,
            //     hideShowColumns:true,
            //     is_in_tab:true
            // },
            tableList: {
                headers:[],
                actions:[],
                rows:[],
            },
            headers: [{
                        text: 'Id',
                        value: 'id',
                        disabled: true,
                        width: '10%',
                        align:'right',
                        title: 'Job Plan Id',
                        dataType:'int'
                    },
                    {
                        text: 'Name',
                        value: 'plan_name',
                        disabled: true,
                        width: '20%',
                        title: 'Job Plan Name'
                    },
                     {
                        text: 'Mode',
                        value: 'display_mode_of_execution',
                        disabled: true,
                        width: '20%',
                        title: 'Job Plan Execution Mode'
                    },
                    {
                        text: 'Status',
                        value: 'display_status',
                        width: '20%',
                        title: 'Job Plan Current Status'
                    },
                    {
                        text: 'Running',
                        value: 'running',
                        width: '20%',
                        title: 'Running Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Completed',
                        value: 'completed',
                        width: '20%',
                        title: 'completed Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Waiting',
                        value: 'waiting_for_execution',
                        width: '20%',
                        title: 'Waiting Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Suspended',
                        value: 'suspended',
                        width: '20%',
                        title: 'Suspended Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Error',
                        value: 'error',
                        width: '20%',
                        title: 'error Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Terminated',
                        value: 'terminated',
                        width: '20%',
                        title: 'terminated Jobs',
                        align: 'right'
                    },
                    {
                        text: 'Start Date',
                        value: 'start_date',
                        width: '20%',
                        title: 'Job Plan Start Date',
                        dataType:'date'
                    },
                    {
                        text: 'Start Time',
                        value: 'start_time',
                        width: '20%',
                        title: 'Job Plan Created Time',
                        dataType:'time',
                        hideInlineFilter: true
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        width: '30%',
                        title: 'Job Plan Created By'
                    },
                ],
            userRole:{},
            Environment:{},
            envIpJson : {
                "filter": [],
                "sort": [{
                    "column": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 10
            }

        }
    },
    props:{
      changedEnv:{
        type:Object
      }
    },
    watch:{
      changedEnv:{
        handler(newvalue){
          this.Environment = newvalue
          this.getCompletedPlanList(this.envIpJson)
        }
      }
    },
    mounted() {
        getEnvironmentList(this)
        this.getCompletedPlanList();
        this.envIpJson.page_size = manageScreenDisplay() -5
        var env_value = this.$session.get('selected_env')
        this.tableList = this.getGridObj(this.headers, 'id', true, {showExport : false})
        if( env_value && env_value.id != '0' && env_value.id !='all_env'){
            this.Environment = this.$session.get('selected_env')
            this.getCompletedPlanList()
        }
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions=[{'text':'history',
                        'key':"id", 
                        selectType:"single",
                        role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_PLAN_SERVICE_ID, EXECUTE_ACTION),
                        index:1}]
        this.focus_env()
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "env_id": _this.Environment.id,
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.JOB_PLAN_URL+EXPORT_JOB_PLAN_HISTORY;
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.JOB_PLAN_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
            _this.loader=false;
            if(objError){
                  _this.loader = null
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = objError;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
            })
        },
        focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
        getPlanHistoryList() {
          let _this = this;
          _this.loader=true;
          var env_id = this.Environment.id;
            if (!env_id)
              return false
          var env_id = _this.Environment.id
          var inputJson = this.envIpJson
         this.$session.set('selected_env', this.Environment)
          var job_data = {'client_id':this.$session.get('client_id'),
                          'env_id': env_id
                        };
          let baseUrl = config.JOB_PLAN_URL;
              baseUrl = baseUrl+ '/job_plan_completed';
          postToServer(this, baseUrl, job_data
              ).then(response => {
                  this.loader=false;
                  _this.tableList.rows = response;
           }).catch(CurrentJobError => {
                    this.loader=false;
               this.tableList.rows=[];
               this.snackbar = true;
                    this.colorValue = 'error'
                    this.snackbartext = CurrentJobError;
            })
        },
        getCompletedPlanList() {
          let _this = this;
          var env_id = this.Environment.id;
            if (!env_id)
              return false
          var env_id = _this.Environment.id
         this.$session.set('selected_env', this.Environment)

          var job_data = {'client_id':this.$session.get('client_id'),
                          'env_id': env_id};
          let baseUrl = config.JOB_PLAN_URL;
              baseUrl = baseUrl+ '/job_plan_completed';
          _this.loader = true
          postToServer(this, baseUrl, job_data
              ).then(response => {
              _this.loader=false;
               _.forEach(response,function(obj){
                obj = _this.manageObjProps(obj)
              })
              _this.tableList.rows = response;
           }).catch(CurrentJobError => {
               _this.loader=false;
               this.tableList.rows=[];
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError;
            })
        },
        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getCompletedPlanList(inputJson);
        },

        onPerPageChange(perPage, page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getCompletedPlanList(inputJson);
        },

        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getCompletedPlanList(inputJson);
        },

        filterData(filterArray, pageno) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getCompletedPlanList(inputJson);
        },
        onHistory(record){
           let _this = this;
         this.$router.push({ name: 'jobplaninstancedetails',
          params: {
           job_plan_id: this.job_plan_id,
           job_plan_instance_id: record.id,
           job_plan_name:record.plan_name,
           execution_environment: this.Environment,
           execution_env_name: this.Environment.name}
         }
         );
         },
         manageObjProps(obj){
            if(obj.mode_of_execution)
                obj.display_mode_of_execution = obj.mode_of_execution.charAt(0).toUpperCase() + obj.mode_of_execution.slice(1)
            if(obj.status)
                obj.display_status = obj.status.charAt(0).toUpperCase()+obj.status.slice(1)
            
            return obj
        },
   }
}
</script>

<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>
