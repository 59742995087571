<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" v-on:change="getAllTodaysJob(), getAllWaitingJobList()" style="-webkit-appearance: menulist" autofocus ref="envname">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Job Status</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-card>
        <v-row class="EDC-Row" no-gutters>
          <v-tabs v-model="activeTab" non-linear @change="tabChange">
            <v-tab class="EDC-Tabs" key="tab1">
              <v-badge :content="tableList.rows.length" inline bordered v-if='tableList.rows.length'>
                <span>Active Jobs</span>
              </v-badge>
              <span v-else>Active Jobs</span>
            </v-tab>
            <v-tab class="EDC-Tabs" key="tab2">
              <v-badge :content="waitingJobList.rows.length" v-if="waitingJobList.rows.length > 0" inline bordered>
                <span>Waiting Jobs</span>
              </v-badge>
              <span v-else>Waiting Jobs</span>
            </v-tab>
            <v-tab class="EDC-Tabs" key="tab3">
              <!-- Nirav asked to chnage Job's History to Completed Jobs on dated 15-05-2020 -->
              Completed Jobs
            </v-tab>
            <v-tab-item>
              <edc-data-grid key="grid-A" :removedRow="removedRow" :dataList="tableList" @onHistory="onHistory" @onTerminate="onTerminate" @onDetailedHistory="onDetailedHistory" @onResume="onResume" @onPause="onPause" @onCellEvent="onCellEvent"@onReExecute="onReExecute" @onEditCommit="onEditCommit" @onEnquiry="onEnquiry" @onShowGraph="onShowGraph" @onDownload="onDownload" @onCreateJobReport="onCreateJobReport" @ondblClick="onHistory" @onDelete="onDelete" @onShowPD="onShowPD" @showObjectTable="onShowPD"  @ShowObjectGraph="onGraphView"></edc-data-grid>
            </v-tab-item>
            <v-tab-item>
              <edc-data-grid key="grid-B" :dataList="waitingJobList" @onDelete="deleteWaitingJob" @onEnquiry="onWaitingEnquiry"></edc-data-grid>
            </v-tab-item>
            <v-tab-item>
              <edc-jobhistory key="pageA" :finishedJobId='finishedJobId' :changedEnv="Environment"></edc-jobhistory>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </v-card>
    </v-container>

    <v-dialog v-model="showfailed_reason" transition="dialog-bottom-transition" :overlay="false" scrollable width="600">
      <v-col class="EDC-Col">
        <v-card class="rounded-card">
          <v-toolbar dark dense>
            <v-col class="EDC-Col text-md-center">Job Error Details
              <v-icon class="text-lg-left" @click="showfailed_reason = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
            </v-col>
          </v-toolbar>
          Process Definition Name:- <b>{{selected_process_definition}} </b><br>
          Job Error:<b>{{job_failed_reason}}</b>
          <v-btn color="success" large block v-on:click="showfailed_reason = false" style="margin-bottom: 0px;">Close</v-btn>
        </v-card>
      </v-col>
    </v-dialog>
    <v-col class="EDC-Col">
      <v-dialog v-model="editcommit_dialog" width="unset" eager persistent>
        <v-col class="EDC-Col">
          <v-card class="rounded-card">
            <v-toolbar dark dense>
              <v-col class="EDC-Col text-md-center">Change Limit Size
                <v-icon class="text-lg-left" @click="editcommit_dialog = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
              </v-col>
            </v-toolbar>
            <v-card-text>
            <v-row no-gutters class="EDC-Row">
              <edc-data-grid key="editlimit" :dataList="newtableList" @onEdit="onEdit" @onSave="onSave" @onCancel="onCancel"></edc-data-grid>
            </v-row>
          </v-card-text>
          </v-card>
        </v-col>
      </v-dialog>

      <v-dialog v-model="viewpd_dialog" width="unset" height='600' eager persistent>
        <v-col class="EDC-Col">
          <v-card class="rounded-card">
            <v-toolbar dark dense max-height="40">
              <v-col class="EDC-Col text-md-center">PD Viewer
                <v-icon class="text-lg-left" @click="viewpd_dialog = false" style="color:#dedede;font-size:15px;float:right">fa-times-circle</v-icon>
              </v-col>
            </v-toolbar>
            <v-card-text>
            <v-row no-gutters class="EDC-Row">
              <edc-pdviewer :objectdetails="objectdetails" key="pdviewer">
                
              </edc-pdviewer>
            </v-row>
          </v-card-text>
          </v-card>
        </v-col>
      </v-dialog>

      <v-dialog v-model="gridDialog" width="900" scrollable style="overflow-y:none;height:200px !important" persistent>
        <v-col class="EDC-Col">
          <v-card class="rounded-card" style="height:100%">
            <v-toolbar dark dense>
              <v-col class="EDC-Col text-md-center">Approval History
                <v-icon class="text-lg-left" @click="gridDialog = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
              </v-col>
            </v-toolbar>
            <edc-data-grid key="enqury" :dataList="enquireList"></edc-data-grid>
          </v-card>
        </v-col>
      </v-dialog>



    </v-col>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import config from '../../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from '../../../methods/serverCall.js';
import {SERVER_ERROR} from '../../../data/client_message.js'
import {GETALLJOBS, GETTODAYSGJOB, JOBREALTIMESTATUS, EXECUTEJOB, TERMINATEJOB,ALL_PUBLISHED_PROCESS_DEF_LIST, RESUMEJOB, SUSPENDJOB,GET_LAST_SCHEDULE_JOBS,GETREALTIMESTATUS, FETCH_STEPS_COMMIT_SIZE,SAVE_NEW_COMMIT_SIZE, RESUME_COORDINATION, EXPORT_TODAYS_JOB, DELETE_JOB_PLAN_OBJECTS, DELETE_JOB_DATA} from '../../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
import getUserRole from '../../../methods/GetUserRole.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import jobHistory from './jobHistory.vue'
import objectPDTabularView from '../../../views/objectPDTabularView.vue'
import {getReadonlyRedirectionUrl} from '../../../methods/commonFunc.js'
import {getURLByDomainByProduct} from '@/methods/domain_management.js'
import {jobTypeHeader, jobModeHeader, jobEXEType, jobStatusActiveJob} from "@/data/job_management.js"

export default {
  name: 'ClientJobListNew',
  components:{
    'edc-jobhistory':jobHistory,
    'edc-pdviewer':objectPDTabularView
  },
  watch:{
      '$store.state.liveStatus':{
        handler(newValue){
          this.getRealTimeStatus(newValue)
        }
      },
      '$store.state.currentWaitingJobToRunning':{
        handler(newValue){
          this.removeFromWaitingGrid(newValue)
        }
      },
      '$store.state.currentWaitingJobToTerminate':{
        handler(newValue){
          this.removeAllWaitingGrid(newValue)
        }
      },
      '$store.state.jobEstimateRemainingTime':{
        handler(newValue){
          this.updateRemainingTime(newValue)
        }
      },
      'tableList.rows':{
        handler(newvalue){
        }
      }
    },
  data: function () {
    return {
      finishedJobId:0,
      objectdetails:{},
      viewpd_dialog:false,
      gridDialog:false,
      showfailed_reason:false,
      job_list: [],
      selected_process_definition:'',
      live_satus: [],
      job_failed_reason:'',
      activeTab:1,
      ActiveJobheaders: [
        { text: 'Job id', value: 'id', align:'right', title: 'Job Id', dataType:'int'},
        { text: 'Process Doc Name', value: 'process_definition_name', title:'Process Doc Name' },
        _.cloneDeep(jobTypeHeader),
        _.cloneDeep(jobStatusActiveJob),
        { text: 'Step', value: 'running_step_count', width: '140px', options:[], title: 'Job Step Count', hideInlineFilter:true},
        { text: '(%)', value: 'running_step_percentage', options:[],align:'right',title:'Running Step Percentage',useProgress:true, progressSize:100, hideInlineFilter:true},
        { text: 'Remaining Time (Sec)', value: 'remaining_time', title: 'Remaining Time In Seconds',align:'right' },
        _.cloneDeep(jobModeHeader),
        _.cloneDeep(jobEXEType),
        { text: 'Plan Name', value: 'plan_name', options:[], title: 'Job Plan Name'},
        { text: 'Policy Date', value: 'actual_date', options:[], title: 'Job Policy Date', dataType:'date'},
        // {text: 'Processing Time', value: "total_running_time", cssClass:'w16', options:[], title: 'Job Processing Time'},
        { text: 'Start date', value: 'start_date', dataType:'date', options:[], title: 'Job Start Date' },
        { text: 'Start time', value: 'start_time', dataType:'time', options:[], title: 'Job Start Time', hideInlineFilter:true},
        
        // { text: 'End date', value: 'end_date', dataType:'date', options:[], title: 'Job End Date' },
        // { text: 'End time', value: 'end_time', dataType:'time', options:[], title: 'Job End Time', hideInlineFilter:true},
        // {text: 'Duration', value: "duration", cssClass:'w16', options:[], title: 'Job Duration'},
        { text: 'Dup Found', value: 'is_execute_duplicate', options:[], title: 'Duplicate Found &/or Skipped'},

        { text: 'Initiated By', value: "created_by", cssClass:'w16', options:[], title: 'Job Execution Initiated By'},
        { text: 'Report', value: 'report_step_available', options:[], title: 'Report Step Available'},
        { text: 'Object ID', value: 'process_definition_id', width: '200px', options:[], title: 'Object ID', align:'right'},
        // { text: 'Report step available', value: 'report_step_available', sortable: false, width: '300px',options:[]},
      ], 
      tableList: {
        headers:[],
        actions:[],
        rows:[]
      },
      waitingJobHeaders: [
        { text: 'Process Doc Name', value: 'process_definition_name',  title: 'Process Doc Name'},
        // { text: 'Environment', value: 'execution_environment.env_name', width: '180px', options:[],cssClass:'w16'},
        { text: 'Status', value: 'status',  title: 'Job Status'},
        { text: 'Mode', value: 'mode_of_execution',  title: 'Mode of Execution'},
        { text: 'Exe Type', value: 'exe_type', title:'Execution Type', title:'Execution Type' },
        { text: 'Start date', value: 'start_date',  title: 'Job Start Date', dataType:'date'},
        { text: 'Start time', value: 'start_time',  title: 'Job Start Time', dataType:'time',hideInlineFilter:true},
        { text: 'Object ID', value: 'process_definition_id', width: '200px', options:[], title: 'Object ID', align:'right'},
      ], 
      waitingJobList: {
        headers: [], 
        actions: [],//if we need conditional action in row then provide key
        rows:[]
      },
      snackbar:false,
      snackbartext:'',
      colorValue:'success',
      snackbartimeout: Snackbar_Default_Timeout,         
      valid:true,
      loader:false,
      Environment:{},
      EnvironmentList:[],
      timer_timespan :3000,
      is_request_sent_to_server:false,
      userRole:{},
      limitHeaders:[
        { text: 'Job id', value: 'job_id', width:"25%", dataType:'int'},
        { text: 'Step Name', value: 'step_name', width:"25%" },
        { text: 'Step Type', value: 'step_type', width:"25%"},
        { text: 'Limit', value: 'commit_size',width:"25%", isNumber:true, useTextField:true, inputType:'number', dataType:'int'},
      ],
      newtableList: {
        headers: [], 
        actions: [],
        rows:[]
      },
      enquireHeaders:[
        { text: 'Number', value: 'seq_num', width:"20%", disabled:true, dataType:'int'},
        { text: 'Action', value: 'action', width:"20%", disabled:true },
        { text: 'Action By', value: 'action_by', width:"20%", disabled:true},
        { text: 'Remark', value: 'remark',width:"20%",},
        { text: 'Action Date', value: 'timezone_aware_action_date',width:"10%", dataType:'date'},
        { text: 'Action Time', value: 'timezone_aware_action_time',width:"10%", dataType:'time', hideInlineFilter:true},
      ],
      enquireList: {
        headers: [], 
        actions: [],
        rows:[]                 
      },
      editcommit_dialog:false,
      NewCommitSizeListOfDict:[],
      show_last_page:false,
      envIpJson : {
          "filter": [],
          "sort": [],
          "page": 1,
          "page_size": 10
      },
      removedRow:{}
    }
  },

  mounted() {
    this.tableList = this.getGridObj(this.ActiveJobheaders,'id',true,{"updatinglivestatus":true})
    this.waitingJobList = this.getGridObj(this.waitingJobHeaders,'id',true,{showExport:false})
    this.newtableList = this.getGridObj(this.limitHeaders, 'step_name',true,{perPage:5,showExport:false,showInlineFilter:false,is_grid_inline_edit:true})
    this.enquireList =  this.getGridObj(this.enquireHeaders,'id',true,{showExport:false,showInlineFilter:false})
    this.envIpJson.page_size = manageScreenDisplay()
    this.activeTab = 0
    this.userRole = this.$session.get('user_role_mapping');
    this.tableList.actions = [
      {'text':'history','key':"id", selectType:"single",index:1},
      {'text':'suspend','key':"perform", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),cmpValue:'suspend',index:2,
      options:[
        {"value": "immediate", "text":'Immediate'} ,
        {"value":"suspend_after_commit", "text": 'After Current Transaction Finished'}
      ],'optionKey':'mode_of_execution','optionKeyValue':'Final'},
      {'text':'resume','key':"status", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:3,
        'cmpValueList':['error','suspended']  
    },
      {'text':'terminate','key':"perform", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:4},
      {'text':'editcommit','key':"allow_edit_commit_size", selectType:"single",cmpValue:'Y', role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION) || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:6},
      {'text': 'showpd', 'key': 'is_restored', cmpValue:'Normal', selectType:"single", index:9, role:true}
    ]

    this.waitingJobList.actions =[
      {'text':'delete','key':"id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION), index:1},
      {'text':'enquiry','key':"type", cmpValue:'approval', selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION), index:2},
      ]

    var env_value = this.$session.get('selected_env')
    if( env_value && env_value.id != '0' && env_value.id !='all_env'){
      this.Environment = this.$session.get('selected_env')
      this.getAllKindOfJobList()
    }
    getEnvironmentList(this)
    // this.timer = setInterval(this.getRealTimeStatus,this.timer_timespan)  
    this.focus_env()
    this.newtableList.actions =[{'text':'edit', 'key':"job_id", selectType:"single", role:true, index:1},
    {'text':"save", "key":"isSave", selectType:"single", 'key':"is_row_editable", role:true, index:2},
    {'text':"cancel", "key":"isCancel", selectType:"single", 'key':"is_row_editable", role:true, index:3}]

    if(this.$session.get('changed_commit_sizes'))
      this.NewCommitSizeListOfDict = this.$session.get('changed_commit_sizes')
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    tabChange(){
      if(this.activeTab === 1 && this.waitingJobList.rows.length === 0){
        this.getAllWaitingJobList()
      }
    },
    getAllWaitingJobList(){
      this.getScheduleJob()
      this.getWaitApprovalJob()
      this.getWaitPlannedJob()
    },
    onCreateJobReport(record){
      let _this = this;
      this.$router.push({ name: 'SelectReportDownload', params: {
        'client_id': this.$session.get('client_id'), 'env_id': this.Environment.id, 'job_id_list': [record.id]
       }});
    },
    getAllKindOfJobList(){
    this.getAllTodaysJob()
    this.getScheduleJob()
    this.getWaitApprovalJob()
    this.getWaitPlannedJob()
  },
    focus_env(){
      if(this.$refs.envname.$el)
        this.$refs.envname.$el.focus()
      else this.$refs.envname.focus()
    },
  onDownload(downloadParams, filterArray){
        let _this = this; 
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "row_option": downloadParams.recordType,
            "client_id": this.$session.get('client_id'),
            "env_id": this.Environment.id,
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.ENGINE_API_URL+EXPORT_TODAYS_JOB;    
        postToServer(_this, url, inputJson).then(Response => {
            var url = config.ENGINE_API_URL + "/static/" + Response;
            window.open(url, '_blank');
            _this.loader=false;
            
        }).catch(objError => {
            _this.loader=false;
            if(objError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = objError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
    },  
  onDetailedHistory:function(record){
    this.$router.push({ name: 'operationwiseresult', params: { env_id: record.execution_environment.id, envname:record.execution_environment.env_name,jobid: record.job_id,mode:record.mode_of_execution, 
      jobname: record.process_definition_name, status:record.status, type:'currentJobList' }});
  },
  onHistory(record){
   let _this = this;
   this.$router.push({ name: 'showjobstepsresult', params: {env_id: record.execution_environment.id, envname:record.execution_environment.env_name,jobname: record.process_definition_name, 
     jobid: record.job_id,mode:record.mode_of_execution,status:record.status, type:'currentJobList',show_last_page:true ,job_plan_name:record.plan_name}});
 },
 hideLoader(){
  let _this = this;
  setTimeout(function(){
    _this.loader = false; 
  },500);
},
GetJobFailedReason(process_doc_name,reason){
  this.showfailed_reason = true
  this.selected_process_definition = process_doc_name
  this.job_failed_reason = reason
},
onCellEvent(record, key){
  if(key == 'status' && (record[key] == 'error')){
    this.GetJobFailedReason(record.process_definition_name,record.details);
  }else{
    this.onHistory(record);
  }
},

onTerminate(record){
  this.perform("terminate", record);
},
onResume(record){
  this.perform("resume", record);
},
onPause(record, extraVal){
  this.perform("suspend", record, extraVal);
},
perform(option, record, extraVal){
  var job_id = record.job_id
  var job_exe_auto_id = record.job_exe_auto_id
  switch(option){
    case 'start':
    record.perform = 'suspend';
    record.status="initializing"
    this.ExecuteJob(job_id,job_exe_auto_id,record);
    break;
    case 'suspend':
    record.perform = 'resume';
    record.status="suspending"
    this.SuspendJob(job_id,job_exe_auto_id,record, extraVal)
    break;
    case 'resume':
    record.perform = 'suspend';
    record.status="resuming";
    this.ResumeJob(job_id,job_exe_auto_id,record)
    break;
    case 'terminate':
    record.perform = null;
    record.status="terminating"
    this.TerminateJob(job_id,job_exe_auto_id,record)
    break;
  }
},
onDelete(record){
  var job_ids = _.map(record,"id")
  var client_id = this.$session.get('client_id')
  var env_id = this.Environment.id
  var job_data = {"env_id":env_id, "client_id":client_id, "deleted_from_job_id_list":true, "job_id_list": job_ids}
  this.loader = true;
  postToServer(this, config.ENGINE_API_URL + DELETE_JOB_DATA, job_data).then(response => {
    this.loader = false
    this.snackbar = true
    this.colorValue = 'success'
    this.snackbartext = response;
    this.getAllTodaysJob();
  }
  ).catch(CurrentJobError => {
    this.loader = false
    this.snackbar = true
    this.colorValue = 'error'
    this.snackbartext = CurrentJobError;
  })
},
getRevisedDataList(data){
  let notFinishedJobs = []
  let finishedJobs = []
  let sortedData = _.reverse(_.sortBy(data,['id']))
  let finishedJobList = ['completed','terminated']
  sortedData.forEach(function(obj){
    if(obj.status && finishedJobList.indexOf(obj.status.toLowerCase()) >-1)
      finishedJobs.splice(0,0,obj)
    else
      notFinishedJobs.splice(0,0,obj)
  })
  // let revisedDataList = notFinishedJobs.concat(finishedJobs)
  return notFinishedJobs
},
getAllTodaysJob (show_loader=true) {
  let _this = this
  var env_id = this.Environment.id;
  var client_id = this.$session.get('client_id');
  if (!env_id)
    return false

  this.waitingJobList.rows = []

  this.$session.set('selected_env', this.Environment)
  this.tableList.rows = []
  this.loader = show_loader;
  var data = {"client_id": client_id,
  "env_id": env_id}
  postToServer(this, config.ENGINE_API_URL + GETTODAYSGJOB, data).then(response => {
    var data = response
    if(data){
      let revised_data = _this.getRevisedDataList(data)

      // let desSortedData = _.reverse(data)

      this.tableList.rows = revised_data
      // this.jobList.rows = data;
      var row_id = 0
      this.tableList.rows.map(function(obj){
        obj.updatinglivestatus = true
        if(obj.is_restored || obj.job_type === "sync_up")
          obj.showreexcute ='N'
        else
          obj.showreexcute = 'Y'
        var CurrentState = obj.status
        obj.is_restored = cloneDeep(obj.is_restored ? "Restoration" : "Normal");

        if (obj.job_type === "sync_up" && obj.is_restored === "Normal")
          obj.is_restored = "Sychronization"
        else if (obj.job_type === "sync_up" && obj.is_restored === "Restoration")
          obj.is_restored = "Un-Sychronization"
        if(obj.activity_type === 'Dispose')
          obj.is_restored = "Disposal"
        else if(obj.activity_type === 'Delete Copy')
          obj.is_restored = "Delete Copy"
        obj.id = obj.job_id
        switch(CurrentState){
          case 'suspended':
          obj.perform = 'resume';
          obj.status="suspended";
          obj.showreexcute ='N';
          break;
          case 'error':
          obj.perform = 'resume';
          obj.status="error";
          obj.showreexcute ='N';
          break;
          case 'running':
          obj.perform = 'suspend';
          obj.status="running";
          obj.showreexcute ='N';
          break;
          case 'initializing':
          obj.perform = 'suspend';
          obj.status="initializing";
          obj.showreexcute ='N';
          break;
          case 'validating':
          obj.perform = 'suspend';
          obj.status="validating";
          obj.showreexcute ='N';
          break;
          case 'preparing':
          obj.perform = 'suspend';
          obj.status="preparing";
          obj.showreexcute ='N';
          break;
          default:
          obj.perform = '';
        }
        obj.status_to_show = ''
        if(obj.status)
          obj.status_to_show = obj.status.charAt(0).toUpperCase() + obj.status.slice(1)
        obj.row_id= row_id
        obj.mode_of_execution = obj.mode_of_execution.charAt(0).toUpperCase() + obj.mode_of_execution.slice(1)
              obj.exe_type = obj.exe_type === 'on_schedule' ? "On Schedule": "On Demand"
        obj.allow_edit_commit_size = 'Y'
        if(obj.mode_of_execution ==='Proof' || obj.status === 'completed' || obj.status === 'terminated'){
          obj.allow_edit_commit_size = 'N'
        }
        obj.allow_delete = 'N'
        if(obj.status === 'completed' || obj.status === 'terminated'){
          obj.allow_delete = 'Y'
        }
        obj.showgraph = 'N'
        if(obj.mode_of_execution !='Proof'){
          obj.showgraph = 'Y'
        }
        obj.showenquiry = 'Y'
        if(obj.is_restored ==='Restoration' || obj.status === 'completed' || obj.status === 'terminated' || !obj.process_definition_version){
          obj.showenquiry = 'N'
        }
        if( obj.status === 'completed' || obj.status === 'terminated')
          obj.running_step_count = 'N/A'
        obj.execution_environment = {}
        obj.execution_environment.env_name = _this.Environment.name
        obj.execution_environment.id = _this.Environment.id
        row_id +=1
      })
    } else {
     this.errorMsg = data.message
   }
   this.hideLoader();
   //call after once fetch job current job status

 }).catch(CurrentJobError => {

  if(CurrentJobError){

  }
  else {
    this.snackbar = true
    this.colorValue = 'error'
    this.snackbartext = SERVER_ERROR;
  }
  this.hideLoader();
})
},
onShowGraph(record){
  this.$router.push({ name: 'livetblgraph', params: { envname:record.execution_environment.env_name,jobname: record.process_definition_name, env_id:record.execution_environment.id,
       jobid: record.job_id,status:record.status_to_show}});
},
getRealTimeStatus(update_data){

  var job_id = update_data.job_id
  var obj = _.find(this.tableList.rows, ["job_id",job_id])
  if(!obj){
    //send call to api
    return this.getSingleJobDetails(job_id)
  }
  obj.updatinglivestatus = true
  if(update_data.running_step_count !='None (None)')
    obj.running_step_count = update_data.running_step_count
  obj.running_step_percentage = update_data.running_step_percentage

  //this start_datetime_without_timezone,end_datetime_without_timezone variable added in pubsub we dont know any timezone about user.So conversion need to add UI side

  if(!obj.start_time){ 
    if(update_data.start_datetime_without_timezone){
      var date_without_miliseconds = update_data.start_datetime_without_timezone.split('.')[0]
      var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")
      var timezoned_start_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')
      obj.start_time = timezoned_start_time
    }
    else 
      obj.start_time = update_data.start_time
  }
  if(update_data.end_datetime_without_timezone && update_data.end_datetime_without_timezone!='None'){
    var date_without_miliseconds = update_data.end_datetime_without_timezone.split('.')[0]
      var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")
        
      var timezoned_formated_date = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format(this.$session.get('UI_date_format'))
      obj.end_date = timezoned_formated_date
      var timezoned_end_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')

      obj.end_time = timezoned_end_time
    }
  else{
    if(update_data.end_date && update_data.end_date!='None'){
      obj.end_date = update_data.end_date
    }
    if(update_data.end_time && update_data.end_time!='None'){
      obj.end_time = update_data.end_time
    }
  }
  obj.details = update_data.details
  obj.process_definition_version = update_data.process_definition_version
  var CurrentState = update_data.status
  var oldStatus = obj.status
  if(obj.process_definition_version)
    obj.allow_edit_commit_size = 'Y'
  if(obj.mode_of_execution === 'Proof' || update_data.status === 'completed' || update_data.status === 'terminated'){
    obj.allow_edit_commit_size = 'N'
  }
  obj.allow_delete = 'N'
  if(update_data.status === 'completed' || update_data.status === 'terminated'){
    obj.running_step_count='N/A'
    obj.allow_delete = 'Y'
  }
  obj.showenquiry = 'Y'
  if(update_data.status === 'completed' || update_data.status === 'terminated' || obj.is_restored === 'Restoration'){
    obj.showenquiry = 'N'
  }
  // if(CurrentState === oldStatus)
  //   continue
  obj.status = update_data.status
  switch(CurrentState){
    case 'suspended':
    obj.perform = 'resume';
    obj.status="suspended";
    break;
    case 'error':
    obj.perform = 'resume';
    obj.status="error";
    break;
    case 'running':
    obj.perform = 'suspend';
    obj.status="running";
    break;
    case 'initializing':
    obj.perform = 'suspend';
    obj.status="initializing";
    obj.showreexcute ='N';
    break;
    case 'validating':
    obj.perform = 'suspend';
    obj.status="validating";
    obj.showreexcute ='N';
    break;
    case 'preparing':
    obj.perform = 'suspend';
    obj.status="preparing";
    obj.showreexcute ='N';
    break;
    case 'suspending':
    obj.perform = '';
    obj.status="suspending";
    obj.showreexcute ='N';
    break;
    case 'terminating':
    obj.perform = '';
    obj.status="terminating";
    obj.showreexcute ='N';
    break;
    default:
    if(CurrentState ==='completed')
      obj.running_step_percentage = 100;
    obj.perform = '';
    if(obj.is_restored === 'Normal')
      obj.showreexcute = 'Y'
  }
  if(obj.status)
  obj.status_to_show = obj.status.charAt(0).toUpperCase() + obj.status.slice(1)
  
  if(['completed','terminated'].indexOf(obj.status)>-1){
    // send this notification to jobHistory page that job is finished
    this.finishedJobId = job_id
    // remove job from current grid
    let removeObj = _.remove(this.tableList.rows, ["job_id",job_id])
    this.removedRow = removeObj
  }
  else{
    var index = _.findIndex(this.tableList.rows,["job_id",job_id])
    this.$set(this.tableList.rows,index,obj)
  }
},
getScheduleJob(){
  let _this = this
  var env_id = this.Environment.id;
  var client_id = this.$session.get('client_id');
  if (!env_id)
    return false
  var data = {}
  this.$session.set('selected_env', this.Environment)
  var data = {"client_id": client_id,
  "env_id": env_id,
  "hours":12}
  postToServer(this, config.SCHEDULER_URL + GET_LAST_SCHEDULE_JOBS, data).then(response => {
    if(response){
      console.log(_this.tableList)
      console.log(response)
      for (var i = 0; i < response.length; i++) {
        var response_row = response[i]
        var modified_row ={}
        modified_row['process_definition_name'] = response_row.params.process_definition_name
        modified_row['process_definition_id'] = response_row.params.process_definition_id
        modified_row['execution_environment'] ={"id":response_row.params.execution_environment,"env_name":response_row.params.execution_environment_name}
        modified_row['mode_of_execution'] = response_row.params.mode_of_execution
        modified_row['id'] = response_row.id
        modified_row['start_date'] = response_row.next_run_time.split(" ")[0]
        modified_row['start_time'] = response_row.next_run_time.split(" ")[1]
        modified_row['exe_type'] ='On Schedule'
        modified_row['status'] ='Scheduled'
        if(response_row.type == "job_plan"){
          modified_row['type'] = response_row.type
          modified_row['job_plan_instance_status'] = response_row.params.job_plan_instance_status
          modified_row['job_plan_id'] = response_row.params.job_plan_id
          modified_row['job_plan_instance_id'] = response_row.params.job_plan_instance_id
          modified_row['scheduler_id'] = response_row.scheduler_id
        }else{
          modified_row['type'] ='Scheduled'
        }
        _this.waitingJobList.rows.push(modified_row)
      }
    }
  }).catch(CurrentJobError => {
    if(CurrentJobError){

    }
    else {
    }
  })
},
getWaitApprovalJob(){
  let _this = this
  var env_id = this.Environment.id;
  var client_id = this.$session.get('client_id');
  if (!env_id)
    return false
  var data = {}
  this.$session.set('selected_env', this.Environment)
  var data = {"client_id": client_id,
  "env_id": env_id}
  postToServer(this, config.COORDINATOR_API_URL + '/get_approval_waiting_jobs', data).then(response => {
    if(response){
      console.log(_this.tableList)
      console.log(response)
      for (var i = 0; i < response.length; i++) {
        var response_row = response[i]
        var modified_row ={}
        modified_row['process_definition_name'] = response_row.request_data.process_definition_name
        modified_row['process_definition_id'] = response_row.request_data.process_definition_id
        modified_row['execution_environment'] ={"id":response_row.environment_id,"env_name":response_row.environment_name}
        modified_row['mode_of_execution'] = 'Final'
        modified_row['start_date'] = ''
        modified_row['start_time'] = ''
        modified_row['exe_type'] ='On Demand'
        modified_row['type'] ='approval'
        modified_row['status'] =response_row.status
        modified_row["id"] = response_row.id
        _this.waitingJobList.rows.push(modified_row)
      }
    }
  }).catch(CurrentJobError => {
    if(CurrentJobError){

    }
    else {
    }
  })
},

getWaitPlannedJob(){
  let _this = this
  var env_id = this.Environment.id;
  var client_id = this.$session.get('client_id');
  if (!env_id)
    return false
  var data = {}
  this.$session.set('selected_env', this.Environment)
  var data = {"client_id": client_id,
  "env_id": env_id}
  postToServer(this, config.JOB_PLAN_URL + '/get_all_waiting_instance', data).then(response => {
    if(response){
      console.log(_this.tableList)
      console.log(response)
      for (var i = 0; i < response.length; i++) {
        var response_row = response[i]
        var modified_row ={}
        modified_row['process_definition_name'] = response_row.process_definition_name
        modified_row['process_definition_id'] = response_row.process_definition_id
        modified_row['execution_environment'] = {"id":this.Environment.id,"env_name":this.Environment.name}
        modified_row['mode_of_execution'] = response_row['mode_of_execution']
        modified_row['start_date'] = ''
        modified_row['start_time'] = ''
        modified_row['exe_type'] ='Planned Job'
        modified_row['type'] ='plan'
        modified_row['status'] = 'Waiting to Run'
        modified_row['job_plan_id'] = response_row['job_plan_id']
        modified_row['job_plan_instance_id'] = response_row['job_plan_instance_id']
        modified_row['id'] = response_row['job_plan_inst_detail_id']
        modified_row['job_plan_instance_status'] = response_row['job_plan_instance_status']
        if (response_row['status'] === 'Sent for approval')
          modified_row['status'] = 'Sent for approval'
        modified_row["id"] = response_row.job_plan_inst_detail_id
        _this.waitingJobList.rows.push(modified_row)
      }
    }
  }).catch(CurrentJobError => {
    if(CurrentJobError){

    }
    else {
    }
  })
},

SuspendJob(job_id,job_exe_auto_id,record,extraVal){
  var job_data = {
    "job_id":job_id,
    "job_exe_auto_id":job_exe_auto_id,
    "client_id":this.$session.get('client_id'),
    "env_id":this.Environment.id,
    "env_name": this.Environment.name,
  }
  if(extraVal === 'suspend_after_commit')
    job_data['status'] = extraVal
  postToServer(this, config.ENGINE_API_URL + SUSPENDJOB, job_data
    ).then(response => {
      var data = response
      if(data){
        record.perform = 'resume';
        record.status="suspended"
      }
    }).catch(SuspendJobError => {
      if(SuspendJobError){
        this.loader = null
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = SuspendJobError;
      }
      else {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = SERVER_ERROR;
      }
    })
  },
  ResumeJob(job_id,job_exe_auto_id,record){
    var job_data = {
      'job_id':job_id,'client_id':this.$session.get('client_id'),
      "env_id":this.Environment.id, "env_name": this.Environment.name, "process_definition_id":record.process_definition_id
    }
    postToServer(this, config.COORDINATOR_API_URL + RESUME_COORDINATION, job_data
      ).then(response => {
        var data = response
        if(data){
          record.perform = null;
          record.status="completed"
        }
        this.loader = null
        this.snackbar = true
        this.colorValue = 'info'
        this.snackbartext = response.message;

      }).catch(ResumeJobError => {
        if(ResumeJobError){
          this.loader = null
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = ResumeJobError;
        }
        else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      })
    },
    TerminateJob(job_id,job_exe_auto_id,record){
      var job_data = {
        "job_id":job_id,
        "job_exe_auto_id":job_exe_auto_id,
        "client_id":this.$session.get('client_id'),
        "status":"terminate",
        "env_id":this.Environment.id,
        "env_name": this.Environment.name
      }
      postToServer(this, config.ENGINE_API_URL + TERMINATEJOB,job_data
        ).then(response => {
          var data = response

          if(data){
           record.perform = '';
         }
       }).catch(TerminateJobError => {
        if(TerminateJobError){
          this.loader = null
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = TerminateJobError;
        }
        else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      })
     },
     ClearFieldOnReStart(record){
      record.start_time = ''
      record.running_step_count = ''
      record.running_step_percentage = ''
    },
    onReExecute(record){
      // this.$router.push({ name: 'managejob', params: { 'process_definition_id': record.process_definition_id, 'process_doc_name': record.process_definition_name, 'activity_id':null,'environment':this.Environment, 'is_restore':false}});
      this.$store.state.createJobParams = { 'process_definition_id': record.process_definition_id, 
                'process_doc_name': record.process_definition_name, 'activity_id':null,'environment':this.Environment, 'is_restore':false,'job_id':record.job_id};
      this.$router.push({ name: 'createJob', params: { 'process_definition_id': record.process_definition_id, 
                'process_doc_name': record.process_definition_name, 'activity_id':null,'environment':this.Environment, 'is_restore':false,'is_rexecute_request':true,'exe_mode':record.mode_of_execution,'job_id':record.job_id}});
    },
    onEditCommit(record){
      let _this = this
      this.editcommit_dialog = true
      var job_id = record.job_id
      var input_json = {"job_id":job_id,"client_id":this.$session.get("client_id")}
      _this.newtableList.rows = []
      _this.loader = true
      postToServer(this, config.ENGINE_API_URL +'/get_commit_size', input_json).then(repository_response => {
        _this.loader = false
        _this.newtableList.rows = repository_response
      }).catch(repository_response_error => {
        _this.loader = false
      });
    },
    onEdit(record){
    },
    onSave(record,iter){
      if(!iter)
        iter=0
      iter++
      var _this = this
      this.loader = true
      this.snackbar = false
      var job_id = record.job_id
      var limit = record.commit_size
      if(!limit || isNaN(limit) || parseInt(limit)<1){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'specify valid limit for step '+ this.newtableList.rows[i]['step_name'];
        return false
      }
      record["client_id"] = this.$session.get("client_id")
      record["user_name"] = this.$session.get('email')
      record["request_by"] = "user"
      postToServer(this, config.ENGINE_API_URL +SAVE_NEW_COMMIT_SIZE, record).then(repository_response => {
        this.loader = false
        _this.newtableList.rows = repository_response
      }).catch(repository_response_error => {
        if(iter<8) // save commit size throws an error
          _this.onSave(record,iter)
        else{
          this.loader = false
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Set commit size failed. Please try again';
        }
      });
    },
    onCancel(record){
      this.onEditCommit(record)
    },
    onEnquiry(record){
      let _this = this;
      record = record[0]
     this.$router.push({ name: 'showworktablesdata', params: {envname:record.execution_environment.env_name,jobname: record.process_definition_name, env_id:record.execution_environment.id,
       jobid: record.job_id}});
      },
    getSingleJobDetails(job_id){
      var data = {"env_id":this.Environment.id,"job_id":job_id}
      var _this = this
      postToServer(this, config.ENGINE_API_URL +'/get_job_details', data,true).then(
        response=>{
          var obj = response
          if(['completed','terminated'].indexOf(obj.status)>-1){
            // if object in completed or terminate state, no need to add into list
            return false
          }
          obj.updatinglivestatus = true
          if(obj.is_restored || obj.job_type === "sync_up")
            obj.showreexcute ='N'
          else
            obj.showreexcute = 'Y'
          var CurrentState = obj.status
          obj.is_restored = cloneDeep(obj.is_restored ? "Restoration" : "Normal");

          if (obj.job_type === "sync_up" && obj.is_restored === "Normal")
            obj.is_restored = "Sychronization"
          else if (obj.job_type === "sync_up" && obj.is_restored === "Restoration")
            obj.is_restored = "Un-Sychronization"
          if(obj.activity_type === 'Dispose')
            obj.is_restored = "Disposal"
          else if(obj.activity_type === 'Delete Copy')
            obj.is_restored = "Delete Copy"
          obj.id = obj.job_id
          switch(CurrentState){
            case 'suspended':
            obj.perform = 'resume';
            obj.status="suspended";
            obj.showreexcute ='N';
            break;
            case 'error':
            obj.perform = 'resume';
            obj.status="error";
            obj.showreexcute ='N';
            break;
            case 'running':
            obj.perform = 'suspend';
            obj.status="running";
            obj.showreexcute ='N';
            break;
            default:
            obj.perform = '';
          }
          obj.status_to_show = ''
          if(obj.status)
            obj.status_to_show = obj.status.charAt(0).toUpperCase() + obj.status.slice(1)
          obj.mode_of_execution = obj.mode_of_execution.charAt(0).toUpperCase() + obj.mode_of_execution.slice(1)
                obj.exe_type = obj.exe_type === 'on_schedule' ? "On Schedule": "On Demand"
          obj.allow_edit_commit_size = 'Y'
          if(obj.mode_of_execution ==='proof' || obj.status === 'completed' || obj.status === 'terminated' || !obj.process_definition_version){
            obj.allow_edit_commit_size = 'N'
          }
          obj.showenquiry = 'Y'
          if(obj.is_restored ==='Restoration' || obj.status === 'completed' || obj.status === 'terminated' || !obj.process_definition_version){
            obj.showenquiry = 'N'
          }
          var is_obj_alreay_there = _.find(_this.tableList.rows,["job_id",job_id])
          obj.execution_environment = {}
          obj.execution_environment.env_name = this.Environment.name
          obj.execution_environment.id = this.Environment.id
          if(!is_obj_alreay_there)
            _this.tableList.rows.unshift(obj)
          if(obj.exe_type === "On Schedule")
            this.removeFromWaitingGrid(obj,'schedule')
      }).catch(error_msg =>{

      })
    },
    deleteWaitingJob(record){
      let _this = this;
      // alert(record.type);//api call192.168.1.14:8037
      let data_to_send={"coordinator_id":record.id}
      let url = config.COORDINATOR_API_URL;
      var deleted_job_plan_id = ''
      if(record.type == 'approval')
        url = url+'/delete_coordinator';
      else if(record.type == 'Scheduled'){
        url = config.SCHEDULER_URL+'/remove_next_occurrence';
        data_to_send.job_id = record.id;
      } 
      else if(record.job_plan_instance_status == "running" || record.status == "running" || record.status == "Waiting to Run" || record.status == "suspended", record.status == "error") {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'You cannot delete planned job.You need to terminate the current running job from that job plan';
        return false
      }else if(record.type == "plan" || record.type == "job_plan"){
        data_to_send = record
        data_to_send.client_id = this.$session.get('client_id')
        url = config.JOB_PLAN_URL + DELETE_JOB_PLAN_OBJECTS;
        data_to_send.env_id = this.Environment.id
        deleted_job_plan_id = record.job_plan_id
      }
      postToServer(this, url, data_to_send, true).then(repository_response => {
        let index = _.findIndex(_this.waitingJobList.rows, ['id', record.id]);
        _this.waitingJobList.rows.splice(index,1);

        if(deleted_job_plan_id){
          while (true) {
            let index = _.findIndex(_this.waitingJobList.rows, ['job_plan_id', deleted_job_plan_id]);
            if(index === -1)
              break;
            _this.waitingJobList.rows.splice(index,1);
          }
        }

        this.snackbar = true
        this.colorValue = 'success'
        if(record.type == "plan" || record.type == "job_plan"){
          this.snackbartext = repository_response;
        }else{
          this.snackbartext = repository_response.message;
        }
      }).catch(CurrentJobError => {
          if(CurrentJobError){
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = CurrentJobError;
          }
          else {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          }
          this.hideLoader();
        })
    },
    onWaitingEnquiry(record){
      let url=config.COORDINATOR_API_URL+'/get_approval_history';
      let _this = this;
      let data_to_send={"coordinator_id":record.id}
      postToServer(this, url, data_to_send, true).then(repository_response => {
          this.gridDialog = true;
          repository_response.map((obj, index)=>{
              obj.seq_num = index+1;
          }); 
        _this.enquireList.rows = repository_response;
      }).catch(CurrentJobError => {

          if(CurrentJobError){
            // this.snackbar = true
            // this.colorValue = 'error'
            // this.snackbartext = CurrentJobError;
            _this.enquireList.rows = [];
            this.gridDialog = true;
          }
          else {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          }
          this.hideLoader();
        });
    },
    removeFromWaitingGrid(data,call_from){
      debugger
      let _this = this
      var grid_data = cloneDeep(_this.waitingJobList.rows)
      for (var i = 0; i < grid_data.length; i++){
        var current_row = grid_data[i]
        if(call_from === 'schedule'){
          if(current_row.process_definition_name === data.process_definition_name && current_row.exe_type === "On Schedule" && current_row.mode_of_execution.toLowerCase() === data.mode_of_execution.toLowerCase()){
              grid_data.splice(i,1)
              break
          }
        }
        else{
            if(current_row.id === data.id && current_row.type === data.row_type){
            grid_data.splice(i,1)
            break
          }
        }
      }
      _this.waitingJobList.rows = grid_data
    },
    removeAllWaitingGrid(data){
      let _this = this
      var grid_data = cloneDeep(_this.waitingJobList.rows)
      for(var j=0;j<data.ids.length;j++){
        var current_id = data.ids[j]
        for (var i = 0; i < grid_data.length; i++){
          var current_row = grid_data[i]
          if(current_row.id == current_id && current_row.type === data.row_type){
            grid_data.splice(i,1)
            break
          }
        } 
      }
      _this.waitingJobList.rows = grid_data
    },
    onShowPD(record){
      this.objectdetails  = {'env_id':this.Environment.id,'object_id':record.process_definition_id,'object_name':record.process_definition_name,'isfromjob':true,'policy_date':record.actual_date}
      this.viewpd_dialog = true
    },
   onGraphView(record){
            // let routeData = this.$router.resolve({name: 'editprocessdef',
            //     query: { 'process_def_id': record.process_definition_id, 
            //     'is_read_only': true, 'env_id':  this.Environment.id, 'object_revision': record.process_definition_version}});
            // window.open(routeData.href, '_blank');   
      let designerHostDetails =_.find(this.$session.get('applications_configuration'),['product_name','OBJECT_DESIGNER'])
        let additionalUrl = getReadonlyRedirectionUrl('PD',this.Environment,{'object_id':record.process_definition_id,'object_version':record.process_definition_version,'object_type':'process definition'})
        let url = getURLByDomainByProduct(this, designerHostDetails,'login',additionalUrl)
        window.open(url, '_blank');  
    },
    updateRemainingTime(record){
      let obj = _.find(this.tableList.rows,["id",record.job_id])
      if(obj)
        obj.remaining_time = record.remaining_time
    },
  }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
  opacity: 0;
}
.showInput {
  visibility: inherit !important;
  width: 50% !important;
}

.srch-ip {
  width: 0px;
  background: white;
  color: black;
  float: right;
  transition: all 1s;
  visibility: hidden;
}

table {
  border: 2px solid grey;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
}

thead {
  line-height: 30px;
  transition: all 10s;
}

th {
  background-color: grey;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

.srch-icon {
  font-size: 16px;
  color: white;
  float: right;
  margin-top: 4%;
} */
</style>
