<template>
  <div id="table_list_panel" class="upperrow" style="width:20%;overflow: hidden;" :style="{'height':containerHeight}" ref="table_list_panel_ref" @mouseover="onMainDivMouseOver" @mouseleave="onMainDivMouseLeave">
    <div id="table_list_header" style="height:40px;background-color:#E0E0E0;position:relative;top:0px;" ref="table_list_header_ref">
      <!-- <v-icon small id="close_tablelist_icon" class="glyphicon" style="color:#494949;position:absulate;margin-top:5%;float:left;margin-right:1%;font-weight:bold;" ref="close_tablelist_icon_ref">
        chevron_left
      </v-icon> -->
      <v-row class="EDC-Row">
        <v-col cols="11" class="EDC-Col" style="padding-left:8px !important;">
          <v-text-field id="tbl_searchbox" dense hide-details v-on:keyup="perform_tablelist_serach" v-model="table_search" ref="tbl_searchbox_ref"
          @click.enter.native="perform_serverside_search(table_search)" :disabled="readonly"></v-text-field>
        </v-col>
        <v-col cols="1" class="EDC-Col" style="padding-top:16px !important;">
          <svgicon class="svg-icon-mini svg-fill-mini" name="Search" :original="true" ref="tbl_searchicon_ref" style="float:right !important;"></svgicon>
        </v-col>
      </v-row>

</div>
<div style="height:90%;overflow:auto;justify:left !important;">
  <v-progress-linear v-if="indeterminate" :indeterminate="indeterminate" style="margin:0px;"></v-progress-linear>
  <v-list :disabled="readonly" id="list_of_tables" ref="list_of_tables_ref"  dense expand>
    <draggable v-if="!tableListLoader" v-model="columnList" :sort="false" @start="startDrag" :options="dragOptions" :move="onMove">
      <v-list-item  hide-details dense v-for="item in columnList" :key="item.title" class="list_items tableListItem"
      ref="list_items_ref" :data-tabletitle='item.table_name'>
      <div style="width:25%;">
      <v-list-item-content>
        <v-list-item-title class="tabletitle EDC-ListItem" v-text="item.column_name" :title="item.column_name"></v-list-item-title>
      </v-list-item-content>
    </div>
    <div style="width:25%;">
      <v-list-item-content>
        <v-list-item-title class="tabletitle EDC-ListItem" v-text="item.table_name" :title="item.table_name"></v-list-item-title>
      </v-list-item-content>
    </div>
      <v-list-item-content  style="text-align:left;" v-if="showDescription">
        <v-list-item-title class="EDC-ListItem" v-text="getTrimDescription(item.description)" :title="item.description"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </draggable>
  <v-progress-circular v-if="tableListLoader"  :width="3" :size="20" color="blue" indeterminate></v-progress-circular>

</v-list>

</div>
</div> 
</template>
<script>
const cloneDeep = require('clone-deep');
import draggable from 'vuedraggable'
import _ from 'lodash'
import '../../compiled-icons';
import vcButton from '@/views/button.vue'

export default {
 components:{
  draggable,
  'vc-button':vcButton,
},
computed:{
  dragOptions () {
    return  {
      animation: 0,
      group: 'description',
    };
  },
},
name: 'ColumnList',
props: ['columnList','containerHeight','tableListLoader','steptablelist','readonly'],
data () {
  return {
    isDragging:false,
    defaultColumnList:[],
    indeterminate:false,
    height:"",
    table_search:'',
    env_id:'',
    schema:'',
    connection_str:'',
    db_type: '',
    tbl_object_type:[],
    showMoreActions:false,
    showDescription:true,
    checkDesciption:false,
    searchForTable:true,
    searchForView:true,
    searchForAlias:true,
    searchForWorkTable:true,
    defaultListOffsetWidth:0,
    resizeListWidth:"500px",
  } 
},
watch: {
  tableheight(newValue, o){
      // debugger
      height = newValue;
    },
    columnList(newvalue,o){
      this.defaultColumnList = newvalue
    },
    readonly(newvalue){

    }
  },
  mounted() {
    if(this.$refs.table_list_panel_ref.$el)
      var table_list_panel = this.$refs.table_list_panel_ref.$el
    else 
      var table_list_panel = this.$refs.table_list_panel_ref
    table_list_panel.addEventListener('scroll',function(e){});
    this.defaultListOffsetWidth = "300px"
    // alert(this.defaultListOffsetWidth)
    this.managetableListWidth(this.resizeListWidth)
  },
  methods:{
    managetableListWidth(width){
      if(this.$refs.list_of_tables_ref.$el){
      this.$refs.list_of_tables_ref.$el.style.width=width;
      // this.$refs.list_of_tables_ref.$el.style.clientWidth = width
      }
    else 
      {
        this.$refs.list_of_tables_ref.style.width=width;
        // this.$refs.list_of_tables_ref.style.clientWidth = width;
      }
    },
    showDescriptionChange(show){
      // if(show)
        this.managetableListWidth(this.resizeListWidth)
      // else
        // this.managetableListWidth(this.defaultListOffsetWidth)
    },
    getTrimDescription(description=""){
      var trimSize = 30
      if(!description)
        return ""
      // if(description.length <=trimSize)
      //     return description
      // var sub_description = description.substr(0,trimSize-3)+"..."
      return description
    },
    startDrag(event){      
      // debugger;
      if(this.readonly)
        return
      let title = event.item.getElementsByClassName("tabletitle")[0].innerText

      if(title){
        // title = title.split("\n")[0]
        title = title.trim()
      }
      let tableName = event.item.getElementsByClassName("tabletitle")[1].innerText
      let _this = this;
      let obj = _.cloneDeep(_.find(_this.columnList,function(o){
        return o.column_name === title && o.table_name === tableName
      }));
      if(!obj){
        if(event.item.dataset && event.item.dataset.tabletitle){
          obj = _.cloneDeep(_.find(_this.columnList,function(o){
            return o.column_name === title && o.table_name === event.item.dataset.tabletitle
          }));
        }
        if(!obj)
          return
      }
     this.$emit('dragged-object', obj);
   },
   onMove ({relatedContext, draggedContext}) {
      // this.$emit('dragged-object',draggedContext.element);
    },
    perform_tablelist_serach(event,loop){
      var input = this.table_search
      if(this.$refs.list_of_tables_ref.$el)
        var table_list = this.$refs.list_of_tables_ref.$el
      else 
        var table_list = this.$refs.list_of_tables_ref
      var list_items = table_list.getElementsByClassName('list_items')
      var filter = input.toUpperCase();
      var key_found = false
      for (var i = 0; i < list_items.length; i++) {
        if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1) 
        {
          list_items[i].style.display = "";
          key_found = true
        }
        else
          list_items[i].style.display = "none";
      }
      if(input.length == 0){
        this.columnList = cloneDeep(this.defaultColumnList)
      }
      
    },
    dragitem(event){
      var innerhtml = event.target.innerHTML
      var parser = new DOMParser()
      var doc = parser.parseFromString(innerhtml, "text/xml");
      var table_type = doc.getElementsByTagName('i')[0].getAttribute("title")
      event.dataTransfer.setData("table_name", event.target.innerText);
      event.dataTransfer.setData("table_type", table_type);
    },
    onMainDivMouseOver(){
      this.$emit('onMainDivMouseOver')
    },
    onMainDivMouseLeave(){
      this.$emit('onMainDivMouseLeave')
    }
  }
}
</script>
<style scoped>

.customCheckbox >>> label{
  top: 5px !important;
}

.v-input--selection-controls {
  margin-top:0px;
  padding-top:0px;
}

.tabletitle{
  
}
.minwidth{
  min-width:700px !important;
}

.minwidthwithoutstep{
  min-width:500px !important;
}

.EDC-ListItemMargin{
  margin-right:10px !important;
}

.tableListItem{
  min-height:32px !important;
  cursor: pointer;
}
</style>