<template>
  <div>
    <edc-data-grid :dataList="tableList" @onSort="sortBy" :loader="loader"  @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @UpdateFilter="filterData" @onDownload="onDownload" :key="uniqueGridId" @redirectToDataviewer="redirectToDataviewer"></edc-data-grid>
<!-- <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
  {{ snackbartext }}
</v-snackbar> -->
    <loading-panel :loader="loader"></loading-panel>
  </div>
</template>

<script>
import edcdatagrid from '../../../views/dataTable/edcdatagrid.vue'
import LoadingPanel from '../../../views/loading'
import config from '../../../config.json'
import { post as postToServer } from '../../../methods/serverCall.js';
import { GET_WORKTABLE_LIST,GET_WORKTABLE_DATA, EXPORT_WORK_TABLE} from '../../../data/url_constants.js';
import {manageScreenDisplay} from '@/methods/special.js'
import { SERVER_ERROR } from '../../../data/client_message.js';
import {DATABASE_NUMERIC_DATATYPE} from '../../../data/metaData.js';
import {getURLByDomainByProduct} from '@/methods/domain_management.js'
import {getPathfinderProduct} from "@/methods/commonFunc.js"
var moment = require("moment-timezone")
import cloneDeep from 'lodash/cloneDeep'
import {
  CLIENT_SIDE,
  SERVER_SIDE
} from '../../../data/macros.js'
import {mapActions} from 'vuex'

export default {
  data(){
    return{
      // job_id:this.$route.params.job_id,
      // env_name:this.$route.params.envname,
      // env_id:this.$route.params.env_id,
      // job_name:this.$route.params.jobname,
      is_duplicate_call:false,
      archivist_redirect_key: '',
      duplicate_filter:{},
      WorkTableList:[],
      WorkTableObj:{},
      CopyOfWorkTableObj:{},
      is_column_details_required: true,
      columns_details:[],
      header_list:[],
      ds_info:{},
      select_rows: true,
      tableList: {
        headers: [],
        actions: [],
        sorting_type: SERVER_SIDE,
        filterType: SERVER_SIDE,
        paginationType: SERVER_SIDE,
        total_count: 0,
        rows: [],
        selected_row: [],
        disableDefaltSorting:true,
        hideShowColumns:true,
        hideSelect:true,
        is_in_tab:false,
        showDataviewerRedirection:true
      },
      envIpJson : {
        "filter": [],
        "sort": [{
          "column_name": "",
          "type": ""
        }],
        "page": 1,
        "page_size": 10
      },
      snackbar: false,
      snackbartext: '',
      snackbartimeout: 5000,
      colorValue: 'success',
      valid: true,
      loader:false,
      loadingpanel:false,
      grid_total_count:0,
      uniqueGridId:1,
    }
  },
  components: {
    'edc-data-grid': edcdatagrid,
    'loading-panel':LoadingPanel
  },
  props:{
    worktableobj:Object
  },
  watch:{
    worktableobj(newvalue){
      if(newvalue){
        this.uniqueGridId++ 
        this.WorkTableObj = newvalue
        this.is_duplicate_call = this.WorkTableObj.is_duplicate
        this.duplicate_filter = cloneDeep(this.WorkTableObj.duplicate_filter)
        // this.CopyOfWorkTableObj = newvalue
      this.GetDataFromWorktable(this.envIpJson)
      }
    }
  },
  mounted() {
    this.envIpJson.page_size = manageScreenDisplay()
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    console.log(this.$route.params)
    // this.getWorkTables()
    // this.focus_worktable()
  },
  methods: {
    onDownload(downloadParams, filterArray){
      debugger;
        let _this = this
        if(!this.WorkTableObj.output_table_reference)
          return false
        if(this.CopyOfWorkTableObj && this.CopyOfWorkTableObj.output_table_reference!=this.WorkTableObj.output_table_reference){
          this.envIpJson = {
            "filter": [],
            "sort": [],
            "page": 1,
            "page_size": 10
          }
          this.columns_details = []
        }
        this.CopyOfWorkTableObj = cloneDeep(this.WorkTableObj)
        let inputJson = this.envIpJson 
        var data_to_pass = {
          "file_format": downloadParams.fileType,
          "table_name":this.WorkTableObj.output_table_name,
          "env_id":this.WorkTableObj.env_id,
          "step_name":this.WorkTableObj.step_name,
          "job_name":this.WorkTableObj.step_name,
          "job_id":this.WorkTableObj.job_id,
          "client_id":this.$session.get('client_id'),
          "filter": inputJson.filter,
          "sort": inputJson.sort,
          "ds_info":this.ds_info,
          "column_details": this.columns_details
        }
        if(_this.tableList.total_count === 0){
          alert('No data available for download')
          return
        }
        if(downloadParams.fileType === 'pdf'){
          alert('pdf download not supported')
          return
        }
        _this.loader=true;
          var url = config.ENGINE_API_URL+ EXPORT_WORK_TABLE;
          postToServer(this, url, data_to_pass).then(Response => {
            _this.loader=false;
            var url = config.ENGINE_API_URL + "/static/" + Response;
            window.open(url, '_blank');
          }).catch(JobStepError => {
            if(JobStepError){
              _this.loader = null
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = JobStepError;
            }
            else {
              _this.loader = null
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
          }
        });
      },

  //   focus_worktable(){
  //     if(this.$refs.wrktblname.$el)
  //       this.$refs.wrktblname.$el.focus()
  //     else this.$refs.wrktblname.focus()
  //   },
  hideLoader(){
    let _this = this;
    setTimeout(function(){
      _this.loader = false; 
    },500);
  },
  sortBy: function (sortJson) {
    let _this = this;
    if(sortJson.length <=0)
        return 
    let inputJson = _this.envIpJson;
    inputJson.sort = sortJson
    this.GetDataFromWorktable(inputJson);
  },
  onPageChange(page, perPage) {
    let _this = this;
    let inputJson = _this.envIpJson;
    inputJson.page = page;
    inputJson.page_size = perPage;
    this.GetDataFromWorktable(inputJson);
  },
  onPerPageChange(perPage,page) {
    let _this = this;
    let inputJson = _this.envIpJson;
    inputJson.page = page;
    inputJson.page_size = perPage;
    this.GetDataFromWorktable(inputJson);
  },
  filterData(filterArray,pageno){
    let _this = this;
    let inputJson = _this.envIpJson;
    inputJson.filter=filterArray;
    inputJson.page=1
    this.GetDataFromWorktable(inputJson);
  },
  // getWorkTables(){
  //   let _this = this
  //   var request_data = {"job_id":this.$route.params.jobid}
  //   postToServer(this, config.ENGINE_API_URL + GET_WORKTABLE_LIST,request_data).then(response => {
  //     this.WorkTableList = response
  //     this.focus_worktable()
  //   }).catch(JobStepError => {
  //     if(JobStepError){
  //       this.snackbar = true
  //       this.colorValue = 'error'
  //       this.snackbartext = JobStepError;
  //     }
  //     else {
  //       this.snackbar = true
  //       this.colorValue = 'error'
  //       this.snackbartext = SERVER_ERROR;
  //     }
  //   })
  // }, 
  redirectToDataviewer(){
    let _this = this
    var redirectionObj  = getPathfinderProduct(_this)
    let data_to_send = {
      "env_id":this.WorkTableObj.env_id,
      "client_id":this.$session.get('client_id'),
      "archivist_ds_type":["engine_data","source_data","target_data"],
      "request_for":"worktable",
      "job_name":this.WorkTableObj.job_name,
      "job_id":this.WorkTableObj.job_id,
      "table_list":this.WorkTableObj.available_table_list,
      "selected_table":{"table_name":this.WorkTableObj.output_table_reference}

    }
    _this.loader=true
    var cache_url = config.EDC_CACHE_SERVICE_URL + "/add_dataviewer_redirect_key"
    postToServer(_this, cache_url, data_to_send).then(response=>{
      _this.loader=false
      if(response){
      var archivist_redirect_key=response
      // write code here to create key and pass that key to the function as query_string
      let query_string = '&archivist_redirect_key='+archivist_redirect_key// append key here
      let url = getURLByDomainByProduct(_this,redirectionObj,'login',query_string)
      window.open(url, '_blank');
      }
      else{
        return
      }
    }).catch(error_response=>{
        _this.loader=false
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
    })
  },
  GetDataFromWorktable (envIpJson){
    let _this = this
    if(!this.WorkTableObj.output_table_reference)
      return false
    /* This check is for reset table headers if only table selection get changed. This method also get called from other events so thats why we used CopyObj concept. PageNumber in TableList json is only for set pagination to 1st page in case of selection change */
    if(this.CopyOfWorkTableObj && this.CopyOfWorkTableObj.output_table_reference!=this.WorkTableObj.output_table_reference){
      this.tableList = {
        headers: [],
        actions: [],
        sorting_type: SERVER_SIDE,
        filterType: SERVER_SIDE,
        paginationType: SERVER_SIDE,
        total_count: 0,
        rows: [],
        selected_rows: [],
        pageNumber:1,
        disableDefaltSorting:true,
        hideDownload:true,
        hideShowColumns:true,
        hideSelect:true,
        hideFilter:false,
        is_in_tab:true,
        itemkey:'rn',
        showDataviewerRedirection:true
      }
      this.envIpJson = {
        "filter": [],
        "sort": [{
            "column_name": "",
            "type": ""
        }],
        "page": 1,
        "page_size": 10
      }
      this.envIpJson.page_size = manageScreenDisplay()-5
      this.columns_details = [],
      this.is_column_details_required = true
    }
    this.CopyOfWorkTableObj = cloneDeep(this.WorkTableObj)
    let inputJson = cloneDeep(this.envIpJson)
    var filter = inputJson.filter
    var ds_info = cloneDeep(this.ds_info)
    if(this.is_duplicate_call){
      //in case of duplicate we create table on target. Design when different server finished

      // add table name filter
      filter.push(this.duplicate_filter)
    }
    var data_to_pass = {
      "table_name":this.WorkTableObj.output_table_name,
      "env_id":this.WorkTableObj.env_id,
      "client_id":this.$session.get('client_id'),
      "filter": filter,
      "page": inputJson.page,
      "page_size": inputJson.page_size,
      "sort": inputJson.sort,
      "ds_info":ds_info,
      "is_column_details_required":this.is_column_details_required,
    }
    _this.loader=true;
    postToServer(_this, config.ENGINE_API_URL + GET_WORKTABLE_DATA,data_to_pass).then(response => {
      _this.loader=false
      var data = response
      if(_this.is_column_details_required && response.columns_details){
          _this.columns_details = JSON.parse(response.columns_details.result)
          _this.is_column_details_required = false
        }
      if(response.total_count)
            _this.grid_total_count = response.total_count
      if(data && data.data.length > 0){
        data = data.data
        var column_list = []
        var obj_col_names = Object.keys(data[0]);
        obj_col_names.map(col=>{
          if(col.toLowerCase() != 'rn' && col.toLowerCase() !== 'total_count'){
            var align = 'left'
            var col_details = _.find(_this.columns_details,["column_name",col.toUpperCase()])
              if(col_details)
              {
                if(DATABASE_NUMERIC_DATATYPE.indexOf(col_details["data_type"].toLowerCase()) >=0)
                 align = "right"
              }

            // index calculated due issue no.554
            var index_of_current_col = _.indexOf(_this.columns_details,col_details)
            let header={ text: col.toUpperCase(), value: col ,'align':align, width:'100px',index:index_of_current_col};
            column_list.push(cloneDeep(header));   
          }
        })
        if(_this.tableList.headers.length === 0)
            _this.tableList.headers = _.sortBy(column_list,"index")
        _this.tableList.rows = data;
        if(data[0].total_count)
           _this.grid_total_count = data[0].total_count
        _this.tableList.total_count = _this.grid_total_count;
        this.ds_info = response.ds_info
        if(_this.tableList.pageNumber)
          _this.tableList.pageNumber = ''
      } else {
        if(_this.is_column_details_required && response.columns_details){
        let headers = []
        _this.columns_details.forEach((obj,indx)=>{
          headers.push({ text: obj.column_name, value: obj.column_name,index:indx})
        })
        _this.tableList.headers = headers
        }
        _this.grid_total_count = 0
        _this.tableList.total_count = _this.grid_total_count;
        _this.tableList.rows = []
       this.errorMsg = data.message
     }
     _this.hideLoader();
                // this.GetJobPercentage()
              }).catch(JobStepError => {
                _this.hideLoader();
                if(JobStepError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = 'Data not found for selected step. Step might be not executed yet';
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
                _this.hideLoader();
              })
            },
          }  
        }
        </script>
        <style scoped>
        .smaller{
          width:20px;
          height:20px;
          font: 10px
        }
        /* tr:nth-child(even) {background-color: #E8E8E8;}
        .blurOpacity{
          opacity: 0;
        }
        .showInput {
          visibility: inherit !important;
          width: 50% !important;
        }

        .srch-ip {
          width: 0px;
          background: white;
          color: black;
          float: right;
          transition: all 1s;
          visibility: hidden;
        }

        table {
          border: 2px solid grey;
          border-radius: 3px;
          background-color: #fff;
          cursor: pointer;
        }

        thead {
          line-height: 30px;
          transition: all 10s;
        }

        th {
          background-color: grey;
          color: rgba(255, 255, 255, 0.66);
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        th.active {
          color: #fff;
        }

        th.active .arrow {
          opacity: 1;
        }

        .arrow {
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          margin-left: 5px;
          opacity: 0.66;
        }

        .arrow.asc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid #fff;
        }

        .arrow.dsc {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #fff;
        }

        .srch-icon {
          font-size: 16px;
          color: white;
          float: right;
          margin-top: 4%;
        } */
        </style>