<template>
  <div>
        <!-- <v-flex xs10 ml-3>
        <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
              <li><a href="/processDefinitionList">Process Definition</a></li>
              <li><a href="/jobhistorylist">Jobs History</a></li><li>{{envname}}</li>
              <li>{{job_name}}({{job_id}})</li><li v-if='status === "running"'><a @click='get_steps_details()'>Refresh</a></li>
        </ul>
      </v-flex> -->
      <v-layout row wrap pl-4 class="breadcrumbBackground" mb-4>
        <v-flex xs8 style="margin:auto">
          <v-layout row wrap>
            <v-flex xs12 pl-3>
              <ul class="breadcrumb">
                <li v-if="$route.params.type == 'currentJobList'"><router-link to="/currentjoblist">Todays Job List</router-link></li>

                <li v-if="$route.params.type == 'jobHistory'"><router-link to="/jobhistorylist">Jobs History</router-link></li>
                <li v-if="$route.params.type == 'jobPlan'" >
                  <a  @click="backToJobPlan">[{{envname}}]</a>
                 </li>
                 <li v-else>
                   <span @click="$router.go(-1)">
                  <a>[{{envname}}]</a>
                </span> 
                 </li>
                <li v-if="$route.params.type == 'jobPlan'">
                  <a @click="backToJobPlan">Job Plan - [{{$route.params.job_plan_name}}]</a>
                  
                </li>
                
                <li>[{{job_id}}] - [{{job_name}}]</li><li>Table Row Details</li><li>{{status}}</li>
              </ul>
            </v-flex>
            <v-flex >

            </v-flex>
          </v-layout>

        </v-flex>
        <v-flex xs4 text-xs-right pr-4 pb-1 style="">

        </v-flex>
      </v-layout>
      <vc-data-table :data="tableList" @onDownload="onDownload"></vc-data-table>
      <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
        {{ snackbartext }}
      </v-snackbar>
      <loading-panel :loader="loader"></loading-panel>               
    </div>
  </template>
  <script>
  import config from '../../../config.json'
  import cloneDeep from 'lodash/cloneDeep';
  import Simplert from 'vue2-simplert'
  import { get as getToServer, post as postToServer } from '../../../methods/serverCall.js';
  import { GETSTEPSROWDETAILS, EXPORT_OPERATION_WISE_ROW } from '../../../data/url_constants.js';
  import { SERVER_ERROR } from '../../../data/client_message.js';
  import {
    CLIENT_SIDE,
    SERVER_SIDE
  } from '../../../data/macros.js'
  import dataTable from '../../../views/dataTable/datatable.vue'
  import loadingPanel from '../../../views/loading.vue'
  import _ from 'lodash'
  var moment = require("moment-timezone")
  export default {
    name: 'DemoGrid',
    data: function () {
      return {
       search:"",
       snackbar:false,
       snackbartext:'',
       snackbartimeout:4000,
       colorValue:'error',
       loader: false,
       loading: false,
       step_list: [],
       job_id:'',
       job_name: '',
       mode:'',
       status:'',
       env_id: '',
       envname:'',
       live_satus: [],
       isfromcurrentlist:false,
       temp_group: {},
       tableList: {
            headers: [
                { text: 'Business Object Name', value: 'business_object_name', cssClass:"w16", options:[],align:"left"},
                { text: 'Action', value: 'activity_type',width:"10%", options:[]},
                { text: 'Table Name', value: 'table_name',cssClass:"w16",},
                { text: 'Expected', value: 'expected_row',align:'right', width:"10%", options:[] },
                { text: 'Processed', value: 'process_row',align:'right', width:"20%", options:[], align:'right'},
                { text: 'Size (In MB)', value: 'size',align:'right', width:"20%", options:[]},
                {text: '% Completed', value: 'completed',align:'right', cssClass:"w16", options:[]},
                { text: 'Row Size (In MB)', value: 'row_size', sortable: false, cssClass:"w16", options:[], align:'right'
                }, 
                { text: 'Index Size (In MB)', value: 'index_size', sortable: false, cssClass:"w16", options:[], align:'right'
                },
            ],

            actions: [],
            sorting_type: CLIENT_SIDE,
            filterType: CLIENT_SIDE,
            paginationType: CLIENT_SIDE,
            total_count: 0,
            rows: [],
            pageNumber:1,
            disableDefaltSorting:true,
            select_rows:true,
            // syncHeaderScroll:false,
        },

      //  tableList: {
      //   headers: [
      //   { text: 'Business Object Name', value: 'business_object_name', width:"20%"},
      //   { text: 'Action', value: 'activity_type',width:"20%"},
      //   { text: 'Table Name', value: 'table_name',width:"15%" },
      //   { text: 'Expected Rows', value: 'expected_row',align:'right', width:"20%"},
      //   { text: 'Processed Rows', value: 'process_row',align:'right', width:"20%"},
      //   { text: 'Size (In MB)', value: 'size',align:'right', width:"20%"},
      //   { text: '% Completed', value: 'completed',align:'right',cssClass:"w2", width:"30%"},
      //   { text: '% Single Row Size', value: 'row_size',align:'right',cssClass:"w2", width:"20%"},
      //   { text: '% Single Index Size', value: 'index_size',align:'right',cssClass:"w2", width:"20%"},
      //   ],
      //   actions: [],
      //   sorting_type: CLIENT_SIDE,
      //   filterType: CLIENT_SIDE,
      //   paginationType: CLIENT_SIDE,
      //   total_count: 0,
      //   rows: [],
      //   syncHeaderScroll:false,
      // },
            //  flag:true,            
          }
        },
        components: {
          'vc-data-table': dataTable,
          'loading-panel':loadingPanel        
        },
        watch:{
          '$store.state.updatedRowCounts':{
            handler(newValue){
          this.UpdateRowCounts(newValue)
        }
      },
        '$store.state.liveStatus':{
          handler(newValue){
            if(this.job_id === newValue.job_id)
            this.status = newValue.status.charAt(0).toUpperCase() +newValue.status.slice(1)
          }
        }
    },
    mounted() {
     this.envname = this.$route.params.envname
     this.job_id =this.$route.params.jobid
     this.job_name = this.$route.params.jobname
     this.mode = this.$route.params.mode
     this.status = this.$route.params.status.charAt(0).toUpperCase() +this.$route.params.status.slice(1)
     this.isfromcurrentlist=this.$route.params.isfromcurrentlist
     this.env_id = this.$route.params.env_id
     this.get_steps_details()
   },
   methods: {
     onDownload(downloadParams, filterArray){
        let _this = this; 
        _this.loader=true;
        let filter=[];
          filterArray.map((obj, index)=>{
              obj.stringArray.map((stringObj, stringIndex)=>{
                  let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                      filter.push(cloneDeep(temp));
              })
          });
          let inputJson={
              "recordType":downloadParams.recordType,
              "row_option":downloadParams.recordType,
              "client_id": this.$session.get('client_id'),
              "env_id": this.env_id,
              "job_id": this.job_id,
              "mode":this.mode,
              "job_name": this.job_name,
              "file_format": downloadParams.fileType,
              "min_range": downloadParams.minRange|| null,
              "max_range": downloadParams.maxRange||null,
              "filter": filter
          }
          var url = config.ENGINE_API_URL+EXPORT_OPERATION_WISE_ROW;
          postToServer(_this, url, inputJson).then(Response => {
              var url = config.ENGINE_API_URL + "/static/" + Response;
              window.open(url, '_blank');
              _this.loader=false;

          }).catch(objError => {
            _this.loader=false;
            if(objError){
                  this.loader = null
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = objError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          });
      },
     get_steps_details(){
       let _this = this;
       this.loader = true
       var job_data = {"job_id": this.job_id, "env_id":this.env_id}
       postToServer(this, config.ENGINE_API_URL + GETSTEPSROWDETAILS, job_data).then(response => {
        if(response){
          response.map((obj)=>{
            obj.activity_type = obj.activity_type.charAt(0).toUpperCase()+obj.activity_type.slice(1)
            if(this.mode === 'proof'){
              obj.completed = '0.00'
            }
            else{
              obj.completed = '100.00' 
              if(obj.expected_row > 0 && obj.process_row < obj.expected_row)
                obj.completed = (obj.process_row/obj.expected_row *100).toFixed(2)
            }
          });
          this.loader = false,
          _this.tableList.rows= response
        }
      }).catch(JobStepError => {
        this.loader = false
        if(JobStepError){

          // this.snackbartext = JobStepError;
        }
        else {
          // this.snackbar = true
          // this.colorValue = 'error'
          // this.snackbartext = SERVER_ERROR;
        }
      })
    },
    UpdateRowCounts(data){
	let _this = this
	data.map((obj)=>{
		var data_already_in_row = false
		for(var i = _this.tableList.rows.length - 1; i >= 0; i--){
			if(_this.tableList.rows[i].job_id === obj.job_id  && _this.tableList.rows[i].business_object_id === obj.business_object_id && _this.tableList.rows[i].step_id===obj.step_id && _this.tableList.rows[i].table_name === obj.table_name){
				data_already_in_row = true
        _this.tableList.rows[i].expected_row = obj.expected_row
        _this.tableList.rows[i].size = obj.size
        _this.tableList.rows[i].process_row = obj.process_row
        _this.tableList.rows[i].index_size = obj.index_size
        _this.tableList.rows[i].row_size = obj.row_size

				_this.tableList.rows[i].completed = '100.00'
				if(_this.tableList.rows[i].expected_row > 0 && _this.tableList.rows[i].process_row < _this.tableList.rows[i].expected_row){
					_this.tableList.rows[i].completed = (obj.process_row/_this.tableList.rows[i].expected_row *100).toFixed(2)
				}
				break;
			}
		}
		if(!data_already_in_row && this.job_id === obj.job_id){
			obj.activity_type = obj.activity_type.charAt(0).toUpperCase()+obj.activity_type.slice(1)
			if(this.mode === 'proof'){
				obj.completed = '0.00'
			}else{
				obj.completed = '100.00' 
				if(obj.expected_row > 0 && obj.process_row < obj.expected_row){
					obj.completed = (obj.process_row/obj.expected_row *100).toFixed(2)
				}
			}
			_this.tableList.rows.push(obj)
		}
		})
	},
  backToJobPlan(){
    let _this = this;
           this.$router.push({ name: 'jobplaninstancedetails',
                              params: {
                                       job_plan_id: this.$route.params.job_plan_id,
                                       job_plan_instance_id: this.$route.params.job_plan_instance_id,
                                       job_plan_name:this.$route.params.job_plan_name,
                                       execution_environment: this.$route.params.env_id,
                                       execution_env_name: this.$route.params.envname}
                                     }
                            );
  },
    // UpdateRowCounts(data){
    //   let _this = this
    //   var data_already_in_row = false
    //   _this.tableList.rows.map((obj)=>{
    //     for (var i = data.length - 1; i >= 0; i--) {
    //       if(obj.job_id === data[i].job_id  && obj.business_object_id === data[i].business_object_id && obj.step_id===data[i].step_id && obj.table_name === data[i].table_name){
    //         data_already_in_row = true
    //         obj.process_row = data[i].process_row
    //         obj.completed = '100.00'
    //         if(obj.expected_row > 0 && obj.process_row < obj.expected_row)
    //             obj.completed = (data[i].process_row/obj.expected_row *100).toFixed(2)
    //       }
    //     }
    //   })
    //   if(!data_already_in_row){
    //     var obj = data
    //     obj.activity_type = obj.activity_type.charAt(0).toUpperCase()+obj.activity_type.slice(1)
    //     if(this.mode === 'proof'){
    //           obj.completed = '0.00'
    //         }
    //         else{
    //           obj.completed = '100.00' 
    //           if(obj.expected_row > 0 && obj.process_row < obj.expected_row)
    //             obj.completed = (obj.process_row/obj.expected_row *100).toFixed(2)
    //         }
    //       _this.tableList.rows.push(obj)
    //   }
    // },
  }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
  opacity: 0;
}
.showInput {
  visibility: inherit !important;
  width: 50% !important;
}

.srch-ip {
  width: 0px;
  background: white;
  color: black;
  float: right;
  transition: all 1s;
  visibility: hidden;
}

table {
  border: 2px solid grey;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
}

thead {
  line-height: 30px;
  transition: all 10s;
}

th {
  background-color: grey;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

.srch-icon {
  font-size: 16px;
  color: white;
  float: right;
  margin-top: 4%;
} */
</style>