<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row" v-if="!hideBreadscrumb">
			<v-col cols="8" class="EDC-Col">
				<v-row class="EDC-Row breadcrumbRow">
					<v-col cols="12" class="EDC-Col">
						<ul class="breadcrumb">
							<li v-if="showJobPlanbtn"><router-link to="/dashboard">{{Environment.name}}</router-link></li>
							<li v-if="!showJobPlanbtn" ><router-link to="/dashboard">{{Environment.name}}</router-link></li>
							<li v-if="!showJobPlanbtn"><router-link to="/process_def_list_for_execution">Job List</router-link></li>
							<li v-if="showJobPlanbtn"><router-link to="/jobplanlist">Job Plan</router-link></li>
							<li v-show="showJobPlanbtn"><router-link :to="{name:'managejobplan',params:{'job_plan_id':job_plan_id,'execution_environment':Environment.id,'execution_env_name':Environment.name}}">{{job_plan_name}}</router-link></li>
							<li v-show="showJobPlanbtn">Edit Job From Job Plan</li>
							<li v-show="!showJobPlanbtn">Create Job</li>
						</ul>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="4"></v-col>
		</v-row>

		<v-row class="EDC-Row" no-gutters align-content="center" style="margin-top:1% !important;">
			<v-col class="EDC-Col">
				<v-card>
					<v-row class="EDC-Row">
						<v-col class="EDC-Col" cols="12">
							<v-row class="EDC-Row" no-gutters>
								<v-col class="EDC-Cols" align="start">
									<span class="EDC-Heading">{{process_definition_name}}</span>
								</v-col>
								<v-col class="EDC-Cols" col="2"></v-col>
							</v-row>		
						</v-col>
					</v-row>
					<v-row class="EDC-Row">
						<v-col class="EDC-Col" cols="12">
							<v-tabs v-model="tabs" non-linear @change="loadTabs">
								<v-tab class="EDC-Tabs">
									Parameters
								</v-tab>
								<v-tab class="EDC-Tabs">
									Reports
								</v-tab>
								<v-tab class="EDC-Tabs">
									Limits
								</v-tab>
								<v-tab class="EDC-Tabs">
									Breakpoints
								</v-tab>
								<v-tab-item step="1" elevation="0">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="12" style="padding-bottom:4px !important;">
											<edc-data-grid :dataList="paramterTableList" key="jobgrid1" @ondblClick='editPolicyDate'></edc-data-grid>
										</v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item step="2" elevation="0" active-class>
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="12" style="padding-bottom:4px !important;">
											<edc-data-grid :dataList="reportTableList" key="jobgrid2"></edc-data-grid>
										</v-col>
									</v-row>
								</v-tab-item>
								<v-tab-item step="3" elevation="0">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="12" style="padding-bottom:4px !important;">
											<edc-data-grid :dataList="limitTableList" key="jobgrid3"></edc-data-grid>
										</v-col>
									</v-row>
									
								</v-tab-item>
								<v-tab-item step="4" elevation="0">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="12" style="padding-bottom:4px !important;">
											<edc-data-grid :dataList="breakpointTableList" key="jobgrid4"></edc-data-grid>
										</v-col>
									</v-row>
								</v-tab-item>
							</v-tabs>
						</v-col>
					</v-row>
					<v-row class="EDC-Row" style="margin-right:1% !important;">
						<v-col col="6" class="EDC-Col EDC-ControlPadding" align="left">
							<vc-button v-if="!hideRunBtn" :disabled="disableButtons" itemText="Create URL"  @click.native="CreateShortcut" :loading="shortcutLoading"></vc-button>
						</v-col>
						<v-col col="6" class="EDC-Col" align="end" style="margin-bottom:1% !important">
							<toggle-button :labels="{checked: 'Proof', unchecked: 'Final'}" :color="{'checked':'#9B9B9B','unchecked':'#607D8B'}" :width="70" :height="28" v-model="mode_of_execution" :sync="true" style="margin:1% !important;font-size:10px !important;" @change="modeChanged"></toggle-button>
							<vc-button v-if="!hideRunBtn" :disabled="disableButtons" itemText="Run" :loading="runloading" @click.native="CreateNewJob"></vc-button>
							<vc-button v-if="!hideScheduleBtn" :disabled="disableButtons" itemText="Schedule..." @click.native="CreateScheduleJob()"></vc-button>
							<vc-button v-if="showEditJobBtn" itemText="Save" @click.native="updateJobPlan()"></vc-button>
							<vc-button itemText="Cancel" @click.native="cancelJob()"></vc-button>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<v-col class="EDC-Col"cols="3" v-if="!hideHistory">
				<v-card class="EDC-CardSideMargin">
					<v-row class="EDC-Row">
						<v-col class="EDC-Col" cols="12">
							<v-row class="EDC-Row" no-gutters>
								<v-col class="EDC-Col" cols="12" align="start">
									<span class="EDC-Heading">Recent History</span>
								</v-col>
							</v-row>
							<v-row class="EDC-Row">
								<v-list dense style="width:100%; text-align:left" v-if="last_run_job_details.last_job_start_date">
									<v-list-item> 
										<v-list-item-title class="EDC-VListItemContent"><b>Avg. Duration:</b></v-list-item-title>
										<v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{last_run_job_details.avg_time}}</b> s</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title class="EDC-VListItemContent"><b>Last Run Date:</b></v-list-item-title>
										<v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{last_run_job_details.last_job_start_date}}</b></v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title class="EDC-VListItemContent"><b>Last Run Time:</b></v-list-item-title>
										<v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{last_run_job_details.last_job_start_time}}</b></v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title class="EDC-VListItemContent"><b>Last Duration:</b></v-list-item-title>
										<v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{last_run_job_details.last_job_duration}}</b> s</v-list-item-title>
									</v-list-item>
								</v-list>
								<span v-else class="EDC-NoRecordText EDC-ControlPadding">{{ NoDataFound }}</span>
							</v-row>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>


		<v-dialog persistent v-model="valueDialog" width="250" style="overflow-y:none;background:white">
			<v-card class="rounded-card">
				<v-toolbar dark dense>
					<v-flex class="text-md-center">Value Type
						<v-icon class="text-lg-left" @click="valueDialog = false;isEditing = false" style="color:#dedede;height:22px;float:right">
						fa-times-circle</v-icon>
					</v-flex>
				</v-toolbar>
				<v-card-text xs12>
					<v-form ref="form" lazy-validation @submit.prevent="savedata()">
						<v-layout row wrap align-center>
							<v-flex xs12 pr-3 pl-3>
								<v-autocomplete :items="valueTypeArray" v-model="valueType" label="Value Type" @input="inputValue=''"></v-autocomplete>
							</v-flex>
							<v-flex xs12 pr-3 pl-3>
								<edc-calender v-if="valueType == 'Actual Date'" :input="inputValue" @update="setDate(...arguments)"
								style="margin-right:10px"> </edc-calender>
								<v-text-field v-else v-model="inputValue" label="Value"></v-text-field>
							</v-flex>
							<v-flex xs12>
								<vc-button type="submit" itemText="Save"></vc-button>
							</v-flex>
						</v-layout>
					</v-form>
				</v-card-text>
			</v-card>    
		</v-dialog>

		<loading-panel :loader="loader"></loading-panel>

		<v-snackbar v-model="snackbar" :timeout="0" :color="colorValue" id="edcmessage" top right>{{ snackbartext }}
			<vc-button itemText="Close" x-small @click.native="snackbar = false">
				close
			</vc-button>
		</v-snackbar>

	</v-container>
</template>

<script>
	import moment from 'moment'
	import config from '../../config.json'
	import {
		get as getToServer,
		post as postToServer
	} from '@/methods/serverCall.js';
	import {
		SERVER_ERROR
	} from '@/data/client_message.js'
	import {GET_ALL_POLICY_LIST,FETCH_BUSINESS_FOR_SELECTED_OBJECT,GET_POLICY_VALUE_BY_POLICY_LIST, 
		GET_POLICY_LIST, DELETE_POLICY, VALIDATE_POLICY, CREATE_NEW_JOB, GET_JOB_BY_ENV_PD,
		FETCH_ALL_STEPS_OF_PD, RUN_COORDINATION, UPDATE_JOB_PLAN} from '@/data/url_constants.js';
	import {CLIENT_SIDE,SERVER_SIDE} from '@/data/macros.js'
	import {ACTIVITY_TYPE_SELECTIVE_RESTORE, ACTIVITY_TYPE_RESTORE,
		ACTIVITY_TYPE_SELECTIVE_DISPOSE} from '../../data/job_management.js'
	import cloneDeep from 'lodash/cloneDeep';
	import orderBy from 'lodash/orderBy';
	import _ from 'lodash'
	import environmentList from '@/methods/EnvironmentList.js'
	import Scheduler from "./../schedular/schedule.vue"
	import Calender from '@/views/calender'
	import {No_Data_Found, Snackbar_Default_Timeout} from '@/constants/constants.js'
	import {getURLByDomainByProduct} from '@/methods/domain_management.js'
	import {Archivist_Product,Workflow_Product,Dataviewer_Product} from '@/constants/constants.js'
	import {getEnvironmentList,environmentSessionBinding} from '@/methods/EnvironmentList.js'

	export default {
	name:"CreateJob",
	components: {
	'edc-calender':Calender
	},
	data: function() {
	return {
		currentRecord:{},
		valueType:"",
		inputValue:"",
		valueTypeArray:['Days','Months','Quarters','Years','Actual Date'],
		valueDialog:false,
		mode_of_execution:true,
		excel_file_format:"xlsx",
		Environment:{},
		process_definition_name: this.$store.state.createJobParams.process_doc_name,
		process_definition_id:this.$store.state.createJobParams.process_definition_id,
		reports_rows:[],
		limit_rows:[],
		breakpoint_rows:[],
		runloading:false,
		tabs:0,
		loader:false,
		job_for_edit:{},
		job_plan_name:'',
		showJobPlanbtn:false,
		job_plan_id:'',
		last_run_job_details:{},
		showSchedule:false,
		NoDataFound: No_Data_Found,
		snackbar:false,
		snackbarButton:true, //variable used in serverCall.js
		snackbartext:'',
		colorValue:'success',
		snackbartimeout: Snackbar_Default_Timeout,
		commonPropsForGridHide:{
			showExport:false,
			showInlineFilter:false,
			showActions:false,
			perPage:5,
			showFooter:false,
			showSelect:false
		},
		paramterTableList: {
			headers: [],
			actions: [],
			rows: [],
			perPage:5
		},
		reportTableList: {
			headers: [],
			actions: [],
			perPage:5,
			rows: []
		},
		limitTableList: {
			headers: [],
			actions: [],
			perPage:5,
			rows: []
		},
		breakpointTableList: {
			headers: [],
			actions: [],
			perPage:5,
			rows: [],
		},
		disableButtons:true,
		EnvironmentList:[],
		shortcutLoading:false,
		}
	},
	props:{
		hideBreadscrumb:{
			type:Boolean,
			default:false
		},
		hideHistory:{
			type:Boolean,
			default:false
		},
		hideMode:{
			type:Boolean,
			default:false
		},
		hideRunBtn:{
			type:Boolean,
			default:false
		},
		hideScheduleBtn:{
			type:Boolean,
			default:false
		},
		showEditJobBtn:{
			type:Boolean,
			default:false
		},
		editJobObj:{
			type:Object,
			default:{},
		},
		requestFor:{
			type:String,
			default:''
		}
	},
	watch:{
		'hideBreadscrumb':{
			handler(newValue){
			}
		},
		'hideHistory':{
			handler(newValue){
			}
		},
		'hideMode':{
			handler(newValue){
			}
		},
		'hideRunBtn':{
			handler(newValue){

			}
		},
		'hideScheduleBtn':{
			handler(newValue){

			}
		},
		'showEditJobBtn':{
			handler(newValue){

			}
		},
		'editJobObj':{
			handler(newValue){
				this.loadJobPlanData(newValue)
			},
			deep:true
		},
		'requestFor':{
			handler(newValue){

			}
		},
    	'EnvironmentList':{
    		handler(newValue){
      			environmentSessionBinding(this,newValue)
		    }
		},
	},
	mounted(){
		let _this = this
		_this.paramterTableList = this.getGridObj([{ text: 'Parameter', value: 'parameter', title: 'Parameter'},
			{ text: 'Type', value: 'type',   title: 'Parameter Type'},
			{ text: 'Default', value: 'default',    title: 'Parameter Default Value'},
			{ text: 'Job Value', value: 'actual_date',  title: 'Parameter Job Value'}],'paramter',true,this.commonPropsForGridHide)
		
		_this.limitTableList = this.getGridObj(
			[{ text: 'Step Name', value: 'step_name', title: 'Step Name'},
			{ text: 'Step Type', value: 'step_type',   title: 'Step Type'},
			{ text: 'Default', value: 'default_limit',   title: 'Default Limit'},
			{ text: 'Revised', value: 'limit',  title: 'Revised Limit',useTextField:true, inputType:'number'}],'id',true, this.commonPropsForGridHide)
		
		this.commonPropsForGridHide['showFooter'] = true
		this.commonPropsForGridHide['showItemPerPage'] = false
		_this.reportTableList = this.getGridObj(
			[{ text: 'Step Name', value: 'step_name', title: 'Step Name'},
			{ text: 'Step Type', value: 'step_type',   title: 'Step Type'},
			{ text: 'XLSX', value: 'xlsx',    title: 'XLSX file type',useCheckbox:true},
			{ text: 'CSV', value: 'csv',    title: 'CSV filetype',useCheckbox:true}],'step_name',true, this.commonPropsForGridHide)

		this.commonPropsForGridHide['showSelect'] = true
		this.commonPropsForGridHide['allPagesRowSelection'] = true
		_this.breakpointTableList = this.getGridObj(
			[{ text: 'Step Name', value: 'step_name', title: 'Step Name'},
			{ text: 'Step Type', value: 'step_type',   title: 'Step Type'}],'step_name',true, this.commonPropsForGridHide)

		var env_value = this.$session.get('selected_env')
		if( env_value && env_value.id != '0' && env_value.id !='all_env'){
			this.Environment = this.$session.get('selected_env')
		}
		if(!this.requestFor){
			if(this.$route.params.is_from_shortcut){
				this.Environment = this.$route.params.environment
				// environment bind happen in dashboard. But when user use short cut, he is not going to the visit dashboard at first place, for that purpose we need do this binding here. For more check WATCH for ENVIORNMENT LIST
				getEnvironmentList(this)
			}
			this.getRecentHistory()

		}
		else if(this.requestFor === 'editjobplan'){
			this.loadJobPlanData(this.editJobObj)
		}
		this.loadJobInfo()
	},
	methods:{
		savedata(){
			let _this= this;
			let inputList = cloneDeep(this.paramterTableList.rows);

			// New requirements from the Nirav - Jira 139.
			// Policy change should get applied to all policies 

			// let obj = inputList.find(function(elem){
			// 	if(elem.id == _this.currentRecord.id)
			// 		return elem;
			// });
			// if(obj){
			// 	obj.policy_value = this.inputValue;   
			// 	obj.value_type = this.valueType === "Actual Date" ? "actual_date":this.valueType;
			// }
			// this.isEditing = false;
			// this.valueDialog = false;
			// this.ValidatePolicyChange(obj)

			// new code 
			_.forEach(inputList,(obj)=>{
				obj.policy_value = this.inputValue;   
				obj.value_type = this.valueType === "Actual Date" ? "actual_date":this.valueType;
				_this.isEditing = false;
				_this.valueDialog = false;
				_this.ValidatePolicyChange(obj)
			})
		},
		setDate(param){
			this.inputValue = param; 
		},
		setMaxDate(param){
			this.massEndValue = param; 
		},
		setMinDate(param){
			this.massStartValue = param; 
		},
		editPolicyDate(record){
			this.currentRecord = record;
			this.valueType = record.value_type === "actual_date"? "Actual Date":record.value_type;
			this.inputValue = record.policy_value;
			if(record.type == 'Policy')
				this.valueDialog = true;
			this.isEditing = true;
		},
		ValidatePolicyChange(record){
			// this.snackbar = false
			var data ={'env_id': this.Environment.id, 'policy_details': record,'pd_id':this.process_definition_id}
			var end_point = config.POLICY_URL +'/validate_policy'
			this.loader = true
			postToServer(this, end_point, data).then(response => {
				if(response){
					this.loader = false
					var obj = response
					obj.parameter = obj.bo_name+'.'+obj.policy_type;
					obj.type = 'Policy';
					obj.default = obj.value_type == 'actual_date' && obj.policy_value ||obj.policy_value+' ' + obj.value_type;
					var index = _.findIndex(this.paramterTableList.rows,['id',record.id])
					this.$set(this.paramterTableList.rows,index,obj)
				}
			}).catch(ProcessDefError => {
				this.loader = false
				this.is_error_occured = true
				if(ProcessDefError ==='Not Found')
					return false
				if(ProcessDefError){
					this.loader = null 
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = ProcessDefError;
				}
				else {
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = SERVER_ERROR;
				}   
			})
		},

		loadJobInfo(){
			this.GetStepList()
			var url = config.PUBLISHER_URL + FETCH_BUSINESS_FOR_SELECTED_OBJECT
			var data = {'env_id': this.Environment.id, 'object_id': this.$route.params.process_definition_id,'client_id':this.$session.get('client_id')}
			if(this.$route.params.is_rexecute_request){
				url = config.ENGINE_API_URL + '/get_job_details'
				data = {'env_id': this.Environment.id,'job_id':this.$route.params.job_id}
				if(this.$route.params.exe_mode === 'Final')
					this.mode_of_execution = false
			}
			this.getJobParametersDetails(url,data,this.$route.params.is_rexecute_request)
		},
		loadJobPlanData(newValue){
			let _this = this
			_this.limit_rows = []
			_this.paramterTableList.rows = []
			_this.reports_rows = []
			_this.breakpoint_rows = []
			_this.tabs=0
			this.job_for_edit = this.$store.state.jobPlanDetails.job_list[newValue.job_sequence - 1]
			this.job_plan_name = newValue.job_plan_name
			this.execution_env_name = this.$session.get('selected_env').name
			this.execution_environment  = newValue.execution_environment
			var response = []
			if(this.job_for_edit.policy_details)
				response = this.job_for_edit.policy_details
			var unique_response = _.uniqBy(response, function (e) {return e.bo_id;});
			unique_response.map(obj => {
				obj.parameter = obj.bo_name+'.'+obj.policy_type;
				obj.type = 'Policy';
				obj.default = obj.value_type == 'actual_date' && obj.policy_value ||obj.policy_value+' ' + obj.value_type;
				_this.paramterTableList.rows.push(cloneDeep(obj));
			});
			this.bo_policy_details = unique_response  

			var repository_response = _this.job_for_edit.limit_details
			var limit_steps = ["archival","copy","purge","jde_summ_f0911","jde_summ_f4111"]
			if(repository_response){
				_.forEach(repository_response,function(obj){
					if(limit_steps.indexOf(obj.step_type.toLowerCase()) > -1){
						if(!obj.default_limit){
							obj.default_limit = obj.limit
						}
						obj.is_row_editable = true
						obj.isNumber = true
						_this.limit_rows.push(obj)
					}
				})
				_this.getLimitHistory()
			}
			_this.loadJobInfo()
		},
		loadTabs(tab_number){
			var _this = this
			setTimeout(function(){
				if(tab_number=== 1)
					if(_this.reportTableList.rows.length===0)
						_this.reportTableList.rows = _this.reports_rows
					if(tab_number === 2)
						if(_this.limitTableList.rows.length === 0)
							_this.limitTableList.rows = _this.limit_rows
						if(tab_number === 3)
							if(_this.breakpointTableList.rows.length === 0)
								_this.breakpointTableList.rows = _this.breakpoint_rows

						},0)
		},
		GetStepList(){
			var _this = this
			var input_json  = { 
				'object_id': this.process_definition_id,
				'env_id':this.Environment.id
			}
			postToServer(this, config.REPOSITORY_API_URL +FETCH_ALL_STEPS_OF_PD, input_json).then(repository_response => {
				_this.breakpoint_rows = cloneDeep(repository_response)
				var report_rows = cloneDeep(repository_response)
				var plan_selected_report = []
				if(_this.job_for_edit && _this.job_for_edit.reports_generation_details)
					plan_selected_report = _this.job_for_edit.reports_generation_details
				_.forEach(report_rows,function(obj){
					obj.xlsx = false
					obj.pdf = false
					obj.csv = false

					if(plan_selected_report){
						var rep_obj = _.find(plan_selected_report,['step_id',obj.step_id])
						if(rep_obj){
							if(rep_obj.xlsx)
								obj.xlsx = true
							if(rep_obj.csv)
								obj.csv =  true
							if(rep_obj.pdf)
								obj.pdf = true
						}
					}
					obj.is_row_editable = true
					if(obj.step_type != 'Procedure' && obj.step_type != 'Report')
						_this.reports_rows.push(obj)  
				})

				if(!_this.requestFor){
					var limit_steps = ["archival","copy","purge","jde_summ_f0911","jde_summ_f4111"]
					if(repository_response){
						_.forEach(repository_response,function(obj){
							if(limit_steps.indexOf(obj.step_type.toLowerCase()) > -1){
								obj.default_limit = obj.limit
								obj.is_row_editable = true
								obj.isNumber = true
								_this.limit_rows.push(obj)
							}
						})
						_this.getLimitHistory()
					}
				}
			}).catch(repository_response_error => {

			});
		},
		getJobParametersDetails(url,data,is_rexecute_request){
			this.loader = true; 
			// this.disableActions = true
			postToServer(this, url, data).then(response => {
				this.disableButtons = false
				this.loader = false
				if(is_rexecute_request){
					/* from ENGINE we got some other data along with policy details but our requirement is we need only policy details to process the below code.*/
					response = response.policy_details
					if(response && response.length > 0){
						/* in case of if user go to schedule this job as a reccruence job then we no need to recalcute this policy values.*/
						response.map(obj =>{
							obj.already_calculated = true
						})
					}
				}
				if (response && response.length > 0) {
					console.log('policy response',response)
					var unique_response = response
					unique_response.map(obj => {
						obj.parameter = obj.bo_name+'.'+obj.policy_type;
						obj.type = 'Policy';
						obj.default = obj.value_type == 'actual_date' && obj.policy_value ||obj.policy_value+' ' + obj.value_type;
						this.paramterTableList.rows.push(cloneDeep(obj));
					});


					this.loader = false;
					this.bo_policy_details = unique_response                
				}}).catch(ProcessDefError => {
					this.loader = false
					if(ProcessDefError ==='Not Found')
						return false
					if(ProcessDefError){
						this.loader = null 
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = ProcessDefError;
					}
					else {
						this.snackbar = true
						this.colorValue = 'error'
						this.snackbartext = SERVER_ERROR;
					}
				})
		},
	GetJobData(is_from_url=false){
		// For URL we will only stored specific data because we have to keep URL as compact as we can.
		var _this = this
		var client_id = this.$session.get('client_id')
		var toggle_details = this.mode_of_execution ? 'proof':'final';
		var created_by = this.$session.get('email')
		var policy_details = _.cloneDeep(_this.getPolicyDetails())
		_this.reports_generation_details = _.cloneDeep(_this.reportTableList
			.rows)
		var reports_step_list = []
		_.forEach( _.cloneDeep(_this.reports_generation_details),function(obj){
			var file_type_list = []
			if(obj.xlsx)
				file_type_list.push(_this.excel_file_format)
			if(obj.csv)
				file_type_list.push("csv")
			if(obj.pdf)
				file_type_list.push("pdf")
			obj["file_type"] = file_type_list
			if(file_type_list.length > 0)
				reports_step_list.push(obj)
		})
		if(policy_details){
			for (var i = policy_details.length - 1; i >= 0; i--) {
				delete policy_details[i].isSave
				delete policy_details[i].isCancel
				delete policy_details[i].is_row_editable
				delete policy_details[i].default
				if(is_from_url){
					
					policy_details[i]['policy_date'] = policy_details[i]['actual_date']
					if(policy_details[i].policy_type === 'Archival policy')
						policy_details[i].policy_type = 'A'
					else if (policy_details[i].policy_type === 'Retention policy')
						policy_details[i].policy_type = 'R'
					else
						policy_details[i].policy_type = 'S'

					delete policy_details[i].default_date
					delete policy_details[i].fiscal_year
					delete policy_details[i].id
					delete policy_details[i].parameter
					// delete policy_details[i].policy_type
					delete policy_details[i].policy_value
					delete policy_details[i].type
					delete policy_details[i].value_type
					policy_details[i]['business_object_id'] = policy_details[i]['bo_id']
					// policy_details[i]['business_object_name'] = policy_details[i]['bo_name']
					// policy_details[i]['effective_date'] =  policy_details[i]['actual_date']
					delete policy_details[i].bo_id
					delete policy_details[i].bo_name
					delete policy_details[i].actual_date
					delete policy_details[i].already_calculated
				}
			}
		}

		let limit_details = _.cloneDeep(this.limitTableList.rows)
		if(!limit_details.length)
			limit_details = _.cloneDeep(_this.limit_rows)
		if(is_from_url){
			if(limit_details && limit_details.length){
				for(var k=0;k<limit_details.length;k++){
					delete limit_details[k].business_object_id
					delete limit_details[k].isNumber
					delete limit_details[k].is_report
					delete limit_details[k].is_row_editable
					delete limit_details[k].step_type
					delete limit_details[k].step_id
					delete limit_details[k].default_limit
					
				}
			}
			if(reports_step_list && reports_step_list.length){
				for(var k=0;k<reports_step_list.length;k++){
					delete reports_step_list[k].business_object_id
					delete reports_step_list[k].is_report
					delete reports_step_list[k].is_row_editable
					delete reports_step_list[k].limit
					delete reports_step_list[k].xlsx
					delete reports_step_list[k].csv
					delete reports_step_list[k].pdf
					delete reports_step_list[k].step_id
				}
			}
		}

		var job_data = {
			'client_id': client_id,
			'process_definition_id': this.process_definition_id,
			'process_definition_name': this.process_definition_name,
			'created_by':created_by ,
			'mode_of_execution':toggle_details,
			'execution_environment': this.Environment.id,
			'execution_env_name': this.Environment.name,
			'mode_of_execution':toggle_details,
			'exe_type':'on_demand',
			'policy_details':policy_details,
			'limit_details':limit_details,
			"reports_generation_details":reports_step_list
		}
		if(is_from_url){
			job_data['environment_id'] = job_data['execution_environment']
			delete job_data['execution_environment']
			// job_data['environment_name'] = job_data['execution_env_name']
			delete job_data['execution_env_name']
			delete job_data['process_definition_name']
			delete job_data['exe_type']
			
			job_data['policy_override'] = job_data['policy_details']
			delete job_data['policy_details']
		
			job_data['limit_override'] = job_data['limit_details']
			delete job_data['limit_details']
			
			job_data['reports'] =  job_data['reports_generation_details']
			delete job_data['reports_generation_details']
			delete job_data['client_id']
			delete job_data['created_by']
			job_data['mode_of_execution'] = 'P'
			if(toggle_details === 'final')
				job_data['mode_of_execution'] = 'F'
			// job_data['exe_type'] = 'on_schedule'
			// job_data['url_for'] = 'run_job'
		}
		return job_data 
	},
	getPolicyDetails(){
		var _this = this
		var return_policy_list = []
		_.forEach(_this.paramterTableList.rows,function(obj){
			if(obj.type.toLowerCase() === "policy"){
				return_policy_list.push(obj)
			}
		})
		return return_policy_list
	},
	CreateNewJob(){
		let _this = this
		var breakpoints = _.map(this.breakpointTableList.allPagesRowSelectionList,'step_id')
		var data = this.GetJobData()
		data['breakpoints'] = breakpoints
		_this.runloading = true
		postToServer(this, config.COORDINATOR_API_URL + RUN_COORDINATION, data).then(response => {
			_this.runloading = false
			if (response) {

				if(response.code ===2004 || response.code===1004){
					this.snackbar = true
					this.colorValue = 'info'
					this.snackbartext = response.message;
					return
				}

				if (response.code != 2012){
					this.colorValue = 'info'
					var self = _this
					setTimeout(function(){
						_this.runloading = false
						if(!self.create_job_error_occured) 
							self.$router.push('/currentjoblist')
					}, 1000);    
				}
				else{
					this.colorValue = 'error'
				}

			}}).catch(ProcessDefError => {
				_this.runloading = false
				if(ProcessDefError){
					this.loader = null 
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = ProcessDefError;
				}
				else {
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = SERVER_ERROR;
				}
			})
		},
		CreateScheduleJob(){
			var data = this.GetJobData()
			data['exe_type'] ='on_schedule'
			this.$router.push({'name' : 'schedulejob', 'params' : { schedule_data: data, type:'new', service_id:config.COORDINATOR_SERVICE_ID,category:'Job', end_point: config.END_POINT_FOR_SCHEDULER}});

		},
		getRecentHistory(){
			let _this = this
			var mode = this.mode_of_execution?"proof":"final"
			var data = {"env_id":this.Environment.id,"pd_id":this.process_definition_id,"mode_type":mode}
			var url = config.ENGINE_API_URL + '/last_run_job_details'
			postToServer(_this, url, data).then(response => {

				_this.last_run_job_details = response
			}).catch(ProcessDefError => {
				_this.runloading = false
				if(ProcessDefError){
					this.loader = null 
					// this.snackbar = true
					// this.colorValue = 'error'
					// this.snackbartext = ProcessDefError;
				}
				else {
					// this.snackbar = true
					// this.colorValue = 'error'
					// this.snackbartext = SERVER_ERROR;
				}
			})
		},

		updateJobPlan(){
			let _this = this
			this.loader = true; 
			var data_to_send = this.GetJobData()
			data_to_send['job_id'] = this.job_for_edit.id
			data_to_send['job_plan_id'] = _this.$store.state.jobPlanDetails.id
			let baseUrl = config.JOB_PLAN_URL;
			baseUrl = baseUrl+ '/update_job_from_job_plan';
			postToServer(_this, baseUrl, data_to_send
				).then(response => {
					this.loader = false;
	    		// _this.$router.push({
	    		// 	name: 'managejobplan',
	    		// 	'params': {
	    		// 		job_plan_id:_this.$store.state.jobPlanDetails.id,
	    		// 		execution_environment:_this.Environment.id,
	    		// 		execution_env_name: _this.Environment.name
	    		// 	}
	    		// });
	    		_this.$emit('closePopUp')

	    	}).catch(CurrentJobError => {
	    		this.loader = false;
	    		console.log(CurrentJobError)
	    		this.snackbar = true;
	    		this.colorValue = 'error'
	    		this.snackbartext = CurrentJobError;
	    	})

	    },
	    cancelJob(){
	    	if(this.requestFor === 'editjobplan')
	    		this.$emit('closePopUp')
	    	else
	    		this.$router.go(-1);
	    },
	    modeChanged(val){
	    	this.last_run_job_details = {}
	    	this.getRecentHistory()
	    },
	    getLimitHistory(){
	    	let _this = this
	    	let client_id = this.$session.get('client_id')
	    	let env_id = this.Environment.id
	    	let bo_id_list = _.map(_this.limit_rows,"business_object_id")
	    	var data = {'env_id':env_id,'client_id':client_id,'bo_id_list':bo_id_list}
	    	let url = config.JOB_STATISTIC_URL +'/get_limit_history'
	    	postToServer(_this,url,data).then(response=>{
	    		console.log('response')
	    		_.forEach(_this.limit_rows,function(obj){
	    			_.forEach(response,function(bo_obj){
	    				if(bo_obj.bo_id === obj.business_object_id)
	    					obj.limit = bo_obj.best_limit
	    			})
	    		})
	    	}).catch(exception=>{

	    	})
	    },
	    CreateShortcut(){
	    	let _this = this
	    	var data = _this.GetJobData(true)
			var breakpoints = _.map(this.breakpointTableList.allPagesRowSelectionList,'step_name')
			data['breakpoints'] = breakpoints
			var dataToSend = {"request_body":data,"api_for":"run_job"}
	    	this.shortcutLoading = true
	    	postToServer(_this,config.GATEWAY_API_URL+'/get_all_api_list/run_job',dataToSend).then(response=>{
	    		this.shortcutLoading = false
	    		console.log(response)
	    		if(response.formatted_content){
					let textWithSingleQuate = response.formatted_content.replaceAll("'",'"')
	    			navigator.clipboard.writeText(textWithSingleQuate)
			    	_this.snackbar = true
			    	_this.colorValue = 'success'
			    	_this.snackbartext = "URL with help text data copied to clipboard."
	    		}
	    		else{
	    			_this.snackbar = true
			    	_this.colorValue = 'error'
			    	_this.snackbartext = "URL copied failed."
	    		}
	    	}).catch(exception=>{
	    		this.shortcutLoading =false
	    		_this.snackbar = true
			    _this.colorValue = 'error'
			    _this.snackbartext = "URL copied failed."
	    	})
	    	// _this.$store.state.customMenuInfo  = {"params":data,"taskURL":shortcutURL,"task_for":'Run Job','service_id':config.COORDINATOR_SERVICE_ID,'end_point': config.END_POINT_FOR_SCHEDULER}
	    	
	    }
	}
}
</script>
<style scoped>
.EDC-SubHeading{
	font-size: 12px !important;
	float: left !important;
	color:var(--v-primary-base) !important;
}

div[role=listitem]:nth-child(even){
	background: #F8F8F8 !important;
}

</style>