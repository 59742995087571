<template>
    <div>
        <v-row class="EDC-Row" v-if="!hideBreadscrumb">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li v-if="showJobPlanbtn">{{execution_env_name}}</li>
                            <li v-if="showJobPlanbtn"><router-link to="/jobplanlist">Job Plan List</router-link></li>
                            <li v-if="showJobPlanbtn"><router-link :to="{name:'managejobplan',params:{'job_plan_id':job_plan_id,'execution_environment':Environment.id,'execution_env_name':Environment.name}}">{{job_plan_name}}</router-link> </li>
                            <li v-if="!showJobPlanbtn">
                                <select v-model="Environment" v-on:change="getProcessDefList" style="-webkit-appearance: menulist" autofocus ref="envname">
                                    <option :value="{}">Select Environment</option>
                                    <option v-for="(env, index)
                                     in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                                </select> 
                            </li>
                            <li>Job List</li>
                        </ul>
                    </v-col>                     
                </v-row>
            </v-col>
            <v-col cols="4" class="EDC-Col">
            </v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @checkLink="checkLink"  @onDownload="onDownload" @onCreate="onExecute" @onCellEvent="onExecute" @onCreateJobPlan="onCreateJobPlan" @onAddMultiple="onAddMultipleJobs" @ondblClick="onExecute" @showObjectTable="onShowPD" @ShowObjectGraph="onGraphView"></edc-data-grid>

        <v-dialog v-model="showjobplan" width="500" height="500" persistent>
            <v-col class="EDC-Col">
                <v-card>
                    <v-toolbar dark dense>
                        <v-col class="text-md-center">Arrange Process Definitions
                            <v-icon class="text-lg-left" @click="showjobplan = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
                        </v-col>
                    </v-toolbar>
                        <v-col cols="12">
                            <table class="table table-striped table-bordered draggable_table"  id="select_column_table" style="margin-top: 3%;">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Process Definition Name</th>
                                </tr>
                            </thead>
                            <draggable v-model="selectedProcessDefinitions" :element="'tbody'">
                                <tr v-for="(item, idx) in selectedProcessDefinitions" :key="idx" >
                                    <td class="handle" style="max-width: 28px;">::</td>
                                    <td class="text-xs-left" v-model="item.object_name">{{item.object_name}}</td>
                                </tr>
                            </draggable>
                            </table>
                        </v-col>
                        <v-col cols="12">
                            <vc-button type="button" @click.native="executeJobPlan" itemText="Execute Plan"></vc-button>
                        </v-col>
                </v-card>
            </v-col>
        </v-dialog>
         <v-dialog v-model="viewobj_dialog" width="unset" height='600' eager persistent>
        <v-col class="EDC-Col">
          <v-card class="rounded-card">
            <v-toolbar dark dense max-height="40">
              <v-col class="EDC-Col text-md-center">Object Viewer
                <v-icon class="text-lg-left" @click="viewobj_dialog = false" style="color:#dedede;font-size:15px;float:right">fa-times-circle</v-icon>
              </v-col>
            </v-toolbar>
            <v-card-text>
            <v-row no-gutters class="EDC-Row">
              <edc-pdviewer :objectdetails="objectdetails" key="pdviewer" v-if="viewobj_dialog && showpd"> 
              </edc-pdviewer>
            </v-row>
          </v-card-text>
          </v-card>
        </v-col>
      </v-dialog>
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        
    </div>
</template>

<script>
import moment from 'moment'
import Breadcrumb from "../../Breadcrumbs.vue"
import config from '../../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from '../../../methods/serverCall.js';
import {SERVER_ERROR} from '../../../data/client_message.js'
import { ALL_PROCESS_DEF, GET_ALL_PROCESS_DEF_ENV, UNPUBLISH_TEMPLATE, ALL_PUBLISHED_PROCESS_DEF_LIST,EXECUTE_JOB_PLAN, EXPORT_JOB_LIST,FETCH_BUSINESS_FOR_SELECTED_OBJECT} from '../../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {SCHEDULER_SERVICE,SCHEDULE_ACTION,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from "../../../data/macros.js"
import getUserRole from '../../../methods/GetUserRole.js'
import {getEnvironmentList} from '../../../methods/EnvironmentList.js'
import draggable from 'vuedraggable'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import objectPDTabularView from '../../../views/objectPDTabularView.vue'
import {getReadonlyRedirectionUrl} from '../../../methods/commonFunc.js'
import {getURLByDomainByProduct} from '@/methods/domain_management.js'

export default {
    name: 'DemoGrid',
    data: function () {
        return {
            userRole:{},
            loader:false,
            showpd:false,
            viewobj_dialog:false,
            process_def: null,
            search: '',
            process_doc_list: [],
            EnvironmentList: [],
            Environment: {},
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            headers: [
                { text: 'Name', value: 'object_name', width:'20%', title: 'Job Name'},
                    // { text: 'Type', value: 'object_type', sortable: false },
                    { text: 'Ver', value: 'object_version',  options:[], align:'right', width:'10%', title: 'Job Version', dataType:'int'},
                    { text: 'Published By', value: 'published_by',  options:[], width:'20%', title: 'Job Published By'},
                    { text: 'Published Date', value: 'timezone_aware_published_date',   options:[],width:'20%', title: 'Job Published Date', dataType:'date'},
                    { text: 'Published Time', value: 'timezone_aware_published_time',   options:[],width:'20%',title: 'Job Published Time', dataType:'time',hideInlineFilter: true},
                    { text: 'Object ID', value: 'object_id', width: '200px', title: 'Object Id', sortable: false, align:'right'},
                    ], 
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },  
            // tableList: {
            //     headers: [
            //     { text: 'Name', value: 'object_name', width:'20%', title: 'Job Name'},
            //         // { text: 'Type', value: 'object_type', sortable: false },
            //         { text: 'Ver', value: 'object_version',  options:[], align:'right', width:'10%', title: 'Job Version', dataType:'int'},
            //         { text: 'Published By', value: 'published_by',  options:[], width:'20%', title: 'Job Published By'},
            //         { text: 'Published Date', value: 'timezone_aware_published_date',   options:[],width:'20%', title: 'Job Published Date', dataType:'date'},
            //         { text: 'Published Time', value: 'timezone_aware_published_time',   options:[],width:'20%',title: 'Job Published Time', dataType:'time',hideInlineFilter: true},
            //         { text: 'Object ID', value: 'object_id', width: '200px', title: 'Object Id', sortable: false, align:'right'},
            //         ],               
            //     actions: [],//if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     disableDefaltSorting:true,
            //     hideExport:false,
            //     hideShowColumns:false
            //     // syncHeaderScroll:false,
            // },
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            showEnvType:false,
            valid:true,
            dbTypefltrAry:[],
            nameTypefltrAry:[],
            'envTypefltrAry':[],
            'chip1':true,
            'searchEnv':"",
            'showname':false,
            'showDBType':false,
            chips: [],
            items: [],
            sorting : config.SORTING_TYPE,
            envIpJson : {
                "filter": [],
                "sort": {
                    "column": "",
                    "type": ""
                },
                "page": 1,
                "page_size": 5
            },
            isloading:false,
            menu:false,
            edit:"",
            perPage: 5,
            perPageArray: [5, 10, 50, 'all'],
            page: 1,
            totalPages:1,
            totalRecords:1,
            filterKey: "",
            sortingData: [],
            inputArray: {
                'Environment Type': [],
                'Name': [],
                'Database Type': [],
                'Database Location':[],
                'Action': []
            },
            sortKey: '',
            sortOrders: {
                'type': 1,
                'name': 1,
                'environment_database_type': 1,
                'databases_locations':1,
                'Action': 1
            },
            showjobplan:false,
            selectedProcessDefinitions:[],
            showJobPlanbtn:false,
            job_plan_name:null,
            execution_environment:null,
            execution_env_name:null,
            policy_details:[],
            job_plan_id:this.$store.state.jobPlanDetails.job_plan_id
        }
    },
    components: {
        draggable,
        'edc-pdviewer':objectPDTabularView,
    },
    props:{
        hideBreadscrumb:{
            type:Boolean,
            default:false
        },
        showAddMultiple:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        'hideBreadscrumb':{
            handler(newValue){
            }
        },
        'showAddMultiple':{
            handler(newValue){
                
            }
        }
    },
    mounted() {
        console.log(this.hideBreadscrumb)
        this.tableList = this.getGridObj(this.headers,'object_name')
        this.userRole = this.$session.get('user_role_mapping');
        if(this.showAddMultiple){
            this.tableList.hideExport=true
            this.tableList.hideShowColumns=true
            this.showCreateSchedulejob = false
            this.showJobPlanbtn = true
            this.job_plan_name = this.$route.params.job_plan_name
            this.execution_environment = this.$route.params.execution_environment,
            this.execution_env_name = this.$route.params.execution_env_name
            this.tableList.actions=[{'text':'addmultiple','key':"object_name", selectType:"multiple", role:true,index:1, 'displayText':'Add Jobs to Job Plan'}
            ];
        }
        else{
            this.tableList.actions=[{'text':'create','key':"object_name", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,SCHEDULE_ACTION) || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION),index:1, 'displayText':'Run'},
                {'text': 'showpd', 'key': 'object_name',  selectType:"single", index:2, role:true}
            ];
        }

        var env_value = this.$session.get('selected_env')
        if( env_value && env_value.id != '0' && env_value.id !='all_env'){
            this.Environment = this.$session.get('selected_env')
            this.getProcessDefList()
        }
        getEnvironmentList(this)
        this.focus_env()
    },
    methods: {
        focus_env(){
            if(this.$refs.envname.$el)
                this.$refs.envname.$el.focus()
            else this.$refs.envname.focus()
        },
    onDownload(downloadParams, filterArray){
        let _this = this
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                filter.push(cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "row_option": downloadParams.recordType,
            "client_id": _this.$session.get('client_id'),
            "env_id": _this.Environment.id,
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.PUBLISHER_URL+EXPORT_JOB_LIST;    
        postToServer(_this, url, inputJson).then(Response => {
            var url = config.PUBLISHER_URL + "/static/" + Response;
            window.open(url, '_blank');
            _this.loader=false;
        }).catch(objError => {
            _this.loader=false;
            if(objError){
              _this.loader = null
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = objError;
          }
          else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
          }
      })
    },
    getProcessDefList () {
        this.$session.set('selected_env', this.Environment)
        let _this = this;
        _this.loader = true; 
        var client_id = this.$session.get('client_id')
        var t_data = {'env_id': this.Environment.id, 'client_id': client_id}
        if (this.Environment.id == 'all'){
            this.getAllProcessDefList();
        } else {

            postToServer(this, config.PUBLISHER_URL + ALL_PUBLISHED_PROCESS_DEF_LIST, t_data).then(response => {
                _this.loader = false; 
                var data = response

                if(data){
                    // this.process_def = null
                    // data.map(obj=>{
                    //     obj.showEdit = false;
                    //     obj.showPublish = true;
                    //     obj.showUnPublish = true;
                    //     if(_this.Environment.allow_create){
                    //         obj.showEdit = true;
                    //         obj.showUnPublish = false;
                    //     }
                    // })
                // this.tableList.headers = [
                //     { text: 'Name', value: 'object_name', sortable: false },
                //     // { text: 'Type', value: 'object_type', sortable: false },
                //     { text: 'Ver', value: 'object_version', sortable: false, options:[], align:'right', width:'10%'},
                //     { text: 'Published By', value: 'published_by', sortable: false, options:[] },
                //     { text: 'Published Date', value: 'timezone_aware_published_date', sortable: false,  options:[] },
                //     { text: 'Published Time', value: 'timezone_aware_published_time', sortable: false,  options:[] }
                // ],
                // this.tableList.actions =[{'text':'create','key':"", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,REPOSITORY_SERVICE,CREATE_ACTION)}]
                data.map(function(obj){
                    obj.display_name = obj.object_name.toUpperCase()
                })
                data = _.sortBy(data,["display_name"])
                this.tableList.rows = data;
                this.tableList.total_count =data.length; 
                this.process_doc_list = data
            }
            else
            {
                data = []
                this.tableList.rows = data;
                this.tableList.total_count = data.length; 
            }
        }).catch(ProcessDocError => {
            _this.loader = false; 
            if(ProcessDocError){
                this.tableList.rows = [];
            }
            else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
            }
        });
    }
},


onExecute:function(record){
    if(this.showAddMultiple)
        return false
    let previousUrl = null;
    let job_plan_name = null
    let execution_env_name= null
    let execution_environment = null
    if(this.$route.params.previousUrl && this.$route.params.previousUrl == 'jobPlan')
        previousUrl = 'jobPlan';
    job_plan_name = this.$route.params.job_plan_name
    execution_env_name = this.$route.params.execution_env_name
    execution_environment = this.$route.params.execution_environment

    this.$store.state.createJobParams={ 'process_definition_id': record.object_id, 
    'process_doc_name': record.object_name, 'activity_id':null,
    'environment':this.Environment, 'is_restore':false};
    this.$router.push({ name: 'createJob', params: { 'process_definition_id': record.object_id, 
        'process_doc_name': record.object_name, 'activity_id':null,
        'environment':this.Environment, 'is_restore':false,
        previousUrl:previousUrl, job_plan_name:job_plan_name,
        execution_env_name:execution_env_name,
        execution_environment:execution_environment, id :this.$route
        .params.id}});

    // this.$router.push({ name: 'managejob', params: { 'process_definition_id': record.object_id, 'process_doc_name': record.object_name, 'environment':this.Environment}});            
},
checkLink(record){
    let _this = this;
    _this.Environment = _.find(_this.EnvironmentList, ['id',record.env_id]);
    _this.process_def = record.id
},
    onCreateJobPlan(record){
        this.showjobplan = true
        this.selectedProcessDefinitions = cloneDeep(record)
    },
    executeJobPlan(){
        var job_data = {
            "client_id":this.$session.get('client_id'),
            "created_by":this.$session.get('email'),
            'execution_environment': this.Environment.id,
            'execution_env_name': this.Environment.name,
            'mode_of_execution':"proof",
            'exe_type':'on_demand',
            'policy_details':[
            ]
        }
        var job_details = []
        for(var i=0;i<this.selectedProcessDefinitions.length;i++){
            var selected_row = this.selectedProcessDefinitions[i]
            var current_job_data = cloneDeep(job_data)
            current_job_data['process_definition_id'] = selected_row['object_id']
            current_job_data['process_definition_name'] = selected_row['object_name']
            job_details.push(current_job_data)
        }
        var data_to_send = {"client_id":this.$session.get('client_id'),"job_details":job_details}
        postToServer(this, config.JOB_MANAGEMENT_URL + EXECUTE_JOB_PLAN, data_to_send).then(response => {

        }).catch(ProcessDefError => {
            this.create_job_error_occured = true
            if(ProcessDefError){
              this.loader = null 
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = ProcessDefError;
          }
          else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
          }
      })
        var self = this
        setTimeout(function(){
            if(!self.create_job_error_occured) 
              self.$router.push('/currentjoblist')
      }, 1000);
    },
    onAddMultipleJobs(records){
        this.loader = true; 
        var _this = this
        var job_list = cloneDeep(this.$store.state.jobPlanDetails.job_list)
        for (var i = 0; i < records.length; i++) {
            var data = {'env_id': this.Environment.id, 'object_id': records[i].object_id,'client_id':this.$session.get('client_id')}
            var _this = this
            // var policy_details = new Promise(function(resolve){return resolve(_this.getJobParametersDetails(data))})
            var job_data = {
              'client_id': this.$session.get('client_id'),
              'process_definition_id': records[i].object_id,
              'process_definition_name': records[i].object_name,
              'created_by':this.$session.get('email') ,
              'mode_of_execution':'final',
              'execution_environment': this.Environment.id,
              'execution_env_name': this.Environment.name,
              'exe_type':'on_demand',
              'other_job_details_need_to_fetch':true,
              'limit_details':[],
              "reports_generation_details":[]
          }
          this.$store.state.jobPlanDetails.job_list.push(cloneDeep(job_data))

      }

      postToServer(_this, config.JOB_PLAN_URL +'/update_job_plan', this.$store.state.jobPlanDetails
          ).then(response => {
            // _this.$router.push({
            //     name: 'managejobplan',
            //     'params': {
            //         job_plan_id:_this.$store.state.jobPlanDetails.job_plan_id,
            //         execution_environment:_this.$store.state.jobPlanDetails.execution_environment,
            //         execution_env_name: _this.$store.state.jobPlanDetails.execution_env_name
            //     }
            // });

            // code added for clear selection after job get added to job plan
            var rows = _.cloneDeep(_this.tableList.rows)
            _this.tableList.rows = []
            _this.tableList.rows = rows
            this.loader = false; 
            this.$emit('closePopUp')

        }).catch(CurrentJobError => {
            this.$store.state.jobPlanDetails.job_list = job_list
           this.loader = false; 
           console.log(CurrentJobError)
           this.snackbar = true;
           this.colorValue = 'error'
           this.snackbartext = CurrentJobError;
       })
    },
    getJobParametersDetails(data){
        this.policy_details = []
        var url = config.PUBLISHER_URL + FETCH_BUSINESS_FOR_SELECTED_OBJECT
        postToServer(this, url, data).then(response => {
            if(response){
                this.policy_details = response

                return response
            }
        }).catch(ProcessDefError => {
            this.loader = false
            if(ProcessDefError ==='Not Found')
                return []
            if(ProcessDefError){
                this.loader = null 
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = ProcessDefError;
            }
            else {
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;
            }
            return ProcessDefError
        })
    },
    onShowPD(record){
        this.showpd = true
      this.objectdetails  = {'env_id':record.env_id,'object_id':record.object_id,'object_name':record.object_name,'isfromjob':true}
      this.viewobj_dialog = true
    },
   onGraphView(record){
        // let routeData = this.$router.resolve({name: 'editprocessdef',
        //     query: { 'process_def_id': record.object_id, 
        //     'is_read_only': true, 'env_id':  record.env_id, 'object_revision': record.object_version}});
        // window.open(routeData.href, '_blank'); 
        let designerHostDetails =_.find(this.$session.get('applications_configuration'),['product_name','OBJECT_DESIGNER'])
        let additionalUrl = getReadonlyRedirectionUrl('PD',this.Environment,record)
        let url = getURLByDomainByProduct(this, designerHostDetails,'login',additionalUrl)
        window.open(url, '_blank');  
    }
}
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>
