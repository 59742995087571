import { render, staticRenderFns } from "./JobPlanInstanceDetails.vue?vue&type=template&id=53edc0b6&scoped=true&"
import script from "./JobPlanInstanceDetails.vue?vue&type=script&lang=js&"
export * from "./JobPlanInstanceDetails.vue?vue&type=script&lang=js&"
import style0 from "./JobPlanInstanceDetails.vue?vue&type=style&index=0&id=53edc0b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53edc0b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VCard,VCol,VContainer,VRow,VSnackbar,VTab,VTabItem,VTabs})
