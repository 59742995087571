<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow" align="center">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <router-link to="/datasourcelist">Datasources</router-link>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form ref="form" v-model="valid">
        <v-card elevation-1>
          <v-container class="EDC-Container">
            <v-col cols="12" class="EDC-Col">

              <v-row no-gutters align="center" class="EDC-Row" style="padding-top:20px !important">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Name of Datasource" v-model="DatasourceName" required :rules="DatasourceNameRules" ref="dsname" class="EDC-UIComponent" dense></v-text-field>
                  <!-- <input type="text" autofocus onFocus="this.select()" v-model="DatasourceName" @blur="untitle_datasource" ref="dsname" class="EDC-UIComponent"/> -->
                </v-col>
                <v-col cols="8" class="EDC-Col EDC-ColsSpacing" style="margin-top: -15px !important">
                  <v-row no-gutters align="center" class="EDC-Row">
                    <v-col cols="2" class="EDC-Col">
                      <label class="EDC-UIComponent" style="float:left" dense>Database Type:</label>
                    </v-col>
                    <v-col cols="10" class="EDC-Col">
                      <v-radio-group v-model="databaseType" row hide-details :rules="databaseTypeRules" @change="setPort" class="EDC-UIComponent" dense>
                        <v-radio v-for="db in databaseTypes" :key="db.id" :label="db.name" :value="db.id" :color="colorCode" class="radioClass" dense></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row> 
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Host" v-model="Host" class="EDC-UIComponent" dense></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Port" v-model="Port" required @keypress="isNumber" :rules="PortRules" class="EDC-UIComponent" dense></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Database Name" v-model="Database"  class="EDC-UIComponent" dense></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Schema Name" v-model="SchemaName"  class="EDC-UIComponent" dense></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-checkbox class="radioClass" :disabled="disableUseWindowsAuth" v-model="useWindowsAuth" label="Use Windows Authentication" hide-details dense></v-checkbox>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="User Name" :disabled="disableCredentials" v-model="UserName"  class="EDC-UIComponent"  dense></v-text-field>
                </v-col>                
              </v-row>

              <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Password" :disabled="disableCredentials" v-model="UserPassword" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1"  class="EDC-UIComponent" dense></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-autocomplete clearable autocomplete :items="agent_list" item-text="machine_name" :rules="agentRules" v-model="mapped_agent" label="Select Agent" class="EDC-UIComponent" return-object dense></v-autocomplete>
                </v-col>
                <!-- <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field label="Timeout (in seconds)" v-model="Timeout1" @keypress="isNumber" required :rules="TimeoutRules" class="EDC-UIComponent" dense></v-text-field>
                </v-col> -->
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-textarea label="Description" rows=1 v-model="Description" class="EDC-UIComponent"></v-textarea>
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing" v-if="databaseType === 'db2' || databaseType === 'db2i'">
                  <v-text-field label="Database Nickname(if available)" v-model="db2nickname" class="EDC-UIComponent"></v-text-field>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing"></v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing"></v-col>
              </v-row>

              <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                <vc-button itemText="Save" @click.native="submit"></vc-button>
                <vc-button itemText="Test Connection" @click.native="TestDBConnection" :loading="testConnectionLoader"></vc-button>
                <vc-button itemText="Cancel" @click.native="onCancel"></vc-button>
              </v-row>

            </v-col>
          </v-container>
        </v-card>
      </v-form>
    </v-container>

    <connectionstring v-if="type_connection_sting"></connectionstring>
    <loading-panel :loader="loader"></loading-panel> 
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
        
  </div>
</template>

<script>
import config from '../../config.json'
import Breadcrumb from "../Breadcrumbs.vue"
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { ADD_DATASOURCE, DS_AND_DB_TYPES, CHECK_CONNECTION, UPDATE_DATASOURCE, GET_ALL_AGENT_MASTER } from '../../data/url_constants.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
     name: 'Schedule',
     data() {
      return {
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        testConnectionLoader:false,
        valid: false,
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        loader: false,
        loading: false,
        DatasourceName: 'Untitled Datasource',
        databaseTypes: [],
        datasourceProperties: [],
        databaseType: "ms_sql",
        Host: '',
        // DatasourceProperty: null,
        Port: '',
        UserName: '',
        UserPassword: '',
        Database: '',
        SchemaName: '',
        Description: '',
        Timeout1: '10',
        agent_list:[],
        mapped_agent:{},
        type_connection_sting:  false,
        db2nickname:'',
        show1: false,
        row:'datasource',
        databaseTypeRules: [v => !!v || 'Database Type is required.'],
        DatasourceNameRules: [v => !!v || 'Datasource Name is required.'],
        HostRules: [v => !!v || 'Host is required.'],
        // DatasourcePropertyRules:[v => !!v || 'Datasource Property is required.'],
        PortRules: [v => !!v || 'Port is required.',
                              v => v.length <= 6 || 'Port must be 4 digit'],
        UserNameRules: [v => !!v || 'User Name is required.'],
        UserPasswordRules: [v => !!v || 'Password is required.'],
        DatabaseRules: [v => !!v || 'Database Name is required.',
                              v => /^[a-zA-Z0-9-_\. ]+$/.test(v) || 'Database Name must be valid'],
        SchemaNameRules: [v => !!v || 'Schema Name is required.',
                              v => /^[a-zA-Z0-9-_ ]+$/.test(v) || 'Schema Name must be valid'],
        TimeoutRules: [v => !!v || 'Timeout is required.',
        v => v > 0 || 'Timeout must be grater than 0'],
        agentRules: [
          v => !!v || 'Agent is required'
        ],
        useWindowsAuth:false,
        defaultPassword:'********'
      }
    },
     components: {
    Breadcrumb
  },
  props: {
    msg: String
  },
  computed:{
    disableCredentials(){
      return this.databaseType === 'ms_sql' && this.useWindowsAuth
    },
    disableUseWindowsAuth(){
      return this.databaseType != 'ms_sql'
    }
  },
  watch:{
    databaseType:{
      handler(newValue){
        this.useWindowsAuth=false
      }
    },
    useWindowsAuth:{
      handler(newValue){
        this.UserName = ''
        this.UserPassword = ''
      }
    }
  },
  mounted () {
       this.get_agent_details()
       this.GetDSAndDBTypes()
       if(this.$refs.dsname.$el)
        this.$refs.dsname.$el.focus()
      else this.$refs.dsname.focus() 
    },
  methods: {
    onCancel(){
      this.$router.push("/datasourcelist")
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    submit () {
        if (this.$refs.form.validate()) {
          // Native form submission is not yet supported
          this.Generate_Connection_String()
        if(this.$route.params.datasourceid && this.$route.params.type && this.$route.params.type == 'edit'){
              this.updateData()
        } else {
          this.addDatasource()
        }
        }
    },        
    untitle_datasource(){
      if (!this.DatasourceName.trim()){
        this.DatasourceName = 'Untitled Datasource'
      }
    },
    setPort(){
          let portArray = {"ms_sql":"1433","oracle":"1521","db2":"50000","db2i":"446"}
          // console.log("dbtype "+this.collection.form.dbtype);
          this.Port = portArray[this.databaseType];
    },
    GetDSAndDBTypes() {
        getToServer(this, config.DATA_SOURCE_URL + DS_AND_DB_TYPES +this.$route.params.datasourceid, true).then(response => {
                var data = response
                // this.databaseTypes.push({"id":"Select DB type","name":"Select DB type"})
                for (var i = 0; i< data.database_name_dict.database.length; i++)
                {
                  this.databaseTypes.push(data.database_name_dict.database[i])
                }
                for (var j = 0; j< data.datasource_property_dict.datasource_property.length; j++)
                {
                  this.datasourceProperties.push(data.datasource_property_dict.datasource_property[j])
                }
                if(this.$route.params.datasourceid)
                  {
                    console.log(data.datasource_details.datasource_info)
                    this.Host=data.datasource_details.datasource_info.host
                    this.Port=data.datasource_details.datasource_info.port
                    this.UserName=data.datasource_details.datasource_info.user
                    this.UserPassword=this.defaultPassword
                    this.Database=data.datasource_details.datasource_info.database_name
                    this.SchemaName=data.datasource_details.datasource_info.schema_name
                    this.DatasourceName= this.$route.params.type == 'copy' ? 'Copy_of_'+data.datasource_details.datasource_info.datasource_name
                    :data.datasource_details.datasource_info.datasource_name;
                    if(data.datasource_details.datasource_info.useWindowsAuth)
                      this.useWindowsAuth = true
                    this.databaseType=data.datasource_details.datasource_info.database_type
                    this.Timeout1=data.datasource_details.datasource_info.timeout
                    // this.DatasourceProperty=data.datasource_details.datasource_info.datasource_property
                    this.row=data.datasource_details.datasource_info.datasource_type
                    this.Description = data.datasource_details.datasource_info.description
                    if(data.datasource_details.datasource_info.db2nickname)
                      this.db2nickname = data.datasource_details.datasource_info.db2nickname
                    this.mapped_agent = {
                       'id': data.datasource_details.datasource_info.mapped_agent_id,
                       'machine_name':data.datasource_details.datasource_info.mapped_agent_name
                     }
                    console.log("testing......................")
                    console.log(this.mapped_agent)
                    if(!data.datasource_details.datasource_info.mapped_agent_id && this.agent_list.length > 0)
                      this.mapped_agent = this.agent_list[0]
                    if(this.$refs.dsname.$el)
                        this.$refs.dsname.$el.focus()
                      else this.$refs.dsname.focus()

                     
                  }
                else
                  this.setPort()    

           }).catch(error_response => {
            if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
              this.loader = false 
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
        })
     },
    addDatasource(){
      var client_id = this.$session.get('client_id')
      var user_id = this.$session.get('user_id')
      var datasource_input_details = {
            "client_id": client_id,
            "user_id": user_id,
            "host": this.Host,
            "port": this.Port,
            "user": this.UserName,
            "password": this.UserPassword===this.defaultPassword?'':this.UserPassword,
            "database_name": this.Database,
            "schema_name": this.SchemaName,
            "datasource_name": this.DatasourceName,
            "database_type": this.databaseType,
            "description": this.Description,
            "timeout": this.Timeout1,
            // "datasource_property": this.DatasourceProperty,
            "datasource_type": this.row,
            "connection_string": this.connection_string,
            'mapped_agent_id': this.mapped_agent.id,
            'mapped_agent_name': this.mapped_agent.machine_name,
            "useWindowsAuth": this.useWindowsAuth,
            "copied_id": this.$route.params.datasourceid
          }
      if(this.databaseType === "db2" || this.databaseType === "db2i")
      {
        datasource_input_details["db2nickname"] =this.db2nickname
      }
      var add_datasource_end_point = config.DATA_SOURCE_URL + ADD_DATASOURCE
      postToServer(this, add_datasource_end_point, datasource_input_details
        ).then(datasourceResponse  => {
            if(datasourceResponse){
              this.colorValue = 'success'
              this.snackbar = true
              this.snackbartext = datasourceResponse;
              this.$cookies.set('datasource_added', true)
              this.$router.push("/datasourcelist")
            }
        }).catch(DatasourceError => {
          if(DatasourceError){
              this.loader = false 
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = DatasourceError;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
      });
    },
    updateData () {
      console.log(this.mapped_agent)
      var client_id = this.$session.get('client_id')
      var user_id = this.$session.get('user_id')
      var datasource_details = {
        'client_id': client_id,
        'user_id':user_id,
        'host': this.Host,
        'port': this.Port,
        'user': this.UserName,
        'password': this.UserPassword===this.defaultPassword?'':this.UserPassword,
        'database_name': this.Database,
        'schema_name': this.SchemaName,
        'database_type': this.databaseType,
        'datasource_id':this.$route.params.datasourceid,
        'datasource_name': this.DatasourceName,
        'description':this.Description,
        'timeout':this.Timeout1,
        // 'datasource_property':this.DatasourceProperty,
        'datasource_type':this.row,
        'connection_string':this.connection_string,
        'mapped_agent_id': this.mapped_agent.id,
        'mapped_agent_name': this.mapped_agent.machine_name,
        "useWindowsAuth":this.useWindowsAuth
      }
      console.log(datasource_details)
      if(this.databaseType === "db2" || this.databaseType === "db2i")
      {
        datasource_details["db2nickname"] =this.db2nickname
      }
      postToServer(this, config.DATA_SOURCE_URL + UPDATE_DATASOURCE, datasource_details
        ).then(datasourceResponse  => {
        if(datasourceResponse){
          this.colorValue = 'success'
          this.snackbar = true
          this.snackbartext = datasourceResponse;
          this.$cookies.set('datasource_added', true)
          this.$router.push("/datasourcelist")
        }
      }).catch(DatasourceError => {
      if(DatasourceError){
          this.loader = false 
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = DatasourceError;
        }
        else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      });
    },

    Generate_Connection_String(){
      this.connection_string = ''
      return
      if(this.databaseType=='ms_sql'){
        if(this.useWindowsAuth)
          this.connection_string='mssql://'+this.Host+':'+this.Port+'/'+this.Database+"?trusted_connection=yes&driver=ODBC Driver 17 for SQL Server"
        else
          this.connection_string='mssql://'+this.UserName+':'+this.UserPassword+'@'+this.Host+':'+this.Port+'/'+this.Database+"?driver=ODBC Driver 17 for SQL Server"
      }
      else if(this.databaseType=='oracle'){
          this.connection_string='oracle+cx_oracle://'+this.UserName+':'+this.UserPassword+'@'+this.Host+':'+this.Port+'/?service_name='+this.Database
      }
      else if(this.databaseType=='db2'){
          this.connection_string='ibm_db_sa://'+this.UserName+':'+this.UserPassword+'@'+this.Host+'/'+this.Database
      } else if(this.databaseType=='db2i'){
          this.connection_string='ibm_db_sa+pyodbc400://'+this.UserName+':'+this.UserPassword+'@'+this.Host+'/'+this.Database+'?TRANSLATE=1'
      }
    },
    get_agent_details(){
      var client_id = this.$session.get('client_id')
      var agent_endpoint = config.AGENT_API_URL + GET_ALL_AGENT_MASTER + client_id
      getToServer(this, agent_endpoint, true).then(response_data => {
         if(response_data){
            this.agent_list = response_data
            //auto select agent
            if(!this.$route.params.datasourceid)
              console.log(this.agent_list[0])
              this.mapped_agent = this.agent_list[0]
         }
        }).catch(error_response => {
          if(error_response){
            // this.snackbar = true
            // this.colorValue = 'error'
            // this.snackbartext = error_response;
          }
          else {
            // this.snackbar = true
            // this.colorValue = 'error'
            // this.snackbartext = SERVER_ERROR;
          }
        });
    },
    TestDBConnection(){
      if (this.$refs.form.validate()) {
        var client_id = this.$session.get('client_id')
        var user_id = this.$session.get('user_id')
        this.Generate_Connection_String();
        var datasource_input_details = {
            "client_id": client_id,
            "user_id": user_id,
            "host": this.Host,
            "port": this.Port,
            "user": this.UserName,
            "password": this.UserPassword===this.defaultPassword?'':this.UserPassword,
            "database_name": this.Database,
            "schema_name": this.SchemaName,
            "datasource_name": this.DatasourceName,
            "database_type": this.databaseType,
            "description": this.Description,
            "timeout": this.Timeout1,
            // "datasource_property": this.DatasourceProperty,
            "datasource_type": this.row,
            "connection_string": this.connection_string,
            "mapped_agent_id": this.mapped_agent.id,
            "from_manage_datasource":true,
            'datasource_id':this.$route.params.datasourceid,
            "useWindowsAuth":this.useWindowsAuth
          }
        var add_datasource_end_point = config.DATA_SOURCE_URL + CHECK_CONNECTION
        this.loader = true;
        postToServer(this, add_datasource_end_point, datasource_input_details, true
          ).then(response => {
          if (response) {
            this.loader = false 
            this.snackbar = true
            this.colorValue = 'success'
            this.snackbartext = 'Connection Successful';
          } 
          else{
            this.loader = false 
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = 'Failed To Connect with DB'
          }
        }).catch(ConnectionError => {
          this.loader = false
          if(ConnectionError){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = ConnectionError;
          }
          else {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          }
       });
      }
    },
  }
}
</script>

<style scoped>
  .EDC-UIComponent{
    margin: 0px !important;
    padding: 0px !important;
  }

  .radioClass >>> label{
    top: 5px !important;
  }
</style>
