<template>
    <div> 
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow">
                    <v-col cols="12" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>Package Builder</li>
                            <li v-if="erp_list.length > 0 "><select v-model="erp_code" autofocus style="-webkit-appearance: menulist;" @change="fetch_erp_version()">
                                <option v-for="code in erp_list" v-bind:key="code.erp_code" :value="code.erp_code">{{code.erp_code_desc}}</option>
                                </select> </li>
                            <li v-if="erp_list.length > 0">
                                <select v-model="erp_version" autofocus style="-webkit-appearance: menulist;" @change="getAllProcessDefList(envIpJson)">
                                <option v-for="version in erp_version_list" v-bind:key="version.id" :value="version">{{version}}</option>
                                </select>
                            <li v-if="erp_list.length == 0">All Erps
                            </li>
                            
                            
                        
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onCreatePackage="onCreatePackage" @onRowSelected="onRowSelected" @UpdateFilter="filterData" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy"></edc-data-grid>

        <simplert :useRadius="true" :useIcon="true" ref="simplert"></simplert>

        <v-dialog v-model="PackageDetails" width="500" persistent>
            <v-col>
                <v-card class="rounded-card">
                    <v-toolbar dark dense>
                        <v-col class="text-md-center">New Package Details
                            <v-icon class="text-lg-left" @click="PackageDetails = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
                        </v-col>
                    </v-toolbar>
                    <v-container grid-list-md align-content-space-around style="padding:10px">
                        <v-form ref="Package_form" v-model="valid" @submit.prevent="create_package">
                            <v-col>
                                <v-row style="width:100%;">
                                    <v-col style="text-align: center">
                                        <v-text-field label="Package Name" v-model="package_display_name" style="margin-right:10px" @blur="!package_description?package_description=package_display_name:false" required :rules="PackageDisplayNameRules"></v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-textarea label="Package Description" rows=1 v-model="package_description" style="margin-right:10px" required :rules="PackageDescriptionRules"></v-textarea>
                                        <v-autocomplete :items="releaseTypeList" v-model="releaseType" item-text="name" item-value="id" dense hide-details>

                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-spacer></v-spacer>
                                            <vc-button @click.native="createPackage"  itemText="Save"></vc-button>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-form>
                    </v-container>
                </v-card>
            </v-col>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

        <loading-panel :loader="loader"></loading-panel>

    </div>
</template>

<script>
import Simplert from 'vue2-simplert'
import moment from 'moment'
// import Breadcrumb from "../../Breadcrumbs.vue"
import config from '../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from '../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import { ALL_PROCESS_DEF, GET_ALL_PROCESS_DEF_ENV } from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import {BTN_COLOR} from '@/data/macros.js'
import {
    fetch_all_erp,
    fetch_erp_version
    } from "./../../methods/erp_details.js";
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import dummyData from './tableData.js'
// import data from './metaData.js'//

export default {
    name: 'DemoGrid',
    data: function () {
        return {
            outlineColor:BTN_COLOR,
            loader:false,
            selectedRows:[],
            promo_path_id: null,
            process_def: null,
            search: '',
            process_doc_list: [],
            erp_list: [],
            erp_version_list: {},
            erp_code: "",
            erp_version : "",
            isDisabled: false,
            PackageDetails: false,
            package_display_name: '',
            PackageDisplayNameRules: [v => !!v || 'Package Display Name is required.'],
            package_description: '',
            PackageDescriptionRules: [v => !!v || 'Package Description is required.'],
            // EnvironmentListRules: [v => !!v || 'Environment is required.'],
            // tableList: {
            //     headers: [
            //         { text: 'Name', value: 'object_name', sortable: true, cssClass:'w16', title: 'Object Name'},
            //         { text: 'Object Id', value: 'object_id', sortable: true, cssClass:'w16', width: '5%', title: 'Object Id'},
            //         { text: 'Type', value: 'object_type_desc', sortable: true, cssClass:'w16', width: '5%', title: 'Object Type'},
            //         { text: 'Ver', value: 'object_revision', sortable: true, options:[], align:'right', cssClass:'w16', width: '5%', title: 'Object Version', dataType:'int'},
            //         { text: 'Erp Version', value: 'erp_version', sortable: true, options:[], align:'right', cssClass:'w16', title: 'Object Erp Version', dataType:'int'},
            //         { text: 'Erp code', value: 'erp_code', sortable: true, options:[], align:'right', cssClass:'w16', title: 'Object Erp Code'},
            //         { text: 'Created By', value: 'added_by', sortable: true, options:[], cssClass:'w16', title: 'Object Created By'},
            //         { text: 'Created Date', value: 'timezone_aware_added_on_date', sortable: true, options:[], cssClass:'w16', title: 'Object Created Date', dataType:'date'},
            //         { text: 'Created On', value: 'timezone_aware_added_on_time', sortable: false, options:[], cssClass:'w16', title: 'Object Created On', dataType:'time', hideInlineFilter: true},
            //         { text: 'Last Modified By', value: 'modified_by', sortable: true, options:[],cssClass:'w16', title: 'Object Last Modified By' },
            //         { text: 'Last Modified Date', value: 'timezone_aware_modified_on_date', sortable: true,  options:[], cssClass:'w16', title: 'Object Last Modified Date', dataType:'date'},
            //         { text: 'Last Modified Time', value: 'timezone_aware_modified_on_time', sortable: false,  options:[], cssClass:'w16', title: 'Object Last Modified Time', dataType:'time', hideInlineFilter: true}
            //     ],               
            //     actions: [{'text':'CreatePackage','key':"object_id", selectType:"multiple", role:true, index:1}],
            //     sorting_type: SERVER_SIDE,
            //     filterType: SERVER_SIDE,
            //     paginationType: SERVER_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     disableDefaltSorting:true,
            //     itemkey: "added_on",
            // },
            tableList: {
                headers:[],
                rows:[],
                actions:[]
            },
            headers: [
                    { text: 'Name', value: 'object_name', sortable: true, cssClass:'w16', title: 'Object Name'},
                    { text: 'Object Id', value: 'object_id', sortable: true, cssClass:'w16', width: '5%', title: 'Object Id'},
                    { text: 'Type', value: 'object_type_desc', sortable: true, cssClass:'w16', width: '5%', title: 'Object Type'},
                    { text: 'Ver', value: 'object_revision', sortable: true, options:[], align:'right', cssClass:'w16', width: '5%', title: 'Object Version', dataType:'int'},
                    { text: 'Erp Version', value: 'erp_version', sortable: true, options:[], align:'right', cssClass:'w16', title: 'Object Erp Version', dataType:'int'},
                    { text: 'Erp code', value: 'erp_code', sortable: true, options:[], align:'right', cssClass:'w16', title: 'Object Erp Code'},
                    { text: 'Created By', value: 'added_by', sortable: true, options:[], cssClass:'w16', title: 'Object Created By'},
                    { text: 'Created Date', value: 'timezone_aware_added_on_date', sortable: true, options:[], cssClass:'w16', title: 'Object Created Date', dataType:'date'},
                    { text: 'Created On', value: 'timezone_aware_added_on_time', sortable: false, options:[], cssClass:'w16', title: 'Object Created On', dataType:'time', hideInlineFilter: true},
                    { text: 'Last Modified By', value: 'modified_by', sortable: true, options:[],cssClass:'w16', title: 'Object Last Modified By' },
                    { text: 'Last Modified Date', value: 'timezone_aware_modified_on_date', sortable: true,  options:[], cssClass:'w16', title: 'Object Last Modified Date', dataType:'date'},
                    { text: 'Last Modified Time', value: 'timezone_aware_modified_on_time', sortable: false,  options:[], cssClass:'w16', title: 'Object Last Modified Time', dataType:'time', hideInlineFilter: true}
                ],     
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            snackbartimeout: Snackbar_Default_Timeout,
            colorValue:'success',
            showEnvType:false,
            valid:true,
            dbTypefltrAry:[],
            nameTypefltrAry:[],
            'envTypefltrAry':[],
            'chip1':true,
            'searchEnv':"",
            'showname':false,
            'showDBType':false,
            chips: [],
            items: [],
            sorting : config.SORTING_TYPE,
            envIpJson : {
                    "filter": [],
                    "sort": [{
                        "column": "object_name",
                        "type": "asc"
                    }],
                    "page": 1,
                    "page_size": 10
                },
            isloading:false,
            menu:false,
            edit:"",
            perPage: 5,
            perPageArray: [5, 10, 50, 'all'],
            page: 1,
            totalPages:1,
            totalRecords:1,
            filterKey: "",
            sortingData: [],
            inputArray: {
                'Environment Type': [],
                'Name': [],
                'Database Type': [],
                'Database Location':[],
                'Action': []
            },
            sortKey: '',
            sortOrders: {
                'type': 1,
                'name': 1,
                'environment_database_type': 1,
                'databases_locations':1,
                'Action': 1
            },
            releaseType:'CST'
        }
    },
    computed:{
        releaseTypeList(){
            if(this.erp_list.length){
                return [
                    {"id":"GA","name":"Stable Release Package"},
                    {"id":"FX","name":"Package For Bug Fix"},
                    {"id":"CST","name":"Custom Package"}
                ]
            }
            else
                return [
                    {"id":"FX","name":"Package For Bug Fix"},
                    {"id":"CST","name":"Custom Package"}
                ]
        }
    },
    watch:{
        erp_list(newval){
        }
    },
    
    components: {
        Simplert
    },
    
    mounted() {
        this.tableList =  this.getGridObj(this.headers, 'object_id', false)
        this.tableList.actions = [{'text':'CreatePackage','key':"object_id", selectType:"multiple", role:true, index:1}]
        var client_id = this.$session.get("client_id")
        var erp_code1 = this.erp_code
        fetch_all_erp(this, client_id)
        if (this.erp_list.length==0) {
            this.getAllProcessDefList(this.envIpJson)   
        }

    },
    
    methods: {
        fetch_erp_version(){
            var client_id = this.$session.get("client_id")
            fetch_erp_version(this, client_id, this.erp_code)
            //this.getAllProcessDefList() 
           
        },
    //     onDownload(downloadParams, filterArray){
    //     let filter=[];
    //     filterArray.map((obj, index)=>{
    //         obj.stringArray.map((stringObj, stringIndex)=>{
    //             let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
    //                 filter.push(cloneDeep(temp));
    //         })
    //     });
    //     let inputJson={
    //         "recordType":downloadParams.recordType,
    //         "client_id": this.$session.get('client_id'),
    //         "env_id": "",
    //         "file_format": downloadParams.fileType,
    //         "min_range": downloadParams.minRange|| null,
    //         "max_range": downloadParams.maxRange||null,
    //         "filter": filter
    //     }
    //     var url = config.DATA_SOURCE_URL+EXPORT_DATASOURCE;    
    //     postToServer(this, url, inputJson).then(envirnentResponse => {
    //         var url = config.DATA_SOURCE_URL + "/static/" + envirnentResponse;
    //         // window.open(url, '_blank');
    //         getToServer(this, url).then(Response => {
    //             let inputurl = window.URL.createObjectURL(new Blob([Response]));
    //             var link = document.createElement('a');
    //             link.href = inputurl;
    //              link.setAttribute('download', envirnentResponse);
    //             link.click();
    //         });
            
    //     });
    // },
        onSubmitButton(){
            let _this = this;
            // if (_this.erp_code == "" || _this.erp_version == "" || _this.selectedRows.length < 1){
            //     this.snackbar = true
            //     this.colorValue = 'error'
            //     this.snackbartext = "Please select ERP Code, ERP Version and Objects";
            //     return 0
            // }
            var object_id_list = []
            var i
            for(i=0; i < this.selectedRows.length; i++){
                object_id_list.push(
                    {"object_id":this.selectedRows[i].object_id,
                    "object_revision": this.selectedRows[i].object_revision})
            }
            this.$router.push({ name: 'select_object_for_package', params: {
            'client_id': this.$session.get('client_id'), 'erp_code': this.erp_code, 'erp_version': this.erp_version, 'object_list':object_id_list}});
        },
        onRowSelected(selectedRows){
            this.selectedRows = selectedRows; 
        },
        getAllProcessDefList (envIpJson) {
            var _this = this;
            _this.loader = true;
            if (this.erp_code == "select" || this.erp_code == "" || this.erp_version=="select" || this.erp_version==""){
                this.tableList.rows = [];
                this.tableList.total_count =0; 
            }
            var client_id = this.$session.get('client_id')
            var t_data = {'erp_code': _this.erp_code, 'erp_version':_this.erp_version, 'client_id': client_id, 
                           "filter": _this.envIpJson.filter,
                           "page": _this.envIpJson.page,
                           "page_size": _this.envIpJson.page_size,
                           "sort": _this.envIpJson.sort}
            postToServer(this, config.REPOSITORY_API_URL + '/get_objects_for_package_building', t_data
            ).then(response => {
                var data = response
                _this.loader = false
                if(data){
                this.tableList.rows = data.result;
                this.tableList.total_count = data.total; 
                // data.map(function(obj,idx){
                //     obj['unique_id'] = idx+1
                // }) 
                this.process_doc_list = data
                } else {
                    data = []
                    this.tableList.rows = data;
                    this.tableList.total_count = data.length; 
                }
            }).catch(ProcessDocError => {
                _this.loader = false
                if(ProcessDocError){
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.tableList.rows = [];
                    this.tableList.total_count =0; 
                    this.snackbartext = ProcessDocError;
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
        },
        submitDbType(){
            let _this = this;
            _this.dbTypefltrAry.push({text:cloneDeep(_this.searchEnv)});
            _this.searchEnv="";
        },
          remove (array, item) {
            array.splice(array.indexOf(item), 1)
            array = [...array]
          },
        getRecordPerPage(){
            let inputJson = this.envIpJson;
            inputJson.page_size = this.perPage;
        },
        removeBubbling(event) {
            event && event.stopPropagation();
        },
        numPages() {
            let _this = this;
            if(_this.totalRecords)
            _this.totalPages = Math.ceil(_this.totalRecords / _this.perPage);
        },
        setRecordPerPage() {
            let _this = this;
            // _this.page = 1;
            _this.numPages();
            // _this.changePage();
        },
        cancelRowData() {
            let _this = this;
            _this.edit = "";
        },
        saveRowData(index) {
            let _this = this;
            _this.edit = "";
        },
        deleteRowData(obj) {
            let _this = this;
            _this.deleteEnvironment(obj.id);
            obj.menu = false;
        },
        changePage() {
            let _this = this,
                dataFrom = 0,
                dataTo = 5;
            _this.showDataAsPerPage(_this.sortingData);
        },
        showDataAsPerPage(data) {
            let _this = this,
                dataFrom = 0,
                dataTo = 5;
            if (_this.page < 1) _this.page = 1;
            if (_this.page > _this.numPages()) _this.page = numPages();
            if (_this.page <= 1) {
                if (data.length >= _this.perPage)
                    _this.tableList = data.slice(0, _this.perPage);
                else
                    _this.tableList = data.slice(0, data.length);
            } else {
                dataFrom = (_this.page - 1) * _this.perPage;
                dataTo = _this.perPage * _this.page;
                if (dataTo <= data.length)
                    _this.tableList = data.slice(dataFrom, dataTo);
                else
                    _this.tableList = data.slice(dataFrom, data.length);
            }
        },
        editrowData(id) {
            let _this = this;
            _this.edit = id;
        },
        columnFilter(elem, key) {
            let _this = this;
            if (!elem)
                _this.setRecordPerPage()
            _this.tableList = _this.sortingData.filter(function (el) {
                return String(el[key]).toLowerCase().indexOf(String(elem).toLowerCase()) > -1;
            })
            // _this.showDataAsPerPage(_this.tableList);
        },
         checkLink(record){
             let _this = this;
            let publish_data = {
                'client_id': this.$session.get('client_id'),
                'action_taken_by': this.$session.get('email'),
                'object_data':[],
                'promotion_path_id': this.promo_path_id,
                'from_env': {
                    'env_id': _this.Environment.id,
                    'env_name': _this.Environment.name}
                }
            publish_data['object_data'] = [{
                        "object_type": record.object_type_code,
                        "object_id": record.object_id,
                        "object_name": record.object_name,
                        "object_desc": record.object_desc,
                        "object_revision": record.object_revision,
                        "approval_request_id": record.approval_request_id,
                        "published_promotion_id": record.published_promotion_id
                    }]
            this.$router.push({ name: 'SelectEnvTypePublish', 
                params: {'publish_data': publish_data}
            });
        },
        onCellEvent:function(record){
            if(this.Environment.allow_create)
                this.onEdit(record)
        },
        onCreatePackage:function(record){
            this.PackageDetails= true
        },
        create_package(){
        if (this.$refs.Package_form.validate()) {
            
        }
        },
        createPackage:function(){
            var _this = this
            if (!this.$refs.Package_form.validate()) {
                return
            }
            var package_id = _this.package_id
            var package_name = _this.package_name
            var package_description = _this.package_description
            var package_display_name = _this.package_display_name
            var package_build_data = {
                'client_id': _this.$session.get('client_id'),
                "erp_code": _this.erp_code, 
                "erp_version": _this.erp_version,
                'package_objects':[],
                "package_description": _this.package_description,
                'package_display_name': package_display_name,
                'release_type':_this.releaseType
                }
            package_build_data.package_objects = _this.selectedRows
            _this.loader = true 
            //_this.tableList.rows;
            postToServer(this, config.PACKAGE_API_URL + '/create_package', package_build_data).then(response => { 
                this.PackageDetails=false
                _this.loader = false
                package_id = response.package_details_id
                package_name = response.package_name
                package_display_name = response.package_display_name
                package_description = response.package_description
              this.$router.push({name: 'PackageList', params: {erp_code: _this.erp_code, erp_version: _this.erp_version, package_id: package_id, package_name: package_name, package_display_name:package_display_name, package_description:package_description}});
                }).catch(ProcessDocError => {
                    _this.loader = false
                    if(ProcessDocError){
                        this.PackageDetails = true;
                        this.loader = false;
                        this.snackbar = true;
                        this.colorValue = 'error';
                        this.snackbartext = ProcessDocError;
                        this.tableList.rows = data;
                        this.tableList.total_count =data.length; 
                        // setTimeout(() => {  _this.loader = false; console.log(ProcessDocError); }, 5000);
                        setTimeout(function(){
                            console.log(ProcessDocError);
                        },5000);
                        
                        
                    }
                    else {
                        this.PackageDetails = false
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                })
        },
        filterData(filterArray){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getAllProcessDefList(this.inputJson)
        },
           onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllProcessDefList(this.inputJson);
        },
        onPerPageChange(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getAllProcessDefList(this.inputJson);
        },
        sortBy: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getAllProcessDefList(this.inputJson);
        },
    }
}
</script>

<style scoped>
</style>