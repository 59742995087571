import { render, staticRenderFns } from "./ManageSecurity.vue?vue&type=template&id=5bb6302c&scoped=true&"
import script from "./ManageSecurity.vue?vue&type=script&lang=js&"
export * from "./ManageSecurity.vue?vue&type=script&lang=js&"
import style0 from "./ManageSecurity.vue?vue&type=style&index=0&id=5bb6302c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb6302c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VContainer,VFlex,VLayout,VSnackbar})
