<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" cols="12">
                <edc-data-grid key="worktableData" :dataList="tableList" @onSort="sortBy" @onPageChange="onPageChange" 
                @onPerPageChange="onPerPageChange" @UpdateFilter="filterData" @onDownload="onDownload" 
                @redirectToDataviewer="redirectToDataviewer" gridFor="worktable" @copyDataToClipboard="copyDataToClipboard" :key="newGridKey">

                </edc-data-grid>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    </v-container>
</template>
<style scoped>
</style>
<script>
    import config from '../../../config.json'
    import { post as postToServer } from '../../../methods/serverCall.js';
    import {manageScreenDisplay} from '@/methods/special.js'
    import {getDataviewerTbleObj} from "../../../constants/dataviewerconstants.js"
    import * as JDEFormatterObj from "@/methods/JDEFormatter.js"
    import {getURLByDomainByProduct} from '@/methods/domain_management.js'
    import {getPathfinderProduct} from "@/methods/commonFunc.js"
  export default {
    name:'ShowWorktableData',
    components:{

    },
    props:{
        worktableObj:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        allStepsDescription:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            datasourceDetailsForPathFinder:{},
            is_column_details_required:true,
            allColumns:[],
            loader:false,
            envIpJson : {
                "filter": [],
                "sort": [{
                "column_name": "",
                "type": ""
                }],
                "page": 1,
                "page_size": 10
            },
            defaultEnvIpJson:{
                "filter": [],
                "sort": [{
                "column_name": "",
                "type": ""
                }],
                "page": 1,
                "page_size": 10
            },
            tableList:[],
            snackbar: false,
            snackbartext: '',
            snackbartimeout: 5000,
            colorValue: 'success',
            tableDataForDownload:{},
            autoIncreament:1,
            newGridKey:"test"
        }
    },
    computed:{

    },
    watch:{
        'worktableObj':{
            handler(newvalue){
                this.resetAll()
                this.getWorkTableData(this.envIpJson)
            }
        },
        'allStepsDescription':{
            handler(newvalue){

            }
        }
    },
    mounted(){
        this.resetAll()
        this.getWorkTableData(this.envIpJson)
    },
    methods:{
        resetAll(){
            this.newGridKey = this.newGridKey+this.autoIncreament++
            this.tableList = _.cloneDeep(this.getGridObj([],'rn',false,{"showSelect":false}))
            this.tableList.perPage = manageScreenDisplay()
            this.is_column_details_required = true
            this.allColumns = []
            this.envIpJson = _.cloneDeep(this.defaultEnvIpJson)
            this.envIpJson.page_size = manageScreenDisplay()
            this.tableDataForDownload = {}
        },
        getData(inputJson){
            var _this = this
            this.snackbar = false
            return {
                "table_name":this.worktableObj.output_table_name,
                "env_id":this.worktableObj.env_id,
                "client_id":this.$session.get('client_id'),
                "filter": inputJson.filter,
                "page": inputJson.page,
                "page_size": inputJson.page_size,
                "sort": inputJson.sort,
                "ds_info":_this.datasourceDetailsForPathFinder,
                "is_column_details_required":_this.is_column_details_required,
                "archivist_ds_type":["engine_data","source_data","target_data"],
                "request_for":"worktable",
                "is_archivist_worktbl_req":true,
                "additional_table_info":_this.worktableObj.table_column_data,
                "column_details":_this.allColumns,
                "all_columns":_this.allColumns,
                "headers":_this.allColumns,
                "allheaders":_this.allColumns,
                "user_id":_this.$session.get('email'),
                "user_name":_this.$session.get('email')
            }
        },
        getWorkTableData(inputJson){
            var _this = this
            if(!this.worktableObj.output_table_name)
                return
            var data_to_pass = _this.getData(inputJson)
            _this.tableList.rows = []
            _this.loader = true
            postToServer(_this, config.DATA_VIEWER_URL+'/get_archivist_worktable_data', 
            data_to_pass).then(response=>{
                _this.loader = false
                if(_.isEmpty(_this.datasourceDetailsForPathFinder))
                    _this.datasourceDetailsForPathFinder=response.ds_info
                let tableDetails = response
                _this.tableDataForDownload  = response.table_data_for_download
                if(_this.is_column_details_required){
                    _.forEach(tableDetails.column_details,(obj)=>{
                        _this.allColumns.push(JDEFormatterObj.formatColumn(obj,_this.datasourceDetailsForPathFinder.db_type))
                    })
                    _this.tableList.headers = _this.allColumns
                    _this.worktableObj.table_other_data['showMoreInfo'] = true
                    _this.worktableObj.table_other_data['header'] = _this.worktableObj.step_name + '('+_this.worktableObj.step_type+')'
                    let stepDescription = ''
                    let descriptionObj = _.find(_this.allStepsDescription,['step_type',_this.worktableObj.step_type.toLowerCase()])
                    if(descriptionObj)
                        stepDescription = descriptionObj.step_description
                    _this.worktableObj.table_other_data['what_step_does'] = stepDescription
                    _this.tableList.moreInformatonDetails = _this.worktableObj.table_other_data
                    _this.is_column_details_required = false
                }
                if(inputJson.page === 1){
                    // we will only 3 pages
                    let countForPagination = inputJson.page_size * 3
                    
                    if(countForPagination>response.total_count)
                        countForPagination = response.total_count
                    _this.tableList.total_count = countForPagination||0
                    _this.tableList.actual_total_count = response.total_count||0
                }

                _this.tableList.rows = tableDetails.data;
                if(tableDetails.error){
                    throw tableDetails.error
                }
            }).catch(error_response=>{
                _this.loader = false
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = error_response;
            })   
        },
        onDownload(downloadParams, filterArray){
            var _this = this
            if(downloadParams.fileType === 'pdf'){
                alert('pdf export is not supported.')
                return
            }
            if(_this.tableList.total_count === 0){
                alert('No data available for download.')
                return
            }
            let data = _.cloneDeep(_this.tableDataForDownload)
            data['return_full_file_path'] = true
            // Object.assign(data,_this.envIpJson)
            data['download_props'] ={}
            data['download_props']['fileType'] = downloadParams.fileType
            data['return_full_file_path'] = true
            data['associate_object_name'] = this.worktableObj.output_table_reference
            data['associate_object_id'] = this.worktableObj.output_table_reference
            data['download_props'] ={}
            data['download_props']['fileType'] = downloadParams.fileType
            data['datalist'] = _.cloneDeep(_this.tableDataForDownload)
            data["headers"] = _this.allColumns,
            data["allheaders"] = _this.allColumns
            _this.loader = true
            postToServer(_this, config.DATA_VIEWER_URL+'/download_pathfinder_data', data).then(response=>{
                _this.loader = false
                var url = config.DATA_VIEWER_URL + "/get_file_download?path=" + response.filepath
                window.open(url, '_blank');
            }).catch(error_response=>{
                _this.loader = false
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = error_response;
            })
        },
        redirectToDataviewer(){
            let _this = this
            var redirectionObj  = getPathfinderProduct(_this)
            let data_to_send = {
                "env_id":this.worktableObj.env_id,
                "client_id":this.$session.get('client_id'),
                "archivist_ds_type":["engine_data","source_data","target_data"],
                "request_for":"worktable",
                "job_name":this.worktableObj.job_name,
                "job_id":this.worktableObj.job_id,
                "table_list":this.worktableObj.available_table_list,
                "selected_table":{"table_name":this.worktableObj.output_table_reference}
            }
            _this.loader=true
            var cache_url = config.EDC_CACHE_SERVICE_URL + "/add_dataviewer_redirect_key"
            postToServer(_this, cache_url, data_to_send).then(response=>{
                _this.loader=false
                if(response){
                    var archivist_redirect_key=response
                    // write code here to create key and pass that key to the function as query_string
                    let query_string = '&archivist_redirect_key='+archivist_redirect_key// append key here
                    let url = getURLByDomainByProduct(_this,redirectionObj,'login',query_string)
                    window.open(url, '_blank');
                }
                else{
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'Failed to cache the details.Issue with caching service';
                }
            }).catch(error_response=>{
                _this.loader=false
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = error_response;
            })
        },
        sortBy: function (sortJson) {
            let _this = this;
            if(sortJson.length <=0)
                return 
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.getWorkTableData(inputJson);
        },
        onPageChange(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getWorkTableData(inputJson);
        },
        onPerPageChange(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.getWorkTableData(inputJson);
        },
        filterData(filterArray,pageno){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.getWorkTableData(inputJson);
        },
        copyDataToClipboard(type){
            this.snackbar = true
            this.colorValue = type
            if(type==='success')
                this.snackbartext = "Query copied to clipboard"
            else
                this.snackbartext = "Failed to copy query to clipboard"
        }
    }
  }
</script>