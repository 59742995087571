<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/jobplanlist">Job Plan List</router-link>
              </li>
              <li>{{ execution_env_name }}</li>
              <li>Run</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container class="EDC-Container">
      <v-row no-gutters class="EDC-Row">
        <v-row class="EDC-Row">
          <v-col class="EDC-Col">
            <v-row class="EDC-Row">
              <v-col class="EDC-Col" cols="5">
                <v-row class="EDC-Row">
                  <v-col class="EDC-Col">
                    <v-card elevation-1 class="EDC-CardMargin">
                      <v-row no-gutters class="EDC-Row">
                        <v-col cols="12" class="EDC-Col">
                          <v-row no-gutters class="EDC-Row">
                            <span class="EDC-Heading">Run Job Plan</span>
                          </v-row>
                          <v-row no-gutters class="EDC-Row">
                            <v-list dense style="width:100%; text-align:left">
                              <v-list-item> 
                                <v-list-item-title class="EDC-VListItemContent"><b>Job Plan Name:</b></v-list-item-title>
                                <v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{ job_plan_name }}</b></v-list-item-title>
                              </v-list-item>
                              <v-list-item> 
                                <v-list-item-title class="EDC-VListItemContent"><b>Description:</b></v-list-item-title>
                                <v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{ job_plan_description }}</b></v-list-item-title>
                              </v-list-item>
                              <v-list-item> 
                                <v-list-item-title class="EDC-VListItemContent"><b>Parallel Job Count:</b></v-list-item-title>
                                <v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{ parallel_job_count }}</b></v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-row>
                        </v-col>
                        <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end" style="padding-right:5px !important">
                          <toggle-button v-model="mode_of_execution" :labels="{checked: 'Proof', unchecked: 'Final'}" :color="{'checked':'#9B9B9B','unchecked':'#607D8B'}" :width="70" :height="28" :sync="true" style="font-size:10px !important; margin-top:5px !important" @change='getLastRunningInstanceDetails'/>
                          <vc-button type="button" item-text="Run" @click.native="ExecuteJobPlan"/>
                          <vc-button type="button" item-text="Schedule..." style="margin:0px" @click.native="CreateScheduleJobPlan"/>
                          <vc-button type="button" item-text="Cancel" @click.native="onCancel"/>
                        </v-row>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="EDC-Row" style="padding-top:4px !important;">
                  <v-col class="EDC-Col">
                    <v-card elevation-1 class="EDC-CardMargin">
                      <v-row class="EDC-Row">
                        <v-col class="EDC-Col" cols="12">
                          <v-row class="EDC-Row" no-gutters>
                            <v-col class="EDC-Col" cols="12" align="start">
                              <span class="EDC-Heading">Recent History</span>
                            </v-col>
                          </v-row>
                          <v-row class="EDC-Row">
                            <v-list dense style="width:100%; text-align:left" v-if="lastInstanceDetails.job_plan_name">
                            <v-list-item> 
                              <v-list-item-title class="EDC-VListItemContent"><b>Job Plan Name:</b></v-list-item-title>
                              <v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{ lastInstanceDetails.job_plan_name }}</b></v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title class="EDC-VListItemContent"><b>Status:</b></v-list-item-title>
                              <v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{ lastInstanceDetails.status }}</b></v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title class="EDC-VListItemContent"><b>End Date:</b></v-list-item-title>
                              <v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{ lastInstanceDetails.end_date }}</b></v-list-item-title>
                            </v-list-item>
                            <v-list-item >
                              <v-list-item-title class="EDC-VListItemContent"><b>End Time:</b></v-list-item-title>
                              <v-list-item-title class="EDC-VListItemContent" style="text-align:right"><b>{{ lastInstanceDetails.end_time }}</b></v-list-item-title>
                            </v-list-item>
                            </v-list>
                            <span v-else class="EDC-NoRecordText EDC-ControlPadding">{{ NoDataFound }}</span>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="EDC-Col" style="padding-left:8px !important">
                  <v-card elevation-1 class="EDC-CardMargin">
                    <v-row class="EDC-Row">
                      <v-col class="EDC-Col"> 
                        <v-treeview class="overflow-y-auto  style-2scroll edcTrree" id="joblist" dense item-children="job_list" item-text="process_definition_name"
                          style="text-align:left;height:80vh;" :items="jobListTree" open-on-click>
                          <template v-slot:label="{item}">
                              <span class="keep-spaces" :title="item.process_definition_name" v-if="item.instance_details_indentation >0"> {{  getLabelText(item) }}</span>
                              <span class="text-truncate" :title="item.process_definition_name"> {{ item.process_definition_name }}</span>
                          </template>
                          <template v-slot:append="{item}">
                              <span v-if="item.object_type ==='job'">{{ getPolicyString(item) }}</span>
                          </template>
                        </v-treeview>
                      </v-col>
                    </v-row>
                  </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import config from '../../config.json'
import {
    get as getToServer,
    post as postToServer
} from '@/methods/serverCall.js';
import {
    SERVER_ERROR
} from '@/data/client_message.js'
import {No_Data_Found, Snackbar_Default_Timeout} from '@/constants/constants.js'
import {GET_JOB_PLAN, EXECUTE_JOB_PLAN, GET_JOB_PLAN_BY_ID, SCHEDULE_JOB_PLAN} from '@/data/url_constants.js';

    import {
        CLIENT_SIDE,
        SERVER_SIDE
    } from '@/data/macros.js'
    // import {ACTIVITY_TYPE_SELECTIVE_RESTORE, ACTIVITY_TYPE_RESTORE,
    //     ACTIVITY_TYPE_SELECTIVE_DISPOSE} from '../../data/job_management.js'
        import cloneDeep from 'lodash/cloneDeep';
        import orderBy from 'lodash/orderBy';
        import _ from 'lodash'
        import environmentList from '@/methods/EnvironmentList.js'

        export default {
            name: 'ClientJobListNew',
    
            data: function () {
                return {
                    mode_of_execution:true,
                    NoDataFound: No_Data_Found,
                    envIpJson : {
                        "filter": [],
                        "sort": {
                            "column": "",
                            "type": ""
                        },
                        "page": 1,
                        "page_size": 10
                    },
                    start_date: null,
                    end_date: '',
                    time2: null,
                    status: "all",
                    userRole:{},
                    ExecutionMode: 'proof',
                    valueDialog:false,
                    loader:false,
                    Environment: this.$session.get('selected_env'),
                    tableList: {
                        headers:
                        [{'text':'Job Name', value: 'display_process_definition_name',width:'70%', sortable:false,avoidTrim:true},
                        {'text':"Policy",value: 'display_policy',width:'30%',cssClass:'w16', sortable:false}],
                        actions: [
                            // {'text':'edit','key':"", selectType:"single", role:true},
                            // {'text':"save","key":"isSave",selectType:"single"},
                            // {'text':"cancel","key":"isCancel",selectType:"single"}
                        ], //if we need conditional action in row then provide key
                        sorting_type: CLIENT_SIDE,
                        filterType: CLIENT_SIDE,
                        paginationType: CLIENT_SIDE,
                        total_count: 0,
                        rows: [],
                        per_page:5,
                        // is_row_edit:true,
                        // select_rows:true,
                        hideActions:true,
                        hideExport:true,
                        hideShowColumns:true,
                        hideFilter:true,
                        hideSelect:true,
                        hideItemPerPage:true,
                        hideRowInfo:true,
                        hidePagination:true
                },
                historyList:{
                    headers: [
                       { text: 'Id', value: 'id', width:'10%',align:'right'
                       },
                        { text: 'Job plan Name', value:'plan_name', sortable:
                        false, width:'30%', options:[], cssClass:'w16'},
                        { text: 'Status', value: 'status', sortable: false,
                        width:'40%'},
                        { text: 'Start Date', value: 'start_date', width:'30%'},
                        { text: 'Start Time', value: 'start_time', width:'30%'},
                        { text: 'End Date', value: 'end_date', width:'20%'},
                        { text: 'End Time', value: 'end_time', width:'20%'}
                        ],
                    caption:"Job Plan History",
                    actions: [],
                    sorting_type: CLIENT_SIDE,
                    filterType: CLIENT_SIDE,
                    paginationType: CLIENT_SIDE,
                    total_count: 0,
                    rows: [],
                    select_rows:false,
                    multipleGrid:true,
                    hideActions:true,
                    hideExport:true,
                    hideShowColumns:true,
                    hideFilter:true,
                    hideSelect:true
                   // syncHeaderScroll:false,
               },
            documentTypeData:[],
            snackbar: false,
            snackbartext: '',
            colorValue: 'success',
            snackbartimeout: Snackbar_Default_Timeout,
            valid: true,
            isEditing:true,
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            job_plan_id : this.$route.params.job_plan_id,
            job_plan_name: this.$route.params.job_plan_name,
            execution_env_name: this.$route.params.execution_env_name,
            job_plan_description: this.$route.params.job_plan_description,
            parallel_job_count:this.$route.params.parallel_job_count,
            valueTypeArray:['Days','Months','Quarters','Years','Actual Date'],
            execution_environment: null,
            lastInstanceDetails:{},
            jobListTree:[]
            // execution_env_name: null
        }
    },
    computed:{
    },
    mounted() {
        this.getJobPlanDetails()
        // this.GetPlanList()
        this.getLastRunningInstanceDetails()
        this.job_plan_name = this.$route.params.job_plan_name
        this.execution_environment = this.$route.params.execution_environment
        this.execution_env_name = this.$route.params.execution_env_name
        

        if(this.historyList.paginationType == CLIENT_SIDE)
        this.envIpJson.page_size="*";
        var env_value = this.$session.get('selected_env')
        if( env_value && env_value.id != '0' && env_value.id !='all_env'){
            this.Environment = this.$session.get('selected_env')
            // this.getAllTodaysJob(this.envIpJson)
        }
        // this.GetStepList()
    },
methods: {
    onHistory:function(record){
            this.$router.push({ name: 'showjobstepsresult', params: {envname:this.Environment.name,jobname: record.process_definition_name,
             jobid: record.id,mode:record.mode_of_execution,status:record.status, type:'jobHistory' }});
        },
    onDetailedHistory:function(record){
            this.$router.push({ name: 'operationwiseresult', params: { envname:this.Environment.name,jobid: record.id,
                mode:record.mode_of_execution,
                jobname: record.process_definition_name, status:record.status, type:'jobHistory' }});
        },
     getJobPlanDetails(){
      let _this = this;
      _this.loader = true
      var job_data = {"job_plan_id": this.job_plan_id, 'client_id':this.$session.get('client_id')}
      let baseUrl = config.JOB_PLAN_URL;
      baseUrl = baseUrl+ GET_JOB_PLAN;
      postToServer(this, baseUrl, job_data
          ).then(response => {
            _this.loader = false
            console.log(response)
            var job_plan_data = response.recursive_job_list
            Object.keys(job_plan_data).forEach(function(key) {
               var policy_value = ''
               job_plan_data[key]['display_process_definition_name'] = _this.addDependency(job_plan_data[key]['process_definition_name'],job_plan_data[key].instance_details_indentation)
               
               if(job_plan_data[key] && job_plan_data[key].policy_details && job_plan_data[key].policy_details.length){
                job_plan_data[key].policy_details.map(obj=>{
                    if(policy_value === ''){
                        policy_value =  obj.actual_date
                      }
                     else{
                        policy_value =  policy_value + "," + obj.actual_date
                     }
               })
               job_plan_data[key].display_policy = policy_value
               } 
            });

            _this.tableList.rows = job_plan_data;
            _this.tableList.total_count = job_plan_data.length
            _this.$store.state.jobPlanDetails = response;
            _this.name = response.plan_name;
            _this.job_plan_id= response.id;
            _this.description = response.description;
            _this.jobListTree = response.recursive_job_list_1

               // this.loader=false;
           }).catch(CurrentJobError => {
            _this.loader = false
             this.snackbar = true;
             this.colorValue = 'error'
             this.snackbartext = CurrentJobError;
         })
       },
    GetPlanList(){
          let _this = this;
          var job_data = {'job_plan_id':this.job_plan_id};
          let baseUrl = config.JOB_PLAN_URL;
              baseUrl = baseUrl+ GET_JOB_PLAN_BY_ID;
          postToServer(this, baseUrl, job_data
              ).then(response => {
                _this.historyList.rows = response;
               // this.loader=false;
           }).catch(CurrentJobError => {
               // this.historyList.rows=[];
               this.snackbar = true;
                this.colorValue = 'error'
                this.snackbartext = CurrentJobError;
            })
        },
    getLastRunningInstanceDetails(){
      let _this = this
      _this.lastInstanceDetails = {}
      var toggle_details = this.mode_of_execution ? 'proof':'final';
      var data = {
        'job_plan_id':this.job_plan_id,
        'client_id':this.$session.get('client_id'),
        'mode':toggle_details
      }
      let url = config.JOB_PLAN_URL +'/get_last_run_job_plan_instance'
      postToServer(this, url, data
              ).then(response => {
                _this.lastInstanceDetails = response
               // this.loader=false;
           }).catch(CurrentJobError => {
               
            })
    },
    ExecuteJobPlan(){
        let _this = this;
        debugger
        _this.loader = true
        var toggle_details = this.mode_of_execution ? 'proof':'final';
        var data_to_send = {"job_plan_id":this.job_plan_id,
                            "job_plan_name": this.job_plan_name,
                            "client_id": this.$session.get('client_id'),
                            "mode_of_execution": toggle_details,
                            'exe_type':'on_demand',
                            'execution_environment': this.$route.params.execution_environment,
                            'execution_env_name': this.$route.params.execution_env_name}
        let baseUrl = config.JOB_PLAN_URL;
          baseUrl = baseUrl+ EXECUTE_JOB_PLAN;
        postToServer(this, baseUrl, data_to_send
          ).then(response => {
            _this.loader = false
            this.snackbar = true;
            this.colorValue = 'info'
            let message = response.message
            if(response.message.includes('execution'))
              message =  response.message.replace('execution','run')
            this.snackbartext = message
            /* Redirection to todays job list instead of job plan history. Issue no.551*/

            // this.$router.push({ name: 'jobplaninstancehistory',
            //             params: {job_plan_id: this.job_plan_id,
            //                      job_plan_name: this.job_plan_name,
            //                      execution_environment: this.$route.params.execution_environment,
            //                      execution_env_name: this.$route.params.execution_env_name}
            //                      }
            //             );
            var self = _this
                setTimeout(function(){
                      self.$router.push('/currentjoblist')
                }, 1000); 

        }).catch(CurrentJobError => {
            _this.loader = false
            this.snackbar = true;
            this.colorValue = 'error'
            this.snackbartext = CurrentJobError;
        })
    },
    GetJobData(){
        debugger
        var client_id = this.$session.get('client_id')
        var toggle_details = this.mode_of_execution ? 'proof':'final';
        var created_by = this.$session.get('email')
        var policy_details = cloneDeep(this.tableList.rows)
        if(policy_details){
            for (var i = policy_details.length - 1; i >= 0; i--) {
                delete policy_details[i].isSave
                delete policy_details[i].isCancel
                delete policy_details[i].is_row_editable
                delete policy_details[i].default
            }
        }
        var job_data = {
          'client_id': client_id,
          'created_by':created_by ,
          "job_plan_id":this.job_plan_id,
          'job_plan_name': this.job_plan_name,
          'mode_of_execution':toggle_details,
          'execution_environment': this.Environment.id,
          'execution_env_name': this.Environment.name,
          'mode_of_execution':toggle_details,
          'exe_type':'on_schedule',
          'policy_details':policy_details
      }
      return job_data 
  },
    CreateScheduleJobPlan(){
        debugger
        // var data = this.GetJobData()
        // this.$router.push({'name' : 'schedulejobplan', 'params' : { schedule_data: data, type:'new', service_id:config.JOB_PLAN_SERVICE_ID, end_point: config.END_POINT_FOR_JOB_PLAN_SCHEDULER}});

        var data = this.GetJobData()
        data['exe_type'] ='on_schedule'
        this.$router.push({'name' : 'schedulejob', 'params' : { schedule_data: data, type:'new', service_id:config.JOB_PLAN_SERVICE_ID,category:"Job Plan", end_point: config.END_POINT_FOR_JOB_PLAN_SCHEDULER}});


    },

    CreateScheduleJob(){
        let _this = this;
        _this.loader = true
        var toggle_details = this.mode_of_execution ? 'proof':'final';
        var data_to_send = {"job_plan_id":this.job_plan_id,
                            "job_plan_name": this.job_plan_name,
                            "client_id": this.$session.get('client_id'),
                            "mode_of_execution": toggle_details,
                            'exe_type':'on_demand',
                            'execution_environment': this.$route.params.execution_environment,
                            'execution_env_name': this.$route.params.execution_env_name}
        let baseUrl = config.JOB_PLAN_URL;
          baseUrl = baseUrl+ SCHEDULE_JOB_PLAN;
        postToServer(this, baseUrl, data_to_send
          ).then(response => {
            _this.loader = false
            this.snackbar = true;
            this.colorValue = 'info'
            this.snackbartext = response.message;
            this.$router.push({ name: 'jobplaninstancehistory',
                        params: {job_plan_id: this.job_plan_id,
                                 job_plan_name: this.job_plan_name,
                                 execution_environment: this.$route.params.execution_environment,
                                 execution_env_name: this.$route.params.execution_env_name}
                                 }
                        );

        }).catch(CurrentJobError => {
            _this.loader = false
            this.snackbar = true;
            this.colorValue = 'error'
            this.snackbartext = CurrentJobError;
        })
    },
   onCancel(){
    this.$router.go(-1);
  },
  addDependency(name,indentation=0){
    for (var i = 0; i < indentation; i++) {
      name = '    '+name
    }
    return name
  },
  getPolicyString(item){
    debugger;
    if(!item.policy_details || !item.policy_details.length)
      return 'N/A'
    let policy_details=item.policy_details
    // if(typeof(policy_details) === 'object')
    //   policy_details =[policy_details]
    let policyDates = [...new Set(_.map(policy_details,"actual_date"))]
    return policyDates.join(',')
  },
  getLabelText(item){
    let lblText = ''
    for(let i=0;i <item.instance_details_indentation;i++){
      lblText = '        '+lblText
    }
    return lblText
  }
}

}
</script>
<style>
/*  define global for right now. have to revisit the code when next time going to use*/
.v-treeview--dense .v-treeview-node__root{
  min-height: 24px !important;
}


</style>
<style scoped>

.txtWrap{
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ls-left{
  text-align: left;
  padding-left: 20px;
}
.font-11{
  font-size:12px
}
.bold{
  font-weight: bold;
}
.EDC-CardMargin{
  margin-top: 5px !important
}

/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>
