<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li v-if="$route.params.type == 'jobPlan'" >
                <a  @click="backToJobPlan">{{envname}}</a>
              </li>
              <li v-else>
                <span @click="$router.go(-1)"><a>{{envname}}</a></span>
              </li>
              <li v-if="$route.params.type == 'jobHistory'">
                <router-link to="/currentjoblist">Job Status</router-link>
              </li>
              <li v-if="$route.params.type == 'currentJobList'">
                <router-link to="/currentjoblist">Job Status</router-link>
              </li>
              <li v-if="$route.params.type == 'archive'">
                <router-link to="/archives">Manage Archives</router-link>
              </li>
              <li v-if="$route.params.type == 'jobPlan'">
                <a @click="backToJobPlan">Job Plan - [{{$route.params.job_plan_name}}]</a>
              </li>
            </ul>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <!-- <span id="radioControls" v-show="isfromcurrentlist">
          <v-radio-group row v-model="whichgrid" @change="loadHistory()" hide-details>
            <v-radio class="radioClass" :color="checkboxColor" label="Live"   value="live"></v-radio>
            <v-radio class="radioClass" :color="checkboxColor" label="History"   value="history"></v-radio>
          </v-radio-group>
        </span> -->
      </v-col>
    </v-row>

    <v-container style="margin-left:15px;margin-top:-30px;padding-left:0px !important;padding-right:30px;margin-bottom:10px !important;">
      <v-card>
        <v-row class="EDC-Row" no-gutters>
          <v-col class="EDC-Cols" cols="12" align="start">
            <span class="EDC-Heading">{{job_name}} ({{job_id}})</span>
          </v-col>
        </v-row>
        <v-row class="EDC-Row" no-gutters>
          <v-tabs v-model="activetab" non-linear @change="TabChanged">
            <v-tab class="EDC-Tabs" key="tab1">Summary</v-tab>
            <v-tab class="EDC-Tabs" key="tab2" v-show="showBusinessTableTab">Business Tables</v-tab>
            <v-tab class="EDC-Tabs" key="tab7" v-show="showSummarizationTab">Summarization Details</v-tab>
            <v-tab class="EDC-Tabs" key="tab3">Logs</v-tab>
            <v-tab class="EDC-Tabs" key="tab4" v-show="showReport">Reports</v-tab>
            <v-tab class="EDC-Tabs" key="tab5" v-if="showWorktable">{{table_type}} {{work_tbl_name}}</v-tab>
            <v-tab class="EDC-Tabs" v-if="mode==='final'" key="tab6">Statistics</v-tab>
            <!-- <v-tabs-items> -->
              <v-tab-item>
                <v-col cols="12" style="background-color:white" class="EDC-Col">
                  <v-col cols="5">
                    <v-data-table dense border="0px" :items="jobsummary" :items-per-page='15' class="elevation-1" hide-default-header hide-default-footer>
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr v-for="(item,index) in items" :key="index+'summary'" >
                            <td class="text-md-left" width="200px">{{ item.name }}</td>
                            <td class="text-md-left">{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-col>
              </v-tab-item>
              <v-tab-item v-show="showBusinessTableTab">
                <edc-data-grid key="JobTabGrid-A" :dataList="operationTableList" @onDownload="onOperationDownload" @redirectToDataviewer="redirectToDataviewer"></edc-data-grid>
              </v-tab-item>
              <v-tab-item v-show="showSummarizationTab">
                <v-row class="EDC-Row">
                  <v-col  cols="6">
                    <v-row class="EDC-Row">
                      <v-col class="EDC-Col" cols="12">
                        <summarization-details :jobId="job_id" :envId="env_id"
                        :jobMode="mode" :jobType="job_type"></summarization-details>
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>
              </v-tab-item>
              <v-tab-item>
                <edc-data-grid key="JobTabGrid-B" v-show="whichgrid === 'history'" :dataList="tableList" @onDownload="onDownload" @onSort="sortByB" @onPageChange="onPageChangeB" @onPerPageChange="onPerPageChangeB" @UpdateFilter="filterDataB" :liveStreamRow='newRow'></edc-data-grid>
                <!-- <edc-data-grid key="JobTabGrid-C" v-show="whichgrid === 'live'" :dataList="livetableList"></edc-data-grid> -->
              </v-tab-item>
              <v-tab-item v-show="showReport">
                <v-col cols="12" class="EDC-Col">
                  <edc-reportgrid :jobid="report_job_id" key="reportGrid"></edc-reportgrid>
                </v-col>
              </v-tab-item>

              <v-tab-item v-if="showWorktable">
                <v-col cols="12" class="EDC-Col">
                  <v-row class="EDC-Row">
                    <v-col cols="4" class="EDC-Col">
                      <v-row style="padding-left:2px; !important;padding-right:2px;!important;padding-bottom:1% !important;">
                        <v-col cols="12" >
                          <!-- <v-card> -->
                            <edc-data-grid key="JobTabGrid-D" :dataList="OutputTableList" @radioSelectionChange="worktableChanged" @onSort="sortByD" @onPageChange="onPageChangeD"  @onCellEvent="onCellEvent" @onPerPageChange="onPerPageChangeD" @UpdateFilter="filterDataD"></edc-data-grid>
                          <!-- </v-card> -->
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="8" class="EDC-Col">
                      <v-row class="EDC-Row">
                        <v-col cols="12" class="EDC-Col">
                          <!-- <v-card> -->
                            <WorkTableData :worktableObj="worktableobj" :allStepsDescription="allStepsDescription"></WorkTableData>
                          <!-- </v-card> -->
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-tab-item>

              <v-tab-item v-if="mode==='final'">
                <v-container class="EDC-Row">
                  <v-row class="EDC-Row">
                    <v-col cols="12">
                      <v-card class="EDC-CardSideMargin">
                        <v-row no-gutters class="EDC-DashboardRow" align="center">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <span class="EDC-Heading">Commit Progess</span>
                          </v-col>
                        </v-row>
                        <v-col cols="12" class="lineecharts">
                          <IEcharts :option="lineoption" :loading="lineloading" @click="onDataPointClick" @ready="onLineGraphReady"/>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row no-gutters style="padding-left:10px !important;padding-right:10px !important" class="EDC-Row">
                    <v-col cols="1" align="left" class="EDC-Col">
                      <label class="v-label" style="float:left;margin-top:5px;">Select Type</label>
                    </v-col>

                    <v-col cols="8" class="EDC-Col" align="left">
                      <v-radio-group v-model="graph_type" @change="graphTypeChange" dense hide-details row>
                        <v-radio label="Total Time" class="radioClass" value="total_time">
                        </v-radio>
                        <v-radio label="Total Count" class="radioClass" value="total_count">

                        </v-radio>
                        <v-radio label="Avg Time" class="radioClass" value="avg_time">
                        </v-radio>
                        <v-radio label="Avg Size" class="radioClass" value="avg_size">

                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="3" class="EDC-Col">

                    </v-col>
                  </v-row>
                  <v-row class="EDC-Row">
                    <v-col cols="12" class="EDC-Col">
                      <v-card class="EDC-Row EDC-CardSideMargin">
                      <v-row no-gutters class="EDC-DashboardRow" align="center">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <span class="EDC-Heading">{{graph_caption}}</span>
                          </v-col>
                        </v-row>
                      <v-row class="EDC-Row echarts">
                        <v-col cols="12">
                      <IEcharts  :option="option" :loading="loading" @click="onClick" @ready="onTimeReady"/>
                    </v-col>
                    </v-row>
                    </v-card>

                    <!-- <v-card class="echarts EDC-Row EDC-CardSideMargin" v-show="graph_type === 'total_count'">
                      <v-row no-gutters class="EDC-DashboardRow" align="center">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <span class="EDC-Heading">Total Count</span>
                          </v-col>
                        </v-row>

                      <IEcharts  :option="option" :loading="loading" @click="onClick" @ready="onCountReady"/>
                    </v-card> -->

                    </v-col>
                  </v-row>
                  <!-- <v-row class="EDC-Row">
                    <v-col cols="3" style="height:400px !important;overflow:auto;padding-top:0px !important;" id="style-2">
                    <v-card class="echarts EDC-Row EDC-CardSideMargin">
                      <v-row no-gutters class="EDC-DashboardRow" align="center">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <span class="EDC-Heading">Total Time</span>
                          </v-col>
                        </v-row>

                      <IEcharts  :option="option" :loading="loading" @click="onClick" @ready="onTimeReady"/>
                    </v-card>
                    </v-col>
                    <v-col cols="3" style="height:400px !important;overflow:auto;padding-top:0px !important;" id="style-2">
                    <v-card class="echarts EDC-Row EDC-CardSideMargin">
                      <v-row no-gutters class="EDC-DashboardRow" align="center">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <span class="EDC-Heading">Total Count</span>
                          </v-col>
                        </v-row>

                      <IEcharts  :option="option" :loading="loading" @click="onClick" @ready="onCountReady"/>
                    </v-card>
                    </v-col>

                    <v-col cols="3" style="height:400px !important;overflow:auto;padding-top:0px !important;" id="style-2">
                       <v-card class="echarts EDC-Row EDC-CardSideMargin">
                        <v-row no-gutters class="EDC-DashboardRow" align="center">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <span class="EDC-Heading">Average Time</span>
                          </v-col>
                        </v-row>
                      <IEcharts  :option="option" :loading="loading" @click="onAvgClick" @ready="onAvgReady"/>
                    </v-card>
                    </v-col>

                    <v-col cols="3" style="height:400px !important;overflow:auto;padding-top:0px !important;" id="style-2">
                       <v-card class="echarts EDC-Row EDC-CardSideMargin">
                        <v-row no-gutters class="EDC-DashboardRow" align="center">
                          <v-col cols="12" class="EDC-DashboardCol">
                            <span class="EDC-Heading">Average Size</span>
                          </v-col>
                        </v-row>

                      <IEcharts  :option="option" :loading="loading" @click="onAvgClick" @ready="onSizeReady"/>
                    </v-card>
                    </v-col>

                  </v-row> -->
                </v-container>

              </v-tab-item>
            </v-tabs>
          </v-row>
        </v-card>
    </v-container>

      <loading-panel :loader="loader"></loading-panel>

      <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
  </template>

  <script>
  import moment from 'moment'
  import Breadcrumb from "../../Breadcrumbs.vue"
  import config from '../../../config.json'
  import { get as getToServer, deleteFromServer, post as postToServer } from '../../../methods/serverCall.js';
  import {SERVER_ERROR} from '../../../data/client_message.js'
  import { ENVIRONMENTLIST_BY_CLIENT, DELETE_ENVIRONMENT, UPDATE_ENVIRONMENT, GETALLSTEPSDETAIL, EXPORT_STEP_DETAILS,GETSTEPSROWDETAILS, EXPORT_OPERATION_WISE_ROW,GET_WORKTABLE_LIST} from '../../../data/url_constants.js';
  import {CLIENT_SIDE, SERVER_SIDE} from '../../../data/macros.js'
  import cloneDeep from 'lodash/cloneDeep';
  import orderBy from 'lodash/orderBy';
  import {CHECKBOX_COLOR} from '../../../data/macros.js'
  import WorkTableData from './showWorkTableDataNew.vue'
  import ReportTab from '../../report/report_download.vue'
  import IEcharts from 'vue-echarts-v3/src/full.js';
  import 'echarts/lib/chart/line'
  import 'echarts/lib/component/polar'
  import {manageScreenDisplay} from '@/methods/special.js'
  import {getLineGraph,getBarGraph} from '@/methods/echartgraphs/transactiongraph.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'
  // import liveedcdatagrid from '../../../views/dataTable/liveedcdatagrid.vue'
  import {getURLByDomainByProduct} from '@/methods/domain_management.js'
  import {getPathfinderProduct} from "@/methods/commonFunc.js"
  import SummarizationDetails from './summarizationDetails.vue'
export default {
  name: 'DemoGrid',
  data: function () {
    return {
      newRow:{},
      lastNlogs:[],
      timeunit:'',
      sizeunit:'',
      countunit:'',
      WorkTableList:[],
      graph_type:'total_time',
      graph_caption:"Total Time",
      activetab:3,
      table_type:'Work Table',
      work_tbl_name:'',
      worktableobj:{},
      isOperationWiseRowLoaded:false,
      // isSummarizedRowLoaded:false,
      checkboxColor:CHECKBOX_COLOR,
      loader:false,
      job_id:"",
      report_job_id:"",
      job_name:"",
      mode:"final",
      status:"",
      isfromcurrentlist:"",
      whichgrid:'history',
      rowlimit:100,
      graphDataLoaded:false,
      logsHeader: [
        { text: 'Log Id', value: 'job_log_id',  options:[],align:"right", title: 'Job Log Id', dataType:'int'},
        { text: 'Step type', value: 'step_type', options:[], title: 'Job Step Type'},
        { text: 'Step name', value: 'step_name', options:[], title: 'Job Step Name'},
        { text: 'Operation', value: 'operation',  title: 'Operation Name'},
        { text: 'Table', value: 'table_name',  options:[], title: 'Table Name' },
        { text: 'Count', value: 'log_count', options:[], align:'right', title: 'Step Query Count', validate:'number', dataType:'int'},
        { text: 'Status' , value: "step_status",  options:[], title: 'Step Status'},
        {text: 'Time (sec)', value: 'processing_time',  align:'right', title: 'Processing Time In Sec', hideInlineFilter:true},
        { text: 'Start Date' , value: "start_date",dataType:'date', options:[], title: 'Step Start Date'},
        { text: 'Start Time' , value: "start_time",dataType:'time', options:[], title: 'Step Start Time', hideInlineFilter:true},
        { text: 'End Date', value: "end_date",dataType:'date',  options:[], title: 'Step End Date'},
        {text: 'End Time', value: "end_time",dataType:'time', options:[], title: 'Step End Time', hideInlineFilter:true},
        { text: 'Log Details', value: "query",  options:[],  title: 'Query',copyToClipboard:true, hideInlineFilter:true}
      ],
      tableList: {
        headers:[],
        actions: [],
        rows:[]
      },
      operationHeaders: [
        { text: 'Business Object Name', value: 'business_object_name', cssClass:"w16", options:[],align:"left"},
        { text: 'Action', value: 'activity_type',width:"10%", options:[], },
        { text: 'Table Name', value: 'table_name',cssClass:"w16",},
        { text: 'Expected', value: 'formated_expected_rows',align:'right', width:"10%", options:[], title: 'Expected Row Count', dataType:'int',sortColumn:'expected_row'},
        { text: 'Processed', value: 'formated_processed_rows',align:'right', width:"20%", options:[], align:'right', title: 'Processed Row Count', dataType:'int',sortColumn:'process_row'},
        { text: 'Skipped', value: 'formated_skipped_rows',align:'right', width:"20%", options:[], align:'right', title: 'Skipped rows due to duplicate found', dataType:'int',sortColumn:'skipped_row'},
        { text: 'Size (In MB)', value: 'size',align:'right', width:"20%", options:[], title: 'Size In MB', dataType:'int'},
        {text: '% Completed', value: 'completed',align:'right', cssClass:"w16", options:[], title: 'Percentage Completed', hideInlineFilter:true},
        { text: 'Row Size (In MB)', value: 'row_size',  cssClass:"w16", options:[], align:'right', title: 'Row Size In MB', dataType:'int'},
        { text: 'Index Size (In MB)', value: 'index_size',  cssClass:"w16", options:[], align:'right', title: 'Index Size In MB', dataType:'int'},
        ],
      operationTableList: {
        headers:[],
        actions: [],
        rows: []
      },
      outputTableHeaders: [
            {text:'',value:'radio',useRadio:true,hideSorting:true,hideFilter:true},
            { text: 'Step Name', value: 'output_table_reference', width:"30%", options:[],align:"left",hideSorting:true,hideFilter:true},
            { text: 'Table Name', value: 'output_table_name',width:"30%",options:[],align:"left",hideSorting:true,hideFilter:true},
            { text: 'Row Count', value: 'row_count', width:"15%", options:[],align:"left",hideSorting:true,hideFilter:true},
      ],
      outputTableOtherOptions:{
        showRadio:true,
        showSelect:false,
        showExport:false,
        showInlineFilter:false,
        showRowInformation:false
      },
      OutputTableList: {
        headers:[],
        actions: [],
        rows:[]
        // sorting_type: CLIENT_SIDE,
        // filterType: CLIENT_SIDE,
        // paginationType: CLIENT_SIDE,
        // total_count: 0,
        // rows: [],
        // pageNumber:1,
        // disableDefaltSorting:true,
        // selected_rows: [],
        // hideActions:true,
        // hideExport:true,
        // hideShowColumns:true,
        // hideFilter:true,
        // hideRowInfo:true,
        // showRadio:true,
        // hideSelect:true,
        // is_in_tab:false,
        // syncHeaderScroll:false,
      },

            //  flag:true,
            snackbar:false,
            snackbartext:'',
            envname:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            showEnvType:false,
            valid:true,
            dbTypefltrAry:[],
            nameTypefltrAry:[],
            'envTypefltrAry':[],
            'chip1':true,
            'searchEnv':"",
            'showname':false,
            'showDBType':false,
            chips: [],
            items: [],
            sorting : config.SORTING_TYPE,
            envIpJson : {
              "filter": [],
              "sort": [{"column_name": "job_log_id","type": "asc"}],
              "page": 1,
              "page_size": 10
            },
            isloading:false,
            menu:false,
            edit:"",
            show_last_page:false,
            show_radios:false,
            livetableList: {
              headers: [

              ],
              actions: [],
              sorting_type: CLIENT_SIDE,
              filterType: CLIENT_SIDE,
              paginationType: CLIENT_SIDE,
              total_count: 0,
              rows: [],
              hidePagination:true,
              hidePerPageArray:true,
              per_page:100,
            },
            jobsummary:[],
            firstStatus:'', // this status used to load tab data if user visited this page at the time of job not in Completed / terminated phase

            // graph
            isDrillDownload:false,
            subtextStyle:{
              color:"#000",
              // fontWeight:'bold',
              // fontStyle:'italic'
            },
            drilldownText:'',
            envname:'',
            job_id:'',
            job_name:'',
            status:'',
            Datajsonlist:[],
            deleteDatajsonlist:[],
            analysis_id:6,
            loading:false,
            pieloading:false,
            lineloading:false,
            total_echart_instance:null,
            total_echart_graph:null,
            total_count_echart_instance:null,
            total_count_echart_graph:null,
            pie_echart_instance:null,
            pie_echart_graph:null,
            line_echart_instance:null,
            line_echart_graph:null,
            avg_echart_instance:null,
            avg_echart_graph:null,
            avg_size_echart_instance:null,
            avg_size_echart_graph:null,
            noOfLines:1,
            datapoint_size:5,
            datapoint_border_width:2,
            line_width:3,
            backend_data:null,
            wholeGraphData:{},
            running_transaction_data:{"transaction_id":"","data":[]},
            call_send_to_fetch_transdetails:false,
            temp_data_container:{},
            showoptions:true,
            whichgraph:'all',
            checkboxColor:CHECKBOX_COLOR,
            isDuplicateFound:'N',
            titleFontSize:{
              fontSize:15,
            },
            pieoptions:{

            },
            lineoption:{

            },
            option :{

            },
            showBusinessTableTab:false,
            showSummarizationTab:false,
            showReport:true,
            allStepsDescription:{}
          }
        },
        components: {
          'WorkTableData':WorkTableData,
          IEcharts,
          'edc-reportgrid':ReportTab,
          // 'live-edc-data-grid':liveedcdatagrid,
          'summarization-details':SummarizationDetails,
        },
        mounted() {
          this.activetab = 0
          this.tableList = this.getGridObj(this.logsHeader,'job_log_id',false, {"showSelect":false})
          this.operationTableList = this.getGridObj(this.operationHeaders,'id')
          this.OutputTableList = this.getGridObj(this.outputTableHeaders,'output_table_name',true,this.outputTableOtherOptions)
          ///// code commented because not implemented becuase USE CASE NOT DISCUSSED 
          this.operationTableList.actions = [{'text':'viewindv','key':"table_name", selectType:"single",index:1,role:true}]
          
          // this.tableList.headers =this.getTableHeaders(); 
          this.livetableList.headers = this.getTableHeaders();
          if(this.tableList.paginationType == CLIENT_SIDE)
            this.envIpJson.page_size="*";
          // this.getEnvironmentDetails();
          // this.get_environment_by_client_id(this.envIpJson);
          this.envname = this.$route.params.envname
          if(this.$route.params.job_plan_name){
            this.job_plan_name = this.$route.params.job_plan_name
          }else{
            this.job_plan_name = "N/A"
          }
          this.env_id = this.$route.params.env_id || this.$session.get('selected_env').id
          this.job_id = this.$route.params.jobid
          this.job_name = this.$route.params.jobname
          this.mode = this.$route.params.mode
          this.status = this.$route.params.status.charAt(0).toUpperCase() + this.$route.params.status.slice(1)
          this.firstStatus = this.status
          this.isfromcurrentlist=this.$route.params.show_last_page
          this.isDuplicateFound = this.$route.params.isDuplicateFound
          this.isShowWorktable = this.$route.params.isShowWorktable
          // this.show_last_page = this.$route.params.show_last_page
          this.show_last_page = false
          if(["initializing","validating","preparing","running"].indexOf(this.$route.params.status)>=0){
            // this.whichgrid ='live'
            // this.whichgraph = "live"
          
          }
          else{
            this.isfromcurrentlist = false
            this.whichgrid ='history'
            this.whichgraph = "all"
          }
          this.envIpJson.page_size = manageScreenDisplay()
          this.getJobDetails()
          this.getAllStepsDescription()
      },
      computed:{
        showWorktable(){
          return this.status && (['completed','terminated'].indexOf(this.status.toLowerCase())===-1 || this.isDuplicateFound === 'Y' || this.isShowWorktable )
        },
        isFinalMode(){
          return this.mode === 'final'
        }
      },
      watch:{
        '$store.state.sqllogslivestatus':{
          handler(newValue){
            this.UpdateSqlLogsDetails(newValue)
          }
        },
        '$store.state.liveStatus':{
          handler(newValue){
            if(this.job_id === newValue.job_id){
              this.status = newValue.status.charAt(0).toUpperCase() + newValue.status.slice(1)
              this.jobsummary[2].value = this.status
              if(newValue.end_date && newValue.end_date!="None")
                this.jobsummary[5].value = newValue.end_date+" "+newValue.end_time
              if(this.status === 'Completed'){
                this.getJobCompletedTransData()
              }
            }
          }
        },
        '$store.state.updatedRowCounts':{
          handler(newValue){
            this.UpdateRowCounts(newValue)
          }
        },
        '$store.state.transctionTableObj':{
          handler(newValue){
           this.updateData(newValue)
         }
       },
     },
     methods: {
      graphTypeChange(item){
        setTimeout(function(){
          var options = _this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
          if(_this.total_echart_instance ){
            _this.total_echart_instance.resize()
          }
          // else if(item === 'total_count'){
          //   _this.total_echart_instance.setOption(options,true)
          //   _this.total_echart_instance.resize()
          // }
          // else if(_this.avg_size_echart_instance && item === 'avg_size'){
          //   _this.avg_size_echart_instance.resize()
          // }
          // else if(_this.avg_echart_instance && item === 'avg_time'){
          //   _this.avg_echart_instance.resize()
          // }
        },10)

      },
      TabChanged(){
        var _this = this
        // // no need to load data gain in case of Completed and Terminated Job
        // if(["Completed","Terminated"].indexOf(this.firstStatus) >=0)
        //   return false
        var _this = this
        if(this.activetab === 1 && !this.isOperationWiseRowLoaded){
          this.get_operationwise_details()

        }
        // if(this.activetab == 2 && !this.isSummarizedRowLoaded){
        //   this.getSummarizedRecordCount()
        // }
        else if(this.activetab === 3 && this.tableList.rows.length === 0){
          // this.show_last_page = this.$route.params.show_last_page
          this.show_last_page = false
          this.get_steps_details(this.envIpJson);
        }
        else if(!this.report_job_id && this.activetab === 4){
          setTimeout(()=>{
            _this.report_job_id = _this.job_id+""
          },100)
        }

        else if(this.showWorktable){
          this.getWorkTables()
        }
        var _this = this
        // need to give time to load that tab panel then need to load graph
        setTimeout(function(){
          if(_this.total_echart_instance && !_this.graphDataLoaded){
            _this.graphDataLoaded = true
            _this.getJobCompletedTransData()
            _this.line_echart_instance.resize()
          }
        },1)
      },
      onDownload(downloadParams, filterArray){
        let _this = this;
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
          obj.stringArray.map((stringObj, stringIndex)=>{
            let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
            filter.push(cloneDeep(temp));
          })
        });
        let inputJson={
          "recordType":downloadParams.recordType,
          "client_id": _this.$session.get('client_id'),
          "env_id": _this.env_id,
          "job_id": _this.job_id,
          "mode":this.mode,
          "job_name": this.job_name,
          "row_option": downloadParams.recordType,
          "file_format": downloadParams.fileType,
          "min_range": downloadParams.minRange|| null,
          "max_range": downloadParams.maxRange||null,
          "filter": filter
        }
        var url = config.ENGINE_API_URL+EXPORT_STEP_DETAILS;
        postToServer(_this, url, inputJson).then(Response => {
          var url = config.ENGINE_API_URL + "/static/" + Response;
          window.open(url, '_blank');
          _this.loader=false;
        }).catch(objError => {
          _this.loader=false;
          if(objError){
            this.loader = null
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = objError;
          }
          else {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          }
        });
      },
      getTableHeaders(){
        return [
        { text: 'Log Id', value: 'job_log_id',  options:[],align:"right", title: 'Job Log Id'},
        { text: 'Step type', value: 'step_type', options:[], title: 'Job Step Type'},
        { text: 'Step name', value: 'step_name', options:[], title: 'Job Step Name'},
        { text: 'Operation', value: 'operation',  title: 'Operation Name'},
        { text: 'Table', value: 'table_name',  options:[], title: 'Table Name' },
        { text: 'Count', value: 'query_result', options:[], align:'right', title: 'Step Query Count', validate:'number'},
        { text: 'Status' , value: "step_status",  options:[], title: 'Step Status'},
        {text: 'Time (sec)', value: 'processing_time',  align:'right', title: 'Processing Time In Sec'},
        { text: 'Start Date' , value: "start_date",dataType:'date', options:[], title: 'Step Start Date'},
        { text: 'Start Time' , value: "start_time",dataType:'date', options:[], title: 'Step Start Time'},
        { text: 'End Date', value: "end_date",dataType:'date',  options:[], title: 'Step End Date'},
        {text: 'End Time', value: "end_time",dataType:'date', options:[], title: 'Step End Time'},
        { text: 'Query', value: "query",  options:[],  title: 'Query',copyToClipboard:true}
        ]
      },

      getRecordPerPage(){
        let inputJson = this.envIpJson;
        inputJson.page_size = this.perPage;
        this.get_environment_by_client_id(inputJson);
      },
      UpdateSqlLogsDetails(data){
        if(this.job_id == data.job_id){
          console.log('sql',data)
          let _this = this
          if(!data.job_log_id)
            return
          if(!data['log_count'])
          data['log_count'] = data['query_result']
          console.log(data)
          var previous_data = cloneDeep(_this.lastNlogs)
          if(!previous_data.length)
            previous_data = cloneDeep(_this.tableList.rows)
          if(data.step_type)
            data.step_type = data.step_type.charAt(0).toUpperCase() + data.step_type.slice(1)
          if(data.operation)
            data.operation = data.operation.charAt(0).toUpperCase() + data.operation.slice(1)

          if(data.start_datetime_without_timezone && data.start_datetime_without_timezone!="None"){
            var date_without_miliseconds = data.start_datetime_without_timezone.split('.')[0]
            var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")

            var timezoned_formated_date = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format(this.$session.get('UI_date_format'))
            data.start_date = timezoned_formated_date
            var timezoned_start_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')

            data.start_time = timezoned_start_time
          }

          if(data.end_datetime_without_timezone && data.end_datetime_without_timezone!="None"){
            var date_without_miliseconds = data.end_datetime_without_timezone.split('.')[0]
            var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")

            var timezoned_formated_date = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format(this.$session.get('UI_date_format'))
            data.end_date = timezoned_formated_date
            var timezoned_start_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')

            data.end_time = timezoned_start_time
          }

          data.liveDataFor = 'sqlLog'
          var data_exists = _.find(previous_data,["job_log_id",data.job_log_id])
          if(data_exists)
          {
            var index = _.findIndex(previous_data,["job_log_id",data.job_log_id])
            this.$set(previous_data,index,data)
          }
          else{
            data.operationRowType = 'add'
            previous_data.unshift(data)
          }
          this.newRow = _.cloneDeep(data)
          var limited_data = previous_data.slice(0,this.rowlimit)
          _this.lastNlogs = limited_data
        }
      },

      get_steps_details(inputJson){
        let _this = this;
        this.loader = true
        var client_id = this.$session.get('client_id')
        var step_data = {
          "client_id":client_id,
          "filter": inputJson.filter,
          "page": inputJson.page,
          "page_size": inputJson.page_size,
          "sort": inputJson.sort,
          "job_id": this.job_id,
          'show_last_page':this.show_last_page,
        }
        postToServer(this, config.ENGINE_API_URL + GETALLSTEPSDETAIL, step_data
          ).then(response => {
            this.loader = false
            var timezone_preference = this.$session.get('time_zone_preference')
            if(response){
                  // data.map(function(obj){
                  // if (isNaN(parseInt(obj.query_result,10)) || obj.query_result.length > 15)
                  // obj.query_result = "N/A"
                  // obj.step_start_time = moment(obj.step_start_time, "x").tz(timezone_preference).format('YYYY-MM-DD | HH:mm:ss')
                  // })
                  var data = response.result
                  data.map(function(obj){
                    if(obj.step_type)
                      obj.step_type = obj.step_type.charAt(0).toUpperCase() + obj.step_type.slice(1)
                    if(obj.operation)
                      obj.operation = obj.operation.charAt(0).toUpperCase() + obj.operation.slice(1)
                  })
                  _this.tableList.rows = data;
                  _this.tableList.total_count = response.total;
                  if(_this.show_last_page){
                    var page_number = Math.ceil(response.total / 10)
                    _this.tableList.pageNumber = page_number
                    _this.show_last_page = false
                  }
                  else{
                    _this.tableList.pageNumber = ''
                  }
                }

              }).catch(JobStepError => {
                this.loader = false
              })
            },

            removeBubbling(event) {
              event && event.stopPropagation();
            },
            numPages() {
              let _this = this;
              if(_this.totalRecords)
                _this.totalPages = Math.ceil(_this.totalRecords / _this.perPage);
            },
            setRecordPerPage() {
              let _this = this;
            // _this.page = 1;
            _this.numPages();
            // _this.changePage();
          },
          changePage() {
            let _this = this,
            dataFrom = 0,
            dataTo = 5;
            _this.showDataAsPerPage(_this.sortingData);
          },
          showDataAsPerPage(data) {
            let _this = this,
            dataFrom = 0,
            dataTo = 5;
            if (_this.page < 1) _this.page = 1;
            if (_this.page > _this.numPages()) _this.page = numPages();
            if (_this.page <= 1) {
              if (data.length >= _this.perPage)
                _this.tableList = data.slice(0, _this.perPage);
              else
                _this.tableList = data.slice(0, data.length);
            } else {
              dataFrom = (_this.page - 1) * _this.perPage;
              dataTo = _this.perPage * _this.page;
              if (dataTo <= data.length)
                _this.tableList = data.slice(dataFrom, dataTo);
              else
                _this.tableList = data.slice(dataFrom, data.length);
            }
          },
          sortByB: function (sortJson) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.sort = sortJson
            this.get_steps_details(inputJson);
          },

          onPageChangeB(page, perPage) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.get_steps_details(inputJson);
          },

          onPerPageChangeB(perPage,page) {
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.page = page;
            inputJson.page_size = perPage;
            this.get_steps_details(inputJson);
          },

          filterDataB(filterArray,pageno){
            let _this = this;
            let inputJson = _this.envIpJson;
            inputJson.filter=filterArray;
            inputJson.page=1
            this.get_steps_details(inputJson);
          },

          loadHistory(){
            let _this = this;
            let inputJson = _this.envIpJson;
            _this.whichgraph = 'all'
              _this.whichgraph = 'live'
            if(_this.whichgrid === 'live'){
              // _this.get_steps_details(inputJson);
            }

            this.loadAllGraph()
          },
          backToJobPlan(){
            let _this = this;
            this.$router.push({ name: 'jobplaninstancedetails',
              params: {
                job_plan_id: this.$route.params.job_plan_id,
                job_plan_instance_id: this.$route.params.job_plan_instance_id,
                job_plan_name:this.$route.params.job_plan_name,
                execution_environment: this.$route.params.env_id,
                execution_env_name: this.$route.params.envname}
              }
            );
          },
          get_operationwise_details(envIpJson){
           let _this = this;
           this.loader = true
           this.operationTableList.rows = []
           var job_data = {"job_id": this.job_id, "env_id":this.env_id}
           postToServer(this, config.ENGINE_API_URL + GETSTEPSROWDETAILS, job_data).then(response => {
            if(response){
             this.isOperationWiseRowLoaded = true

              response.map((obj)=>{
                // obj.skipped_row = 0
                obj.activity_type = obj.activity_type.charAt(0).toUpperCase()+obj.activity_type.slice(1)
                if(!obj.skipped_row)
                  obj.skipped_row = "0"
                if(this.mode === 'proof'){
                  obj.completed = '0.00'
                }
                else{
                  obj.completed = '100.00'
                  if(obj.expected_row > 0 && obj.process_row < obj.expected_row)
                    obj.completed = ((obj.process_row+parseInt(obj.skipped_row))/obj.expected_row *100).toFixed(2)
                }
              });
              this.loader = false;
              _this.operationTableList.rows = response;
              _this.operationTableList.total_count = response.length;
            }
          }).catch(JobStepError => {
            this.loader = false
            if(JobStepError){

            }
            else {

            }
          })
        },



        onOperationDownload(downloadParams, filterArray){
          let _this = this;
          _this.loader=true;
          let filter=[];
          filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
              let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
              filter.push(cloneDeep(temp));
            })
          });
          let inputJson={
            "recordType":downloadParams.recordType,
            "row_option":downloadParams.recordType,
            "client_id": this.$session.get('client_id'),
            "env_id": this.env_id,
            "job_id": this.job_id,
            "mode":this.mode,
            "job_name": this.job_name,
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
          }
          var url = config.ENGINE_API_URL+EXPORT_OPERATION_WISE_ROW;
          postToServer(_this, url, inputJson).then(Response => {
            var url = config.ENGINE_API_URL + "/static/" + Response;
            window.open(url, '_blank');
            _this.loader=false;

          }).catch(objError => {
            _this.loader=false;
            if(objError){
              this.loader = null
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = objError;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
          });
        },
        UpdateRowCounts(data){
          let _this = this
          _this.showBusinessTableTab = true
          data.map((obj)=>{
            var data_already_in_row = false
            for(var i = _this.operationTableList.rows.length - 1; i >= 0; i--){
              if(_this.operationTableList.rows[i].job_id === obj.job_id  && _this.operationTableList.rows[i].business_object_id === obj.business_object_id && _this.operationTableList.rows[i].step_id===obj.step_id && _this.operationTableList.rows[i].table_name === obj.table_name){
                data_already_in_row = true
                _this.operationTableList.rows[i].expected_row = obj.expected_row
                _this.operationTableList.rows[i].process_row = obj.process_row
                _this.operationTableList.rows[i].formated_expected_rows = obj.formated_expected_rows
                _this.operationTableList.rows[i].formated_processed_rows = obj.formated_processed_rows
                _this.operationTableList.rows[i].formated_skipped_rows = obj.formated_skipped_rows
                _this.operationTableList.rows[i].size = obj.size
                _this.operationTableList.rows[i].process_row = obj.process_row
                _this.operationTableList.rows[i].index_size = obj.index_size
                _this.operationTableList.rows[i].row_size = obj.row_size

                _this.operationTableList.rows[i].completed = '100.00'
                if(_this.operationTableList.rows[i].expected_row > 0 && _this.operationTableList.rows[i].process_row < _this.operationTableList.rows[i].expected_row){
                  _this.operationTableList.rows[i].completed = (obj.process_row/_this.operationTableList.rows[i].expected_row *100).toFixed(2)
                }
                break;
              }
            }
            if(!data_already_in_row && this.job_id === obj.job_id){
              obj.activity_type = obj.activity_type.charAt(0).toUpperCase()+obj.activity_type.slice(1)
              if(this.mode === 'proof'){
                obj.completed = '0.00'
              }else{
                obj.completed = '100.00'
                if(obj.expected_row > 0 && obj.process_row < obj.expected_row){
                  obj.completed = (obj.process_row/obj.expected_row *100).toFixed(2)
                }
              }
              _this.operationTableList.rows.push(obj)
            }
          })
        },
        getJobDetails(){
          var env_id = this.$session.get('selected_env').id
          var job_id = this.job_id
          var data ={"job_id":job_id,env_id:env_id}
          this.loader = true
          postToServer(this, config.ENGINE_API_URL + '/get_job_details', data).then(response => {
            this.loader = false
            if(response){
              var keys  = Object.keys(response)
              // index used for sorting
              var data_list = []
              for (var i = 0; i <keys.length; i++) {
                // if(keys[i] === "process_definition_name")
                //   data_list.push({"name":"Process Definition Name","value":response.process_definition_name,"index":1})
                if(keys[i] == "job_type"){
                  var job_type = "Normal"
                  if(response.is_restored)
                    job_type="Restoration"
                  if(job_type === "Normal" && response.job_type === "sync_up")
                    job_type = "Sychronization"
                  if(job_type === "Restoration" && response.job_type === "sync_up")
                    job_type = "Un-Sychronization"
                  this.job_type = job_type
                  data_list.push({"name":"Job Type","value":job_type,"index":9})
                }

                if(keys[i] === 'activity_ids' && response.activity_ids){
                  data_list.push({"name":"Activity Ids","value":response.activity_ids,"index":10})
                }

                if(keys[i] === "process_definition_version")
                  data_list.push({"name":"Process Definition Version","value":response.process_definition_version ? response.process_definition_version:'N/A',"index":2})

                if(keys[i] === "mode_of_execution"){
                  this.mode = response.mode_of_execution
                  var mode = response.mode_of_execution.charAt(0).toUpperCase()+response.mode_of_execution.slice(1)
                  data_list.push({"name":"Run Mode","value":mode,"index":3})
                }

                // if you change this index then also make change in live status watch
                if(keys[i] === "status"){
                  var status = response.status.charAt(0).toUpperCase()+response.status.slice(1)
                  data_list.push({"name":"Status","value":status,"index":4})
                }

                if(keys[i] === 'policy_dates')
                  data_list.push({"name":"Policies","value":response.policy_dates ? response.policy_dates:'N/A',"index":5})

                if(keys[i] === "start_date")
                  data_list.push({"name":"Start Date & Time","value":response.start_date +' '+response.start_time,"index":6})

                if(keys[i] === "end_date")
                {
                  var end_date = 'NA'
                  if(response.end_date)
                    end_date = response.end_date +' '+response.end_time
                  data_list.push({"name":"End Date & Time","value":end_date,"index":7})
                }
                if(keys[i] === "total_running_time"){
                  data_list.push({"name":"Processing Time","value":response.total_running_time,"index":8})
                }
                 if(keys[i] === "duration"){
                  data_list.push({"name":"Duration","value":response.duration,"index":9})
                }

                if(keys[i] === "created_by")
                  data_list.push({"name":"Initiated By","value":response.created_by,"index":10})

                if(keys[i] === "archives_id")
                  data_list.push({"name":"Generated activity ids", "value":response.archives_id, "index":12})

              }
              if(this.job_plan_name){
                  data_list.push({"name":"Plan Name","value":this.job_plan_name,"index":11})
                }
              this.jobsummary = _.sortBy(data_list,"index")

              if(response.is_exists_bo_table_data)
                this.showBusinessTableTab = true
              
              if(response.is_exists_summ_count_data)
                this.showSummarizationTab = true
            }
          }).catch(JobStepError => {
            this.loader = false
            if(JobStepError){

            }
            else {

            }
          })
        },
        onCellEvent(record){
          debugger;
          var _this =this
          // this.worktableobj ={}
          if((record.operation === 'Insert'|| record.operation ==='Analyze' || record.step_type === 'report'||record.operation === 'Duplicate_findings')){
            if(this.work_tbl_name !=record.step_name){
              // below 2 variable used to perform spl operation while performing duplicate step data fetching
              var is_duplicate = false  // switch datasources
              var duplicate_filter = {} // filter specific table
              this.work_tbl_name = record.step_name
              var output_table_name = record.table_name
              if(record.operation === 'Duplicate_findings'){
                this.work_tbl_name = record.table_name
                output_table_name = record.processing_table
                is_duplicate = true
                duplicate_filter ={"column":"table_name","value":this.work_tbl_name}
              }
              this.worktableobj = {'output_table_name':output_table_name,'env_id':this.$session.get('selected_env').id,'output_table_reference':this.work_tbl_name,'is_duplicate':is_duplicate,'duplicate_filter':duplicate_filter,'step_name':record.output_table_reference,'job_id':this.job_id,'available_table_list':_this.OutputTableList.rows}
            }
          }
        },

        getWorkTables(envIpJson){
          let _this = this
          var request_data = {"job_id":this.job_id}
          _this.loader = true
          postToServer(this, config.ENGINE_API_URL + GET_WORKTABLE_LIST,request_data).then(response => {
            _this.loader = false
            response.map(function(obj){
              obj.env_id = _this.$session.get('selected_env').id
              obj.display_name =  obj.output_table_reference +' - '+ obj.output_table_name + ' - ' + obj.row_count + ' - ' + obj.step_type
              if(obj.table_column_data)
                obj.table_column_data = JSON.parse(obj.table_column_data)
              else
                obj.table_column_data = ''
            })
            this.WorkTableList = response
            this.OutputTableList.rows = response
            this.OutputTableList.total_count = response.length;
          }).catch(JobStepError => {
             _this.loader = false
            if(JobStepError){
            // this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = JobStepError;
          }
          else {
            // this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = SERVER_ERROR;
          }
        })
        },

        sortByD: function (sortJson) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.sort = sortJson
          this.getWorkTables(inputJson);
        },

        onPageChangeD(page, perPage) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          this.getWorkTables(inputJson);
        },

        onPerPageChangeD(perPage,page) {
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.page = page;
          inputJson.page_size = perPage;
          this.getWorkTables(inputJson);
        },

        filterDataD(filterArray,pageno){
          let _this = this;
          let inputJson = _this.envIpJson;
          inputJson.filter=filterArray;
          inputJson.page=1
          this.getWorkTables(inputJson);
        },

        worktableChanged(record){
          let worktableobj = _.cloneDeep(record)
          worktableobj['step_name']=record.output_table_reference
          worktableobj['job_id']=this.job_id
          worktableobj["envname"]=this.envname
          worktableobj['job_name'] = this.job_name
          worktableobj['available_table_list'] = this.OutputTableList.rows
          this.worktableobj ={}
          this.worktableobj = worktableobj
        },

        ////graph
        onLineGraphReady(instance,echarts){
          this.line_echart_instance = instance
          this.line_echart_graph = echarts
        },
        onTimeReady(instance,echarts){
          this.total_echart_instance = instance
          this.total_echart_graph = echarts
        },
        onCountReady(instance,echarts){
          this.total_count_echart_instance = instance
          this.total_count_echart_graph = echarts
        },
        onAvgReady(instance,echarts){
          this.avg_echart_instance = instance
          this.avg_echart_graph = echarts
        },
        onSizeReady(instance,echarts){
          this.avg_size_echart_instance = instance
          this.avg_size_echart_graph = echarts
        },
        onClick(event,instance,ECharts){

        },
        onAvgClick(){

        },
        getDataPoints(response){

        },
        getDataPoints_inCouple(response){

        },
        onDataPointClick(event, instance, ECharts){
          var data = event
          if(event.data.length > 1)
            this.drilldownText = event.seriesName + ' at '+event.data[0] + " is " +event.data[1]
          this.ShowDrillDownGraph(event.dataIndex)
        },
        ShowDrillDownGraph(data_index){
          let _this = this
          _this.isDrillDownload = true
          var data_point_data = cloneDeep(this.wholeGraphData.step_details[0].transaction_details[data_index])

          var table_insert_stats = []
          var table_delete_stats = []
          var table_data = data_point_data.table_details
          for (var j = 0; j < table_data.length; j++) {
            var current_row = table_data[j]
            _this.ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats)
        } // j for loop end
        var toolbox = {
          show:true,
          feature:{
            myTool1:{
              show:true,
              title:'Back',
              icon:'M464 0H144c-26.5 0-48 21.5-48 48v48H48c-26.5 0-48 21.5-48 48v320c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-96 464H48V256h320v208zm96-96h-48V144c0-26.5-21.5-48-48-48H144V48h320v320z',
              onclick:function(){
                var transactions = _this.wholeGraphData.step_details[0].transaction_details
                _this.drilldownText = ''
                _this.processTransactionData(transactions)
              }
            },
                // saveAsImage:{
                //  title:'Save',
                // },
              }
            }
            this.generate_total_bar_graph(table_insert_stats,table_delete_stats,toolbox)
          },
          getJobCompletedTransData(){
            this.loading = true
            this.lineloading = true
            postToServer(this, config.JOB_STATISTIC_URL + '/get_job_statistics', {"job_id":this.job_id}).then(response => {
              this.wholeGraphData = response
              console.log('this.wholeGraphData',this.wholeGraphData)
              this.processTransactionData(response.step_details[0].transaction_details)
              this.loading = false
              this.lineloading =false
            }).catch(JobStepError => {
              this.loading = false
              this.lineloading = false
            })
          },
          processTransactionData(transaction_details){
            var transactions = cloneDeep(transaction_details)
            var table_insert_stats = []
            var table_delete_stats = []
            var commit_time = []
            var commit_line_graph_data =[]
            var commit_size = []
            for (var i = 0; i < transactions.length; i++) {
              var current_trans = transactions[i]
              commit_time.push({"value":current_trans.transaction_time,"name":"TransNo "+(i+1)})
              commit_line_graph_data.push([current_trans.transaction_end_time,current_trans.transaction_time])
              commit_size.push([current_trans.transaction_end_time,current_trans.chunk_size])
              if(current_trans.status === 'commit'){
                var table_data = current_trans.table_details
                for (var j = 0; j < table_data.length; j++) {
                  var current_row = table_data[j]
                  this.ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats)
            } // j for loop end
          }
        } // i for loop finish
        this.generate_line_graph(commit_line_graph_data,commit_size)
        this.generate_total_bar_graph(table_insert_stats,table_delete_stats)
        // this.generate_pi(commit_time)
      },
      ProcessOnTableGraph(current_row,table_insert_stats,table_delete_stats){
        var operation = current_row.operation
        var table_name = current_row.table_name
        if(!current_row['avg_size']) // avg_size not come with live publish data
          current_row["avg_size"] = 0
        current_row["avg_size"] = parseInt(current_row["avg_size"])
        current_row["total_time"] = parseInt(current_row["total_time"])
        current_row["total_count"] = parseInt(current_row["total_count"])
        current_row["total_size"] = current_row["total_count"] * (current_row["avg_size"]/(1024))
        if(operation === "insert"){
          var obj = _.find(table_insert_stats,["table_name",table_name])
          if(obj){
            obj.total_time = obj.total_time + current_row.total_time
            obj.total_count = obj.total_count + current_row.total_count
            var index = _.findIndex(table_insert_stats,["table_name",table_name])
            obj.time_graph_key = [table_name,obj.total_time]
            obj.size_graph_key = [table_name,obj.total_count]
            obj.avg_time_key = [table_name,this.calculateAvgTime(obj.total_count,obj.total_time)]
            obj.avg_time_size_key = [table_name,this.calculateAvgSizeTime(obj.total_size,obj.total_time)]
            this.$set(table_insert_stats,index,obj)
          }
          else{
            current_row.time_graph_key = [table_name,current_row.total_time]
            current_row.size_graph_key = [table_name,current_row.total_count]
            current_row.avg_time_key = [table_name,this.calculateAvgTime(current_row.total_count,current_row.total_time)]
            current_row.avg_time_size_key = [table_name,this.calculateAvgSizeTime(current_row.total_size,current_row.total_time)]
            table_insert_stats.push(current_row)
          }
        }
        else {
          var obj = _.find(table_delete_stats,["table_name",table_name])
          if(obj){
            obj.total_time = obj.total_time + current_row.total_time
            obj.total_count = obj.total_count + current_row.total_count
            obj.time_graph_key = [table_name,obj.total_time]
            obj.size_graph_key = [table_name,obj.total_count]
            obj.avg_time_key = [table_name,this.calculateAvgTime(obj.total_count,obj.total_time)]
            obj.avg_time_size_key = [table_name,this.calculateAvgSizeTime(obj.total_size,obj.total_time)]
            var index = _.findIndex(table_delete_stats,["table_name",table_name])
            this.$set(table_delete_stats,index,obj)
          }
          else{
            current_row.time_graph_key = [table_name,current_row.total_time]
            current_row.size_graph_key = [table_name,current_row.total_count]
            current_row.avg_time_key = [table_name,this.calculateAvgTime(current_row.total_count,current_row.total_time)]
            current_row.avg_time_size_key = [table_name,this.calculateAvgSizeTime(current_row.total_size,current_row.total_time)]
            table_delete_stats.push(current_row)
          }
        }
      },
      calculateAvgTime(count ,timeInSec){
        if(timeInSec === 0 || count === 0)
          return 0
        return (count/timeInSec).toFixed(2)
      },
      calculateAvgSizeTime(size,timeInSec){
        if(timeInSec === 0 || size === 0)
          return 0
        return (size/timeInSec).toFixed(2)
      },
      generate_line_graph(commit_line_graph_data,commit_size){
        let linegraphData = getLineGraph(commit_line_graph_data,commit_size,this.wholeGraphData.step_details[0].time_unit)
        this.line_echart_instance.setOption(linegraphData, true)
      },
      generate_total_bar_graph(insert_data,delete_data,toolbox={}){
        let _this = this
        if(insert_data.length < 1 && delete_data.length < 1)
          return false
        var tables = _.map(insert_data, "table_name")
        if(!tables.length)
          tables = _.map(delete_data, "table_name")

        var insert_time_series_data = _.map(insert_data, "time_graph_key")
        var delete_time_series_data = _.map(delete_data, "time_graph_key")
        var insert_size_series_data = _.map(insert_data, "size_graph_key")
        var delete_size_series_data = _.map(delete_data, "size_graph_key")
        if(_this.graph_type === 'total_time'){
          _this.graph_caption = "Total Time"
        let totalTimeData = getBarGraph(["Ins.Time","Del.Time"],'Time in('+this.wholeGraphData.step_details[0].time_unit+')',tables,insert_time_series_data,delete_time_series_data,toolbox)
        _this.total_echart_instance.setOption(totalTimeData, true)
        return
        }

        if(_this.graph_type === 'total_count'){
          _this.graph_caption = "Total Count"
        let totalRowCountData = getBarGraph(["Ins.Count","Del.Count"],'Row Count in('+this.wholeGraphData.step_details[0].count_unit+')',tables,insert_size_series_data,delete_size_series_data,toolbox)
          _this.total_echart_instance.setOption(totalRowCountData, true)
        }
        _this.generate_avg_bar_graph(insert_data,delete_data,toolbox)

      },
      generate_avg_bar_graph(insert_data,delete_data,toolbox={}){
        let _this = this
        if(insert_data.length < 1 && delete_data.length < 1)
          return false
        var tables = _.map(insert_data, "table_name")
        if(!tables.length)
          tables = _.map(delete_data, "table_name")
        var insert_avg_time_series_data = _.map(insert_data, "avg_time_key")
        var delete_avg_time_series_data = _.map(delete_data, "avg_time_key")
        var insert_avg_size_time_series_data = _.map(insert_data, "avg_time_size_key")
        var delete_avg_size_time_series_data = _.map(delete_data, "avg_time_size_key")
        if(_this.graph_type === 'avg_time'){
          _this.graph_caption ="Avg Time"
        let timeAvgData = getBarGraph(["Ins.AvgT","Del.AvgT"],'Time in('+this.wholeGraphData.step_details[0].time_unit+')',tables,insert_avg_time_series_data,delete_avg_time_series_data,toolbox)
        _this.total_echart_instance.setOption(timeAvgData, true)
        return
        }
        if(_this.graph_type === 'avg_size'){
           _this.graph_caption ="Avg Size"
        let sizeAvgData = getBarGraph(["Ins.AvgS","Del.AvgS"],'Size in('+this.wholeGraphData.step_details[0].size_unit+')',tables,insert_avg_size_time_series_data,delete_avg_size_time_series_data,toolbox)
        _this.total_echart_instance.setOption(sizeAvgData, true)
        return
        }
  },

  updatetimedata(data){
    var table_name = data.table_name
    var obj = _.find(this.Datajsonlist,["table_name",table_name])

    if(data.operation === "insert"){
      data.insert_count  = parseInt(data.total_count)
      data.delete_count = 0
    }
    else{
      data.delete_count = parseInt(data.total_count)
      data.insert_count = 0
    }

    if(obj){
      obj.insert_count = obj.insert_count + data.insert_count
      obj.delete_count = obj.delete_count + data.delete_count
      var index = _.findIndex(this.Datajsonlist,["table_name",table_name])
      this.$set(this.Datajsonlist,index,obj)
    }
    else{
      this.Datajsonlist.push(data)
    }
    var tablelist = _.map(this.Datajsonlist,"table_name")
    var insert_data_list = _.map(this.Datajsonlist,"insert_count")
    var delete_data_list =_.map(this.Datajsonlist,"delete_count")
    var option = {
      xAxis: {
        type: 'category',
        name:'Tables',
        nameGap:0,
        data:tablelist,
        nameLocation:'center',
        axisTick:{
          alignWithLabel:true,
        },
        axisLabel: {
          interval: 0,
          rotate: 30
        },
      },
      yAxis: {
        type: 'value'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer: {
          type: '',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      series: [{
        name:"Insert",
        data: insert_data_list,
        type: 'bar'
      },
      {
        name:"Delete",
        data: delete_data_list,
        type: 'bar'
      }]
    }
    this.total_echart_instance.setOption(option, true)
  },
  ConvertTime(time,unit){
    if(!unit || unit.toLowerCase() === 'milliseconds')
      return time

    if(unit.toLowerCase() === 'seconds')
      return Math.floor(time/1000)
    if(unit.toLowerCase() === 'minutes' || unit.toLowerCase() === 'minute')
      return Math.floor(time/60000)
    return Math.floor(time/3600000)
  },
  ConvertSize(time,unit){
    if(!unit || unit.toLowerCase() === 'milliseconds')
      return time

    if(unit.toLowerCase() === 'seconds')
      return Math.floor(time/1000)
    if(unit.toLowerCase() === 'minutes' || unit.toLowerCase() === 'minute')
      return Math.floor(time/60000)
    return Math.floor(time/3600000)
  },
  ConvertCount(count,unit){
    if(!unit)
      return count

    if(unit.toLowerCase() === 'ks')
      return Math.floor(count/1000)
    if(unit.toLowerCase() === 'million')
      return Math.floor(count/1000000)
    return count
  },
  updateData(data){
    /*new data received. Now check is the new transaction or old*/
    if(this.job_id != data.job_id)
      return false
    var timeunit = 'milliseconds'
    var sizeunit="KB"
    var countunit="Ks"
    if(this.wholeGraphData.step_details.length && this.wholeGraphData.step_details[0].time_unit){
      timeunit = this.wholeGraphData.step_details[0].time_unit
      sizeunit = this.wholeGraphData.step_details[0].size_unit
      countunit = this.wholeGraphData.step_details[0].count_unit
    }

    let totalTime = this.ConvertTime(data.total_time,timeunit)
    // let totalCount = this.ConvertCount(data.total_count,countunit)
    // let totalSize = this.conve
    data.total_time = totalTime
    // data.total_count = totalCount
    if(this.running_transaction_data && this.running_transaction_data.transaction_id){
      if(data.transaction_id === this.running_transaction_data.transaction_id)
      {
        this.running_transaction_data["data"].push(data)
        return this.CreateLiveGraph()
      }
      else{
        var old_transaction_id = this.running_transaction_data.transaction_id
        this.running_transaction_data ={"transaction_id":"","data":[]}
        this.running_transaction_data.transaction_id = data.transaction_id
        this.running_transaction_data["data"].push(data)

            // we need to fetch all details of old_transacion_id to design new graph.
            this.fetchSingleTransDetails(old_transaction_id)
          }
        }
        else{
          var new_transaction_id = data.transaction_id
          this.running_transaction_data.transaction_id = data.transaction_id
          this.running_transaction_data["data"].push(data)
          // fetch data only once when visited this page
          this.fetchSingleTransDetails(new_transaction_id)
        }
      },
      fetchSingleTransDetails(transaction_id){
        if(!transaction_id)
          return false
         var timeunit = 'milliseconds'
         var sizeunit="KB"
         var countunit="Ks"
         if(!this.wholeGraphData ||!this.wholeGraphData.step_details)
          return
        if(this.wholeGraphData.step_details.length && this.wholeGraphData.step_details[0].time_unit){
          timeunit = this.wholeGraphData.step_details[0].time_unit
          sizeunit = this.wholeGraphData.step_details[0].size_unit
          countunit = this.wholeGraphData.step_details[0].count_unit
        }
        var data = {"transaction_id":transaction_id,'timeunit':timeunit,'sizeunit':sizeunit,'countunit':countunit,"job_id":this.job_id}
        let _this = this
        postToServer(this, config.JOB_STATISTIC_URL + '/get_transaction_statistics', data).then(response => {
          console.log(response)
          if(response.status === "commit"){
            // add this response into wholeGraphData
            if(!_this.wholeGraphData.step_details || _this.wholeGraphData.step_details.length < 1){
              _this.wholeGraphData = {"step_details":[{"transaction_details":[]}]}
              _this.wholeGraphData.step_details[0].transaction_details.push(response)
            }
            else{
              var obj = _.find(_this.wholeGraphData.step_details[0].transaction_details, ["id",response.id])
              if(!obj)
                _this.wholeGraphData.step_details[0].transaction_details.push(response)
            }

            _this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
          }
          else{
            if(response.id === _this.running_transaction_data.transaction_id){
              _this.arrangeRunningData(response.table_details)
            }
          }
        }).catch(JobStepError => {

        })
      },
      arrangeRunningData(response_data){
        let _this = this
        /*
        Now we need to add response table details in running_transaction_data.data if that not not present. Where we add its order is mattered here. Becuase table comes in respose in always get excuted first.So we need to add that table at index level on. So loop of the response table details start from last element
        */
        for (var i = response_data.length - 1; i >= 0; i--) {
          var table_name = response_data[i].table_name
          var operation = response_data[i].operation
          var data_list = _this.running_transaction_data["data"]
          var is_data_found = false
          for (var j = data_list.length - 1; j >= 0; j--) {
            if(table_name === data_list[j]["table_name"] && operation === data_list[j]["operation"]){
              is_data_found = true
              break;
            }
          }
          if(!is_data_found)
            _this.running_transaction_data["data"].unshift(response_data[i])
        }
        if(_this.whichgraph === 'live' && !_this.isDrillDownload)
          this.CreateLiveGraph()
      },
      CreateLiveGraph(){
        if(this.whichgraph === "all" || this.isDrillDownload)
          return false
        let _this = this
        var insert_data = []
        var delete_data = []
        var data_rows =cloneDeep(_this.running_transaction_data["data"])
        for (var i = 0; i < data_rows.length; i++) {
          var current_row = data_rows[i]
          _this.ProcessOnTableGraph(current_row,insert_data,delete_data)
        }
        _this.generate_total_bar_graph(insert_data,delete_data)
      },
      loadAllGraph(){
        let _this = this;
        _this.isDrillDownload = false
        _this.total_echart_instance.setOption(this.option, true)
        if(_this.whichgraph === 'live'){
          _this.CreateLiveGraph()
        }
        else{
          _this.processTransactionData(_this.wholeGraphData.step_details[0].transaction_details)
        }
      },
      getSummarizedRecordCount(){
        let _this = this;
           this.loader = true
           var job_data = {"job_id": this.job_id, "env_id":this.env_id}
           postToServer(this, config.ENGINE_API_URL + '/get_summarization_count', job_data).then(response => {
            this.loader = false
            console.log(response)
            if(response.summarize_count && response.summarize_count.length)
              _this.manageSummarizationCount(response.summarize_count[0])
            if(response.summarize_option)
              this.manageSummarizationOptions(response.summarize_option)
          }).catch(JobStepError => {
            this.loader = false
            if(JobStepError){

            }
            else {

            }
          })
      },
      updateSummarizationRow(sumarizationData){
        var _this = this
        if(this.job_id!=sumarizationData.job_id)
          return
        // considering single row will be present in the summarization record
        _this.$nextTick(()=>{
          _this.manageSummarizationCount(sumarizationData)
        })
      },
      manageSummarizationCount(countData){
        let summarization_ratio = 0
        if(countData.summarized_count > 0)
          summarization_ratio = Math.ceil(countData.processed_bf_count/countData.summarized_count)*100
        this.summarizationCountObj = {"expected_bf_count":countData.localize_expected_bf_count,"processed_bf_count":countData.localize_processed_bf_count,"summarized_count":countData.localize_summarized_count,"skipped_count":countData.localize_skipped_count,"summarization_ratio":summarization_ratio}
      },
      manageSummarizationOptions(optionData){
        let _this = this
        let F0902_archive_check = 'No'
        let write_in_catalog = 'No'
        if(optionData.f0902_archive_check)
          F0902_archive_check = 'Yes'
        if(optionData.write_in_catalog)
          write_in_catalog = 'Yes'
          _this.summarizationOptionData = {"f0902_archive_check":F0902_archive_check,"write_in_catalog":write_in_catalog}
      },
      redirectToDataviewer(record){
        let _this = this
        var redirectionObj  = getPathfinderProduct(_this)
        let data_to_send = {
          "env_id":this.env_id,
          "client_id":this.$session.get('client_id'),
          "archivist_ds_type":["source_data","target_data"],
          "request_for":"business_data",
          "job_name":_this.job_name,
          "job_id":this.job_id,
          "table_list":[],
          "selected_table":{"table_name":record.table_name}
        }
        _.forEach(_this.operationTableList.rows,(obj)=>{
          data_to_send.table_list.push({"output_table_name":obj.table_name})
        })

        _this.loader=true
        var cache_url = config.EDC_CACHE_SERVICE_URL + "/add_dataviewer_redirect_key"
        postToServer(_this, cache_url, data_to_send).then(response=>{
          _this.loader=false
          if(response){
          var archivist_redirect_key=response
          // write code here to create key and pass that key to the function as query_string
          let query_string = '&archivist_redirect_key='+archivist_redirect_key// append key here
          let url = getURLByDomainByProduct(_this,redirectionObj,'login',query_string)
          window.open(url, '_blank');
          }
          else{
            return
          }
        }).catch(error_response=>{
            _this.loader=false
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
        })
      },
      getAllStepsDescription(){
        var _this = this
        var data = {}
        postToServer(_this, config.REPOSITORY_API_URL +'/get_process_designer_steps_description',data).then(response=>{
          _this.allStepsDescription =  response
        }).catch(error=>{

        })
      }

    }
  }
  </script>
  <style scoped>

  .echarts {
    width: 1200px;
    height: 230px !important;
  }
  .lineecharts{
    width: 1250px;
    height: 200px !important;
  }
  .graph_div {
    width:500px !important;
    overflow: auto !important;
  }

  tr:nth-child(even) {background-color: #E8E8E8;}

  /* tr:hover td {background:#A9A9A9;} */
  .blurOpacity{
    opacity: 0;
  }
  .showInput {
    visibility: inherit !important;
    width: 50% !important;
  }

  .srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
  }
  #radioControls .v-input--selection-controls {
   margin-top: 0px !important;
   padding-top: 0px !important;
 }


/*
 *  STYLE 2
 */

#style-2::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  border-radius: 10px;
  background-color: white;
}

#style-2::-webkit-scrollbar
{
  width: 5px;
  background-color: white;
}

#style-2::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #DCDCDC;

}

.scrollbar
{
  background: white;
  overflow-y: scroll !important;
  margin-bottom: 25px;
  overflow-x: hidden !important;
}
.v-sheet {
  border-radius: 5px;
}

.radioClass >>> label{
    top: 5px !important;
}

.v-input--selection-controls {
     margin-top: 0px;
     padding-top: 0px;
}

.theme--light.v-icon{
  color:var(--v-primary-base) !important;
}

</style>
