<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <router-link to="/dashboard">
                  {{ execution_env_name }}
                </router-link>
              </li>
              <li>
                <router-link to="/jobplanstatus">Job Plan Status</router-link>
              </li>
              <li>Plan Details </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-container>
      <v-card>
        <v-row class="EDC-Row" no-gutters>
          <v-tabs v-model="activeTab" non-linear>
            <v-tab class="EDC-Tabs" key="tab1">
              <v-badge :content="tableList.rows.length" inline bordered>
                <span>Instance Details</span>
              </v-badge>
            </v-tab>
            <v-tab class="EDC-Tabs" key="tab2">
              <span>Instance logs</span>
            </v-tab>
            <v-tab-item>
              <edc-data-grid key="grid-A" :dataList="tableList" @onDetailedHistory="onDetailedHistory" @onHistory="onHistory" @onDownload="onDownload"></edc-data-grid>
            </v-tab-item>
            <v-tab-item>
              <edc-data-grid key="grid-b" :dataList="logTable"></edc-data-grid>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </v-card>
    </v-container>
    <!-- <edc-data-grid :dataList="tableList" @onDetailedHistory="onDetailedHistory" @onHistory="onHistory" @onDownload="onDownload"></edc-data-grid> -->

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
      
  </div>
</template>
<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import {JOB_PLAN_INSTANCE_HISTORY_DETAILS, EXPORT_JOB_PLAN_INST_DETAILS} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import getUserRole from '@/methods/GetUserRole.js'
import {manageScreenDisplay} from '@/methods/special.js'
import {SCHEDULER_SERVICE,SCHEDULE_ACTION,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION} from '@/data/macros.js'
import config from '../../config.json'
import {getEnvironmentList} from '../../methods/EnvironmentList.js'
import cloneDeep from 'lodash/cloneDeep';
import {SERVER_ERROR} from '@/data/client_message.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  name: 'EventList',
  
  data: function () {
    return {
      snackbar:false,
      snackbartext:'',
      job_plan_name:'',
      colorValue:'success',
      snackbartimeout: Snackbar_Default_Timeout,
      EnvironmentList: [],
      EnvironmentListRules: [v => !!v || 'Environment is required.'],
      calendarDetails: this.$store.state.calendarDetails,
      calenderList: [],
      activeTab:1,
      // tableList: {
      //   headers: [{
      //     text: 'Job Id',
      //     value: 'job_id',
      //     width: '20%',
      //     align:'right',
      //     dataType:'int'
      //   },
      //   {
      //     text: 'Seq No',
      //     value: 'index_no',
      //     width: '20%',
      //     align:'right',
      //     dataType:'int'
      //   },
      //   {
      //     text: 'Process definition name',
      //     value: 'displayPDName',
      //     width: '30%',
      //     avoidTrim:true,
      //   },

      //   {
      //     text: 'Status',
      //     value: 'status',
      //     inputKey: 'valueType',
      //     inputValue: 'date',
      //     width: '30%'
      //   },
      //   {
      //     text: 'Start Date',
      //     value: 'start_date',
      //     width: '20%',
      //     dataType:'date'
      //   },
      //   {
      //     text: 'Start time',
      //     value: 'start_time',
      //     width: '20%',
      //     dataType:'time',
      //     hideInlineFilter: true
      //   },
      //   {
      //     text: 'End Date',
      //     value: 'end_date',
      //     width: '20%',
      //     dataType:'date'
      //   },
      //   {
      //     text: 'End time',
      //     value: 'end_time',
      //     width: '20%',
      //     dataType:'time',
      //     hideInlineFilter: true
      //   }
      //   ],
      //           //if we need conditional action in row then provide key
      //           sorting_type: CLIENT_SIDE,
      //           filterType: CLIENT_SIDE,
      //           paginationType: CLIENT_SIDE,
      //           total_count: 0,
      //           rows: [],
      //           select_rows: true,
      //           syncHeaderScroll: false,
      //           disableDefaltSorting:true,
      //           hideExport:true,
      //           hideShowColumns:true,
      //           is_in_tab:true
      //         },

      tableList: {
                headers:[],
                actions:[],
                rows:[],
            },
      headers: [{
          text: 'Job Id',
          value: 'job_id',
          width: '20%',
          align:'right',
          dataType:'int'
        },
        {
          text: 'Seq No',
          value: 'index_no',
          width: '20%',
          align:'right',
          dataType:'int'
        },
        {
          text: 'Process definition name',
          value: 'displayPDName',
          width: '30%',
          avoidTrim:true,
        },

        {
          text: 'Status',
          value: 'status',
          inputKey: 'valueType',
          inputValue: 'date',
          width: '30%'
        },
        {
          text: 'Start Date',
          value: 'start_date',
          width: '20%',
          dataType:'date'
        },
        {
          text: 'Start time',
          value: 'start_time',
          width: '20%',
          dataType:'time',
          hideInlineFilter: true
        },
        {
          text: 'End Date',
          value: 'end_date',
          width: '20%',
          dataType:'date'
        },
        {
          text: 'End time',
          value: 'end_time',
          width: '20%',
          dataType:'time',
          hideInlineFilter: true
        }
        ],
        //  logTable: {
        //         headers: [{
        //           text: 'Log Id',
        //           value: 'log_id',
        //           width: '15%',
        //           dataType:'int'
        //         },
        //         {
        //           text: 'Job id',
        //           value: 'job_id',
        //           width: '15%',
        //           dataType:'int'
        //         },
        //         {
        //           text: 'Description',
        //           value: 'description',
        //           width: '25%'
        //         },
        //         {
        //           text: 'Created By',
        //           value: 'created_by',
        //           width: '15%',
        //           title: 'Job Plan Log Created By',
        //           dataType:'date'
        //         },
        //         {
        //           text: 'Created Date',
        //           value: 'created_date',
        //           width: '15%',
        //           title: 'Job Plan Log Created Date',
        //           dataType:'date'
        //         },
        //         {
        //           text: 'Created Time',
        //           value: 'created_time',
        //           width: '15%',
        //           title: 'Job Plan Log Created Time',
        //           dataType:'time',
        //           hideInlineFilter: true
        //           },
        //         ],
        //         //if we need conditional action in row then provide key
        //         sorting_type: CLIENT_SIDE,
        //         filterType: CLIENT_SIDE,
        //         paginationType: CLIENT_SIDE,
        //         total_count: 0,
        //         rows: [],
        //         select_rows: true,
        //         syncHeaderScroll: false,
        //         disableDefaltSorting:true,
        //         hideExport:true,
        //         hideShowColumns:true,
        //         is_in_tab:true
        //       },
        
        logTable: {
                headers:[],
                rows:[],
                actions:[]
            },      
        log_headers: [{
                  text: 'Log Id',
                  value: 'log_id',
                  width: '15%',
                  dataType:'int'
                },
                {
                  text: 'Job id',
                  value: 'job_id',
                  width: '15%',
                  dataType:'int'
                },
                {
                  text: 'Description',
                  value: 'description',
                  width: '25%'
                },
                {
                  text: 'Created By',
                  value: 'created_by',
                  width: '15%',
                  title: 'Job Plan Log Created By',
                  dataType:'date'
                },
                {
                  text: 'Created Date',
                  value: 'created_date',
                  width: '15%',
                  title: 'Job Plan Log Created Date',
                  dataType:'date'
                },
                {
                  text: 'Created Time',
                  value: 'created_time',
                  width: '15%',
                  title: 'Job Plan Log Created Time',
                  dataType:'time',
                  hideInlineFilter: true
                  },
                ],
              userRole:{},
              Environment:{},
               envIpJson : {
                "filter": [],
                "sort": [{
                    "column": "",
                    "type": ""
                }],
                "page": 1,
                "page_size": 10
                    },
              job_plan_id : this.$route.params.job_plan_id,
              job_plan_instance_id: this.$route.params.job_plan_instance_id,
              execution_environment:this.$route.params.execution_environment,
              execution_env_name:this.$route.params.execution_env_name,
              loader:false,
            }
          },
          mounted() {
            getEnvironmentList(this)
            this.getPlanHistoryInstanceDetailsList();
            this.getLogList();
            this.activeTab = 0
            this.job_plan_name = this.$route.params.job_plan_name
            this.envIpJson.page_size = manageScreenDisplay()-5
            this.tableList = this.getGridObj(this.headers, 'id', true, {showExport : false})
            this.logTable = this.getGridObj(this.log_headers, 'log_id', true, {showExport : false})
            this.tableList.actions =[
            // {'text':'detailedHistory','key':"job_id", selectType:"single"},
            {'text':'history','key':"job_id", selectType:"single",index:1}
            ]

            var env_value = this.$session.get('selected_env')
            if( env_value && env_value.id != '0' && env_value.id !='all_env'){
              this.Environment = this.$session.get('selected_env')
              this.getPlanHistoryInstanceDetailsList()
              this.getLogList()
            }
        // this.userRole = this.$session.get('user_role_mapping');
        // this.tableList.actions=[{'text':'create','key':"", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,SCHEDULE_ACTION) || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION)}]
      },
      methods: {
        onDownload(downloadParams, filterArray){
            let _this = this
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "env_id": _this.Environment.id,
                'job_plan_instance_id': this.job_plan_instance_id,
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.JOB_PLAN_URL+EXPORT_JOB_PLAN_INST_DETAILS;    
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.JOB_PLAN_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
            _this.loader=false;
            if(objError){
                  _this.loader = null
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = objError;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
            })
        },
        focus_env(){
          if(this.$refs.envname.$el)
            this.$refs.envname.$el.focus()
          else this.$refs.envname.focus()
        },
      getPlanHistoryInstanceDetailsList() {
        let _this = this;
        _this.loader = true;
        var env_id = this.Environment.id;
        if (!env_id)
          return false
        var env_id = _this.Environment.id
        this.$session.set('selected_env', this.Environment)
        var job_data = {'client_id':this.$session.get('client_id'),
        'execution_environment': env_id,
        'job_plan_instance_id': this.job_plan_instance_id};
        let baseUrl = config.JOB_PLAN_URL;
        baseUrl = baseUrl+ JOB_PLAN_INSTANCE_HISTORY_DETAILS;
        postToServer(this, baseUrl, job_data
          ).then(response => {
           _this.loader=false;
           _.forEach(response, function(obj){
            obj['displayPDName'] = _this.addDependency(obj['process_definition_name'],obj.instance_details_indentation)
           })
           _this.tableList.rows = response;
         }).catch(CurrentJobError => {
           _this.loader=false;
           this.tableList.rows=[];
           this.snackbar = true;
           this.colorValue = 'error'
           this.snackbartext = CurrentJobError;
         })
       },
       getLogList() {
          let _this = this;
          var env_id = this.Environment.id;
            if (!env_id)
              return false
          var env_id = _this.Environment.id
         this.$session.set('selected_env', this.Environment)

          var job_data = {'client_id':this.$session.get('client_id'),
                          'env_id': env_id,
                          'job_plan_instance_id': this.job_plan_instance_id};
          let baseUrl = config.JOB_PLAN_URL;
              baseUrl = baseUrl+ '/get_job_instance_logs';
          _this.loader = true
          postToServer(this, baseUrl, job_data
              ).then(response => {
              _this.loader=false;
              _this.logTable.rows = response;
           }).catch(CurrentJobError => {
               _this.loader=false;
               this.logTable.rows=[];
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError;
            })
        },
       onDetailedHistory:function(record){
          // debugger;
          this.$router.push({ name: 'operationwiseresult', params: { env_id: this.execution_environment, envname:this.execution_env_name,jobid: record.job_id,mode:record.mode_of_execution, 
            jobname: record.process_definition_name, status:record.status, type:'jobPlan',job_plan_id: this.job_plan_id,job_plan_name: this.job_plan_name,job_plan_instance_id:this.job_plan_instance_id}});
        },
        addDependency(name,indentation=0){
          for (var i = 0; i < indentation; i++) {
            name = '    '+name
          }
          return name
      },
        onHistory(record){
         let _this = this;
         this.$router.push({ name: 'showjobstepsresult', params: { env_id: this.execution_environment, envname:this.execution_env_name,jobid: record.job_id,mode:record.mode_of_execution, 
          jobname: record.process_definition_name, status:record.status, type:'jobPlan',job_plan_id: this.job_plan_id,job_plan_name: this.job_plan_name,job_plan_instance_id:this.job_plan_instance_id}});
       },
     }
   }
   </script>

   <style scoped>
   /* tr:nth-child(even) {background-color: #E8E8E8;}
   .blurOpacity{
    opacity: 0;
  }
  .showInput {
    visibility: inherit !important;
    width: 50% !important;
  }

  .srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
  }

  table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
  }

  thead {
    line-height: 30px;
    transition: all 10s;
  }

  th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  th.active {
    color: #fff;
  }

  th.active .arrow {
    opacity: 1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
  }

  .srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
  } */
  </style>


