<template>
  <div>
    <v-row class="EDC-Row" v-if="!hideBreadscrumb">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                <select v-model="Environment" v-on:change="getPlanList" style="-webkit-appearance: menulist">
                  <option :value="{}">Select Environment</option>
                  <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.name}}</option>
                </select>
              </li>
              <li>Job Plan List</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <edc-data-grid :dataList="tableList" @onEdit="onEdit" @onNew="onNew" @onCreate="onExecute" @onDelete="deleteJobPlan" @onDownload="onDownload" @ondblClick="onEdit" @onAddMultiple="onAddMultipleJobsPlan"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>    
</template>
<script>
import { get as getToServer, deleteFromServer, post as postToServer } from '@/methods/serverCall.js';
import { GET_PLAN_LIST,EXECUTE_JOB_PLAN, DELETE_JOB_PLAN, EXPORT_ALL_JOB_PLAN_LIST} from '@/data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '@/data/macros.js'
import getUserRole from '@/methods/GetUserRole.js'
import {SCHEDULER_SERVICE,SCHEDULE_ACTION,JOB_MANAGEMENT_SERVICE,JOB_PLAN_SERVICE_ID,EXECUTE_ACTION, CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION} from '@/data/macros.js'
import config from '../../config.json'
import {getEnvironmentList} from '../../methods/EnvironmentList.js'
import cloneDeep from 'lodash/cloneDeep';
import {SERVER_ERROR} from '@/data/client_message.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'EventList',
    props:{
        hideBreadscrumb:{
            type:Boolean,
            default:false
        },
        showAddMultiple:{
            type:Boolean,
            default:false
        },
        currentJobPlanId:{
            type:Number,
            default:0
        }
    },
    data: function () {
        return {
            EnvironmentList: [],
            EnvironmentListRules: [v => !!v || 'Environment is required.'],
            calendarDetails: this.$store.state.calendarDetails,
            calenderList: [],
            loader:false,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            // tableList: {
            //     headers: [{
            //             text: 'Id',
            //             value: 'id',
            //             disabled: true,
            //             align:'right',
            //             title: 'Job Plan Id',
            //             dataType:'int'
            //         },
            //         {
            //             text: 'Name',
            //             value: 'plan_name',
            //             disabled: true,
            //             title: 'Job Plan Name'
            //         },
            //         {
            //             text: 'Parallel Count',
            //             value: 'parallel_job_count',
            //             disabled: true,
            //             title: 'Number of jobs going to run parallelly',
            //             align:'right'
            //         },
            //         {
            //             text: 'Created Date',
            //             value: 'created_date',
            //             title: 'Job Plan Created Date',
            //             dataType:'date',
            //             align:'right'
            //         },
            //         {
            //             text: 'Created Time',
            //             value: 'created_time',
            //             title: 'Job Plan Created Time',
            //             dataType:'time',
            //             align:'right',
            //             hideInlineFilter: true
            //         },
            //         {
            //             text: 'Created By',
            //             value: 'created_by',
            //             inputKey: 'valueType',
            //             inputValue: 'date',
            //             width: '30%',
            //             title: 'Job Plan Created By'
            //         },
            //     ],

            //     actions: [{
            //             'text': 'edit',
            //             'key': "id",
            //             selectType: "single",
            //             role: true,
            //             index:1
            //         },
            //         {
            //             'text': 'new',
            //             'key': "id",
            //             role: true,
            //             index:4
            //         },
            //         {
            //             'text': 'delete',
            //             'key': "id",
            //             selectType: "single",
            //             role: true,
            //             index:2
            //         },
            //         {
            //             'text':'create',
            //             'key':"id",
            //             selectType:"single",
            //             role:true,
            //             index:3,
            //             'displayText':'Run'
            //         }
            //     ], //if we need conditional action in row then provide key
            //     sorting_type: CLIENT_SIDE,
            //     filterType: CLIENT_SIDE,
            //     paginationType: CLIENT_SIDE,
            //     total_count: 0,
            //     rows: [],
            //     selected_rows: [],
            //     syncHeaderScroll: false,
            // },
            tableList: {
                headers:[],
                actions:[],
                rows:[],
            },
            headers: [{
                        text: 'Id',
                        value: 'id',
                        disabled: true,
                        align:'right',
                        title: 'Job Plan Id',
                        dataType:'int'
                    },
                    {
                        text: 'Name',
                        value: 'plan_name',
                        disabled: true,
                        title: 'Job Plan Name'
                    },
                    {
                        text: 'Parallel Count',
                        value: 'parallel_job_count',
                        disabled: true,
                        title: 'Number of jobs going to run parallelly',
                        align:'right'
                    },
                    {
                        text: 'Created Date',
                        value: 'created_date',
                        title: 'Job Plan Created Date',
                        dataType:'date',
                        align:'right'
                    },
                    {
                        text: 'Created Time',
                        value: 'created_time',
                        title: 'Job Plan Created Time',
                        dataType:'time',
                        align:'right',
                        hideInlineFilter: true
                    },
                    {
                        text: 'Created By',
                        value: 'created_by',
                        inputKey: 'valueType',
                        inputValue: 'date',
                        width: '30%',
                        title: 'Job Plan Created By'
                    },
                ],
            userRole:{},
            Environment:{},

        }
    },
    watch:{
        'hideBreadscrumb':{
            handler(newValue){
            }
        },
        'showAddMultiple':{
            handler(newValue){
                
            }
        },
        'currentJobPlanId':{
            handler(newValue){
                
            }
        }
    },
    mounted() {
        getEnvironmentList(this)
        this.getPlanList();
        this.tableList = this.getGridObj(this.headers, 'id')
        var env_value = this.$session.get('selected_env')
        if( env_value && env_value.id != '0' && env_value.id !='all_env'){
            this.Environment = this.$session.get('selected_env')
            this.getPlanList()
        }
        this.userRole = this.$session.get('user_role_mapping');
        // this.tableList.actions=[{'text':'create','key':"", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SCHEDULER_SERVICE,SCHEDULE_ACTION) || getUserRole(this.userRole,JOB_MANAGEMENT_SERVICE,EXECUTE_ACTION)}]
        if(this.showAddMultiple){
            this.tableList.hideExport=true
            this.tableList.hideShowColumns=true
            this.showCreateSchedulejob = false
            this.showJobPlanbtn = true
            this.job_plan_name = this.$route.params.job_plan_name
            this.execution_environment = this.$route.params.execution_environment,
            this.execution_env_name = this.$route.params.execution_env_name
            this.tableList.actions=[{'text':'addmultiple','key':"id", selectType:"multiple", role:true,index:1, 'displayText':'Add Jobs plan to Job Plan'}]            

        }
        else{
            this.tableList.actions = [{
                        'text': 'edit',
                        'key': "id",
                        selectType: "single",
                        role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_PLAN_SERVICE_ID, UPDATE_ACTION),
                        index:2

                    },
                    {
                        'text': 'new',
                        'key': "id",
                        role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_PLAN_SERVICE_ID, CREATE_ACTION),
                        index:4,
                    },
                    {
                        'text': 'delete',
                        'key': "id",
                        selectType: "single",
                        role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_PLAN_SERVICE_ID, DELETE_ACTION),
                        index:3
                    },
                    {
                        'text':'create',
                        'key':"id",
                        selectType:"single",
                        role:this.userRole.is_superadmin || getUserRole(this.userRole,JOB_PLAN_SERVICE_ID, EXECUTE_ACTION),
                        index:1,
                        "displayText":"Run"
                    }
                ] 
        }
    },
    methods: {
        onDownload(downloadParams, filterArray){
            let _this = this
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": _this.$session.get('client_id'),
                "env_id": _this.Environment.id,
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.JOB_PLAN_URL+EXPORT_ALL_JOB_PLAN_LIST;    
            postToServer(_this, url, inputJson).then(Response => {
                var url = config.JOB_PLAN_URL + "/static/" + Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
            _this.loader=false;
            if(objError){
                  _this.loader = null
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = objError;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
            })
        },
        focus_env(){
      
    },
    getPlanList() {
          let _this = this;
          var env_id = this.Environment.id;
            if (!env_id)
              return false
          var env_id = _this.Environment.id
         this.$session.set('selected_env', this.Environment)

          var job_data = {'client_id':this.$session.get('client_id'),
                          'execution_environment': env_id};
          let baseUrl = config.JOB_PLAN_URL;
              baseUrl = baseUrl+ GET_PLAN_LIST;
          _this.loader = true
          postToServer(this, baseUrl, job_data
              ).then(response => {
                let newJobPlanList = []
                _.forEach(response,(obj)=>{
                    if(obj.id == _this.currentJobPlanId)
                        return
                    newJobPlanList.push(obj)
                })
              _this.loader=false;
              _this.tableList.rows = newJobPlanList;
           }).catch(CurrentJobError => {
               _this.loader=false;
               this.tableList.rows=[];
               this.snackbar = true;
               this.colorValue = 'error'
               this.snackbartext = CurrentJobError;
            })
        },
        onNew(){
            this.$router.push(
            {name: 'managejobplan',
            'params':
                {execution_environment: this.Environment.id,
                 execution_env_name: this.Environment.name
                }
            });
        },
        onEdit(record) {
            this.$router.push({
                name: 'managejobplan',
                    'params': {
                    job_plan_id: record.id,
                    execution_environment: this.Environment.id,
                    execution_env_name: this.Environment.name,
                    type: 'edit'
                    
                }
            });
        },
        deleteJobPlan(record){
            console.log(record)
            var job_data = {"job_plan_id": record.id}
            let baseUrl = config.JOB_PLAN_URL;
            baseUrl = baseUrl+ DELETE_JOB_PLAN;
            postToServer(this, baseUrl, job_data
              ).then(response => {
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = response;
                this.getPlanList()
                   // this.loader=false;
               }).catch(CurrentJobError => {
                 this.snackbar = true;
                 this.colorValue = 'error'
                 this.snackbartext = CurrentJobError;
             })

        },

        onExecute:function(record){
             this.$router.push({
                name: 'executejobplan',
                'params': {
                    job_plan_id: record.id,
                    job_plan_name: record.plan_name,
                    job_plan_description: record.description,
                    execution_environment: this.Environment.id,
                    execution_env_name: this.Environment.name,
                    parallel_job_count:record.parallel_job_count
                    }
                });
        },
        onAddMultipleJobsPlan(records){
            this.loader = true; 
            var _this = this
            var job_list = cloneDeep(this.$store.state.jobPlanDetails.job_list)
            for (var i = 0; i < records.length; i++) {
                var data = {'env_id': this.Environment.id, 'object_id': records[i].object_id,'client_id':this.$session.get('client_id')}
                var _this = this
                // var policy_details = new Promise(function(resolve){return resolve(_this.getJobParametersDetails(data))})
                var job_data = {
                'client_id': this.$session.get('client_id'),
                'process_definition_id': records[i].id,
                'process_definition_name': records[i].plan_name,
                'created_by':this.$session.get('email') ,
                'mode_of_execution':'final',
                'execution_environment': this.Environment.id,
                'execution_env_name': this.Environment.name,
                'exe_type':'on_demand',
                'other_job_details_need_to_fetch':false,
                'limit_details':[],
                "reports_generation_details":[],
                "object_type":'job_plan'
            }
            this.$store.state.jobPlanDetails.job_list.push(cloneDeep(job_data))

        }

        postToServer(_this, config.JOB_PLAN_URL +'/update_job_plan', this.$store.state.jobPlanDetails
            ).then(response => {
                // _this.$router.push({
                //     name: 'managejobplan',
                //     'params': {
                //         job_plan_id:_this.$store.state.jobPlanDetails.job_plan_id,
                //         execution_environment:_this.$store.state.jobPlanDetails.execution_environment,
                //         execution_env_name: _this.$store.state.jobPlanDetails.execution_env_name
                //     }
                // });

                // code added for clear selection after job get added to job plan
                var rows = _.cloneDeep(_this.tableList.rows)
                _this.tableList.rows = []
                _this.tableList.rows = rows
                this.loader = false; 
                this.$emit('closePopUp')

            }).catch(CurrentJobError => {
                this.$store.state.jobPlanDetails.job_list = job_list
            this.loader = false; 
            console.log(CurrentJobError)
            this.snackbar = true;
            this.colorValue = 'error'
            this.snackbartext = CurrentJobError;
        })
    }
        
    },
}
</script>

<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>


