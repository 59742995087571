<template>
  <v-flex id="mainflex">
    <v-card class="rounded-card EDC-Designer-Row">
      <v-container class="EDC-Container">
        <v-row class="EDC-Row " justify="start">
          <v-col class="EDC-Col text-truncate" cols="10" align="start">
            <ul class="breadcrumb" style="display:inline-flex;">
              <!-- <li><a @click="savedata(true)">{{env_name}} </a></li>
              <li><a @click="savedata(true)">{{archivalobj.diagram_name}} </a></li> -->
              <li><a @click="savedata(true)">{{archivalobj.steptype}} </a></li>
              <li><input type="text" v-model="stepname" :title="stepname" :readonly="readonly" @blur="StepNameChanged()" :size="nameTextboxSize"/></li>
            </ul>
            <span v-if="!readonly" style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>
            <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
            (Readonly Mode)</span>
            <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
          </v-col>
          <v-col cols="2" class="EDC-Col">
            <v-icon style="font-size:20px;float:right;padding-left:12px !important;" class="v-align" @click="savedata(true)" title='close'>far fa-times-circle</v-icon>
            <span style="font-size:13px;float:right;margin-right:5px;" :style="{'color': colorCode}">
              <v-progress-circular :width="3" v-if="validateProgress" :size="30" color="blue" indeterminate></v-progress-circular>
              <showerrorlisttemplate :validation_response="validation_response" :stepType="archivalobj.steptype"
              @set-error="setError" @totalCount="totalCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;"></showerrorlisttemplate></span>
              <span style="float:right;" class="v-align" :style="{'color': colorCode}">
              <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:-20%;"  title="Validate Step" @click="validatestepdata" ></svgicon>
            </span> 
            <svgicon class="svg-icon-grid svg-fill-grid" name="object_notes"  style="float:right;"  title="Add Step Description" @click="descriptionPanel = true"></svgicon>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="EDC-Container">
        <v-row class="EDC-Row">
          <v-col cols="12" class="EDC-Col">
            <v-stepper v-model="stepper" class="EDC-Stepper" non-linear>
              <v-stepper-header class="EDC-StepperHeader">
                <v-stepper-step step="1" :rules="selectObjectRule" class="EDC-StepperStep" editable>
                  Object Selection
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="2" class="EDC-StepperStep" editable>
                  Options
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="3" class="EDC-StepperStep" editable>
                  Apply Filter
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content class="EDC-Stepper-Content" step="1" elevation="0">
                  <!-- <v-row class="EDC-Row" style="padding-bottom:8px !important;">
                    <v-col class="EDC-Col" cols="5">
                      
                    </v-col>
                    <v-col class="EDC-Col" cols="4" style="padding-right:48px !important;">
                      
                    </v-col>
                  </v-row> -->
                  <v-row class="EDC-Row">
                    <v-col cols="3" class="EDC-Col">
                      <v-row class="EDC-Row" style="padding-top:8px !important;">
                        <v-checkbox :disabled="readonly" :color="colorCode" :label="archivalobj.run_on_target_label" v-model="run_on_target"
                      class="customCheckbox"  @change="runOnTargetChange" hide-details>
                      </v-checkbox>
                      </v-row>
                      <v-row class="EDC-Row">
                        <v-text-field dense :readonly="readonly" label="Commit Size" style="margin-top: 20px;margin-left:5px;margin-right:5px;" v-model="limit" v-on:blur="savedata()" hide-details @keypress="validateLimit" @blur="onLimitBlur" autocomplete="off"></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="4" class="EDC-Col" style="padding-left:5px !important;padding-right:10px !important;">
                      <v-autocomplete v-model="join_from_table" :disabled="readonly" label="Search Driver Table Here" cache-items hide-no-data
                      :loading="loading" :items="table_list" :search-input.sync="search" item-text="title" @input="setFilterColumn()" return-object item-value="title"
                     ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="EDC-Col" style="padding-left:20px !important;">
                      <v-row class="EDC-row">
                        <v-col cols="8" class="EDC-Col">
                          <v-autocomplete :disabled="readonly" v-model="selected_bus_obj" label="Search Business object Here" 
                          :items="bussinessObjectList" @input="setBussinessObject(selected_bus_obj)" item-text="display_name"  item-value="id"></v-autocomplete>
                        </v-col>
                        <v-col class="EDC-Col">         
                          <vc-button style="border-radius:10px;" itemText="Generate BO"  @click.native="GenerateBO" :loading="generateboclick"
                            :disabled="generateboclick || !is_driver_is_from_db || (selectedBussinessObject && selectedBussinessObject.id) || readonly">
                              
                          </vc-button>
                        </v-col>
                      </v-row>
                    </v-col>  
                  </v-row>
                  <v-row class="EDC-Row">
                    <v-col cols="3" class="EDC-Col"></v-col>
                    <v-col cols="4" class="EDC-Col">
                      <table class="table table-striped table-bordered draggable_table"  id="select_column_table" style="margin-left:5px;margin-right:5px;width:97%;">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Column</th>
                            <th>Report Heading</th>
                            <th>Order Type</th>
                          </tr>
                        </thead>
                        <draggable v-model="columns_for_order" :element="'tbody'">
                          <tr v-for="(item, idx) in columns_for_order" :key="idx">
                            <td class="handle" style="max-width: 28px;">::</td>
                            <td class="text-xs-left" v-model="item.column_name">
                            {{item.column_name}}
                            </td>
                            <td>
                              <v-text-field v-model="item.report_alias_name" style="width: 110px;" placeholder="Report Alias" solo @blur="savedata()" :title="item.report_alias_name" hide-details :readonly="readonly"></v-text-field>
                            </td>
                            <td>
                              <toggle-button :disabled="readonly" id="switch_button" v-model="item.type" :labels="{checked: 'DESC', unchecked: 'ASC'}":sync="true" :width="60"/>
                            </td>
                          </tr>
                        </draggable>
                      </table>
                    </v-col>
                    <v-col cols="4" class="EDC-Col">
                      <table class="table table-striped table-bordered draggable_table"  id="select_column_table" style="margin-left:5px;margin-right:5px;width:97%;">
                      <thead>
                        <tr>
                          <th>Business Object Keys</th>
                        </tr>
                      </thead>
                      <tr v-for="(item, idx) in business_object_keys" :key="idx" >
                        <td class="text-xs-left">
                          {{item}}
                        </td>
                      </tr>
                    </table>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content class="EDC-Stepper-Content" step="2" elevation="0">
                  <v-row class="EDC-Row">
                    <v-checkbox :disabled="readonly" :color="colorCode" label="Flattening Allowed"  v-model="flattening_allowed"
                    class="customCheckbox" @change="savedata()" hide-details>
                    </v-checkbox>
                  </v-row>
                  <v-row class="EDC-Row">
                    <v-checkbox :disabled="readonly" :color="colorCode" label="Truncate Target Table"
                    v-model="truncateTarget"
                    class="customCheckbox" v-if="archivalobj.steptype=='Copy'" @change="savedata()"  hide-details>
                    </v-checkbox>
                  </v-row>


                  <v-row class="EDC-Row">
                    <v-checkbox :disabled="readonly" :color="colorCode" label="Skip Duplicate" v-model="skip_duplicate" hide-details
                    class="customCheckbox" @change="savedata()" v-if="archivalobj.steptype!='Purge'">
                    </v-checkbox>
                  </v-row>

                  <v-row class="EDC-Row">
                    <v-radio-group hide-details v-model="purgeType" @change="changedPurgeType" dense>
                      <v-radio :disabled="readonly" class="customCheckbox" :color="colorCode" label="Process using transactions"  value="withtransaction"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-row class="EDC-Row">
                    <v-radio-group hide-details v-model="purgeType" @change="changedPurgeType" dense>
                      <v-radio :disabled="readonly" class="customCheckbox" :color="colorCode" label="Process without transactions"  value="withouttransaction"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-row class="EDC-Row">
                    <v-radio-group hide-details v-model="purgeType" @change="changedPurgeType" dense>
                      <v-radio :disabled="readonly" class="customCheckbox" :color="colorCode" label="Truncate and Re-insert"  value="truncate_reinsert"></v-radio>
                    </v-radio-group>
                  </v-row>


                  <v-row class="EDC-Row" v-if="purgeType === 'truncate_reinsert'">
                   <v-checkbox style="padding-left:30px !important;" :disabled="readonly" :color="colorCode" label="Disable Indexes" v-model="disable_index" hide-details
                    class="customCheckbox" @change="savedata()">
                      
                    </v-checkbox>
                  </v-row>

                  <v-row class="EDC-Row" v-if="purgeType === 'truncate_reinsert'">
                    <v-checkbox style="padding-left:30px !important;" :disabled="readonly" :color="colorCode" label="Rebuild Indexes" v-model="rebuild_index" hide-details
                    class="customCheckbox" @change="savedata()">
                    </v-checkbox>
                  </v-row>


                  <v-row class="EDC-Row">
                    <v-radio-group hide-details v-model="purgeType" @change="changedPurgeType" dense>
                      <v-radio class="customCheckbox" :color="colorCode" label="Drop and Replace"  value="drop_replace" :disabled="readonly"></v-radio>
                    </v-radio-group>
                  </v-row>

                  <v-row class="EDC-Row" style="padding-left:30px !important;" v-if="purgeType === 'drop_replace'">
                   <v-checkbox :disabled="readonly" :color="colorCode" label="Disable Indexes" v-model="disable_index" hide-details
                    class="customCheckbox" @change="savedata()">    
                    </v-checkbox>
                  </v-row>

                  <v-row class="EDC-Row" style="padding-left:30px !important;" v-if="purgeType === 'drop_replace'">
                    <v-checkbox :disabled="readonly" :color="colorCode" label="Rebuild Indexes" v-model="rebuild_index" hide-details
                    class="customCheckbox" @change="savedata()">
                    </v-checkbox>
                  </v-row>

                </v-stepper-content>
                <v-stepper-content class="EDC-Stepper-Content" step="3" elevation="0">
                  <new-edc-criteria :policy_list="policy_list" :showpolicies="showpolicies" :columns_for_filter="columns_for_filter" :column_name_list="column_name_list" :parentsteptype="getStepType()"
                  @savedata="saveCriteria" @new="add_new_filter_row" @delete="delete_filter_row" :acceleratorObj="acceleratorObj" :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI" :readonly="readonly"></new-edc-criteria>  
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="descriptionPanel" width="1000" eager>
    <v-card>
      <rich-text-box style="backgroud:white" key="archivalRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">
        
      </rich-text-box>
      <v-card-actions>
        <v-spacer></v-spacer>
        <vc-button  itemText= "Close" @click.native="descriptionPanel = false">
                
              </vc-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-flex>        
</template>
<script>
  // const cloneDeep = require('clone-deep');
  import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
  import draggable from 'vuedraggable'
  import moment from 'moment'
  import {getRectangle} from "../../methods/flowcharts/shapes.js"
  import {validateConnection,validateMagnet,get_port_group,get_port_items,reset_port_markup} from "../../methods/flowcharts/ports.js"
  import {getDefaultLinkProperies,RestrictCyclicConnection,ValidateSourceTarget,ValidateOutgoingLink} from "../../methods/flowcharts/links.js"
  import {PerformSmartRouting} from "../../methods/flowcharts/graph.js" 
  import {JOIN_OPERATOR_ARRAY_JSON,JOIN_TYPE,AGGREGATE_FUNCTIONS, FLITER_JOIN_OPERATOR_ARRAY_JSON,VALIDATION_IN_RPOCESS_MESSAGE} from '../../methods/flowcharts/constants.js'
  import Sortable from 'sortablejs'
  import { post as postToServer } from './../../methods/serverCall.js';
  import {getTableList, getTableColumns,getColumnsMoreInfo,getColumnsWithDescription} from "../../methods/agent_api_call.js"
  import {handleKeyevents} from "../../methods/flowcharts/papers.js"
  export const DATE_TYPE_ARRAY = {'Database Date':'MM/DD/YYYY', 'JDE Julien': '', 'CCYYMMDD': 'CCYYMMDD', 'Century Only': 'CC', 'Year Only': 'YY', 'Month Only':'MM', 'YYDDD': 'YYDDD'};
  import dateformat from './dateformat.vue'
  import { get as getToServer } from './../../methods/serverCall.js';
  import { GET_BUSINESS_DATA, GET_BUSINESS_GRAPH_DATA,ADD_SINGLE_TABLE_BO } from '../../data/url_constants.js'
  import config from '../../config.json'
  import find from 'lodash/find'
  import cloneDeep from 'lodash/cloneDeep'
  import isEmpty from 'lodash/isEmpty'
  import {getPanandZoomComponent,performZoomActions,performPanningActions} from "../../methods/flowcharts/paningandzoooming.js"
  import {validate_process_def_json} from "../../methods/call_validation.js"
  import Criteria from "@/views/newcriteria.vue"
  import showerrorlisttemplate from './showerrorlisttemplate.vue'
  import _ from 'lodash'
  import {DEFAULT_DESCRIPTION,getColDescription} from "../../methods/flowcharts/commonFunc.js"
  import RichTextBox from "@/views/richTextEditor/index.vue";

  export default {
    components:{
      draggable,
      dateformat,
      "new-edc-criteria":Criteria,
      showerrorlisttemplate,
      'rich-text-box':RichTextBox,

    },
    data () {
      return {
        nameTextboxSize:20,
        main_filterUI:[],
        secondary_filterUI:{groupList:[]},
        stepper:1,
        selectObjectRule:[],
        errorListCount:0,
        colorCode:COLOR_CODE,
        outlineColor:BTN_COLOR,
        validateProgress:false,
        validation_response:{},
        search: null,
        loading:false,
        flattening_allowed:true,
        run_on_target:false,
        limit:"100",
        stepname:this.archivalobj.cell_name,
        join_from_table:{},
        old_join_from_table:{},
        join_to_table:'',
        dateformatobj:'',
        jto_column_list:[],
        jfrom_column_list:[],
        filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
        jfrom_drv_table: "false",
        // table_column_list:[],
        table_list:[],
        connection_str:"",
        schema:"",
        db_type:"",
        datasource_id:"",
        target_connection_str:"",
        target_schema:"",
        target_datasource_id:"",
        default_table_list:"",
        columns_for_filter: [],
        value_type_list: ['Value', 'Date', 'Column'],
        start_parentheses_list: [{'value':'('}, {'value':'(('}, {'value':'((('}, {'value':'(((('}],
        end_parentheses_list: [')', '))', ')))', '))))'],
        column_name_list: [],
        filter_column_tbl_obj: {
          's_parentheses': '',
          'column_name': '',
          'operator': '',
          'v_type': 'Value',
          'value': '',
          'e_parentheses': '',
          'is_drv_table': false,
          'table_name':'',
          error_list:{}
        },
        dateformat_dialog: false,
        selectedBussinessObject:{},
        env_id:"",
        savedstatus:'edited',
        is_error_occured:false,
        auto_increment_id:0,
        source_cells_detail:{},
        selected_bus_obj:"",
        policy_list:[],
        filter_popup_index:0,
        datasource_error:'',
        is_driver_is_from_db:false,
        generateboclick:false,
        showpolicies:true,
        env_name:this.$session.get('selected_env').name,
        columns_for_order:[],
        business_object_keys:[],
        skip_duplicate:true,
        disable_index:false,
        rebuild_index:false,
        sourceCellColumnReferences:[],
        purgeType:'withtransaction', // this variable first used for only purge.Then we decide we will go for archive and copy. But by that time we already deployed this on few clients. So we are not able to change it.
        vueEditorObj:{},
        descriptionPanel:false,
        pd_description:'',
        truncateTarget:false,
        old_stepname:'',
        old_limit:'',
      }
    },
    props:['archivalobj','bussinessObject','savedatastatus','iserroroccured','policies','bussinessObjectList','datasourceerror','tblsprimarykeys','acceleratorObj','readonly','redoundoCommandObj','redoundoMsg'],
    computed: {
      table_column_list(){
        let data = this.$store.state.cacheData[this.env_id+'_table_column_list'];
        return data ? data : [];
      }
    },
    watch: {
      redoundoCommandObj(newvalue){
        if(!newvalue || !newvalue.action_to_take || newvalue.step_id != this.archivalobj.stepId)
          return
        this.manageRedoUndoActions(_.cloneDeep(newvalue))
      },
      '$store.state.errorList':{
        handler(newValue){
          if(!_.isEmpty(newValue)){
            // let idArray = Object.keys(newValue);
            if(this.archivalobj && newValue[this.archivalobj.stepId]){
              this.validation_response = newValue[this.archivalobj.stepId];
            }          
          }
        },
        deep:true
      },
      search (val) {
        val && val !== this.join_from_table.title && this.querySelections(val)
      },
      datasourceerror(newvalue){
        this.datasource_error =newvalue
      },
      policies(newvalue){
        this.policy_list = newvalue
      },
      savedatastatus(newvalue,oldvalue){
        this.savedstatus = newvalue
      },
      iserroroccured(newvalue,oldvalue){
        this.is_error_occured =newvalue
      },
      tblsprimarykeys(newvalue,oldvalue){
        this.tblsprimarykeys =newvalue
        this.getDriverKeys(true)
      },
      readonly(newvalue){

      },
      archivalobj(newvalue,oldvalue){
        // debugger;
        this.resetArchivalStep()
        this.validation_response ={}
        //Code to bind error message if user perform validation at flowchart level
        var flowchart_errors = this.$store.state.errorList
        if(flowchart_errors && this.archivalobj && flowchart_errors[newvalue.stepId]){
          this.validation_response = flowchart_errors[newvalue.stepId];
        }

        if(newvalue.business_obj_id){
          this.selected_bus_obj = newvalue.business_obj_id
          this.setBussinessObject(this.selected_bus_obj)
        }
        if(newvalue.step_data){
          this.skip_duplicate = false
          
          if(newvalue.step_data.description){
            this.pd_description = newvalue.step_data.description
            this.vueEditorObj = {'description':newvalue.step_data.description}
          }

          if(newvalue.step_data.truncateTarget)
                this.truncateTarget = true
          // as per the issue no.496 , for newly added step Skip Duplicate should be by default selected. As skip_duplicate is boolean value, new.step_data.step_data.skip_duplicate will alway return false in case of the 'key is not in there 'or 'key set as false'. So if key is not there then we need make it skip_duplicate as true 


          if(!_.has(newvalue.step_data,'skip_duplicate') || newvalue.step_data.skip_duplicate)
            this.skip_duplicate = true

          // if(newvalue.steptype==='Purge'){
            this.disable_index = false
            this.rebuild_index = false
            this.purgeType='withtransaction'

            if(newvalue.step_data.purgeType)
              this.purgeType = newvalue.step_data.purgeType

            this.disable_index = newvalue.step_data.drop_index
            this.rebuild_index = newvalue.step_data.create_index

          // }

          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.select_table_list && newvalue.step_data.drv_table.select_table_list.length){
            this.limit = newvalue.step_data.limit
            this.old_limit = newvalue.step_data.limit
            this.join_from_table.title = newvalue.step_data.drv_table.select_table_list[0];
            this.join_from_table.type = 'T'
            if(newvalue.step_data.drv_table.driver_table_type){
              this.join_from_table.type = newvalue.step_data.drv_table.driver_table_type
            }
            this.jfrom_drv_table=false
            if(newvalue.step_data.drv_table.is_drv_table){
              this.join_from_table.type = 'S'
              this.jfrom_drv_table=true
            }
          }
          this.is_driver_is_from_db = false
          if(newvalue.step_data.isDriverFromDb)
            this.is_driver_is_from_db = true
          if(newvalue.step_data.selected_table_data){
            this.join_from_table = newvalue.step_data.selected_table_data 
            this.old_join_from_table = _.cloneDeep(this.join_from_table)
          }

          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.selection)
            this.column_name_list = newvalue.step_data.drv_table.selection;
          
          if(newvalue.step_data.main_filterUI)
          this.main_filterUI = newvalue.step_data.main_filterUI
        if(newvalue.step_data.secondary_filterUI)
          this.secondary_filterUI = newvalue.step_data.secondary_filterUI

          // this.designOrderBy(this.column_name_list)
          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.order_by && newvalue.step_data.drv_table.order_by.length > 0)
            this.columns_for_order = newvalue.step_data.drv_table.order_by

          this.columns_for_filter=[];

          this.add_new_filter_row()
          if(newvalue.step_data.drv_table && newvalue.step_data.drv_table.filter && newvalue.step_data.drv_table.filter.length)
            this.columns_for_filter=newvalue.step_data.drv_table.filter;
          
          // as per the issue no.496 , for newly added step Flattening allowed should be by default selected. As flattening_allowed is boolean value, new.step_data.step_data.flattening_allowed will alway return false in case of the 'key is not in there' or 'key set as false'. So if key is not there then we need make it flattening_allowed as true 

          this.flattening_allowed = false
          if(!_.has(newvalue.step_data,'flattening_allowed') || newvalue.step_data.flattening_allowed)
            this.flattening_allowed = true

          this.run_on_target = false
          if(newvalue.step_data.run_on_target)
            this.run_on_target = newvalue.step_data.run_on_target
        }
        if(newvalue.cell_name){
          this.stepname = newvalue.cell_name
          this.old_stepname = newvalue.cell_name
        }
        if(newvalue.tables_detail){
          this.table_list = newvalue.tables_detail.table_list
          this.connection_str= newvalue.tables_detail.connection_str
          this.schema= newvalue.tables_detail.schema
          this.db_type= newvalue.tables_detail.db_type
          this.default_table_list = newvalue.tables_detail.default_table_list
          this.datasource_id = newvalue.tables_detail.datasource_id
          this.target_schema = newvalue.tables_detail.target_schema
          this.target_connection_str = newvalue.tables_detail.target_connection_str
          this.target_datasource_id = newvalue.tables_detail.target_datasource_id
          if(this.join_from_table.type && this.join_from_table.type != "S"){
            /* add driver table in to table list if driver tbl not present in table list.
               issue is if selected table is not from default table list then its not get bind to
               the driver drop down list
            */
            var obj = _.find(this.default_table_list, ["table_name",this.join_from_table.title])
            if(!obj){
              var new_tbl ={"title":this.join_from_table.title,"type":this.join_from_table.type}
              this.default_table_list.push(new_tbl)
              this.table_list.push(new_tbl)
            }
          }
        }
        if (newvalue.env_id){
          this.env_id = newvalue.env_id
        }
        this.source_cells_detail=[]
        if(newvalue.source_cells_detail && newvalue.source_cells_detail.length > 0){
          this.source_cells_detail = newvalue.source_cells_detail
          this.table_list = cloneDeep(this.default_table_list)
          var step_list = newvalue.source_cells_detail
          for(var i=0;i<step_list.length;i++){

              this.sourceCellColumnReferences = _.concat(this.sourceCellColumnReferences,step_list[i].column_list_references)

              //auto selection of driver table is case of driver table not selected 
              // for archival, purge and copy. BUG ISSUE NO. 115

              let key = this.env_id + '_table_column_list';

              if (!this.$store.state.cacheData[key]) {
                this.$store.state.cacheData[key] = [];
              }
              // this.table_column_list.push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns})
              let cacheIndex = -1;
              cacheIndex = _.findIndex(this.$store.state.cacheData[key], function(o) { return o.table_name == step_list[i].source_name; });

              if(cacheIndex >= 0){
                this.$store.state.cacheData[key].splice(cacheIndex, 1); 
              }

              this.$store.state.cacheData[key].push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns, 'table_column_descriptions':step_list[i].cols_description})

              if(!this.join_from_table.title){
                this.join_from_table.title = step_list[i].source_name
                this.column_name_list = step_list[i].columns
                this.jfrom_drv_table = true
                this.designOrderBy(this.column_name_list)
              }

              for(var each_table_count=0;each_table_count<this.table_list.length;each_table_count++){
                if (this.table_list[each_table_count].title == step_list[i].source_name){
                  this.table_list.splice(each_table_count, 1)
                  break;
                }
              }
              this.table_list.push({"type":"S","title":step_list[i].source_name})
              for(var tbl_col_count=0;tbl_col_count<this.table_column_list.length; tbl_col_count++){
                if (this.table_column_list[tbl_col_count].table_name == step_list[i].source_name){
                  this.table_column_list.splice(tbl_col_count, 1)
                  break
                }
              }

              // DONT REMOVE THIS CODE. This code is duplicate. Same code we have above. But above code not storing step_data into this.$store.state.cacheData[key] once this code block get executed. But here it stored that.
              cacheIndex = -1;
              cacheIndex = _.findIndex(this.$store.state.cacheData[key], function(o) { return o.table_name == step_list[i].source_name; });

              if(cacheIndex >= 0){
                this.$store.state.cacheData[key].splice(cacheIndex, 1); 
              }

              this.$store.state.cacheData[key].push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns, 'table_column_descriptions':step_list[i].cols_description})
              
            }
          }
          else{
            this.table_list = cloneDeep(this.default_table_list)
          }
        },
        limit(newvalue){
          this.savedata(false)
        },
        filter_data(newvalue){
          this.savedata(false)
        },
        jfrom_drv_table(newvalue){
          this.savedata(false)
        },
        stepname(newvalue,oldvalue){
          if(newvalue){
            this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
          }
          this.savedata(false)
        },
        join_from_table(newvalue,oldvalue){
          this.old_join_from_table = _.cloneDeep(newvalue)
          if(oldvalue && oldvalue.title)
            this.old_join_from_table = oldvalue
        },
        run_on_target(newvalue){
          this.savedata(false)
        },
        acceleratorObj(newvalue){

        },
        stepper(newvalue){
          this.selectObjectRule = []
          if(newvalue !="1" && (this.columns_for_order.length === 0 || this.business_object_keys.length === 0 || this.columns_for_order.length!=this.business_object_keys.length))
            this.selectObjectRule = [() => false]
        },
        redoundoMsg(newvalue){

        },
      },
      methods:{
        manageRedoUndoActions(redoundoCommandObj){
          var _this = this
          if(redoundoCommandObj.action_to_take === 'step_name_changed_from_inside'){
            if(!redoundoCommandObj.update_details)
              return
            this.old_stepname = this.stepname
            this.stepname = redoundoCommandObj.update_details.new_step_name
          }
          else if(redoundoCommandObj.action_to_take ==='step_limit_change'){
            this.limit = redoundoCommandObj.new_limit
            this.onLimitBlur({},true)
          } else if(redoundoCommandObj.action_to_take === 'run_on_target_change'){
            this.run_on_target = !this.run_on_target
            if(this.run_on_target === redoundoCommandObj.value){
              this.columns_for_order = redoundoCommandObj.columns_for_order
              this.table_list = redoundoCommandObj.table_list
              this.join_from_table = redoundoCommandObj.join_from_table
              this.querySelections('')
              this.savedata()
            } 
            else
              this.runOnTargetChange(false,true)
          }
          else if(redoundoCommandObj.action_to_take === 'driver_table_change'){
            this.join_from_table = redoundoCommandObj['new_value']
            this.setFilterColumn(true)
          } else if(redoundoCommandObj.action_to_take === 'business_obj_change'){
            this.selected_bus_obj = redoundoCommandObj['new_value']
            this.setBussinessObject(this.selected_bus_obj,true)
          }
        },
        StepNameChanged(){
          this.stepname = this.stepname.trim()
          if(this.old_stepname.trim() === this.stepname)
            return false
          if(this.stepname.length ===0)
            this.stepname = this.old_stepname.trim()
          else{
            this.$emit('sendNonGraphEvent','step_name_changed_from_inside',{"action_type":"step_name_changed_from_inside","update_details":{"step_id":this.archivalobj.stepId,"step_name":this.old_stepname,"new_step_name":this.stepname}})
            this.old_stepname = this.stepname.trim()
            this.savedata(false)    
          }
        },
        getStepType(){
          if(!this.archivalobj || !this.archivalobj.steptype)
            return ''
          return this.archivalobj.steptype.toLowerCase()
        },
        runOnTargetChange(event,fromRedoUndo){
          if(!fromRedoUndo){
            // send redo undo data.we are saving negation of event value, for the purpose of restore the old data. When user click on checkbox, event value is negation of current value. for example if user check the box, event value will be true, it means we have preserve data from the unchecked checkbox.
            let data = {"value":!event,"columns_for_order":_.cloneDeep(this.columns_for_order),
                        "table_list":_.cloneDeep(this.table_list),"join_from_table":_.cloneDeep(this.join_from_table),"step_id":this.archivalobj.stepId}
            this.$emit('sendNonGraphEvent','run_on_target_change',data)
          }

          // clear driver selection
          this.columns_for_order = []
          this.table_list = []
          this.join_from_table = {}
          this.querySelections('')
          this.savedata()
        },
        totalCount(count){
          this.errorListCount = count;
        },
        setError(errorList){
          let _this = this;
          // _this.Remove_Error_List();
          _this.Perform_Validation(errorList);
        },
        Perform_Validation(error_data) {
          var _this = this
          _this.Remove_Error_List()
          if (typeof error_data === 'object') {
            if (error_data.step_data) {
              var step_data_errors = error_data.step_data
              if (step_data_errors.drv_table && step_data_errors.drv_table.filter) {
                _this.Perform_Validation_On_Filter(step_data_errors.drv_table.filter)
              }
            }
          }
          this.$forceUpdate();
            // console.log(JSON.stringify(_this.columns_for_filter));
          },
          Perform_Validation_On_Filter(filter_error_data){
            var _this = this;
            var rows = Object.keys(filter_error_data);
              // debugger;
              rows.map(row=>{ // it will provide rowindex like 0,1,2
                let columnArray = Object.keys(filter_error_data[row]); 
                columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
                  _this.columns_for_filter[row].error_list[column] = filter_error_data[row][column]; 
                })
              })
            // console.log("Column for filter:"+JSON.stringify(_this.columns_for_filter));  
          },
          Remove_Error_List(){  
            this.Remove_From_Normal_List(this.columns_for_filter)
          },
          Remove_From_Normal_List(list){
            for(var i=0;i<list.length;i++){
              list[i].error_list = {}
            }
          },
          saveCriteria(columns_for_filter,main_filter,secondary_filter){
          // debugger;
          this.columns_for_filter = columns_for_filter;
          this.main_filterUI = main_filter
          this.secondary_filterUI=secondary_filter 
          this.savedata();
        },
        delete_filter_row(idx){
          var current_filter_length = this.columns_for_filter.length
          if(current_filter_length > 1 && current_filter_length-1 ===  idx)
            delete this.columns_for_filter[idx-1]['operation']
          this.columns_for_filter.splice(idx, 1)
          if(this.columns_for_filter.length === 0){
            var obj = cloneDeep(this.filter_column_tbl_obj);
            this.columns_for_filter.push(obj);
          }
        },
        querySelections (v) {
          this.loading = true
          this.Get_Tables(v);
        },
        Get_Tables(table_search_string){
          var _this = this;
          getTableList(_this,_this.env_id, _this.connection_str, _this.schema, _this.db_type, table_search_string).then(tableResponse =>{
            var latest_table_list = tableResponse.table_view_list;
            _this.table_list = latest_table_list;
            this.loading = false;
          }).catch(errorResponse => {});
        }, 
        savedata(is_close){
          if(is_close)
            this.datasource_error = ''
          let data = this.getArchiveStepData()
          this.$emit('update-object',data,is_close);
        },
        resetValue(idx){
        // this.columns_for_filter[idx].value = '';
        this.savedata()
      },
      getdate(index,data){
        index = this.filter_popup_index
        var date_data = cloneDeep(data)
        this.columns_for_filter[index].value = date_data["value"]
        this.columns_for_filter[index].datedetails = date_data
        this.dateformat_dialog = false
      },
      validatestepdata(){
        var _this = this;
        _this.Remove_Error_List();
        if(_this.validateProgress){
          alert(VALIDATION_IN_RPOCESS_MESSAGE)
          return false
        }
        var data = this.getArchiveStepData();
        data['env_id'] = this.env_id
        data['previous_steps_data'] = this.source_cells_detail

        _this.selection_error = ''

        if(_this.columns_for_order.length != _this.business_object_keys.length){
          _this.validation_response = {"step_data":{"business_obj_id":{"keysMistmatch":["Number of driver columns and business object keys are not matching"]}}}
          _this.$store.state.errorList[this.archivalobj.stepId] = {"step_data":{"business_obj_id":{"keysMistmatch":["Number of driver columns and business object keys are not matching"]}}}
          return
        }
        if(!_this.verifyColumnNames()){
          _this.validation_response = {"step_data":{"business_obj_id":{"keysMistmatch":["Driver columns and business object keys are not matching."]}}}
          _this.$store.state.errorList[this.archivalobj.stepId] = {"step_data":{"business_obj_id":{"keysMistmatch":["Driver columns and business object keys are not matching."]}}}
          return
        }
        _this.validateProgress = true;
        validate_process_def_json(this, data).then(validation_response => {
          _this.validateProgress = false;
          _this.validation_response = validation_response;
          _this.$store.state.errorList[this.archivalobj.stepId] = validation_response
        },error=>{
         _this.validateProgress = false;
       })
      },
      verifyColumnNames(){
        var _this = this
        // check column names are matching or not.
        let driverColumnNames = _.map(_this.columns_for_order,(obj)=>{
          return obj.column_name.toLowerCase()
        })
        let businessKeys = _.map(_this.business_object_keys,(obj)=>{
          return obj.toLowerCase()
        })
        return _.isEqual(_.sortBy(driverColumnNames),_.sortBy(businessKeys))
      },
      getArchiveStepData(){
        this.env_name = this.$session.get('selected_env').name
        let _this = this
      // debugger;
      this.is_error_occured =false
      this.savedstatus = "edited"
      if(this.join_from_table.type === 'S')
        this.is_driver_is_from_db = false
      var filter_data = cloneDeep(this.columns_for_filter)
      if(filter_data.length === 1 && !filter_data[0].column_name)
        filter_data = []
      for (var i = filter_data.length - 1; i >= 0; i--) {
        filter_data[i].is_drv_table = this.jfrom_drv_table
        filter_data[i].table_name = this.join_from_table.title
      }
      var selection_list = []
      for (var i = 0; i < this.columns_for_order.length; i++) {
        if(!this.columns_for_order[i].id)
          this.columns_for_order[i]["id"] = _this.get_new_id()
        if(!this.columns_for_order[i].report_alias_name)
          this.columns_for_order[i].report_alias_name =""
        // validating duplicate report alias
        for (var j = 0; j <i; j++) {
          if(this.columns_for_order[i].report_alias_name && this.columns_for_order[i].report_alias_name.toLowerCase() === this.columns_for_order[j].report_alias_name.toLowerCase()){
            this.columns_for_order[i].report_alias_name = this.columns_for_order[i].report_alias_name + "_" + this.columns_for_order[i]["id"]
            break
          }
        }
        // now make selection list
        var current_order_item = cloneDeep(this.columns_for_order[i])
        current_order_item["is_drv_table"] = this.jfrom_drv_table
        current_order_item["table_name"] = this.join_from_table.title
        selection_list.push(current_order_item)
      }

      if(this.archivalobj.steptype === 'Purge'){
        if(this.purgeType !='withtransaction'){
          if(this.rebuild_index)
            this.disable_index = true
        }
      }

      var data = {'stepname':_this.stepname,'step_type':this.archivalobj.steptype,
      'step_data':{ "skip_duplicate":this.skip_duplicate,'drop_index':this.disable_index,'create_index':this.rebuild_index,'purgeType':this.purgeType, 'main_filterUI':this.main_filterUI,'secondary_filterUI':this.secondary_filterUI,"type":this.archivalobj.steptype, "limit":this.limit,"isDriverFromDb":this.is_driver_is_from_db,"flattening_allowed":this.flattening_allowed,"run_on_target":this.run_on_target,
      "selected_table_data":this.join_from_table,
      "drv_table":{'is_drv_table':this.jfrom_drv_table,"select_table_list":[this.join_from_table.title],'selection':this.column_name_list,'select_columns':selection_list,
      'join':[],'filter':filter_data,'order_by':this.columns_for_order,"driver_table_type":_this.join_from_table.type
    },
    "truncateTarget":this.truncateTarget,'business_object_keys':this.business_object_keys,
    "business_obj_id":isEmpty(this.selectedBussinessObject) ? "" :this.selectedBussinessObject.id,
    "business_object_policy_id":isEmpty(this.selectedBussinessObject) ? "" :this.selectedBussinessObject.policy_name,
    "business_object_version":isEmpty(this.selectedBussinessObject) ? "" :this.selectedBussinessObject.object_version,
    "auto_id":this.auto_increment_id,'description':this.pd_description
  }}
  return data;
},
get_new_id(){
  return ++this.auto_increment_id;
},
resetArchivalStep(){
  this.stepper = 1
  this.selectedBussinessObject={};
  this.join_from_table={};
  this.selected_bus_obj='';
  this.limit="100";
  this.column_name_list=[];
  this.columns_for_filter=[];
  this.columns_for_order=[];
  this.business_object_keys =[];
  this.sourceCellColumnReferences = []
  this.main_filterUI = []
  this.secondary_filterUI = {groupList:[]}
  this.pd_description = ''
  this.vueEditorObj = {'description':''}
  this.truncateTarget = false
  this.datasource_error = ''
},
add_new_filter_row(){
  var current_filter_length = this.columns_for_filter.length
  if(current_filter_length > 0){
    if(!this.columns_for_filter[current_filter_length-1]['operation'])
      this.columns_for_filter[current_filter_length-1]['operation'] = false   
  }
  var obj = cloneDeep(this.filter_column_tbl_obj);
  obj['id'] = this.get_new_id()
  obj.table_name = this.join_from_table.title
  this.columns_for_filter.push(obj);
},
resetIsDrvTable(c_name, idx){
    // for(var i=0;i< this.column_name_list.length;i++){
    //   if(this.column_name_list[i][c_name]){
    //     this.columns_for_filter[idx].is_drv_table = this.column_name_list[i][c_name] 
    //     break;
    //   }else{
    //     this.columns_for_filter[idx].is_drv_table = false
    //   }
    // }
  },
  setBussinessObject(id,fromRedoUndo){
    this.business_object_keys =[]
    if(!id){
      this.selectedBussinessObject = {}
      return false
    }
    let _this = this;
    // oldSelectedBussinessObject used to change policy reference
    // TODO: Need to take care of policy change when user change the driver table and create the business object
    let oldSelectedBussinessObject = _.cloneDeep(_this.selectedBussinessObject)
    let old_selected_bus_obj = ''
    if(!_.isEmpty(oldSelectedBussinessObject))
      old_selected_bus_obj = oldSelectedBussinessObject.id

    _this.selectedBussinessObject = find(_this.bussinessObjectList, ['id', id]);
    if(!_this.selectedBussinessObject)
    {
      _this.selectedBussinessObject = {}
      this.stepname ="Archive"
      return
    }
    
    // no need to pass data if object is is same. This is rare condition, but it might happen. 
    if(id === oldSelectedBussinessObject.id)
      oldSelectedBussinessObject = {}

    this.stepname = _this.selectedBussinessObject.name;
    this.business_object_keys = _this.selectedBussinessObject.unique_identifier_keys
    this.$emit('update-policy-list',_this.selectedBussinessObject,oldSelectedBussinessObject)
    if(!fromRedoUndo){
      this.$emit("sendNonGraphEvent","business_obj_change",{"step_id":this.archivalobj.stepId,"old_value":old_selected_bus_obj,"new_value":_this.selected_bus_obj})
    }
  },
  setFilterColumn(fromRedoUndo){
    this.datasource_error = ''
    this.is_driver_is_from_db = true
    let _this = this;
    this.columns_for_filter =[]
    var table = _this.join_from_table.title
    var table_type = 'table'
    if(this.join_from_table.type === 'T')
      table_type = "table"
    else if (this.join_from_table.type === 'V')
      table_type = "view"
    else if(this.join_from_table.type === 'A')
      table_type = "alias"
    else{
      table_type = 'step'
      this.is_driver_is_from_db = false
      this.join_from_table.type = 'S'
    }
      // let stepTable = find(_this.archivalobj.source_cells_detail, ['source_name', table]);
      if(_this.join_from_table.type === 'S'){
        this.is_driver_is_from_db = false
        this.jfrom_drv_table=true;
        
      }else{
        this.is_driver_is_from_db = true
        this.jfrom_drv_table=false;
        this.getDriverKeys()
      }
      table && getTableColumns(_this,_this.table_column_list,_this.env_id,_this.connection_str,_this.schema, table, table_type).then(tableColumnResponse=> {
        if(tableColumnResponse){
          if(tableColumnResponse.column_list)
              tableColumnResponse = tableColumnResponse.column_list
          _this.column_name_list = tableColumnResponse
          if(_this.join_from_table.type === 'S')    
            _this.designOrderBy(_this.column_name_list)        
          if(_this.columns_for_filter.length === 0){
            _this.add_new_filter_row()
          }  
          // this.designOrderBy(_this.column_name_list)           
        }
      }).catch();
      this.savedata(false)
      if(!fromRedoUndo){
        this.$emit("sendNonGraphEvent","driver_table_change",{"step_id":this.archivalobj.stepId,"old_value":this.old_join_from_table,"new_value":this.join_from_table})
      }
    },
    designOrderBy(columnlist){
      let that = this
      this.columns_for_order = []
      var is_drv_table = this.jfrom_drv_table
      for (var i = 0; i < columnlist.length; i++) {
        this.columns_for_order.push({'column_name':columnlist[i], 'is_drv_table': is_drv_table, 'type':false,"report_alias_name":columnlist[i]})
      } 
      var table_type = 'table'
      if(this.join_from_table.type === 'T')
        table_type = "table"
      else if (this.join_from_table.type === 'V')
        table_type = "view"
      else if(this.join_from_table.type === 'A')
        table_type = "alias"
      else
        table_type = 'step'

      var kwargs = {"table_column_list":this.table_column_list, "env_id":this.env_id,
      "connection_str":this.connection_str,"schema":this.schema, "table_name":this.join_from_table.title, "table_type": table_type}
      getColumnsWithDescription(this,kwargs).then(tableColumnResponse => {
        var substringIndex = 0
        if(that.acceleratorObj.acceleration_type === 'jde_ed_e1')
          substringIndex = 2

        if(this.jfrom_drv_table === true || this.jfrom_drv_table === 'true')
          substringIndex = 0

        if(tableColumnResponse.description){
          for(var i=0;i<this.columns_for_order.length;i++){
            this.columns_for_order[i].report_alias_name = getColDescription(substringIndex,
              this.columns_for_order[i].column_name,tableColumnResponse.description,is_drv_table)
          }
        }
        }).catch({});
    },

    // getColDescription(substringIndex,column_name,all_col_description,is_drv_table)
    // {
    //   if (all_col_description.length == 0)
    //     return 'N/A'

    //   // incase of drv table we will match the whole column name. 
    //   // In case of drv table we storing whle column name
    //   if (is_drv_table == true || is_drv_table === 'true')
    //     substringIndex = 0
    //   let col_alias = column_name.substring(substringIndex)
    //   var description_obj = _.find(all_col_description,["column_name",col_alias])
    //   if (description_obj && description_obj.description)
    //     return description_obj.description
    //   return 'N/A'
    // },


    dateHandler(idx){
      this.filter_popup_index = idx
      var date_details = this.columns_for_filter[idx].datedetails
      if(date_details)
        this.dateformatobj = cloneDeep(date_details)
      else
        this.dateformatobj ={}
      this.dateformat_dialog = true
    },
    close_date_format_popup(){
      this.dateformat_dialog = false
    },
    singletblchange(){

    },
    getDriverKeys(from_watch){
      this.datasource_error = ""
      this.columns_for_order = []
      // from_watch variable is only for remove cyclic call to agent api
      var table_name = this.join_from_table.title
      var drv_obj = _.find(this.tblsprimarykeys,['table_name',table_name])
      if(drv_obj)
        this.designOrderBy(drv_obj.keys)
      else if(!from_watch){
        this.$emit('update-tblsprimarykeys',this.join_from_table.title,this.join_from_table.type,this.run_on_target)
      }
    },
    GenerateBO(){
      let _this = this
      _this.selectedBussinessObject = {}
      if(!_this.join_from_table || !_this.join_from_table.type)
      {
        alert('Table for archival not selected')
        return false
      }
      if(_this.join_from_table.type == 'S'){
        alert('You cannot perform archival on driver table')
        return false
      }
      this.datasource_error = ''
      this.generateboclick =true
      var table_type = _this.join_from_table.type
      if(table_type === 'T')
        table_type = 'table'
      else if (table_type === 'V')
        table_type = 'view'
      else
        table_type = 'synonyms'
      var env_id = _this.env_id
      var env_name = this.$session.get('selected_env').name
      var connection_str = this.connection_str
      let erp_code = this.archivalobj.erp_code
      let erp_version = this.archivalobj.erp_version

      if(!erp_code || !erp_version){
        erp_code = 'CST'
        erp_version = '001'
      }
      var mapped_list = []
      if(_this.$session.get('mapped_agent_details'))
        mapped_list = _this.$session.get('mapped_agent_details')
      var mapped_agent_obj= _.find(mapped_list,['conn_str',connection_str])
      var mapped_agent_id = ''
      if(mapped_agent_obj)
        mapped_agent_id = mapped_agent_obj.mapped_agent_id
      var schema = _this.schema
      var table_name =_this.join_from_table.title
      var db_type =_this.db_type
      var client_id = this.$session.get('client_id')
      var added_by = this.$session.get('email')
      var data_to_pass = {"table_type":table_type,"env_id":env_id,"schema":schema,"conn_str":connection_str,"table_name":table_name,"db_type":db_type,"client_id":client_id,"env_name":env_name,"added_by":added_by,"object_type_code": "BO","mapped_agent_id":mapped_agent_id,"ds_id":_this.datasource_id,'erp_code':erp_code,'erp_version':erp_version}
      postToServer(this, config.REPOSITORY_API_URL + ADD_SINGLE_TABLE_BO, data_to_pass).then(response => {
        _this.selectedBussinessObject['id'] = response.object_id
        _this.selectedBussinessObject['name'] = response.object_name
        _this.selectedBussinessObject['object_id'] = response.object_id
        _this.selectedBussinessObject['object_name'] = response.object_name
        _this.selectedBussinessObject["object_version"] = response.object_revision
        _this.selectedBussinessObject["display_name"] = response.object_name + ' ('+response.object_id+')'
        _this.selectedBussinessObject["unique_identifier_keys"] = response.unique_identifier_keys
        _this.selected_bus_obj = _this.selectedBussinessObject['id']
        _this.datasource_error = 'Business Object created successfully'
        _this.bussinessObjectList.push(_this.selectedBussinessObject)
        _this.setBussinessObject(_this.selected_bus_obj)
        _this.$emit('update-policy-list',_this.selectedBussinessObject)
        _this.generateboclick =false
      }).catch(CurrentJobError => {
        this.generateboclick = false
        if(CurrentJobError){
          this.datasource_error = CurrentJobError
        }
        else {

        }
      })
    },
    validateLimit(e){
      if(["0","1","2","3","4","5","6","7","8","9"].indexOf(e.key) < 0)
        e.preventDefault()
    },
    onLimitBlur(e,fromRedoUndo){
      if(this.old_limit!=this.limit && !fromRedoUndo){
        this.$emit('sendNonGraphEvent','step_limit_change',{"step_id":this.archivalobj.stepId,"old_limit":this.old_limit,"new_limit":this.limit})
      }
      if(this.limit.trim()==="")
        return
      // to deal with 0000 or 00111 kind of values in limit

      this.limit = parseInt(this.limit)
      // to deal with 0. 0 cause the error in engine library
      if(this.limit === 0)
        this.limit = ""
      this.old_limit = this.limit
      this.limit = this.limit.toString()
    },
    callgetColumnsMoreInfo(type_of_request){
      getColumnsMoreInfo(this,this.env_id,this.$session.get("client_id"),this.columns_for_order,type_of_request,this.sourceCellColumnReferences)
    },
    changedPurgeType(){
      this.disable_index = false
      this.rebuild_index = false
    },
    DescriptionChange(pd_description){
      this.pd_description = pd_description
      this.savedata()
    },
  }
}
</script>
<style scoped>
.customCheckbox >>> label{
  top: 5px !important;
}
.v-autocomplete.v-input{
  margin:0px;
}
.upperrow{
  display:inline-block;
  height:350px;
  overflow: auto;
  border:1px solid grey;
}

.lowerrow{
  display:block;
  height:200px;
  overflow: auto;
  /* border:1px solid grey; */

}

#mainflex{
  background-color:grey;
}

.list_items {
  height:30px;
  draggable:true;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
table.v-table thead tr, table.v-table tbody tr{
  height: 38px;
}
.sticky {
  position: absolute;
}
#filter_column_table .v-input__slot{
  margin-bottom: -10px;
  margin-top: -5px;
}
#select_column_table .v-select__slot{
  margin-bottom: -6px;
}
#filter_column_table .v-input__slot input, .v-select-list .v-list__tile__content{
  font-size: 15px;
}
#select_column_table td{
  font-size: 13px;
}
#select_column_table .v-input__slot{
  font-size: 14px;
  margin-bottom: -8px;
  margin-top: -5px;
}
#select_column_table .v-input__append-inner i{
  /*font-size: 15px;*/
}
#select_column_table .v-text-field__slot{
  width: 65px;
}
#select_column_table .v-text-field__slot{
  margin-top: -5px;
}
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.v-radio label{
  font-size: 15px;
}
.v-radio i{
  font-size: 20px;
}
.v-dialog__activator{
  margin-top: -10px;
}

#switch_button {
  margin-bottom: -1rem !important;
  vertical-align: top !important;
}

.v-input--selection-controls {
     margin-top: 4px !important;
     padding-top: 0px !important;
}

</style>