<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row" style="padding-top:10px !important" align="center">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb" style="padding-left: 14px !important">
              <li>
                <router-link to="/dashboard">Home</router-link>
              </li>
              <li>
                <router-link to="/calendar">Job Calendar</router-link>
              </li>
              <li>
                <router-link :to="{'name':'update_calendar',params:{ calendarDetails: $store.state.calendarDetails, type:'edit'}}">{{ $store.state.calendarDetails.name }}</router-link>
              </li>
              <li>Manage Job Calendar Event</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-row class="EDC-Row">
      <v-card class="EDC-VCard" elevation-1>
        <v-form ref="add_user_form" v-model="valid">
          <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
              <span class="EDC-Heading">Manage Job Calendar Event</span>
            </v-col>
          </v-row>

          <v-row class="EDC-Row EDC-SchRow EDC-RowsSpacing" align="start">
            <v-col class="EDC-Col EDC-UIPadding" cols="2">
              <v-text-field v-model="jobObject.name" label="Event Name" :rules="nameRules" dense/>
            </v-col>
          </v-row>

          <v-row class="EDC-Row EDC-SchRow" align="start">
            <v-col class="EDC-Col EDC-ColEventType" cols="1">
              <label class="v-label" style="float:left">Frequency</label>
            </v-col>
            <v-col class="EDC-Col EDC-ColMainRadio" cols="1">
              <v-radio-group v-model="schedule_type" @change="changeScheduleType" hide-details dense>
                <v-radio class="radioClass" :color="colorCode" label="Once" value="one_time"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="EDC-Col" cols="6" v-if="schedule_type ==='one_time'">
              <v-row class="EDC-Row">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <calender :input="jobObject.exception_start_date" @update="setExceptionStartDate" style=" float:left" :label="begin_date_label" :dense="true" :isMinDate="true"></calender>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <timer :inputtime="start_clock_model" @update="updateStartTime" :label="start_time_label" dense></timer>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding EDC-ColTimeZone" cols="4">
                  <v-autocomplete hide-details v-model="jobObject.timezone" label="Time Zone" :items="timeZoneItems" style="float:left;width:100%" dense></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <calender :input="jobObject.exception_end_date" :label="end_date_label" :isMinDate="true" @update="setExceptionEndDate" :dense="true" style=" float:left"/>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <timer :inputtime="end_clock_model" :label="end_time_label" @update="updateEndTime"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="EDC-Row EDC-SchRow" align="start">
            <v-col class="EDC-Col" cols="1"></v-col>
            <v-col class="EDC-Col EDC-ColMainRadio" cols="1">
              <v-radio-group hide-details v-model="schedule_type" @change="changeScheduleType" dense>
                <v-radio class="radioClass" :color="colorCode" label="Weekly"  value="weekly" :disabled="is_one_time_schedule"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="EDC-Col" cols="6" v-if="schedule_type==='weekly'">
              <v-row class="EDC-Row">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <calender :input="jobObject.exception_start_date" @update="setExceptionStartDate" style=" float:left" :label="begin_date_label" :dense="true" :isMinDate="true"></calender>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <timer :inputtime="start_clock_model" @update="updateStartTime" :label="start_time_label" dense></timer>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding EDC-ColTimeZone" cols="4">
                  <v-autocomplete hide-details v-model="jobObject.timezone" label="Time Zone" :items="timeZoneItems" style="float:left;width:100%" dense></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <!-- <calender :input="jobObject.exception_end_date" :label="end_date_label" :isMinDate="true" @update="setExceptionEndDate" :dense="true" style=" float:left"/> -->
                <v-select  v-model="jobObject.endIndays" style="-webkit-appearance: menulist" autofocus :items="endIndaysDatasArray" item-text="item_text" return-object item-value="value" label="End In"  dense>                  
                </v-select>

                  <!--v-text-field v-model="jobObject.endIndays" label="End In" hide-details dense style="float:left;width:100%"></v-text-field-->
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <timer :inputtime="end_clock_model" :label="end_time_label" @update="updateEndTime"/>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col class="EDC-Col EDC-UIPadding" cols="12">
                  <v-select :items="dayOfWeeks" multiple v-model="everyday" item-text="fullname" return-object item-value="fullname" label="Select days of Week" hide-details dense>
                    <v-list-item slot="prepend-item" ripple @click="toggleWeek">
                      <v-list-item-action>
                        <v-icon>{{weekIcon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-title style="text-align:left">Select All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"/>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="EDC-Col EDC-ColUntil" cols="1" v-if="schedule_type==='weekly'" align-items="start">
              <label class="v-label" style="float:center">UNTIL</label>
            </v-col>
            <v-col class="EDC-Col" cols="2" v-if="schedule_type==='weekly'">
              <v-row class="EDC-Row EDC-Indefinitely">
                <v-radio-group @change="changeRangeOfReccurance" v-model="recurrenceEndOn" hide-details dense>
                  <v-radio class="radioClass" :color="colorCode" label="INDEFINITELY" value="indefinitely"></v-radio>
                </v-radio-group>
              </v-row>
              <v-row class="EDC-Row EDC-Date EDC-RowsSpacing">
                <v-col cols="1" class="EDC-Col EDC-RadioMargin">
                  <v-radio-group v-model="recurrenceEndOn" @change="changeRangeOfReccurance" hide-details dense>
                    <v-radio class="radioClass" :color="colorCode" label="" value="until_date"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="11" class="EDC-Col EDC-CalenderMargin">
                  <calender :input="jobObject.recurring.range_of_recurrence.until_date" @update="setUntillDate" :dense="true" style="float:right" :isMinDate="true" :disabled="recurrenceEndOn!='until_date'"></calender>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col cols="1" class="EDC-Col" style="margin-top:4px !important">
                  <v-radio-group @change="changeRangeOfReccurance" v-model="recurrenceEndOn" hide-details dense>
                    <v-radio class="radioClass" :color="colorCode" label="" value="afterNinteration"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="11" class="EDC-Col EDC-TextfieldMargin">
                  <v-text-field v-model="jobObject.recurring.range_of_recurrence.end_after_times" label="End After Iterations" hide-details dense style="float:left;width:100%" :disabled="recurrenceEndOn!='afterNinteration'"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="EDC-Col" cols="1"></v-col>
          </v-row>

          <v-row class="EDC-Row EDC-SchRow" align="start">
            <v-col class="EDC-Col" cols="1"></v-col>
            <v-col class="EDC-Col EDC-ColMainRadio" cols="1">
              <v-radio-group hide-details v-model="schedule_type" @change="changeScheduleType" dense>
                <v-radio class="radioClass" :color="colorCode" label="Monthly"  value="monthly" :disabled="is_one_time_schedule"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="EDC-Col" cols="6" v-if="schedule_type==='monthly'">
              <v-row class="EDC-Row">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <calender :input="jobObject.exception_start_date" @update="setExceptionStartDate" style=" float:left" :label="begin_date_label" :dense="true" :isMinDate="true"></calender>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <timer :inputtime="start_clock_model" @update="updateStartTime" :label="start_time_label" dense></timer>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding EDC-ColTimeZone" cols="4">
                  <v-autocomplete hide-details v-model="jobObject.timezone" label="Time Zone" :items="timeZoneItems" style="float:left;width:100%" dense></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <!--calender :input="jobObject.exception_end_date" :label="end_date_label" :isMinDate="true" @update="setExceptionEndDate" :dense="true" style=" float:left"/-->
                   <!--v-text-field v-model="jobObject.endIndays" label="End In" hide-details dense style="float:left;width:100%"></v-text-field-->
                     <v-select  v-model="jobObject.endIndays" style="-webkit-appearance: menulist" autofocus :items="endIndaysDatasArray" item-text="item_text" return-object item-value="value" label="End In"  dense>                  
                    </v-select>
                </v-col>
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <timer :inputtime="end_clock_model" :label="end_time_label" @update="updateEndTime"/>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col class="EDC-Col EDC-UIPadding EDC-ColTimeZone" cols="12">
                  <v-select :items="dayOfMonthArray" multiple v-model="everymonth" item-text="fullname" return-object item-value="fullname" label="Select Months" hide-details dense>
                    <v-list-item slot="prepend-item" ripple @click="toggleMonth">
                      <v-list-item-action>
                        <v-icon>{{monthIcon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-title style="text-align:left;">Select All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"/>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col EDC-ColRadio" cols="1">
                      <v-radio-group hide-details v-model="monthlytype" @change="monthlyTypeChanged" dense>
                        <v-radio class="radioClass" :color="colorCode" label="" value="each"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col class="EDC-Col EDC-TextfieldMargin" cols="11">
                      <v-select :items="day_array" v-model="jobObject.recurring.recurrence_pattern.date_of_month" label="Specified Day of Month" :disabled="monthlytype==='every'" hide-details dense></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col class="EDC-Col EDC-UIPadding" cols="4">
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col EDC-ColRadio" cols="1">
                      <v-radio-group @change="monthlyTypeChanged" hide-details v-model="monthlytype" dense disabled>
                        <v-radio class="radioClass" :color="colorCode" label=""  value="every" disabled></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col class="EDC-Col EDC-TextfieldMargin" cols="11">
                      <v-select v-model="jobObject.recurring.recurrence_pattern.which_day_of_week" item-text="text" item-value='value' :items="day_of_week_array" label="Occurrence" :disabled="monthlytype==='each'" hide-details dense></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="EDC-Col EDC-WeekDay" cols="4">
                  <v-select :items="dayOfWeeks" item-text="fullname"  item-value="fullname" v-model="jobObject.recurring.recurrence_pattern.selected_week_day" label="Week Day" :disabled="monthlytype==='each'" hide-details dense></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="EDC-Col EDC-ColUntil" cols="1" v-if="schedule_type==='monthly'" align-items="start">
              <label class="v-label" style="float:center">UNTIL</label>
            </v-col>
            <v-col class="EDC-Col" cols="2" v-if="schedule_type==='monthly'">
              <v-row class="EDC-Row EDC-Indefinitely">
                <v-radio-group @change="changeRangeOfReccurance" v-model="recurrenceEndOn" hide-details dense>
                  <v-radio class="radioClass" :color="colorCode" label="INDEFINITELY" value="indefinitely"></v-radio>
                </v-radio-group>
              </v-row>
              <v-row class="EDC-Row EDC-Date EDC-RowsSpacing">
                <v-col cols="1" class="EDC-Col EDC-RadioMargin">
                  <v-radio-group v-model="recurrenceEndOn" @change="changeRangeOfReccurance" hide-details dense>
                    <v-radio class="radioClass" :color="colorCode" label="" value="until_date"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="11" class="EDC-Col EDC-CalenderMargin">
                  <calender :input="jobObject.recurring.range_of_recurrence.until_date" @update="setUntillDate" :dense="true" style="float:right" :isMinDate="true" :disabled="recurrenceEndOn!='until_date'"></calender>
                </v-col>
              </v-row>
              <v-row class="EDC-Row EDC-RowsSpacing">
                <v-col cols="1" class="EDC-Col" style="margin-top:4px !important">
                  <v-radio-group @change="changeRangeOfReccurance" v-model="recurrenceEndOn" hide-details dense>
                    <v-radio class="radioClass" :color="colorCode" label="" value="afterNinteration"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="11" class="EDC-Col EDC-TextfieldMargin">
                  <v-text-field v-model="jobObject.recurring.range_of_recurrence.end_after_times" label="End After Iterations" hide-details dense style="float:left;width:100%" :disabled="recurrenceEndOn!='afterNinteration'"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="EDC-Col" cols="1"></v-col>
          </v-row>

          <v-row class="EDC-Row EDC-RowsSpacing" justify="end" style="margin-right:5px !important">
            <vc-button itemText="Add" @click.native="submit"></vc-button>
            <vc-button itemText="Cancel" @click.native="CancelJobEvent"></vc-button>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
     <simplert ref="simplert" :use-radius="true" :use-icon="true"/>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
  </div>
</template>
<script>
import config from "../../config.json"
import Timer from "../../views/timecontrol"
import {
  get as getToServer,
  post as postToServer
} from './../../methods/serverCall.js';
import {
  SERVER_ERROR
} from '../../data/client_message.js'
import {
  GET_EVENT_JOB,
  ADD_EVENT_JOB,
  UPDATE_EVENT_JOB,
  VALIDATE_EVENT
} from '../../data/url_constants.js';
import {
  DAY_OF_WEEKS,
  DAY_OF_MONTH_ARRAY,
  WEEK_ARRAY,
  DAY_ARRAY,
  DAY_OF_WEEK_ARRAY,
  JOB_OBJECT
} from '../../data/metaData.js'
import filter from 'lodash/filter'
import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import Calender from '@/views/calender'

var moment = require("moment-timezone");
export default {
  name: 'Schedule',
  components: {
    'timer':Timer,
    Calender,
  },
  data() {
    return {
      everyday:[],
      everymonth:[],
      recurrenceEndOn:'indefinitely',
      monthlytype:'each',
      category:'Job',
      schedule_id:'',
      disabled: 0,
      snackbar:false,
      snackbartext:'',
      snackbartimeout: Snackbar_Default_Timeout,
      colorValue:'error',
      loader:false,
      loading: false,
      outlineColor:BTN_COLOR,
      colorCode:COLOR_CODE,
      type:'new',
      weeklySelectAll: true,
      monthlySelectAll: true,
      dayOfWeeks: _.cloneDeep(DAY_OF_WEEKS),
      dayOfMonthArray: _.cloneDeep(DAY_OF_MONTH_ARRAY),
      schedule_name: "",
      start_date: "",
      end_date: "",
      timezone: "",
      dayOfMonth: "Date of Month",
      time: null,
      menu2: false,
      modal2: false,
      timeZoneItems: moment.tz.names(),
      WeeklyArray: [],
      monthArray: [],
      row: 'One Time',
      recurrence: 'Weekly',
      week_array: WEEK_ARRAY,
      day_array: DAY_ARRAY,
      day_of_week_array: DAY_OF_WEEK_ARRAY,
      jobObject: _.cloneDeep(JOB_OBJECT.params),
      is_one_time_schedule:false,
      iterationRules: [v => /^((\d+(\.\d *)?)|((\d*\.)?\d+))$/.test(v) || 'End after iterations should be numeric'],
      text_clock_model:'',
      dropdown_clock_model:'',
      open_clocklist:false,
      end_date_label:'End Date (YYYY-MM-DD)',
      begin_date_label:'Beginning Date (YYYY-MM-DD)',
      start_time_label:'Start Time (HH:MM)',
      end_time_label:'End Time (HH:MM)',
      which_monthly_type:"date_of_month", // this variable is used for manage montly radio button. Currently its manage by True false value which is wrong. Because you cannot set boolean value to radio button as its get changed based on selection and radio group behaves randomly.DONT USE RADIO VALUES AS BOOLEAN,
      schedule_type: "one_time", // this variable used to identify recurring or one time job.Same mistake done for radio button as explained for 'which_monthly_type' variable

      nameRules:[v => !!v || 'Name is required'],
      start_clock_model:'',
      end_clock_model:'',
      endIndaysDatasArray : [{"item_text": '+00', "value": 0}, {"item_text":'+01', "value": 1},
                            {"item_text": '+02', "value": 2}, {"item_text":'+03', "value": 3},
                            {"item_text": '+04', "value": 4}, {"item_text":'+05', "value": 5},
                            {"item_text": '+06', "value": 6}, {"item_text":'+07', "value": 7}
                            ]
    }
  },
        computed: {
          selectAllWeek(){
            return this.everyday.length === this.dayOfWeeks.length
          },
          selectFewWeek(){
            return this.everyday.length > 0 && !this.selectAllWeek
          },
          weekIcon(){
            if(this.selectAllWeek) 
              return 'mdi-checkbox-marked'
            if(this.selectFewWeek)
              return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
          },
          selectAllMonth(){
            return this.everymonth.length === this.dayOfMonthArray.length
          },
          selectFewMonth(){
            return this.everymonth.length >0 && !this.selectAllMonth
          },
          monthIcon(){
            if(this.selectAllMonth) 
              return 'mdi-checkbox-marked'
            if(this.selectFewMonth)
              return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
          }
        },
        watch: {
          'jobObject.recurring.range_of_recurrence.until_date'(newvalue, o) {
            if (newvalue)
              this.jobObject.recurring.range_of_recurrence.end_on = false;
          },
          'jobObject.recurring.range_of_recurrence.end_after_times'(newvalue, o) {
            if (newvalue)
              this.jobObject.recurring.range_of_recurrence.end_on = false;
          },
          dayOfMonthArray: {
            handler: function (val, o) {
              let selectedMonth = filter(val, 'value');
              this.monthlySelectAll = selectedMonth.length == val.length ? true : false;
            },
            deep: true
          },
          dayOfWeeks: {
            handler: function (val, oldVal) {
              let selectedWeek = filter(val, 'value');
              this.weeklySelectAll = selectedWeek.length == val.length ? true : false;
            },
            deep: true
          },
          schedule_type:{
            handler:function(val){

            }
          }
        },
        mounted() {
          let _this = this;
            // debugger;
            delete this.jobObject.start_from
            delete this.jobObject.start_time
            delete this.jobObject.end_time
            this.jobObject.exception_start_date = ''
            this.jobObject.exception_end_date = ''
            this.jobObject.endIndays = 0
            if (this.$route.params.eventDetails && this.$route.params.eventDetails.id) {
              this.jobObject.name = this.$route.params.eventDetails.id;
              this.getJobEvent();
            }else if(this.$route.params.eventDetails){
              this.setJobEvent(this.$route.params.eventDetails);
            }
            else{
              this.jobObject = _.cloneDeep(JOB_OBJECT.params);
              this.jobObject.timezone = this.$session.get('time_zone_preference');
            }

          },
          props: {
            msg: String
          },
          methods: {
            setJobEvent(response){
              this.jobObject = response;
              let _this = this;
        // this.jobObject.exception_start_time = response.exception_start_time.HH + ':' + response.exception_start_time.mm;
        // this.jobObject.exception_end_time = response.exception_end_time.HH + ':' + response.exception_end_time.mm;
        this.jobObject.is_recurring = (response.is_recurring =="One Time" ? false:true);
        if(this.jobObject.is_recurring){
          if(response.recurring.recurrence_pattern.recurrence_pattern_type == 'weekly'){
            _this.dayOfWeeks.map(obj=>{
              let tempValue = _.indexOf(response.recurring.recurrence_pattern.week_array,obj.text)
              obj.value =  tempValue !== -1 ? true:false
            });
          }
          if(response.recurring.recurrence_pattern.recurrence_pattern_type == 'monthly'){
            _this.dayOfMonthArray.map(obj=>{
              let tempValue = _.indexOf(response.recurring.recurrence_pattern.month_array,obj.text);
              obj.value =  tempValue !== -1 ? true:false;
            });
          }
        }
      },
      toggleWeek(){
        this.$nextTick(()=>{
          if(this.selectAllWeek)
            this.everyday = []
          else
            this.everyday = this.dayOfWeeks
        })
      },
      toggleMonth(){
        this.$nextTick(()=>{
          if(this.selectAllMonth)
            this.everymonth = []
          else
            this.everymonth = this.dayOfMonthArray
        })
      },
      changeScheduleType(val){
        this.jobObject.is_recurring = false
        if(val === "recurring")
          this.jobObject.is_recurring = true
      },
      toggleUntillDate(val) {
        if (val) {
          this.jobObject.recurring.range_of_recurrence.until_date = null
          this.jobObject.recurring.range_of_recurrence.end_after_times = null
        }
      },
      toggleMonthlySelect(val) {
        this.dayOfMonthArray.map(obj => {
          obj.value = val ? true : false;
        })
      },
      toggleSelect(val) {
        this.dayOfWeeks.map(obj => {
          obj.value = val ? true : false;
        });
      },
      submit () {
            if (this.$refs.add_user_form.validate()) {
                this.saveData()
            }
        },
      saveData() {
        let _this = this;
        var clientId = this.$session.get('client_id')
        var user_id = this.$session.get('email')
        this.jobObject.client_id = clientId;
        this.jobObject.created_by = user_id;
        let inputParam = _this.getSaveObject(this.jobObject);
        let url = config.JOB_CALENDAR_URL;
        if(this.jobObject.id){
          url+= UPDATE_EVENT_JOB
          inputParam['id'] = this.jobObject.id  
        }else{
          url+= ADD_EVENT_JOB
        }
        inputParam['calendar_id'] = this.$route.params.calendarDetails.id;
        postToServer(this, url, inputParam).then(
          addResponse  => {
            let record = _this.$store.state.calendarDetails;
            _this.$router.push({ name: 'update_calendar', 
              params: { calendarDetails: record, type:'edit',eventDetails:addResponse,
              manageJobCalendar:true,previousRecord:_this.$route.params.previousRecord}});
          }).catch(error_response => {
            if(error_response){
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
          });
        },
        getSaveObject(obj) {
          let freshObject = _.cloneDeep(JOB_OBJECT.params)
          let tempObject = _.cloneDeep(obj)
          let _this = this;
          delete freshObject.workflow_details
          delete freshObject.category
          delete freshObject.service_id
          delete freshObject.end_point
          delete freshObject.start_from
          delete freshObject.start_time
          delete freshObject.execution_environment
          delete freshObject.execution_env_name
          freshObject.exception_start_date =tempObject.exception_start_date
          freshObject.exception_end_date = tempObject.exception_end_date
          freshObject.name = tempObject.name
          freshObject.timezone = tempObject.timezone
          freshObject.client_id = tempObject.client_id
          freshObject.created_by = tempObject.created_by
          freshObject.exception_start_job = tempObject.exception_start_job  
          freshObject.exception_end_job = tempObject.exception_end_job
          freshObject.exception_id = tempObject.exception_id
          if (tempObject.endIndays)
            freshObject.endIndays = tempObject.endIndays.value
          freshObject.recurring.recurrence_pattern.recurrence_pattern_type = _this.schedule_type
          freshObject.is_recurring = false

          if(_this.schedule_type!="one_time"){
            freshObject.is_recurring = true
            if(_this.schedule_type === 'weekly'){
              let selectedWeek = _.filter(_this.everyday,'value')
              selectedWeek.map((obj, index) => {
                freshObject.recurring.recurrence_pattern.week_array.push(obj.text);
              });
            }
            else {
                //code for monthly
                let selectedMonth = this.everymonth
                selectedMonth.map((obj, index) => {
                  freshObject.recurring.recurrence_pattern.month_array.push(obj.text);
                });

                freshObject.recurring.recurrence_pattern.is_day_of_month =true
                if(_this.monthlytype === "each"){
                  freshObject.recurring.recurrence_pattern.is_day_of_month = false
                  freshObject.recurring.recurrence_pattern.date_of_month = tempObject.recurring.recurrence_pattern.date_of_month
                }
                else{
                  freshObject.recurring.recurrence_pattern.selected_week_day = _.find(_this.dayOfWeeks,["fullname",tempObject.recurring.recurrence_pattern.selected_week_day]).text.toLowerCase()
                  freshObject.recurring.recurrence_pattern.which_day_of_week = tempObject.recurring.recurrence_pattern.which_day_of_week
                }

              }


            // set until data
            if(_this.recurrenceEndOn === 'indefinitely')
              freshObject.recurring.range_of_recurrence.end_on=true
            else if(_this.recurrenceEndOn === 'until_date')
              freshObject.recurring.range_of_recurrence.until_date = tempObject.recurring.range_of_recurrence.until_date
            else
              freshObject.recurring.range_of_recurrence.end_after_times = tempObject.recurring.range_of_recurrence.end_after_times

          }


          if(this.start_clock_model){
            if(this.start_clock_model.length != 5)
              return false
            var split_by_colon = this.start_clock_model.split(':')
            var hours =split_by_colon[0] 
            var minute = split_by_colon[1] 
            freshObject.exception_start_time = {
              'HH': hours,
              'mm': minute
            }
          }

          if(this.end_clock_model){
            if(this.end_clock_model.length != 5)
              return false
            var split_by_space = this.end_clock_model.split(' ')
            var split_by_colon = this.end_clock_model.split(':')
            var hours =split_by_colon[0] 
            var minute = split_by_colon[1] 
            freshObject.exception_end_time = {
              'HH': hours,
              'mm': minute
            }
          }

          freshObject.is_active = true;
          return freshObject;
        },
        setDate(dateParam) {
          let _this = this;
          _this.jobObject.start_from = dateParam;
        },
        setUntillDate(dateParam) {
          let _this = this;
          _this.jobObject.recurring.range_of_recurrence.until_date = dateParam;
          _this.jobObject.recurring.range_of_recurrence.end_on = false;
        },
        getJobEvent() {
          var data_to_pass = {"id":this.$route.params.eventDetails.id};
          let _this = this;
          postToServer(this, config.JOB_CALENDAR_URL + GET_EVENT_JOB,data_to_pass).then(response => {
            this.jobObject = response.params;
            // this.jobObject.exception_start_time = response.params.exception_start_time.HH + ':' + response.params.exception_start_time.mm;
            this.Time24to12(response.params.exception_start_time,'Start')
            // this.jobObject.exception_end_time = response.params.exception_end_time.HH + ':' + response.params.exception_end_time.mm;
            this.Time24to12(response.params.exception_end_time,'End')

            _this.schedule_type = "one_time"
            if (this.jobObject.is_recurring) {
              _this.schedule_type = this.jobObject.recurring.recurrence_pattern.recurrence_pattern_type
              if (_this.schedule_type == 'weekly') {
                _this.dayOfWeeks.map(function(obj){
                  if(_this.jobObject.recurring.recurrence_pattern.week_array.indexOf(obj.text) >=0)
                    _this.everyday.push(obj)
                })
              } else {
                _this.dayOfMonthArray.map(function(obj){
                  obj.value = false
                  if(_this.jobObject.recurring.recurrence_pattern.month_array.indexOf(obj.text) >=0)
                    _this.everymonth.push(obj)
                })

                _this.monthlytype = 'each'
                if(_this.jobObject.recurring.recurrence_pattern.is_day_of_month){
                  _this.monthlytype = "every"
                  let day = _this.jobObject.recurring.recurrence_pattern.selected_week_day[0].toUpperCase() +  _this.jobObject.recurring.recurrence_pattern.selected_week_day.slice(1)
                  _this.jobObject.recurring.recurrence_pattern.selected_week_day = _.find(_this.dayOfWeeks,["text",day]).fullname
                }
              }

            // manage until
            if(_this.jobObject.recurring.range_of_recurrence.end_on)
              _this.recurrenceEndOn = 'indefinitely'
            else if(_this.jobObject.recurring.range_of_recurrence.until_date)
              _this.recurrenceEndOn = 'until_date'
            else
              _this.recurrenceEndOn = 'afterNinteration'


          }

        }).catch(error_response => {

        })
      },
      Time24to12(time,for_what){
        var hours = time.HH
        var minute = time.mm
        if(for_what === 'Start')
          this.start_clock_model = hours+":"+minute
        else 
          this.end_clock_model = hours+":"+minute
      },
      updateStartTime(changedtime){
        this.start_clock_model = changedtime
      },
      updateEndTime(changedtime){
        this.end_clock_model = changedtime
      },
      setExceptionEndDate(dateParam){
        let _this = this;
        _this.jobObject.exception_end_date = dateParam;
      },
      setExceptionStartDate(dateParam){
        let _this = this;
        _this.jobObject.exception_start_date = dateParam;
      },
      changeRangeOfReccurance(value){
        this.jobObject.recurring.range_of_recurrence.end_after_times=''
        this.jobObject.recurring.range_of_recurrence.until_date=null
      },
      monthlyTypeChanged(){
        this.jobObject.recurring.recurrence_pattern.date_of_month =''
        this.jobObject.recurring.recurrence_pattern.which_day_of_week =''
        this.jobObject.recurring.recurrence_pattern.selected_week_day=''
      },

      CancelJobEvent(){
          this.$router.push("/calendar")
      },
    },

  }
  </script>

  <style scoped>
  .radioClass >>> label{
    top: 3px !important;
  } 
  /*.radioClass >>> i{
    font-size: 30px
  }*/
  .customCheckbox >>> label{
    top: 5px !important;
  }
  /* .customCheckbox >>> i{
    top: 5px !important;
  } */
  .pointer{
    cursor: pointer;
  }
  .pointer >>> span{
    cursor: pointer;
  }
  .custWidth{
    width: 55px;
  }
  .customCheckbox >>> label{
    top: 0px !important;
  }
  .v-input--selection-controls{
    margin-top:0px !important;
    padding-top: 0px !important; 
  }

  .v-divider--vertical {
    min-height:10%;
  }
  .theme--light.v-divider {
    border-color: rgba(0,0,0,.50);
  }

  .EDC-VCard {
    padding: 0px !important;
    margin: 12px !important;
    margin-bottom: 0px !important;
    height: 100% !important;
    width: 1230px !important;
  }

  .EDC-VCardTitle {
    background: #494949 !important;
    color: white !important;
    text-align: left !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin: 0px !important;
    height: 30px !important;
  }

  .EDC-SchRow{
    padding-left:10px !important;
    padding-right:10px !important;
  }

  .EDC-ColAlignPadding {
    padding-left:32px !important
  }

  .EDC-ColTimeZone {
    margin-top:4px !important;
  }

  .EDC-ColRadio {
    padding-top:8px !important;
    margin-left: -3px !important;
  }

  .EDC-ColRun{
    padding-top:15px !important;
  }

  .EDC-ColUntil{
    padding-top:10px !important;
  }

  .EDC-ColMainRadio{
    margin-top:6px !important;
  }

  .EDC-RadioMargin {
    margin-top: -8px !important;
    margin-left: -3px !important;
  }

  .EDC-CalenderMargin {
    margin-top: -18px !important;
    padding-left: 10px !important;
  }

  .EDC-TextfieldMargin {
    padding-left: 10px !important;
  }

  .EDC-UIPadding {
    padding-right:10px !important;
  }

  .EDC-ComboboxPadding {
    padding-top: 5px !important;
  }

  .EDC-Indefinitely {
    margin-top: 12px !important;
  }

  .EDC-Date {
    margin-top: 18px !important;
  }

  </style>