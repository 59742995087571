<template>
    <v-btn :loading="loading" outlined :disabled="disabled" 
                    color="apptext" class="border" elevation="1" small :title="itemHelp">{{itemText}}</v-btn>
</template>
<script>
import { BTN_COLOR } from '@/data/macros.js';
export default {
    name:'vcButton',
    data() {
      return {
          buttonColor:BTN_COLOR
      }
    },
    props: {
      itemText: String,
      loading: Boolean,
      disabled:Boolean,
      itemHelp:String,
    },
    watch:{
        'disabled':{
            handler(newValue){
                
            }
        }
    }
}
</script>
<style scoped>
.border{
    border-radius:10px;
    margin:4px !important;
}
</style>
