import Vue from 'vue'
import Router from 'vue-router'

// import datasourcelist from '../components/datasources/datasourceList.vue'
import datasourcelist from '../components/datasources/datasourceListNew.vue'
import Register from '../components/authorization/register.vue'
import Login from '../components/authorization/login.vue'
import ForgotPassword from '../components/authorization/forgot_password.vue'
import ResendEmail from '../components/authorization/resend_email.vue'

import ResetPassword from '../components/authorization/reset_password.vue'
import ChangePassword from '../components/authorization/change_password.vue'
import ResetUserPassword from '../components/authorization/reset_user_password.vue'
import Schedule from '../components/schedular/schedule.vue'
import ScheduleJobPlan from '../components/schedular/ScheduleJobPlan.vue'

import Environmentlist from '../components/edcenvironment/environmentlistNew.vue'
import ManageEnvironment from '../components/edcenvironment/manageenvironment.vue'
import SecurityList from '../components/security/SecurityList.vue'
import ManageSecurity from '../components/security/ManageSecurity.vue'
import StepDetails from '../components/templates/step_details.vue'
import ClientSettings from '../components/templates/client_settings.vue'
import EdcInstance from '../components/templates/edc_instance.vue'
// import Flowchart from '../components/sqlDesigner/flowchart.vue'
import Flowchart from '../components/sqlDesigner/edcProcessDesigner.vue'

import AddBulkUser from '../components/userprovisioning/add_bulk_user.vue'
import AddUser from '../components/userprovisioning/manage_user.vue'
import UpdateUser from '../components/userprovisioning/manage_user.vue'
import ManageDataSource from '../components/datasources/manageDatasource.vue'
// import ManageWorkflowRoute from '../components/serviceWorkflow/manageWorkflowroute.vue'
import ManageWorkflowMapping from '../components/serviceWorkflow/manageWorkflowmapping.vue'
import ManageApprovalTask from '../components/serviceWorkflow/manageTaskapproval.vue'
import ManageTaskForApprovalList from '../components/serviceWorkflow/grid/ManageTaskForApprovalListNew.vue'
import manageApprovalRequestList from '../components/serviceWorkflow/grid/user_approval_request.vue'
import approvalHistoryList from '../components/serviceWorkflow/grid/approval_history.vue'

// import WorkflowRouteList from '../components/serviceWorkflow/workflowrouteList.vue'
import WorkflowRouteList from '../components/serviceWorkflow/grid/WorkflowRouteListNew.vue'
import WorkflowMappingList from '../components/serviceWorkflow/workflowmappingList.vue'
import RequestHistory from '../components/serviceWorkflow/requestHistoryList.vue'
import WorkflowIDE from '../components/serviceWorkflow/ide_for_workflowroute.vue'

// import processdefinitionlist from '../components/jobManagement/processDefList.vue'
import processdefinitionlist from '../components/jobManagement/grid/processDefListNew.vue'
// import jobUi from '../components/jobManagement/jobUi.vue'
import jobUi from '../components/jobManagement/manageJob.vue'
import managejobNew from '../components/jobManagement/manageJobNew.vue'
// import Businessobjectdesigner from '../components/sqlDesigner/businessobjectdesigner.vue'
import Businessobjectdesigner from '../components/sqlDesigner/edcBusinessObjectDesigner.vue'

import ManageAccount from '../components/templates/manage_account.vue'
// import currentjoblist from '../components/jobManagement/currentjoblist.vue'
import currentjoblist from '../components/jobManagement/grid/CurrentJobListNew.vue'
// import jobhistorylist from '../components/jobManagement/jobHistoryList.vue'
// import showjobstepsresult from '../components/jobManagement/showjobstepsresult.vue'
import jobhistorylist from '../components/jobManagement/grid/jobHistory.vue'
import showjobstepsresult from '../components/jobManagement/grid/jobDetails.vue'
// import operationwisedetails from '../components/jobManagement/operationwisedetails.vue'
import operationwisedetails from '../components/jobManagement/grid/OperationWiseDetailsNew.vue'
import userlist from '../components/userprovisioning/userListNew.vue'
import deleteduserlist from '../components/userprovisioning/deleteduserlist.vue'
import schedulelist from '../components/schedular/grid/scheduleListNew.vue'
// import schedulelist from '../components/schedular/schedulelist.vue'
// import schedulejobhistory from '../components/schedular/job_history.vue'
import schedulejobhistory from '../components/schedular/grid/jobHistoryNew.vue'

import dateformat from '../components/sqlDesigner/dateformat.vue'

import PageNotFound from '../views/pageNotFound.vue'
import SelectEnvTypePublish from '../components/sqlDesigner/select_env_type_publish.vue'
import AllPublishTemplate from '../components/sqlDesigner/all_publish_templates.vue'

import ManagePolicyList from '../components/policy/grid/policyListNew.vue'

import PolicyRuleList from '../components/policy/PolicyRuleList.vue'
import AddUserToGroup from '../components/user'
import PublishHistory from '../components/publisher/publish_history.vue'
// import PublishRequest from '../components/publisher/publish_request.vue'
import PublishRequest from '../components/publisher/PublishRequestNew.vue'
import requestStatus from '../components/publisher/request_status.vue'

import GraphTemplate from '../components/templates/graph_template.vue'
import workflow from '../components/workflow'
import ManageRole from '../components/security/ManageRole.vue'
import RoleList from '../components/security/RoleList.vue'
import UserRolesList from '../components/security/user_roles.vue'
import UserRoleMapping from '../components/security/userrolemapping.vue'
import EnvironmentRoleMapping from '../components/security/environmentrolemapping.vue'
import UserRoleSet from '../components/security/userroleset.vue'

import ManageUserRole from '../components/security/ManageUserRole.vue'
import PublishRuleList from '../components/publisher/publishrulelist.vue'
import ProcessDefListExecution from '../components/jobManagement/grid/processDefListForExecution.vue'
import ValueType from '@/views/ValueType.vue'
import JobList from "@/components/jobManagement/JobRule.vue"
import condition from '../components/serviceWorkflow/condition_dialog.vue'
import JobRuleList from "@/components/jobManagement/JobRule.vue"
import RichTextEditor from "@/views/richTextEditor"
import quillEditor from "@/views/richTextEditor/quillEditor.vue"
import Dashboard from "../components/Dashboard.vue"
import Archives from '../components/jobManagement/datamanagement/archives.vue'
import BusinessObjectActivityList from '../components/jobManagement/datamanagement/businessobjectactivity.vue'
import UserProfile from '../components/authorization/userProfile.vue'
import BusinessObjectEnquiry from '../components/jobManagement/datamanagement/show_activity_data.vue'
import package_builder from "../components/package_builder"
import select_object_for_package from "../components/package_builder/select_object.vue"
import FileUpload from "../components/package_builder/import_package.vue"
import PublishApprovalStatus from '../components/publisher/approvalstatus.vue'
import LDAPSetting from '../components/templates/configureLDAP.vue'
import SMTPSetting from '../components/templates/configureSMTP.vue'
import JobCalendar from '@/components/jobCalendar/jobcalendar.vue'
import Calendar from '@/components/jobCalendar/calendar.vue'
import AddCalendar from '@/components/jobCalendar/manage_calendar.vue'
import manageNewCalendar from '@/components/jobCalendar/manage_new_calendar.vue'
import UpdateCalendar from '@/components/jobCalendar/manage_calendar.vue'
import HistoryCalendar from '@/components/jobCalendar/historyCalendar.vue'
import ManageJobCalendar from '@/components/jobCalendar/managejobcalendar.vue'
import ProcessDefExeRule from '@/components/jobCalendar/ProcessDefExecRule.vue'
import eventlist from '@/components/jobCalendar/eventlist.vue'
import apexChart from '../views/apexChart.vue'
import apexChart2 from '../views/apexChart2.vue'
import LiveTableGraph from '../components/jobManagement/graphs/job_transaction_graph.vue'
import ShowWorktableData from '@/components/jobManagement/grid/show_worktables_data.vue'
import JobPlanList from '@/components/jobPlan/JobPlanList.vue'
import JobPlanStatus from '@/components/jobPlan/JobPlanStatus.vue'
import ManageJobPlan from '@/components/jobPlan/ManageJobPlan.vue'

import BOActivityGraph from '../components/jobManagement/graphs/bo_activity_graph.vue'
import userHistory from '../components/userprovisioning/userHistoryDetails.vue'

import ExecuteJobPlan from '@/components/jobPlan/ExecuteJobPlan.vue'
import JobPlanHistory from '@/components/jobPlan/JobPlanHistory.vue'
import JobPlanInstanceHistory from '@/components/jobPlan/JobPlanInstanceHistory.vue'
import JobPlanInstanceDetails from '@/components/jobPlan/JobPlanInstanceDetails.vue'

import ReportDownloadStatus from '../components/report/report_download.vue'
import PublishRequestDetails from '../components/publisher/publish_request_details.vue'
import PublishRequestList from '../components/publisher/publish_request_list.vue'
import CreateServiceReport from '../components/ServiceReport/create_service_report.vue'

import ManageNotificationGroup from '../components/notification_group/manage_notification_group.vue'
import NotificationGroupList from '../components/notification_group/notification_group_list.vue'
import NotificationGroupRule from '../components/notification_group/notification_group_rule.vue'
import NotificationGroupUserMapping from '../components/notification_group/notification_group_mapping.vue'
import MigrationDBInfo from '../components/migration/migration_db_info.vue'
import MigrateUsers from '../components/migration/migrate_users.vue'
import AgentDownload from '../components/agentmanagement/agentmanagement.vue'
import AgentList from '../components/agentmanagement/agentList.vue'
import PerformMigration from '../components/migration/migration_master.vue'
import CatalogMigration from '../components/migration/migrate_catalog.vue'
import AgentQueueList from '../components/agentmanagement/agent_queue_list.vue'
import PackageList from "../components/package_builder/PackageList.vue"
import PackageObject from "../components/package_builder/PackageObject.vue"

import ComplianceArchive from '../components/jobManagement/grid/ComplianceArchive.vue'
// import Disposal from '../components/jobManagement/datamanagement/Disposal.vue'

import TestTemplate from "../views/testtemplate.vue"

import JobLimitRule from "../components/jobManagement/grid/joblimitrule.vue"

import newJointjsDemo from "../components/newjointjs/newgraph.vue"

import shortcutUrlRedirection from "../components/authorization/shortcutUrlRedirection.vue"

import edcConfirmationTest from "../views/edcConfirmationBox/edcConfirmationTest.vue"


import SummarizationActivityList from '../components/jobManagement/datamanagement/summarization_management.vue'

import ClearCatalogData from '../components/jobManagement/catalogmanagement/clearcatalogdata.vue'

import CatalogDeletionHistory from '../components/jobManagement/catalogmanagement/deleteRequestList.vue'

import CatalogDeletionDetails from '../components/jobManagement/catalogmanagement/deleteRequestDetails.vue'

import erpAcceleratorList from '../components/edcenvironment/erpAcceleratorList.vue'
import manageErpAccelerator from '../components/edcenvironment/manageErpAccelerator.vue'

import restAPIList from "../components/develop/gatewayapi.vue"
import restAPICalledHistory from "../components/develop/api_called_history.vue"
import ManageNotificationGroupTable from '../components/notification_group/manage_notification_group_table.vue'

import MediaObjectList from "../components/mediaObject/mediaObjectList.vue"

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {

            path: '/userhistory',
            name: 'userHistory',
            component: userHistory,
            meta: {
                breadcrumb: 'Home / User history'
            }
        },
        {
            path: '/historyCalendar',
            name: 'historyCalendar',
            component: HistoryCalendar,
            meta: {
                breadcrumb: 'Home / historyCalendar'
            }
        },{
            path: '/managejobplan',
            name: 'managejobplan',
            component: ManageJobPlan,
            meta: {
                breadcrumb: 'Home / managejobplan'
            }
        },
        {
            path: '/ExecuteJobPlan',
            name: 'executejobplan',
            component: ExecuteJobPlan,
            meta: {
                breadcrumb: 'Home / executejobplan'
            }
        },
        {
            path: '/jobplanlist',
            name: 'jobplanlist',
            component: JobPlanList,
            meta: {
                breadcrumb: 'Home / JobPlanList'
            }
        },
        {
            path: '/JobPlanHistory',
            name: 'jobplanhistory',
            component: JobPlanHistory,
            meta: {
                breadcrumb: 'Home / job plan history'
            }
        },
        {
            path: '/jobPlanInstanceHistory',
            name: 'jobplaninstancehistory',
            component: JobPlanInstanceHistory,
            meta: {
                breadcrumb: 'Home / job plan history'
            }
        },
        {
            path: '/jobPlanInstanceDetails',
            name: 'jobplaninstancedetails',
            component: JobPlanInstanceDetails,
            meta: {
                breadcrumb: 'Home / job plan history'
            }
        },
        {
            path: '/processDefinitionrule',
            name: 'ProcessDefExeRule',
            component: ProcessDefExeRule,
            meta: {
                breadcrumb: 'Home / ProcessDefExeRule'
            }
        },{
            path: '/eventlist',
            name: 'eventlist',
            component: eventlist,
            meta: {
                breadcrumb: 'Home / eventlist'
            }
        },{
            path: '/livetblgraph',
            name: 'livetblgraph',
            component: LiveTableGraph,
            meta: {
                breadcrumb: 'Home / LiveTableGraph'
            }
        },{
            path: '/apexChart2',
            name: 'apexChart2',
            component: apexChart2,
            meta: {
                breadcrumb: 'Home / apexChart2'
            }
        },{
            path: '/apexChart',
            name: 'apexChart',
            component: apexChart,
            meta: {
                breadcrumb: 'Home / apexChart'
            }
        },{
            path: '/import_package',
            name: 'fileupload',
            component: FileUpload,
            meta: {
                breadcrumb: 'Home / fileupload'
            }
        },
        {
            path: '/userprofile',
            name: 'userprofile',
            component: UserProfile,
            meta: {
                breadcrumb: 'Home / UserProfile'
            }
        },{
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
                breadcrumb: 'Home / Dashboard'
            }
        },{
            path: '/quilleditor',
            name: 'quilleditor',
            component: quillEditor,
            meta: {
                breadcrumb: 'Home / quillEditor'
            }
        },{
            path: '/richtexteditor',
            name: 'richtexteditor',
            component: RichTextEditor,
            meta: {
                breadcrumb: 'Home / RichTextEditor'
            }
        },{
            path: '/jobrulelist',
            name: 'jobrulelist',
            component: JobRuleList,
            meta: {
                breadcrumb: 'Home / Job Rule List'
            }
        },{
            path: '/policyrulelist',
            name: 'policyrulelist',
            component: PolicyRuleList,
            meta: {
                breadcrumb: 'Home / PolicyRuleList'
            }
        },{
        path: '/publishruleList',
        name: 'publishruleList',
        component: PublishRuleList,
        meta: {
            breadcrumb: 'Home / Publish Rule List'
        }
    },
    {
            path: '/rolelist',
            name: 'rolelist',
            component: RoleList,
            meta: {
                breadcrumb: 'Home / RoleList'
            }
        },{
            path: '/UserRolesList',
            name: 'userroleslist',
            component: UserRolesList,
            meta: {
                breadcrumb: 'Home / User Role List'
            }
        },{
            path: '/userrolemapping',
            name: 'UserRoleMapping',
            component: UserRoleMapping,
            meta: {
                breadcrumb: 'Home / Userrolemapping'
            }
        },{
            path: '/environmentrolemapping',
            name: 'EnvironmentRoleMapping',
            component: EnvironmentRoleMapping,
            meta: {
                breadcrumb: 'Home / environmentrolemapping'
            }
        },{
            path: '/userroleset',
            name: 'UserRoleSet',
            component: UserRoleSet,
            meta: {
                breadcrumb: 'Home / userroleset'
            }
        },{
            path: '/ManageRole',
            name: 'ManageRole',
            component: ManageRole,
            meta: {
                breadcrumb: 'Home / ManageRole'
            }
        },
        {
            path: '/manageuserrole',
            name: 'manageuserrole',
            component: ManageUserRole,
            meta: {
                breadcrumb: 'Home / ManageUserRole'
            }
        },{
            path: '/addusertogroup',
            name: 'addusertogroup',
            component: AddUserToGroup,
            meta: {
                breadcrumb: 'Home / add user to group'
            }
        }, {
            path: '/valuetype',
            name: 'valuetype',
            component: ValueType,
            meta: {
                breadcrumb: 'Home / workflow'
            }
        }, {
            path: '/workflow',
            name: 'workflow',
            component: workflow,
            meta: {
                breadcrumb: 'Home / workflow'
            }
        },
        {
            path: '/Flowchart',
            name: 'processdefinition',
            component: Flowchart,
            meta: {
                breadcrumb: 'Home / Process Definition'
            }
        },
        {
            path: '/businessobjectdesigner',
            name: 'businessobject',
            component: Businessobjectdesigner,
            meta: {
                breadcrumb: 'Home / Business Object'
            }
        },
        {
            path: '/workflowide',
            name: 'workflowide',
            component: WorkflowIDE,
            meta: {
                breadcrumb: 'Home / Workflows'
            }
        }, {
            path: '/Schedule',
            name: 'schedulejob',
            component: Schedule,
            meta: {
                breadcrumb: 'Home / Schedule Job'
            }
        },
        {
            path: '/ScheduleJobPlan',
            name: 'schedulejobplan',
            component: ScheduleJobPlan,
            meta: {
                breadcrumb: 'Home / Schedule Job Plan'
            }
        },
        {
            path: '/stepdetails',
            name: 'StepDetails',
            component: StepDetails,
            meta: {
                breadcrumb: 'Home / Stepdetails'
            }
        },
        {
            path: '/Register',
            name: 'Register',
            component: Register,
            meta: {
                breadcrumb: 'Home / Register'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                breadcrumb: 'Home / Login'
            }
        },
        {
            path: '/forgotpassword',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                breadcrumb: 'Home / ForgotPassword'
            }
        },
        {
            path: '/forgot',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                breadcrumb: 'Home / ResetPassword'
            }
        },
        {
            path: '/reset_and_login',
            name: 'ResetAndLogin',
            component: ResetPassword,
            meta: {
                breadcrumb: 'Home / ResetPassword'
            }
        },
        {
            path: '/changepassword',
            name: 'ChangePassword',
            component: ChangePassword,
            meta: {
                breadcrumb: 'Home / Change Password'
            }
        },
        {
            path: '/resetuserpassword',
            name: 'ResetUserPassword',
            component: ResetUserPassword,
            meta: {
                breadcrumb: 'Home / Reset User Password'
            }
        },
        {
            path: '/clientsettings',
            name: 'ClientSettings',
            component: ClientSettings,
            meta: {
                breadcrumb: 'Home / ClientSettings'
            }
        },
        {
            path: '/edc_instance',
            name: 'EdcInstance',
            component: EdcInstance,
            meta: {
                breadcrumb: 'Home / ClientSettings'
            }
        },
        {
            path: '/addbulkuser',
            name: 'AddBulkUser',
            component: AddBulkUser,
            meta: {
                breadcrumb: 'Home / AddBulkUser'
            }
        },
        {
            path: '/adduser',
            name: 'AddUser',
            component: AddUser,
            meta: {
                breadcrumb: 'Home / AddUser'
            }
        },
        {
            path: '/update_user',
            name: 'update_user',
            component: UpdateUser,
            meta: {
                breadcrumb: 'Home / update_user'
            }
        },

        {
            path: '/datasourcelist',
            name: 'datasourcelist',
            component: datasourcelist,
            meta: {
                breadcrumb: 'Home / Datasource List'
            }
        },
        {
            path: '/managedatasource',
            name: 'AddDatasource',
            component: ManageDataSource,
            meta: {
                breadcrumb: 'Home / Add Datasource'
            }
        },
        // {
        //     path: '/manageworkflowroute',
        //     name: 'ManageWorkflowRoute',
        //     component: ManageWorkflowRoute,
        //     meta: {
        //         breadcrumb: 'Home / AddWorkflowRoute'
        //     }
        // },
        // {
        //     path: '/editworkflowroute',
        //     name: 'editWorkflowRoute',
        //     component: ManageWorkflowRoute,
        //     meta: {
        //         breadcrumb: 'Home / Workflow Route List / Edit Workflow Route'
        //     }
        // },
        {
            path: '/manageworkflowmapping',
            name: 'ManageWorkflowMapping',
            component: ManageWorkflowMapping,
            meta: {
                breadcrumb: 'Home / AddWorkflowMapping'
            }
        },
        {
            path: '/:id/editworkflowmapping',
            name: 'editWorkflowMapping',
            component: ManageWorkflowMapping,
            meta: {
                breadcrumb: 'Home / Manage Workflow Mapping / Edit Workflow Mapping'
            }
        },
        {
            path: '/manageapprovaltask/',
            name: 'ApprovalTask',
            component: ManageApprovalTask,
            meta: {
                breadcrumb: 'Home / ApprovalTask'
            }
        },
        {
            path: '/managetaskforapprovallist',
            name: 'ManageTaskForApprovalList',
            component: ManageTaskForApprovalList,
            meta: {
                breadcrumb: 'Home / TaskForApprovalList'
            }
        },
        {
            path: '/manageapprovalrequestlist',
            name: 'manageApprovalRequestList',
            component: manageApprovalRequestList,
            meta: {
                breadcrumb: 'Home / ApprovalRequestList'
            }
        },
        {
            path: '/approvalhistorylist',
            name: 'approvalHistoryList',
            component: approvalHistoryList,
            meta: {
                breadcrumb: 'Home / ApprovalRequestHistory'
            }
        },
        {
            path: '/managedatasource/:datasourceid/:type',
            name: 'managedatasource',
            component: ManageDataSource,
            meta: {
                breadcrumb: 'Home / Data Sources / Edit Data Source'
            }
        },
        {
            path: '/environmentlist',
            name: 'Environmentlist',
            component: Environmentlist,
            meta: {
                breadcrumb: 'Home / Environmentlist'
            }
        },
        {
            path: '/manageenvironment',
            name: 'AddEnvironment',
            component: ManageEnvironment,
            meta: {
                breadcrumb: 'Home / Add Environment'
            }
        },
        {
            path: '/managesecurity',
            name: 'ManageSecurity',
            component: ManageSecurity,
            meta: {
                breadcrumb: 'Home / manage security'
            }
        },
        {
            path: '/securitylist',
            name: 'SecurityList',
            component: SecurityList,
            meta: {
                breadcrumb: 'Home / security list'
            }
        },
        {
            path: '/manageenvironment/:environment_id/:type',
            name: 'manageenvironment',
            component: ManageEnvironment,
            meta: {
                breadcrumb: 'Home / AddEnvironment'
            }
        },

        {
            path: '/workflowRouteList',
            name: 'WorkflowRouteList',
            component: WorkflowRouteList,
            meta: {
                breadcrumb: 'Home / WorkflowRouteList'
            }
        },
        {
            path: '/workflowmappingList',
            name: 'WorkflowMappingList',
            component: WorkflowMappingList,
            meta: {
                breadcrumb: 'Home / WorkflowMappingList'
            }
        },
        {
            path: '/Flowchart',
            name: 'editprocessdef',
            component: Flowchart,
            meta: {
                breadcrumb: 'Home / Process Definition'
            }
        },
        {
            path: '/processDefinitionList',
            name: 'processdefinitionlist',
            component: processdefinitionlist,
            meta: {
                breadcrumb: 'Home / Process Definition'
            }
        },
        {
            path: '/editJob',
            name: 'managejobNew',
            component: managejobNew,
            meta: {
                breadcrumb: 'Home / Process Definition / Execute Process'
            }
        },
        {
            path: '/createJob/:process_definition_id/:process_doc_name',
            name: 'createJob',
            component: jobUi,
            meta: {
                breadcrumb: 'Home / Process Definition / jobUi'
            }
        },

        {
            path: '/jobhistorylist',
            name: 'jobshistory',
            component: jobhistorylist,
            meta: {
                breadcrumb: 'Home/Process Definition/Jobs History'
            }
        },
        {
            path: '/stepsresult',
            name: 'showjobstepsresult',
            component: showjobstepsresult,
            meta: {
                breadcrumb: 'Home/Process Definition/Jobs History/Show Job Steps Result'
            }
        },
        {
            path: '/operationwise',
            name: 'operationwiseresult',
            component: operationwisedetails,
            meta: {
                breadcrumb: 'Home/Process Definition/Jobs History/Operationwise Result'
            }
        },

        {
            path: '/manageaccount',
            name: 'ManageAccount',
            component: ManageAccount,
            meta: {
                breadcrumb: 'Home / Account Preference'
            }
        },
        {
            path: '/currentjoblist',
            name: 'currentjobs',
            component: currentjoblist,
            meta: {
                breadcrumb: 'Home/Process Definition/Current Jobs'
            }
        },
        {
            path: '/:request_id/requesthistory',
            name: 'requesthistory',
            component: RequestHistory,
            meta: {
                breadcrumb: 'Home / Manage Approval Request List / Request History'
            }
        },
        {
            path: '/userlist',
            name: 'userlist',
            component: userlist,
            meta: {
                breadcrumb: 'Home / Users List'
            }
        },
        {
            path: '/deleteduserlist',
            name: 'deleteduserlist',
            component: deleteduserlist,
            meta: {
                breadcrumb: 'Home / Deleted Users List'
            }
        },
        {
            path: '/scheduledJobList',
            name: 'schedulelist',
            component: schedulelist,
            meta: {
                breadcrumb: 'Home / Scheduled Job List'
            }
        },
        {
            path: '/schedulejobhistory',
            name: 'schedulejobhistory',
            component: schedulejobhistory,
            meta: {
                breadcrumb: 'Home / Scheduled Job History'
            }
        },
        {
            path: '/businessobjectdesigner',
            name: 'editbusinessobject',
            component: Businessobjectdesigner,
            meta: {
                breadcrumb: 'Home / Business Object'
            }
        },
        {
            path: '/dateformat',
            name: 'dateformat',
            component: dateformat,
            meta: {
                breadcrumb: 'Home / Dateformat'
            }
        },
        {
            path: '/select_env_type_publish',
            name: 'SelectEnvTypePublish',
            component: SelectEnvTypePublish,
            meta: {
                breadcrumb: 'Home / select env business obj'
            }
        },
        {
            path: '/all_publish_templates/',
            name: 'AllPublishTemplate',
            component: AllPublishTemplate,
            meta: {
                breadcrumb: 'Home / select env business obj'
            }
        },
        {
            path: '/process_def_list_for_execution',
            name: 'ProcessDefListExecution',
            component: ProcessDefListExecution,
            meta: {
                breadcrumb: 'Home / Process Def List Execution'
            }
        },
        {
            path: '/',
            redirect: '/Login'
        },
        {
            path: "*",
            component: PageNotFound,
            meta: {
                breadcrumb: 'Home / pageNotFound'
            }
        },
        {
            path: '/policyList',
            name: 'ManagePolicyList',
            component: ManagePolicyList,
            meta: {
                breadcrumb: 'Home / Policy List'
            }
        },
        {
            path: '/publish_history',
            name: 'PublishHistory',
            component: PublishHistory,
            meta: {
                breadcrumb: 'Home / Publish History'
            }
        },
        {
            path: '/publish_request',
            name: 'PublishRequest',
            component: PublishRequest,
            meta: {
                breadcrumb: 'Home / Publish History'
            }
        },
        {
            path: '/request_status',
            name: 'requestStatus',
            component: requestStatus,
            meta: {
                breadcrumb: 'Home / Request Status'
            }
        },
        {
            path: '/graphtemplate',
            name: 'graphtemplate',
            component: GraphTemplate,
            meta: {
                breadcrumb: 'Home / Graph Template'
            }
        },
        {
            path: '/condition',
            name: 'condition',
            component: condition,
            meta: {
                breadcrumb: 'Home / condition'
            }
        },
        {
            path: '/allactivity',
            name: 'businessobjectactivitylist',
            component: BusinessObjectActivityList,
            meta: {
                breadcrumb: 'Home / datamanagement / activities'
            }
        },
        {
            path: '/summarizationactivity',
            name: 'summarizationactivitylist',
            component: SummarizationActivityList,
            meta: {
                breadcrumb: 'Home / datamanagement / Summarization'
            }
        },

        {
            path: '/archives',
            name: 'archives',
            component: Archives,
            meta: {
                breadcrumb: 'Home / datamanagement/ archives'
            }
        },
        {
            path: '/showacitvitydata',
            name: 'showacitvitydata',
            component: BusinessObjectEnquiry,
            meta: {
                breadcrumb: 'Home / datamanagement/ showacitvitydata'
            }
        },
        {
            path: '/resendemail',
            name: 'ResendEmail',
            component: ResendEmail,
            meta: {
                breadcrumb: 'Home / ResendEmail'
            }
        },
        {
            path: '/package_builder',
            name: 'package_builder',
            component: package_builder,
            meta: {
                breadcrumb: 'Home / Package builder'
            }
        },
        {
            path: '/select_object_for_package',
            name: 'select_object_for_package',
            component: select_object_for_package,
            meta: {
                breadcrumb: 'Home / Package builder / Select object'
            }
        },
        {
            path: '/publishapprovalstatus',
            name: 'publishapprovalstatus',
            component: PublishApprovalStatus,
            meta: {
                breadcrumb: 'Home / Publish Approval Status'
            }
        },
        {
            path: '/ldapsetting',
            name: 'ldapsetting',
            component: LDAPSetting,
            meta: {
                breadcrumb: 'Home / LDAP Setting'
            }
        },
        {
            path: '/smtpsetting',
            name: 'smtpsetting',
            component: SMTPSetting,
            meta: {
                breadcrumb: 'Home / SMTP Setting'
            }
        },
        {
            path: '/jobcalendar',
            name: 'jobcalendar',
            component: JobCalendar,
            meta: {
                breadcrumb: 'Home / Job Calendar'
            }
        },
        {
            path: '/calendar',
            name: 'Calendar',
            component: Calendar,
            meta: {
                breadcrumb: 'Home / Calendar'
            }
        },
        {
            path: '/add_calendar',
            name: 'AddCalendar',
            component: AddCalendar,
            meta: {
                breadcrumb: 'Home / Calendar'
            }
        },
        {
            path: '/update_calendar',
            name: 'update_calendar',
            component: UpdateCalendar,
            meta: {
                breadcrumb: 'Home / update_user'
            }
        },
        {
            path: '/managejobcalendar',
            // path: '/managejobcalendar/:jobevent_id',
            name: 'managejobcalendar',
            component: manageNewCalendar,
            meta: {
                breadcrumb: 'Home / Job Calendar / Manage Job Calendar'
            }
        },
        {
            path: '/showworktablesdata',
            // path: '/managejobcalendar/:jobevent_id',
            name: 'showworktablesdata',
            component: ShowWorktableData,
            meta: {
                breadcrumb: 'Home / Todays Job / Show Worktable Data'
            }
        },
        {
            path: '/boactivitygraph',
            name: 'boactivitygraph',
            component: BOActivityGraph,
            meta: {
                breadcrumb: 'Home / BO Activity Graph'
            }
        },
        {
            path: '/report_status',
            name: 'ReportDownloadStatus',
            component: ReportDownloadStatus,
            meta: {
                breadcrumb: 'Home / Report Status'
            }
        },
        {
            path: '/publish_request_details',
            name: 'PublishRequestDetails',
            component: PublishRequestDetails,
            meta: {
                breadcrumb: 'Home / Publish request'
            }
        },
        {
            path: '/publish_request_list',
            name: 'PublishRequestList',
            component: PublishRequestList,
            meta: {
                breadcrumb: 'Home / Publish request'
            }
        },
        {
            path: '/create_service_report',
            name: 'CreateServiceReport',
            component: CreateServiceReport,
            meta: {
                breadcrumb: 'Home / Create service report'
            }
        },
        {
            path: '/manage_notification_group',
            name: 'ManageNotificationGroup',
            component: ManageNotificationGroup,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/get_all_notification_group',
            name: 'NotificationGroupList',
            component: NotificationGroupList,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/get_all_notification_group_rule',
            name: 'NotificationGroupRule',
            component: NotificationGroupRule,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/get_all_notification_group_mapping',
            name: 'NotificationGroupUserMapping',
            component: NotificationGroupUserMapping,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/migration_add_info',
            name: 'MigrationDBInfo',
            component: MigrationDBInfo,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/migrate_users',
            name: 'MigrateUsers',
            component: MigrateUsers,
            meta: {
                breadcrumb: 'Home / manage notification group'
            }
        },
        {
            path: '/download_agent',
            name: 'AgentDownload',
            component: AgentDownload,
            meta: {
                breadcrumb: 'Home / DownloadAgent'
            }
        },
        {
            path: '/agent_list',
            name: 'AgentList',
            component: AgentList,
            meta: {
                breadcrumb: 'Home / AgentList'
            }
        },
        {
            path: '/perform_migration',
            name: 'PerformMigration',
            component: PerformMigration,
            meta: {
                breadcrumb: 'Home / perform migration'
            }
        },
        {
            path: '/catalog_migration',
            name: 'CatalogMigration',
            component: CatalogMigration,
            meta: {
                breadcrumb: 'Home / catalog migration'
            }
        },
        
        {
            path: '/agent_queue_list',
            name: 'AgentQueueList',
            component: AgentQueueList,
            meta: {
                breadcrumb: 'Home / AgentQueueList'
            }
        },
        {
            path: '/compliancearchive',
            name: 'Compliance Archival',
            component: ComplianceArchive,
            meta: {
                breadcrumb: 'Home/Compliance Archival'
            }
        },
        {
            path: '/PackageList',
            name: 'PackageList',
            component: PackageList,
            meta: {
                breadcrumb: 'Home / PackageList'
            }
        },
        {
            path: '/PackageObject',
            name: 'PackageObject',
            component: PackageObject,
            meta: {
                breadcrumb: 'Home / PackageObject'
            }
        },
        {
            path: '/testtemplate',
            name: 'TestTemplate',
            component: TestTemplate,
            meta: {
                breadcrumb: 'Home / TestTemplate'
            }
        },
        {
            path: '/joblimitrule',
            name: 'Job Limit Rule',
            component: JobLimitRule,
            meta: {
                breadcrumb: 'Home / Job Limit Rule'
            }
        },
        {
            path: '/jobplanstatus',
            name: 'jobplanstatus',
            component: JobPlanStatus,
            meta: {
                breadcrumb: 'Home / Job Plan Status'
            }
        },
        {
            path:'/newjointjsdemo',
            name:'newjointjsdemo',
            component:newJointjsDemo,
            meta:{
                breadcrumb:"Home"/"New Jointjs Demo"
            }
        },
        {
            path: '/manage_job',
            name: 'manageShortCut',
            component: shortcutUrlRedirection,
            meta: {
                breadcrumb: 'Home / shortcut Url Redirection'
            }
        },
        {
            path: '/redirect_shortcut',
            name: 'manageShortCut',
            component: shortcutUrlRedirection,
            meta: {
                breadcrumb: 'Home / shortcut Url Redirection'
            }
        },
        {
            path:'/test_confirmation',
            name:'edcConfirmationTest',
            component:edcConfirmationTest,
            meta:{
                breadcrumb:'Home/edcConfirmationTest'
            }
        },
        {
            path: '/clear_catalog_data',
            name: 'clearCatalogData',
            component: ClearCatalogData,
            meta: {
                breadcrumb: 'Home / Clear Catalog'
            }
        },
        {
            path: '/catalogdeletionhistory',
            name: 'catalogDeletionHistory',
            component: CatalogDeletionHistory,
            meta: {
                breadcrumb: 'Home / Catalog Deletion History'
            }
        },
        {
            path: '/catalogdeletiondetails',
            name: 'catalogDeletionDetails',
            component: CatalogDeletionDetails,
            meta: {
                breadcrumb: 'Home / Catalog Deletion Details'
            }
        },
        {
            path: '/erpacceleratorlist',
            name: 'erpAcceleratorList',
            component: erpAcceleratorList,
            meta: {
                breadcrumb: 'Home / erpAcceleratorList'
            }
        },
        {
            path: '/manageErpAccelerator',
            name: 'manageErpAccelerator',
            component: manageErpAccelerator,
            meta: {
                breadcrumb: 'Home / manageErpAccelerator'
            }
        },
        {
            path: '/rest_apis',
            name: 'restAPIList',
            component: restAPIList,
            meta: {
                breadcrumb: 'Home / restAPIs'
            }
        },
        {
            path: '/rest_api_called_history',
            name: 'restAPICalledHistory',
            component: restAPICalledHistory,
            meta: {
                breadcrumb: 'Home / api_called_history'
            }
        },
        {
            path: '/manage_notification_group_table',
            name: 'ManageNotificationGroupTable',
            component: ManageNotificationGroupTable,
            meta: {
                breadcrumb: 'Home / manage notification group for table'
            }
        },
        {
            path: '/media_object_list',
            name: 'MediaObjectList',
            component: MediaObjectList,
            meta: {
                breadcrumb: 'Home / media object list'
            }
        },
    ]
})
